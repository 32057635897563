import React, {useState} from 'react';
import { useForm } from "react-hook-form";
//API
import MasterDataService from '../../../../services/masterdata';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function AddDiagnosisModal(props){
    const { register, handleSubmit,reset, formState: { errors } } = useForm();
    const msds = new MasterDataService();
    const[dataLoading,setDataLoading] = useState(false);
    const [errmsg, setErrorMsg] = useState(false);
    const diagupnotify = () => toast.success("Diagnosis created successfully!");
    const diagupnotifyErr = () => toast.error("Diagnosis couldn't be created!");

    const onSubmit = (data) => {
        setDataLoading(true);
        let reqData =  {
            "mddiagnosisid":0,
            "diagnosis_name":data.diagnosisName.trim(),
            "diagnosis_hint":data.hint
            }

        msds.createDiagnosis(reqData)
          .then((response) => {
            if (response.data) {
                if (response.data.status === 'success') {
                    setDataLoading(false);
                    props.data.getDiagnosisData();
                    msgDisplay(true);
                    clearForm();
                    diagupnotify();
                    hideModal('closeDiagModal');
              }
            }
          })
          .catch(function (error) {
            console.log(error.response.data);
            setErrorMsg(true);
            setDataLoading(false);
          })
      }
      function hideModal(target) {
        document.getElementById(target).click();
        reset();
      };
      const msgDisplay = (val) => {
        props.data.messageCallback(val);
        
      }   
      function clearForm(){
          reset();
          setDataLoading(false);
      } 
    return(
        <div className="modal" id="createDiagnosis" data-bs-backdrop="false"
                tabIndex="-1" >
                <div className="modal-dialog shadow-lg">
                    <div className="modal-content">

                        <div className="modal-header">
                            <h4 className="modal-title">Create Diagnosis</h4>
                            <button type="button" className="btn-close"
                            onClick={()=>clearForm()} data-bs-dismiss="modal"></button>
                        </div>

                        <form onSubmit={handleSubmit(onSubmit)} >
                        <div className="modal-body p-4">
                        {errmsg ? <div className="err-msg">Diagnosis name already exist</div> : '' }
                            <div className='row text-start'>
                                <div className="mb-3">
                                    <label htmlFor="diagnosisName" className="form-label">Diagnosis Name <span className='required-field'>*</span></label>
                                    <input type="text"  className={`form-control p-2 ${errors.diagnosisName ? "is-invalid" : ""}`} {...register('diagnosisName', { required: true },{validate: (value) => { return !!value.trim()}})}
                                    maxLength="100"/>
                                    {(errors.diagnosisName && errors.diagnosisName.type === 'required') ? <div className="invalid-feedback">This field is required</div> : ''}
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="hint" className="form-label">Hint</label>
                                    <input type="text" className={`form-control p-2 ${errors.hint ? "is-invalid" : ""}`} {...register('hint', { required: false },{validate: (value) => { return !!value.trim()}})} />
                                </div>
                            </div>
                        </div>


                        <div className="modal-footer ">
                            <button type="button"
                            onClick={()=>clearForm()} id="closeDiagModal" className="btn btn-cancel text-white px-2 " data-bs-dismiss="modal">Cancel</button>
                            <button type="submit" disabled={dataLoading?true:false} className="btn btn-save text-white px-2">Create </button>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
    );
}

export default AddDiagnosisModal;