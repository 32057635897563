import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Chart from 'react-apexcharts';
import { useLocation } from 'react-router-dom';
import AuthService from '../../services/auth';

function AccountChart() {
  const location = useLocation();
  const history = useHistory();
  const [newrequest, setRequest] = useState(['']);
  const [newrequestpipe, setRequestpipe] = useState(['']);
  const [newrequestDate, setRequestDate] = useState(['']);
  const ls = new AuthService();
  const [isMounted, setIsMounted] = useState(true);

  function fetchGenderData() {
    let reqData = {
      AccountID: location.state.rowId,
      userid: localStorage.getItem('userid')
    }
    ls.dashboard(reqData)
      .then((response) => {
        if (isMounted) {
          if (response.data.status == 'success') {
            let grpData = response.data.sr_graph;
            const graphDates = [];
            const reqInits = [];
            const reqProgs = [];
            const reqRels = [];
            if (grpData.dates.length != 0 && grpData.dates.length < 7) {
              // for(let i=grpData.dates.length;i<=grpData.dates.length;i--){
              // setRequestDate(grpData.dates);
              // }
              setRequestDate(grpData.dates);
              // for(let i=grpData.reqinits.length;i>=grpData.reqinits.length;i--){
              setRequest(grpData.reqinits);
              // }
              // for(let i=grpData.reqprogs.length;i>=grpData.reqprogs.length;i--){
              setRequestpipe(grpData.reqprogs);
              //  }
            }
            else if (grpData.dates.length > 0 && grpData.reqinits.length > 0 && grpData.reqprogs.length > 0) {

              for (let i = grpData.dates.length - 1; i >= grpData.dates.length - 7; i--) {
                graphDates.push(grpData.dates[i]);
                // setRequestDate(grpData.dates)
              }
              for (let i = grpData.reqinits.length - 1; i >= grpData.reqinits.length - 7; i--) {
                reqInits.push(grpData.reqinits[i]);
                // setRequest(grpData.reqinits);
              }
              for (let i = grpData.reqprogs.length - 1; i >= grpData.reqprogs.length - 7; i--) {
                reqProgs.push(grpData.reqprogs[i]);
                // setRequestpipe(grpData.reqprogs);
              }
              setRequest(reqInits);
              setRequestpipe(reqProgs);
              setRequestDate(graphDates);
            } else {
              // return null;
              setRequest([]);
              setRequestpipe([]);
              setRequestDate([]);
            }
            //  setRequest(reqInits);
            //  setRequestpipe(reqProgs);
            //  setRequestDate(graphDates);
            // setRequest(grpData.sr_graph.reqinits);
            // setRequestpipe(grpData.sr_graph.reqprog);
            // setRequestDate(grpData.sr_graph.dates);
          }
        }
      })
      .catch(function (error) {
        // if(error.response.status === 403){
        //   history.push('/login');
        // }
      })
  }

  useEffect(() => {
    fetchGenderData();
    return () => { setIsMounted(false); }
  }, []);

  const requestAccSeries = [{
    name: 'New Request',
    data: newrequest
  }, {
    name: 'Request in pipeline',
    data: newrequestpipe
  }];

  const requestAccOptions = {
    chart: {
      type: 'bar',
      height: '100%',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false
      }
    },
    title: {
      text: "New Request vs Released Result",
      align: 'left',

      style: {
        fontWeight: 'bold',
        FontFamily: 'Lato',
      }
    },
    legend: {
      show: true,
      position: 'top',
      align: 'right',
      horizontalAlign: 'left',
      fontFamily: 'Lato',
      markers: {
        width: 15,
        height: 3,
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded'
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      categories: newrequestDate,
    },
    colors: ["#24a8fa", "#ffa902"],
    fill: {
      opacity: 1
    },
  }
  return (
    <>
      <Chart type='bar' options={requestAccOptions} series={requestAccSeries} width='100%' height='100%' />
    </>
  );
}

export default AccountChart;