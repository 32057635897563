
import { ax } from '../services/base';
import config from '../assets/config';

export default class RecordingAnalysisService {

    //checkboxes for diagnosis, symptoms, medication, supplement, lifestyle
    GetMultiCheckBoxes(payload) {
        return ax.post(`${config.apiURL}service_request/getassociatecommon`, payload);
    }
    //save call for associate checkboxes
    SaveMultiCheckBoxes(payload) {
        return ax.post(`${config.apiURL}service_request/saveassociatecommon`, payload);
    }
    //diagnosis tab
    SaveDiagnosis(payload) {
        return ax.post(`${config.apiURL}service_request/add_associate_mddiagnosisTherapies`, payload);
    }
    getDiagnosis(reqId) {
        return ax.get(`${config.apiURL}service_request/getmddiagnosisTherapiesall/${reqId}`);
    }
    //symptoms tab
    GetSymptoms(reqId) {
        return ax.get(`${config.apiURL}service_request/getmdsymptomsTherapiesall/${reqId}`);
    }
    SaveSymptoms(payload) {
        return ax.post(`${config.apiURL}service_request/add_associate_mdsymptomsTherapies`, payload);
    }
    //medication tab
    GetAssociateMedication(reqId) {
        return ax.get(`${config.apiURL}service_request/getmdmedicTherapiesall/${reqId}`);
    }
    SaveAssociateMedication(payload) {
        return ax.post(`${config.apiURL}service_request/add_associate_mdmedicTherapies`, payload);
    }
    //supplement tab
    GetAssociateSupplement(reqId) {
        return ax.get(`${config.apiURL}service_request/getmdsupplementTherapiesall/${reqId}`);
    }
    SaveAssociateSupplement(payload) {
        return ax.post(`${config.apiURL}service_request/add_associate_mdsupplementation`, payload);
    }
    //lifestyle tab
    GetLifestyleTab(reqId) {
        return ax.get(`${config.apiURL}service_request/getmdlifestyleTherapiesall/${reqId}`);
    }
    getLifestyle() {
        return ax.post(`${config.apiURL}patient/lifestyle_mdata/`)
    }
    getLifeStyleWithCategory(payload) {
        return ax.post(`${config.apiURL}patient/lifestyle_mdata/`, payload);
    }
    SaveLifeStyleTab(payload) {
        return ax.post(`${config.apiURL}service_request/add_associate_mdlifestyle`, payload);
    }
    //preview template
    DisplayTemplatePreview(payload) {
        return ax.post(`${config.apiURL}service_request/gettemplate`, payload);
    }
    //Interpretation Marker
    GetInterpretMarket(payload) {
        return ax.post(`${config.apiURL}service_request/getinterpretationmakers`, payload);
    }
    //Add interpretation Marker
    // AddInterpretMarker(payload){
    //     return ax.post(`${config.apiURL}service_request/addinterpretationmakers`,payload);
    // }
    //Save Interpretation marker data
    SaveInterpretMarketData(payload) {
        return ax.post(`${config.apiURL}service_request/saveinterpretationmarkers`, payload);
    }
    //Existing Interpretation
    GetExistingInterpretation(payload) {
        return ax.post(`${config.apiURL}service_request/getnonexitmarkers`, payload);
    }
    //Save existing Interpretation
    SaveExistingInterpretation(payload) {
        return ax.post(`${config.apiURL}service_request/addinterpretationmarkers`, payload);
    }

    //Export excel preview
    ExportExcelPreview(reqId) {
        return ax.get(`${config.apiURL}service_request/reqxl-export-Preview/${reqId}`);
    }
    //Export template
    ExportTemplateHtml(payload) {
        return ax.post(`${config.apiURL}service_request/gettemplatehtml`, payload);
    }
    //automation
    AutomateInterpretation(payload) {
        return ax.post(`${config.apiURL}automate/serviceautointerpretation`, payload);
    }
    UpdateAutomateInterpretation(payload) {
        return ax.post(`${config.apiURL}automate/updateserviceautointerpretation`, payload);
    }
    //summary of findings
    saveSummaryofFindings(payload) {
        return ax.post(`${config.apiURL}service_request/add_sum_of_finding`, payload);
    }
    getSummaryofFindings(reqID) {
        return ax.get(`${config.apiURL}service_request/get_sum_of_finding/${reqID}`);
    }
    //neurofeedback conditions
    saveNeurofeedbackAll(payload) {
        return ax.post(`${config.apiURL}service_request/add_neurofeedback`, payload);
    }
    getNeurofeedbackAll(reqID) {
        return ax.get(`${config.apiURL}service_request/get_neurofeedback/${reqID}`);
    }
}