import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import "../../../../assets/styles/pipelinerequest/tabcontent/diagnosistab.scss";
import RecordingAnalysisService from "../../../../services/RecordingAnalysis";
import { BsPlusLg } from "react-icons/bs";
import CreatableSelect from "react-select/creatable";
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';

export default function PossibleSupplementation() {
  const location = useLocation();
  const [active, setActive] = useState();
  const [val, setVal] = useState();
  const [isactive, setIsActive] = useState();
  const [dId, setDId] = useState([]);
  const [checkBoxes, setCheckBoxes] = useState([]);
  const [selectedBox, setSelectedBox] = useState([]);
  const [isMounted, setIsMounted] = useState(true);
  const [ndID, setNdID] = useState([]);
  const [unSelectBx, setUnSelectBx] = useState([]);
  const [hasConsentDoc, setHasConsentDoc] = useState(false);
  const [selectedSupplement, setSelectedSupplement] = useState("");
  const [unsupp, setUnsupp] = useState([]);
  const ra = new RecordingAnalysisService();
  //toaster
  const [msg, setMsg] = useState("");
  const notifySupp=()=>toast.success("Supplement updated successfully!")
  const notifySuppErr = () => toast.error("Supplement couldn't be updated!");
  const notifySuppRmv=()=>toast.success("Supplement deleted successfully!")
  const notifySuppRmvErr = () => toast.error("Supplement couldn't be deleted!");
  //unselected checkbox
  const options = checkBoxes.map((checkBoxes) => {
    return {
      value: checkBoxes.supplement_name,
      label: checkBoxes.supplement_name,
      id: checkBoxes.id,
      checked: checkBoxes.ischoices,
    };
  });
  const supplementOption = unsupp.map((data, i) => {
    return {
      label: data.supplement_name,
      value: data.supplement_name,
      id: data.id,
    };
  });
  function getSupplementation() {
    let reqData = {
      service_request_id: location.state.reqId,
    };
    ra.GetMultiCheckBoxes(reqData).then((response) => {
      if (isMounted) {
        if (response.data.supplement !== []) {
          let supp = [];
          let unsupp = [];

          for (let i = 0; i < response.data.supplement.length; i++) {
            if (response.data.supplement[i].ischoices === true) {
              dId.push(response.data.supplement[i].id);
              supp.push(response.data.supplement[i]);
            } else {
              ndID.push(response.data.supplement[i].id);
              unsupp.push(response.data.supplement[i]);
            }
          }
          setCheckBoxes(supp);
          setUnsupp(unsupp);
        }
      }
    });
  }

  function saveSupplement() {
    let reqData = {
      service_request: location.state.reqId,
      supplementation_tps: dId.toString(),
      unsupplementation_tps: ndID.toString(),
    };
    ra.SaveAssociateSupplement(reqData).then((response) => {
      if (response.data.status === "success") {
        getSupplementation();
        handleCancel();
        notifySupp();
        // setMsg("Supplement updated successfully!");
        // setTimeout(() => {
        //   setMsg("");
        // }, 5000);
      }
    })
    .catch(function(error){
      notifySuppErr();
    })
  }
  const handleChange = (i) => (e) => {
    let checked = e.target.checked;
    checkBoxes[i].ischoices = !checkBoxes[i].ischoices;
    if (checked === true) {
      unSelectBx.push(checkBoxes[i]);
      dId.push(checkBoxes[i].id);
      setNdID(ndID.filter((element) => element !== checkBoxes[i].id));
    } else if (checked === false) {
      ndID.push(checkBoxes[i].id);
      setUnSelectBx(
        unSelectBx.filter((element) => element.id !== checkBoxes[i].id)
      );
      setDId(dId.filter((element) => element !== checkBoxes[i].id));
    }
  };

  useEffect(() => {
    getSupplementation();
    return () => {
      setIsMounted(false);
    };
  }, []);
  const removeSupplement = (id) => {
    let reqData = {
      service_request: location.state.reqId,
      supplementation_tps: "",
      unsupplementation_tps: id.toString(),
    };
    ra.SaveAssociateSupplement(reqData).then((response) => {
      if (response.data.status === "success") {
        getSupplementation();
        notifySuppRmv();
        // setMsg("Supplement deleted successfully!");
        // setTimeout(() => {
        //   setMsg("");
        // }, 5000);
      }
    })
    .catch(function(error){
      notifySuppRmvErr();
    })
  };
  const handleSupplementChange = (selectedSupplement) => {
    const values = [...selectedSupplement].map((o) => o.id);
    setSelectedSupplement(selectedSupplement);
    setDId(values);
    setNdID((current) =>
      current.filter((diag) => {
        if (diag.id !== values) return diag.id;
      })
    );
  };
  const handleCancel = ()=>{
    setSelectedSupplement(null);
  }
  return (
    <div className="diagnosis-tab mt-3">
      {/* <ToastContainer/> */}
      <div className="row g-0 mb-2">
        {msg ? (
          <div className="row align-items-center justify-content-center mb-2">
            <div className=" success-msg  " style={{ width: "60%" }}>
              {msg}
            </div>
          </div>
        ) : null}
        <h6 className="text-start col">Possible Appropriate Supplementation</h6>
        <div
          className="ms-auto text-end col add-icon"
          data-bs-toggle="modal"
          data-bs-target="#addSupplement"
        >
          {/* <button type="button" className="btn btn-primary" onClick={() => saveSupplement()}>Save</button> */}
          <BsPlusLg />
        </div>
      </div>
      <Container className="p-0">
        <div className="checkbox-container mt-3 ps-1">
          <p className="text-start">Based upon our internal studies and available published data, the following nutritional supplements may be indicated.
             Please consult with your health care provider for appropriateness and possible interactions with current treatments.</p>
          <div className="row p-2" style={{ height: "auto" }}>
            {checkBoxes.map((tag, i) => {
              return (
                <ul key={i} className="mx-5">
                  <li className="border-0 text-start medic-list w-75">{tag.supplement_name}</li>
                </ul>
                // <div
                //   key={i}
                //   className="tag col-auto mx-2 my-1 ps-1 py-0"
                //   style={{
                //     whiteSpace: "no-wrap",
                //     }}
                // >
                //   {tag.supplement_name}
                //   <div
                //     className="ms-auto col-1 p-1 remove-tag"
                //     key={tag.id}
                //     onClick={() => removeSupplement(tag.id)}
                //   >
                //     <span>x</span>
                //   </div>
                // </div>
              );
            })}
          </div>
        </div>
      </Container>
      <div className="modal" id="addSupplement" tabIndex="-1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Add Supplementation</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body p-4 text-start">
              <Select
                isMulti
                value={selectedSupplement}
                onChange={handleSupplementChange}
                options={supplementOption}
              />
            </div>
            <div className="modal-footer ">
              <button
                type="button"
                id="closeDelDiag"
                className="btn btn-save text-white px-3"
                onClick={() => saveSupplement()}
                data-bs-dismiss="modal"
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-cancel text-white px-3"
                data-bs-dismiss="modal"
                onClick={()=>handleCancel()}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
