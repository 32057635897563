import { ax } from '../services/base';
import config from '../assets/config';

export default class PatientAccountService {
    getAllPatientDetails(payload){
        return ax.post(`${config.apiURL}patient/getPatient`,payload);
    }
    CreatePatient(payload){
        return ax.post(`${config.apiURL}patient/addpatient`,payload);
    }
    DeletePatient(patientId){
        return ax.delete(`${config.apiURL}patient/deletepatient/${patientId}`);
    }
    PatientMedicationDetails(patientId){
        return ax.get(`${config.apiURL}patient/listpatientmedication/${patientId}`);
    }
    GetPatientDetail(patientId){
        return ax.get(`${config.apiURL}patient/getPatient/${patientId}`);
    }
    AddMedication(payload){
        return ax.post(`${config.apiURL}patient/add-medication`,payload);
    }
    GetMedication(medicationId){
        return ax.get(`${config.apiURL}patient/getmedication/${medicationId}`);
    }
    DeletePatientMedication(medicationId){
        return ax.delete(`${config.apiURL}patient/delMedication/${medicationId}`);
    }
    GetAllPatientTag(){
        return ax.get(`${config.apiURL}patient/pnttag-all`);
    }
    CreatePatientTag(payload){
        return ax.post(`${config.apiURL}patient/savePatientTags`,payload);
    }
    GetPatientTag(patientId){
        return ax.get(`${config.apiURL}patient/getPatientTag/${patientId}`);
    }
    RemovePatientTag(tagId){
        return ax.delete(`${config.apiURL}patient/remove-pnttag/${tagId}`);
    }
    GetAccountType(){
        return ax.get(`${config.apiURL}account/getAccountAll`);
    }
    ExportPatient(payload){
        return ax.post(`${config.apiURL}patient/pntxl-export`,payload);
    }
    ConfirmDeletePatient(payload){
        return ax.post(`${config.apiURL}patient/deletepatient`,payload);
    }
}