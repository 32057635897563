export const tempData=[
    {
        "status": "success",
        "req_list_EC": {
            "id": 3,
            "rulename": "Alpha/beta ratio",
            "rfieldtype": "textbox",
            "docfieldtype": "EC",
            "rsheetname": "Ratios",
            "mrule": "I16",
            "status": "Active",
            "Alphabeta_ratio_EC": [
                -0.9731666449926581
            ],
            "amplitude_asymmetry_EC": 1,
            "Posterior_dominant_rhythm_EC": 2.3171105747414233,
            "Theta_beta_ratio_EC": [
                0.6193265262177262
            ],
            "Theta_beta_ratio_EO": [
                0.6193265262177262
            ]
        },
        "req_list_EO": {
            "id": 3,
            "rulename": "Alpha/beta ratio",
            "rfieldtype": "textbox",
            "docfieldtype": "EC",
            "rsheetname": "Ratios",
            "mrule": "I16",
            "status": "Active",
            "Alpha/beta ratio_EC": [
                -0.9731666449926581
            ],
            "amplitude asymmetry_EC": 1,
            "Posterior dominant rhythm_EC": 2.3171105747414233,
            "Theta/beta ratio_EC": [
                0.6193265262177262
            ],
            "Theta/beta ratio_EO": [
                0.6193265262177262
            ]
        }
    }
];