import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import '../../assets/styles/pipelinerequest/viewrequest.scss';
import { useRouteMatch } from 'react-router-dom';
import PipelineRequestService from '../../services/PipelineRequest';
import { ClipLoader, ScaleLoader } from "react-spinners";
import { format } from 'date-fns';
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";
import RequestService from '../../services/SeriveRequest';
import { Col } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import DatePicker from "react-datepicker";
import { BiErrorCircle } from 'react-icons/bi';
import { toast } from "react-toastify";
import htmlToFormattedText from "html-to-formatted-text";
import LoadingIcon from '../../assets/img/sample-icons/sandbox.gif';

function DateFormatter(cell, row) {
  if (row) {
    return (
      <span>
        {format(new Date(cell), 'MM/dd/yyyy')}
      </span>
    );
  }
}
function stateFormatter(cell) {
  if (cell === "Request Init") {
    return (
      <p style={{ color: 'rgb(237 5 5)', marginBottom: '0px' }}>
        {cell}
      </p>
    );
  }

  return (
    <p style={{ color: "#ffb101" }}>
      {cell}
    </p>
  );
}



const NoDataIndication = () => (
  <div className='spinner d-flex justify-content-center text-center h-100'>
  <img src={LoadingIcon} alt="loading-icon" width="200" height="200"/>
  {/* <ScaleLoader size={60} color="#79a3bb" style={{ marginTop: '50px' }} /> */}
</div>
);

const RemotePagination = ({ data, page, sizePerPage, onTableChange, noDataIndication, totalSize, rowEvents, columns }) => (
  <div>
    <BootstrapTable
      remote={true}
      keyField="id"
      data={data}
      columns={columns}
      noDataIndication={noDataIndication}
      pagination={paginationFactory({ page, sizePerPage, totalSize })}
      onTableChange={onTableChange}
      rowEvents={rowEvents}

    />

  </div>
);

function ViewRequest() {

  const { url } = useRouteMatch();
  const [pipelineData, setPipelineData] = useState([]);
  const history = useHistory();
  const [isMounted, setIsMounted] = useState(true);
  const [startDate, setStartDate] = useState();
  const [sDate, setSdate] = useState('');
  const [eDate, setEdate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [docLoaded, setDocLoaded] = useState(false);
  //remote table
  const [data, setData] = useState([]);
  const [inputVal, setInputVal] = useState("");
  const [totalDataSize, setTotalDataSize] = useState(0);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [sortName, setSortName] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [dataLoading, setDataLoading] = useState(true);
  const [exportData, setExportData] = useState([]);
  const [dat, setDat] = useState(false);
  const [date1, setDate1] = useState(false);
  const [date2, setDate2] = useState(false);
  const [isdate, setIsdate] = useState(false);
  const [sameDate,setSameDate] = useState(false);
  const [loading, setLoading] = useState(false);
  const exportnotify = () =>
    toast.success("Excel downloaded successfully!");
  //API CALL
  const prq = new PipelineRequestService();
  const reqSrv = new RequestService();
  const userRole = localStorage.getItem('userrole');

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      if (userRole !== "staff") {
        localStorage.setItem('reqId', row.id);
        history.push({
          pathname: `${url}/:pipeline-request`,
          state: {
            reqId: row.id,
            patientid:row.patientid
          }
        });
      }
    },
  };
  const sortOrderTable = (order, column) => {
    if (!order) return (
      <><span>&nbsp;&nbsp;<FaSort color="#858585" /></span>
      </>);
    else if (order === 'asc') return (<><span>&nbsp;&nbsp;<font color="red"><FaSortUp color="#000" /></font></span>
    </>);
    else if (order === 'desc') return (<><span>&nbsp;&nbsp;<font color="red"><FaSortDown color="#000" /></font></span>
    </>);
    return null;
  }

  const columns = [
    {
      dataField: 'sl.no', text: 'S.No', formatter: (cell, row, rowIndex, formatExtraData) => {
        if (page === 1) { return rowIndex + 1; }
        else { return ((page - 1) * sizePerPage) + (rowIndex + 1); }
      },
    },
    { dataField: 'RequestNumber', text: 'Request No', sort: true, sortCaret: sortOrderTable },
    { dataField: 'patient_name', text: 'Patient Name', sort: true, sortCaret: sortOrderTable },
    { dataField: 'dob', text: 'Date of Birth' },
    { dataField: 'gender', text: 'Gender' },
    { dataField: 'account_name', text: 'Account Name', sort: true, sortCaret: sortOrderTable },
    { dataField: 'created_on', text: 'Submitted date', sort: true, formatter: DateFormatter, sortCaret: sortOrderTable },
    { dataField: 'status', text: 'Status', formatter: stateFormatter }
  ];
  const handleDateChange = (date) => {
    setStartDate(format(new Date(date), 'MM/dd/yyyy'));
    setSdate(format(new Date(date), "yyyy-MM-dd"));
    setDate1(true);
  };

  const handleEndDateChange = (date) => {
    setEndDate(format(new Date(date), 'MM/dd/yyyy'));
    setEdate(format(new Date(date), "yyyy-MM-dd"));
    setDate2(true);

  };
  const handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    const currentIndex = (page - 1) * sizePerPage;
    let order;
    //sort
    if (sortOrder === 'asc') {
      order = data.sort((a, b) => {
        if (a[sortField] > b[sortField]) {
          return 1;
        } else if (b[sortField] > a[sortField]) {
          return -1;
        }
        return 0;
      });
    } else {
      order = data.sort((a, b) => {
        if (a[sortField] > b[sortField]) {
          return -1;
        } else if (b[sortField] > a[sortField]) {
          return 1;
        }
        return 0;
      });
    }
    setSortName(sortField);
    setSortOrder(order);
    //REMOTE PAGINATION
    setPage(page);
    setData(data.slice(currentIndex, currentIndex + sizePerPage));
    setSizePerPage(sizePerPage);
    fetchPipelineRequest(page, sizePerPage, inputVal, sortField, sortOrder);
  }

  async function fetchPipelineRequest(page, sizePerPage, inputVal, sortName, sortOrder) {
    let reqData = {
      DataFinder: {
        "pagesize": sizePerPage,
        "currentpage": page,
        "sortbycolumn": sortName || "",
        "sortby": sortOrder || "",
        "searchdata": inputVal || ""
      }
    }
    prq.GetPipelineRequest(reqData).then((response) => {
      if (isMounted) {
        if (response.data) {
          if (response.data.ServiceRequestDetail !== [] && response.data.DataFinder.totalrecords !== 0) {
            setDataLoading(true);
            setPipelineData(response.data.ServiceRequestDetail);
            setTotalDataSize(response.data.DataFinder.totalrecords);
            setData(response.data.ServiceRequestDetail.slice(0, sizePerPage));
          } else {
            setDataLoading(false);
            setData([]);
          }

        }
      }
    })
      .catch(function (error) {
        if (error.response.status === 403) {
          history.push('/login');
        }
      })
  }
  useEffect(() => {
    fetchPipelineRequest(page, sizePerPage, inputVal);
    return () => { setIsMounted(false) }
  }, []);

  const handleSearch = (e) => {
    if (e.key === "Enter") {
      setInputVal(e.target.value);
      fetchPipelineRequest(page, sizePerPage, e.target.value);
    }
  }
  const resetSearch = (e) => {
    if (e.key === "Backspace" && !e.target.value) {
      setInputVal("");
      fetchPipelineRequest(page, sizePerPage, e.target.value);
    }
  }
function exportService(reqData){
  reqSrv.ExportServiceData(reqData).then((response) => {
    if (response.data) {
        if (response.data.data !== [] || response.data.data !== ""){
        setDocLoaded(true);
        setDat(false);  
            setSameDate(false);
        let new_list = response.data.data.map((obj) => {
          return ({
            RequestNumber: obj.RequestNumber,
            PatientName: obj.patient_name,
            DOB: format(new Date(obj.dob), 'dd-MMM'),
            YearBorn: format(new Date(obj.dob), 'yyyy'),
            Gender: obj.gender,
            AccountName: obj.account_name,
            Dx: obj.diagnosis_name,
            Sx: obj.symptoms_name,
            MedicationPast: obj.medication_past,
            MedicationsCurrent: obj.medications_current,
            Tags: obj.TagName,
            ClinicalHistory: htmlToFormattedText(obj.clinical_history),

          })
        })
        setExportData(new_list);
      } else {
        setDocLoaded(false);
        exportnotify();
        hideModal('closeexp');
        
    }
  }
  }).catch(function (error) {
    setDocLoaded(false);
  })
}
  async function exportPatient() {
    setDat(true);
    setIsdate(false);
    setSameDate(false);
    // setLoading()
    let reqData = {
      "list": "all",
      "start_on": sDate,
      "end_on": eDate
    }
     if (date1 === true && date2 === false) {
      return setIsdate(true);
    } 
    else if(date1===true&& date2===true){
      if(sDate == eDate){
     return setSameDate(true);
      }
      else {
        exportService(reqData);
      }
    }
    else {
      exportService(reqData);
    }
  }

  // var name = exportData;
  // const reader = new FileReader();
  // reader.onload = (evt) => { // evt = on_file_select event
  //     /* Parse data */
  //     const bstr = evt.target.result;
  //     const wb = XLSX.read(bstr, {type:'binary'});
  //     /* Get first worksheet */
  //     const wsname = wb.SheetNames[0];
  //     const ws = wb.Sheets[wsname];
  //     /* Convert array of arrays */
  //     const data = XLSX.utils.sheet_to_csv(ws, {header:1});
  //     /* Update state */
  //     console.log("Data>>>"+data);
  // };
  // reader.readAsBinaryString(exportData);
  const downloadExcel = () => {
    const workbook = XLSX.utils.book_new();
    var worksheet = XLSX.utils.json_to_sheet(exportData, { cellStyles: true });
    // worksheet.Cells.Columns[0].ApplyStyle(st, new StyleFlag() { WrapText = true });
    // worksheet.getCell('D1').alignment = { wrapText: true };
    // workbook.s = { font: { bold: true } };
 
    var wscols = [
      { wch: 10 },
      { wch: 20 }, // "characters"
      { wch: 10 },
      { wch: 10 },
      { wch: 20 },
      { wch: 40 },
      { wch: 40 },
      { wch: 40 },
      { wch: 40 },
      { wch: 40 },
      { wch: 50 },
      { wch: 50 },
      { wch: 50 },
      { hidden: true } // hide column
    ];
    // cell.s = {
    //   alignment: {
    //     wrapText: '1', // any truthy value here
    //   },
    // };
    worksheet['!cols'] = wscols;
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1", true);
    // XLSX.read(workbook, { type: 'file' });
    XLSX.writeFile(workbook, "eegData.xlsx");
    hideModal('closeexp');
    handleCancelForm();
  };
  function hideModal(target) {
    document.getElementById(target).click();
  };
  const handleCancelForm = () => {
    setSdate("");
    setEdate("");
    setStartDate("");
    setEndDate("");
    setDocLoaded(false);
    setDat(false);
    setDate1(false);
    setDate2(false);
    setIsdate(false);
    setSameDate(false);
  }
  return (
    <div className='col view-request'>
      <div className='row g-0 ps-3 pt-3'>
        <div className='table-request my-2 me-3'>
          <div className='table-data me-4'>
            <div className='pe-3'>
              <div className='row mb-2'>
                <div className='col text-start ms-1'>
                  <h5>Service Requests in Pipeline</h5>
                </div>
                <div className='col text-end '>
                  <i className="bi bi-search ms-1 ps-2" id='search-icon'></i>
                  <input
                    type="text"
                    onKeyDown={(e) => handleSearch(e)}
                    onKeyUp={(e) => resetSearch(e)}
                    className='py-1 ps-3' placeholder="Search Request" />
                </div>
                <button className='btn btn-export col-auto ms-2 me-3 text-white py-0 px-2'
                  data-bs-toggle="modal" data-bs-target="#exportService">Export</button>
              </div>
           
              <RemotePagination
                data={data}
                page={page}
                columns={columns}
                rowEvents={rowEvents}
                sizePerPage={sizePerPage}
                totalSize={totalDataSize}
                noDataIndication={() => { return (dataLoading ? <NoDataIndication /> : "No data available") }}
                onTableChange={handleTableChange}
              />
            </div>
          </div>
        </div>
      </div>
      {/* EXPORT SERVICE REQUEST DATA MODAL */}
      <div className="modal" id="exportService" data-bs-backdrop="false"
        tabIndex="-1" >
        <div className="modal-dialog shadow-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Export Patients Summary</h4>
              <button type="submit" className="btn-close" data-bs-dismiss="modal" onClick={() => handleCancelForm()} ></button>
            </div>
            <div className="modal-body p-4 text-start">
              <div className='col-md-12 text-start'>
                <label className='form-label'>Select Request created date</label>
                <div className='row ps-0 pe-0 mb-3'>
                  <Col className="me-0">
                    <DatePicker
                      className='form-control col-auto'
                      dateFormat="MM/dd/yyyy"
                      autoComplete='off'
                      placeholderText="Start Date"
                      name="startDate"
                      maxDate={new Date()}
                      value={startDate}
                      onChange={handleDateChange}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    /></Col>
                  to
                  <Col>
                    <DatePicker
                      className='form-control col-auto'
                      dateFormat="MM/dd/yyyy"
                      placeholderText="End date"
                      name="endDate"
                      autoComplete='off'
                      minDate={new Date(startDate)}
                      maxDate={new Date()}
                      value={endDate}
                      onChange={handleEndDateChange}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    /></Col>
                </div>
                {isdate === true ? <div className='text-danger mt-3 mb-0'>
                  <BiErrorCircle className='me-2' />Please fill both the fields
                </div> : sameDate?<div className='text-danger mt-3 mb-0'>
                  <BiErrorCircle className='me-2' />End date should not be same as start date.
                </div>:""}
                {dat===true && isdate===false && sameDate==false ?
                  <div className='col-md-3 mx-auto align-items-center my-auto'>
                    <ClipLoader color="#000" size="16" className="mt-5 me-4" /><span className="mt-3 ms-2">Downloading...</span>
                  </div> : ""}
                  {/* {dat===true && sameDate===false  ?
                  <div className='col-md-3 mx-auto align-items-center my-auto'>
                    <ClipLoader color="#000" size="16" className="mt-4" />Downloading...
                  </div> : ""} */}
                {/* {date1===false && date2===true? date1===true && date2 === false?
                                <div className='text-danger mt-3 mb-0'>
                  <BiErrorCircle className='me-2'/>Please fill both the fields
                </div>: isdate?<div className='text-danger mt-3 mb-0'>
                  <BiErrorCircle className='me-2'/>Please fill both the fields
                </div>:<div className='text-danger mt-3 mb-0'>
                  <BiErrorCircle className='me-2'/>Please fill both the fields
                </div>
                :""} */}
              </div>
              <div>
              </div>
            </div>
            <div className="modal-footer ">
              <button type="button" id='closeexp' className="btn btn-cancel text-white px-3" data-bs-dismiss="modal" onClick={() => handleCancelForm()}>Cancel</button>

              {docLoaded ? <button type="button" className="btn btn-download text-white px-2" onClick={() => downloadExcel()}>Download</button> :
                <button type="submit" className="btn btn-save text-white px-4" onClick={() => exportPatient()} >Submit</button>
              }
              {/* {docLoaded? <button type="button" className="btn btn-download text-white px-2" onClick={() => downloadExcel()}>Download</button> :
               isdate?<button type="submit" className="btn btn-save text-white px-4" onClick={() => exportPatient()} >Submit</button>:dat?
               <button className="btn btn-loading text-white px-4" ><ClipLoader color="#fff" size="10" /></button>
               : <button type="submit" className="btn btn-save text-white px-4" disabled={dat?true:false} onClick={() => exportPatient()} >Submit</button> 
              } */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewRequest;