import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
//API
import MasterDataService from '../../../../services/masterdata';

function AddLifestyleModal(props) {

  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const msds = new MasterDataService();
  const [dataLoading, setDataLoading] = useState(false);
  const [errmsg, setErrorMsg] = useState(false);
  const lifestyleSuccess = () => toast.success("Lifestyle created successfully!");
    const lifestyleError = (errmsg) => toast.error(errmsg); 

  const onSubmit = (data) => {
    setDataLoading(true);
    let reqData = {
      "mdlifestyleid": 0,
      "lifestyle_name": data.lifestyleName.trim(),
      "lifestyle_hint": data.hint,
      "ls_category": data.lifestyleCategory
    }
    msds.createLifestyle(reqData)
      .then((response) => {
        if (response.data) {
          if (response.data.status === 'success') {
            setDataLoading(false);
            msgDisplay(true);
            hideModal('closeLyfModal');
            lifestyleSuccess();
            props.data.getLifestyleData();
          }
        }
      })
      .catch(function (error) {
        setErrorMsg(true);
        setDataLoading(false);
        lifestyleError("Lifestyle Name already exists");
      })
  }
  function hideModal(target) {
    document.getElementById(target).click();
    reset();
  };

  const msgDisplay = (val) => {
    props.data.messageCallback(val);
  }
  
  function clearForm() {
    reset();
  }
  return (
    <div className="modal" id="createLifestyle" data-bs-backdrop="false"
      tabIndex="-1" >
      <div className="modal-dialog shadow-lg">
        <div className="modal-content">

          <div className="modal-header">
            <h4 className="modal-title">Create Lifestyle</h4>
            <button type="button" className="btn-close"
              onClick={() => clearForm()} data-bs-dismiss="modal"></button>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} >
            <div className="modal-body p-4">
              {/* {errmsg ? <div className="err-msg">Lifestyle name already exist</div> : ''} */}
              <div className='row text-start'>
                <div className="mb-3">
                  <label htmlFor="lifestyleCategory" className="form-label">Lifestyle Category
                    <span className='required-field'>*</span></label>
                  <select className={`form-select p-2 ${errors.lifestyleCategory ? "is-invalid" : ""
                    }`}
                    {...register(
                      "lifestyleCategory",
                      { required: true })}>
                    <option value="">Select</option>
                    <option value="High Theta">High Theta</option>
                    <option value="High Beta">High Beta</option>
                    <option value="High Alpha">High Alpha</option>
                    <option value="High delta/Overwhelm">High delta/Overwhelm</option>
                  </select>

                  {(errors.lifestyleCategory && errors.lifestyleCategory.type === 'required') ? <div className="invalid-feedback">This field is required</div> : ''}
                </div>
                <div className="mb-3">
                  <label  className="form-label">Lifestyle Name <span className='required-field'>*</span></label>
                  <input type="text" maxLength="100" className={`form-control p-2 ${errors.lifestyleName ? "is-invalid" : ""}`} {...register('lifestyleName', { required: true }, { validate: (value) => { return !!value.trim() } })} />
                  {(errors.lifestyleName && errors.lifestyleName.type === 'required') ? <div className="invalid-feedback">This field is required</div> : ''}
                </div>
                <div className="mb-3">
                  <label htmlFor="hint" className="form-label">Hint</label>
                  <input type="text" className={`form-control p-2 ${errors.hint ? "is-invalid" : ""}`} {...register('hint', { required: false }, { validate: (value) => { return !!value.trim() } })} />
                </div>
              </div>
            </div>

            <div className="modal-footer ">
              <button type="button"
                onClick={() => clearForm()} id="closeLyfModal" className="btn btn-cancel text-white px-2" data-bs-dismiss="modal">Cancel</button>
              <button type="submit" disabled={dataLoading ? true : false} className="btn btn-save text-white px-2">Create </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddLifestyleModal;