import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import '../../assets/styles/pipelinerequest/tabcontent/associatedocument.scss';
import edfImage from '../../assets/img/edfimage.png';
import PipelineRequestService from '../../services/PipelineRequest';
import { ClipLoader } from 'react-spinners';
import jobManagerService from '../../services/jobManagerService';
import { format } from 'date-fns';

export default function JobEdfProcess() {
    const location = useLocation();
    const [lowband, setLowband] = useState("");
    const [highband, setHighband] = useState("");
    const [loading, setLoading] = useState(false);
    const [isMounted, setIsMounted] = useState(true);
    const [file, setFile] = useState(null);
    const [afterfile, setAfterFile] = useState(null);
    const [title, setTitle] = useState("");
    const [sData, setSData] = useState([]);
    const [selData, setSelData] = useState([]);
    const [selctedrowID, setSelectedID] = useState();
    const jm = new jobManagerService();
    const pr = new PipelineRequestService();
    const docId = location.state.id;

    function jobManagerList() {
        let id = docId;
        jm.GetEdfJob(id).then((response) => {
            if (isMounted) {
                if (response.data) {
                    setSelectedID(response.data);
                    setSelData(response.data.Job_info[0]);
                    setSData(response.data.Job_info[0].processing_steps);
                } else {

                }

            }
        })
            .catch(function (error) {
                if (error.response.status === 403) {

                }
            })
    }

    useEffect(() => {
        jobManagerList();
        return () => { setIsMounted(false) }
    }, []);

    const callEdf = () => {
        setLoading(true);
        setFile(null);
        let data = {
            "docid": selData.Docid,
            "Lowbandpass": Number(lowband),
            "highbandpass": Number(highband)
        }
        pr.getEDFdata(data).then((res) => {
            if (res.data) {
                setLoading(false);
                setFile(res.data.Raw_Graph);
            }
        })
    }

    return (
        <div className=' p-2 text-start'>
            <div className='row p-2'>
                <div className="tab-content border rounded-0 ">
                    <h5 className='py-3 px-3'>EDF Processing Detail</h5>
                    <div className="tab-pane fade show active p-2" id="home" role="tabpanel" aria-labelledby="home-tab">
                        {/* {selData.map((d,i)=>{ */}
                        <div className='row bg-light p-2 mx-1'>

                            <div className='form-group col'>
                                <label className='fw-bold'>Job #: </label><span className='ms-1'> {selData.job_number}</span>
                            </div>
                            <div className='form-group col'>
                                <label className='fw-bold'>Submitted On: </label><span className='ms-1'>
                                    {/* {format(new Date(selData.submitted_on),"MM-dd-yyyy")} */}
                                </span>
                            </div>
                            <div className='form-group col'>
                                <label className='fw-bold'>Submitted by:</label><span className='ms-1'> {selData.name_from_edf}</span>
                            </div>
                            <div className='form-group col'>
                                <label className='fw-bold'>Overall Status: </label><span className='ms-1 text-capitalize'
                                >{selData.job_status}</span>
                            </div>

                        </div>
                        {/* })} */}
                        <div className="table bordered responsive-table px-1 mt-2">
                            <table className='w-100 bordered'>
                                <thead>
                                    <tr>
                                        <th className='p-2'>Steps</th>
                                        <th className='p-2 text-center'>Status</th>
                                        <th className='p-2 text-center'>Output View</th>
                                        <th className='p-2'>Comments</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className='p-2 heading'><td className="p-2">PreProcessing</td><td></td><td></td><td></td></tr>
                                    {sData.map((item, i) => {
                                        if (item.heading == "Preprocessing") {
                                            return (
                                                <tr key={i} >
                                                    <td className='p-2'>{item.step_name}</td>
                                                    <td className={`p-2 text-center`}><div className={`p-1 mx-auto  w-50 ${item.step_status}`}>{item.step_status}</div></td>
                                                    <td className='p-2 text-center'>{item.step_status == "complete" ? <a onClick={() => { setFile(item.before_output);setAfterFile(item.output); setTitle(item.step_name) }} data-bs-toggle="modal" data-bs-target="#filleview" style={{ cursor: 'pointer' }}><img src={edfImage} alt="edf image" width="30" height="20" /></a>
                                                        : "----"}</td>
                                                    <td className='p-2'>{item.comments}</td>
                                                </tr>)
                                        }
                                    })}
                                    <tr className='p-2 heading'><td className="p-2">EEG Feature Extraction</td><td></td><td></td><td></td></tr>
                                    {sData.map((item, i) => {
                                        if (item.heading == "EEG Feature Extraction") {
                                            return (
                                                <tr key={i}>
                                                    <td className='p-2'>{item.step_name}</td>
                                                    <td className={`p-2 text-center`}><div className={`p-1 mx-auto  w-50 ${item.step_status}`}>{item.step_status}</div></td>
                                                    <td className='p-2 text-center'>{item.step_status == "complete" ? <a onClick={() => { setFile(item.before_output);setAfterFile(item.output); setTitle(item.step_name) }} data-bs-toggle="modal" data-bs-target="#filleview" style={{ cursor: 'pointer' }}><img src={edfImage} alt="edf image" width="30" height="20" /></a>
                                                        : "----"}</td>
                                                    <td className='p-2'>{item.comments}</td>
                                                </tr>
                                            )
                                        }
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal" id="filleview" data-bs-backdrop="false" tabIndex="-1">
                <div className="modal-dialog shadow-lg test">
                    <div className="modal-content">
                        <div className="modal-header border-bottom">
                            <h4 className="modal-title">{title}</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                            ></button>
                            <hr />
                        </div>
                        <div className="modal-body px-4 py-2">
                            {file !== null ?
                                <div className='row'>
                                    <div className='col'> 
                                    <label className='ps-3 '>Before:</label>
                                    <img src={`data:image/png;base64,${file}`} alt="data" width={100} height="100" className='col-md-12' />
                                    </div>
                                    <div className='col'>
                                    <label className='ps-3 '>After:</label>
                                    <img src={`data:image/png;base64,${afterfile}`} alt="data" width={100} height="100" className='col-md-12' />
                                    </div>
                                </div> : ""
                            }
                        </div>
                        {loading ? <div className='text-center' style={{ height: '100px' }}> <ClipLoader size={15} color="blue" className="mx-auto" /> </div> : null}
                        <div className="modal-footer me-4">
                            <div className='group-btn'>
                                <button type="button" id="uploadlose" className="btn btn-cancel text-white px-2 text-end" data-bs-dismiss="modal"
                                    onClick={() => { setFile(null); setLoading(false); setLowband(""); setHighband("") }}  >Cancel</button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}