import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import CreateMedicineModal from './modal/medicine/CreateMedicineModal';
import '../../assets/styles/masterData/medicine.scss';
import DeleteMedicineModal from './modal/medicine/DeleteMedicineModal';
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";
import LoadingIcon from '../../assets/img/sample-icons/sandbox.gif';
//Api
import MasterDataService from '../../services/masterdata';
import { deleteTip, editTip, newMedication } from '../ToolTip/ToolTip';
import { ScaleLoader } from "react-spinners";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const NoDataIndication = () => (
  <div className='spinner d-flex justify-content-center text-center h-100'>
    <img src={LoadingIcon} alt="loading-icon" width="200" height="200" />
  </div>
);

export default function Medication() {
  const history = useHistory();
  const { SearchBar } = Search;
  const [mname, setMname] = useState("Medicine Name");
  const [formatterText, setFormatterText] = useState([]);
  const [medicationData, setMedicationData] = useState([]);
  const [selctedrowID, setSelectedID] = useState();
  const [selctedrowData, setSelectedData] = useState([]);
  const [errmsg, setErrorMsg] = useState(false);
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [formValues, setFormValues] = useState([{ reference: "", url: "" }]);
  const [isFlagSet, setIsFlagSet] = useState(false);
  const [isFlagSet1, setIsFlagSet1] = useState(false);
  const [data, setData] = useState([]);
  const [hasRef, setHasRef] = useState(false);
  const [tdataLoaded, setTdataLoaded] = useState(false);
  const [isMounted, setIsMounted] = useState(true);
  //API
  const msds = new MasterDataService();
  const medicupnotify = () => toast.success("Medication created successfully!");
  const medicupnotifyErr = () => toast.error("Medication couldn't be updated!");
  const medicupdnotify = () => toast.success("Medication updated successfully!");
  const medicdelnotify = () => toast.success("Medication deleted successfully!");
  const medicdelnotifyErr = () => toast.error("Medication couldn't be deleted!");

  //remote page
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const options = {
    paginationSize: sizePerPage,
    pageStartIndex: page,
    sizePerPageList: [{
      text: '10', value: 10
    },
    { text: '20', value: 20 },
    { text: '50', value: 50 }
    ],
    prePageText: '<',
    nextPageText: '>',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    showTotal: false,
    alwaysShowAllBtns: true,
    withFirstAndLast: false,
    disablePageTitle: true,
  };

  //CALLBACK FUNCTION FOR CREATE MEDICATION
  function messageCallback(item) {
    setIsFlagSet(item);
    medicupnotify();
  }
  //CALLBACK FUNCTION FOR DELETE SYMPTOMS
  function messageCallback1(item) {
    setIsFlagSet1(item);
    medicdelnotify();

  }

  const rowEvents = {
    onClick: (e, row, rowIndex, index) => {
      setMname(row.medicine_name);
      setSelectedID(row.id);
      setSelectedData(row);
      setFormValues(row.MdMedcRef);
    },
  };

  const handleTableChange = (type, { page, sizePerPage, filters }) => {
    let result = medicationData;
    //PAGINATION
    const currentIndex = (page - 1) * sizePerPage;
    setPage(page);
    setData(result.slice(currentIndex, currentIndex + sizePerPage));
    setSizePerPage(sizePerPage);
    getMedicationData();
  }

  function displayRef(row) {
    setFormatterText([]);
    setFormatterText(row);
  }

  function getMedicationData() {
    msds.getMedication()
      .then((response) => {
        if (isMounted) {
          if (response.data.status == "success") {
            if (response.data.data !== [] || response.data.data !== null || response.data.data !== "") {
              setTdataLoaded(true);
              setMedicationData(response.data.data);
            } else {
              setTdataLoaded(false);
            }
          }
        }
      }).catch(function (error) {
        if (error.response.status === 403) {
          history.push('/login');
        }
      })
  }
  useEffect(() => {
    getMedicationData();
    return () => { setIsMounted(false); }
  }, [])

  useEffect(() => {
    reset(selctedrowData);
  }, [selctedrowData]);

  function actionFormatter(cell, row, rowIndex, formatExtraData) {
    return (
      <div className="row m-2 action-icons align-items-center text-center p-0">
        {editTip}{deleteTip}
        <i className="bi bi-pencil edit-btn col me-1" data-tip data-for="editTip" data-bs-toggle="modal" data-bs-target="#updateMedicine"></i>
        <i className="bi bi-trash2 del-btn col me-1" data-tip data-for="deleteTip" data-bs-toggle="modal" data-bs-target="#deleteMedicine"></i>
      </div>);
  }
  function referenceFormatter(cell, row, rowIndex, formatExtraData) {
    function showRef() {
      displayRef(row.MdMedcRef);
      setHasRef(true);
    }
    if (row.MdMedcRef[0] != undefined) {
      return (
        <div className="row align-items-center justify-content-center p-2" style={{ background: '#F7F5F5' }}>
          <p className="col mb-0">{row.MdMedcRef[0].ref_name.substring(0, 70)}.,</p>
          <span className='col-auto ms-1 px-2 py-1 text-white' style={{ border: 'solid 1px #e1e1e1', borderRadius: '6px', background: '#1f98df', cursor: 'pointer' }} onClick={() => showRef()}>Read More</span>
        </div>
      );
    }
  }

  const sortOrder = (order, column) => {
    if (!order) return (<span>&nbsp;&nbsp;<FaSort color="#858585" /></span>);
    else if (order === 'asc') return (<span>&nbsp;&nbsp;<font color="red"><FaSortUp color="#000" /></font></span>);
    else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="red"><FaSortDown color="#000" /></font></span>);
    return null;
  }

  const columns = [
    { dataField: 'medicine_name', text: 'Medicine Name', sort: true, sortCaret: sortOrder },
    { dataField: 'ref_name', text: 'Reference', sort: true, sortCaret: sortOrder, formatter: referenceFormatter, style: { width: '50%' } },
    { dataField: 'action', text: 'Action', headerAlign: 'center', formatter: actionFormatter, style: { width: '9%' } },
  ];

  //UPDATE REFERENCE AND URL
  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  }

  function addFormFields() {
    setFormValues([...formValues, { reference: "", url: "" }])
  }

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues)
  }

  const onSubmit = (data) => {
    const refval = formValues.map(function (elem) { return elem.ref_name; }).join("|");
    const urlval = formValues.map(function (elem) { return elem.ref_url; }).join("|");
    let reqData = {
      "Mdmedicid": selctedrowID,
      "medicine_name": data.medicineName,
      "ref_name": refval,
      "ref_url": urlval
    }
    msds.createMedication(reqData)
      .then((response) => {
        if (response.data) {
          if (response.data.status === 'success') {
            hideModal('closeMedicUpdate');
            getMedicationData();
            medicupdnotify();
          }
        }
      })
      .catch(function (error) {
      })
  }

  function hideModal(target) {
    document.getElementById(target).click();
    reset();
  };

  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
    console.log(url);
  };
  return (
    <div className='col medicine'>
      <div className='row g-0 ps-3 pt-3 pe-3'>
        <div className=' table-request my-2 '>
          <CreateMedicineModal data={{ isFlagSet: isFlagSet, messageCallback: messageCallback, getMedicationData: getMedicationData }} />
          <DeleteMedicineModal data={{ id: selctedrowID, isFlagSet1: isFlagSet1, messageCallback1: messageCallback1, getMedicationData: getMedicationData }} />

          {/* UPDATE MEDICATION START */}
          <div className="modal" id="updateMedicine" data-bs-backdrop="false"
            tabIndex="-1" >
            <div className="modal-dialog shadow-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Update Medicine</h4>
                  <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                </div>

                <div className="modal-body p-4">
                  {errmsg ? <div className="err-msg">Medicine name already exist</div> : ''}
                  <div className='row text-start'>
                    <div className="mb-3">
                      <label className="form-label">Medicine Name
                        <span className='required-field'>*</span></label>
                      <input type="text" maxLength="100" className={`form-control p-2 ${errors.medicineName ? "is-invalid" : ""}`} {...register('medicineName', { required: true }, { validate: (value) => { return !!value.trim() } })}
                        defaultValue={selctedrowData.medicine_name} />
                      {(errors.medicineName && errors.medicineName.type === 'required') ? <div className="invalid-feedback">This field is required</div> : ''}
                    </div>
                    {formValues.map((element, index) => (
                      <div className="form-inline" style={{ boxShadow: '0 4px 2px -4px grey', padding: '12px' }} key={index}>

                        <label className="col form-label">Reference</label>
                        {
                          index ?
                            <button onClick={() => removeFormFields(index)} style={{ padding: '5px', color: '#ffffff', backgroundColor: 'rgb(242 75 65)', borderRadius: '3px', margin: '0px 0px 10px 0px', float: 'right', width: '35px' }}> <i className="bi bi-dash-square"></i> </button>
                            : null
                        }
                        <textarea className='form-control py-2 px-4' name="ref_name" defaultValue={element.ref_name} onChange={e => handleChange(index, e)} rows='3' />
                        <label className="col form-label">URL</label>
                        <input type="text" className="form-control" name="ref_url" defaultValue={element.ref_url} onChange={e => handleChange(index, e)} />
                      </div>
                    ))}
                    <div className="button-section mt-3">
                      <button onClick={() => addFormFields()} style={{ padding: '5px', color: '#ffffff', background: '#1f98df', borderRadius: '3px', width: '35px' }}> <i className="bi bi-plus-square"></i>  </button>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" id="closeMedicUpdate" className="btn btn-cancel text-white px-2 " data-bs-dismiss="modal">Cancel</button>
                  <button type="button" className="btn btn-save text-white px-2" onClick={handleSubmit((onSubmit))}>Update</button>
                </div>
              </div>
            </div>
          </div>
          {/* UPDATE MEDICATION END */}
          {newMedication}
          <div className='row table-data p-0 m-0 '>
            <ToolkitProvider
              search>
              {
                props => (
                  <div >
                    <div className='row mb-2 pe-1'>
                      <div className='col text-start ms-1'>
                        <h5>Medication</h5>
                      </div>
                      <div className='col text-end'>
                        <button data-tip data-for="newMedicationTip" type='button' className='btn-create btn px-3 py-1 ms-3 me-2'
                          data-bs-toggle="modal" data-bs-target="#createMedicine">
                          <i className="bi bi-person-plus-fill"></i>Add</button>
                        <i className="bi bi-search ms-1 ps-2 mb-2 " id='search-icon'></i>
                        <SearchBar placeholder=" Search Medicine "
                          {...props.searchProps} />
                      </div>
                    </div>
                    <Row className='ps-2'>
                      <div className='col-md-8 m-0 p-0'>
                        <BootstrapTable
                          {...props.baseProps}
                          keyField='id'
                          data={medicationData}
                          columns={columns}
                          rowEvents={rowEvents}
                          pagination={paginationFactory(options)}
                          onTableChange={handleTableChange}
                          wrapperClasses="table-responsive"
                          noDataIndication={() => { return (tdataLoaded ? "No data available" : <NoDataIndication />) }}
                        />
                      </div>
                      <div className="side-content col-md-4 p-0 m-0">
                        <div className='header p-2 mx-3' > <h6 className='mt-3'>{mname}</h6></div>
                        {hasRef === false ? <div className='content-body p-3 mx-3 text-center h-25 rounded'><p className='noData'>No Medicine Found</p></div> : ""}
                        {formatterText.map((item, i) => (
                          <div key={i}>
                            <div className="content-body p-3 mx-3 text-start" >
                              {item.ref_name}
                              <div className='url text-start mt-2 text-primary' onClick={() => openInNewTab(item.ref_url)}>{item.ref_url}</div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </Row>
                  </div>
                )
              }
            </ToolkitProvider>
          </div>
        </div>
      </div>
    </div>
  );
}