import React, {useState} from 'react';
import { useForm } from "react-hook-form";
//api
import MasterDataService from '../../../../services/masterdata';

function AddSupplementModal(props){
    const { register, handleSubmit,reset, formState: { errors } } = useForm();
    const msds = new MasterDataService();
    const[dataLoading,setDataLoading] = useState(false);
    const [errmsg, setErrorMsg] = useState(false);

    const msgDisplay = (val) => {
        props.data.messageCallback(val);
        
      }
    const onSubmit = (data) => {
        setDataLoading(true);
        let reqData =  {
            "Mdsupplementid":0,
            "supplement_name":data.supplementName.trim(),
            "supplement_hint":data.hint
            }

        msds.createSupplement(reqData)
          .then((response) => {
            if (response.data) {
                if (response.data.status === 'success') {
                    setDataLoading(false);
                    msgDisplay(true);
                    hideModal('closeSupplement');
                    props.data.getSupplementData();
              }
            }
          })
          .catch(function (error) {
            setErrorMsg(true);
            setDataLoading(false);
          })
      }

      function hideModal(target) {
        document.getElementById(target).click();
      };
    function clearForm(){
        reset();
    }
    return(
        <div className="modal" id="createSupplement" data-bs-backdrop="false"
                tabIndex="-1" >
                <div className="modal-dialog shadow-lg">
                    <div className="modal-content">

                        <div className="modal-header">
                            <h4 className="modal-title">Create Supplement</h4>
                            <button type="button" className="btn-close"
                            onClick={()=>clearForm()} data-bs-dismiss="modal"></button>
                        </div>

                        <form onSubmit={handleSubmit(onSubmit)} >
                        <div className="modal-body p-4">
                            {errmsg ? <div className="err-msg">Supplement name already exist</div> : '' }
                            <div className='row text-start'>
                                <div className="mb-3">
                                    <label htmlFor="supplementName" className="form-label">Supplement Name <span className='required-field'>*</span></label>
                                    <input type="text" maxLength="100" className={`form-control p-2 ${errors.supplementName ? "is-invalid" : ""}`} {...register('supplementName',{ required: true },{validate: (value) => { return !!value.trim()}})}/>
                                    {(errors.supplementName && errors.supplementName.type === 'required') ? <div className="invalid-feedback">This field is required</div> : ''}
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="hint" className="form-label">Hint</label>
                                    <input type="text" className={`form-control p-2 ${errors.hint ? "is-invalid" : ""}`} {...register('hint', { required: false },{validate: (value) => { return !!value.trim()}})} />
                                    {/* {(errors.hint && errors.hint.type === 'required') ? <div className="invalid-feedback">This field is required</div> : ''} */}
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer ">
                            <button type="button"
                            onClick={()=>clearForm()} id="closeSupplement" className="btn btn-cancel text-white px-2 " data-bs-dismiss="modal">Cancel</button>
                            <button type="submit" disabled={dataLoading?true:false} className="btn btn-save text-white px-2">Create </button>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
    );
}

export default AddSupplementModal;