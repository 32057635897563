import { deleteTip, editTip } from "../../ToolTip/ToolTip";
import {FaSort,FaSortUp,FaSortDown} from "react-icons/fa";


function actionFormatter(cell, row, rowIndex, formatExtraData){
    return(
  <div className="col align-items-center text-center">
    {editTip}{deleteTip}
  {/* <i className="bi bi-pencil me-2" data-tip data-for="editTip" data-bs-toggle="modal" data-bs-target="#updateMarker" style={{color:"#fff",background:'#8199bf',padding:'5px',borderRadius:'5px'}}></i> */}
  <i className="bi bi-trash2" data-tip data-for="deleteTip" data-bs-toggle="modal" data-bs-target="#deleteMarker"  style={{color:"#fff",background:'#f44336',padding:'5px',borderRadius:'5px'}}></i>
  </div>
    );
  }
  function statusFormatter(cell) {
    if (cell === "Active") {
      return (
        <p style={{ color: "#87b880", marginBottom: '0px',fontWeight:'bolder' }}>
          {cell}
        </p>
      );
    }
    return (
      <p style={{ color: "#d25b47", marginBottom: '0px',fontWeight:'bolder' }}>
        {cell}
      </p>
    );
  }
  const sortOrder=(order,column)=>{
    if (!order) return (<span>&nbsp;&nbsp;<FaSort color="#858585"/></span>);
    else if (order === 'asc') return (<span>&nbsp;&nbsp;<font color="red"><FaSortUp color="#000"/></font></span>);
    else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="red"><FaSortDown color="#000"/></font></span>);
    return null;
  
  }

const columns = [
    { dataField: 'markername', text: 'Marker Name', sort: true,sortCaret: sortOrder },
    { dataField: 'mfieldname', text: 'Field Name', sort: true,sortCaret: sortOrder },
    { dataField: 'mfieldtype', text: 'Field Type', sort:true, sortCaret: sortOrder},
    // { dataField: 'status', text: 'Status', sort: true,sortCaret: sortOrder,formatter:statusFormatter },
    { dataField: 'action', text: 'Action', headerAlign: 'center',formatter:actionFormatter},
  ];
  
 

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    sizePerPageList: [{
        text: '10', value: 10
    },
    { text: '20', value: 20 },
    { text: '50', value: 50 }
  
    ],
    prePageText: '<',
    nextPageText: '>',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page', 
    showTotal: false,
    alwaysShowAllBtns: true,
    withFirstAndLast: false,
    disablePageTitle: true,
  
  };
  
  export { columns, options };