import { Suspense, lazy } from 'react';
import {
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles/table/table.scss';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

//TABLE MODULES
import NewRequest from './components/newRequest/NewRequest';
import ViewRequest from './components/pipelineRequest/ViewRequest';
import Account from './components/account/Account';
import ReleasedRequest from './components/releasedRequest/ReleasedRequest';
import PatientList from './components/patientList/PatientList';
import PatientDetails from './components/patientList/PatientDetails';
import AccountDetails from './components/account/AccountDetails';
import MultiStepForm from './components/pipelineRequest/multi-form/MultiStepWizard';
//MASTER TABLE
import Supplement from './components/masterdata/Supplement';
import Symptoms from './components/masterdata/Symptoms';
import Diagnosis from './components/masterdata/Diagnosis';
import LifeStyle from './components/masterdata/LifeStyle';
import Medication from './components/masterdata/Medication';
import Marker from './components/masterdata/Marker.js';
import AmazonSearch from './components/search/AmazonSearch';
import InterpretMaster from './components/masterdata/InterpretMaster';
import PatientGraph from './components/PatientGraph';
import EdfConfig from './components/EdfConfig';
import JobManager from './components/jobmanager/JobManager';
import JobEdfProcess from './components/jobmanager/JobEdfProcess';
//NON AUTHENTICATION MODULES
const Login = lazy(() => import('./components/login/Login'));
const SignupForm = lazy(() => import('./components/login/SignupForm'));
const ForgotPassword = lazy(() => import('./components/login/ForgotPassword'));
const ResetRequest = lazy(() => import('./components/login/ResetRequest'));
const ResetSuccess = lazy(() => import('./components/login/ResetSuccess'));
const ChangePassword = lazy(() => import('./components/login/ChangePassword'));
const PageNotFound = lazy(() => import('./components/account/patientForm/pageNotFound'));
const PatientForm = lazy(() => import('./components/account/patientForm/PatientForm'));

//LAYOUT
const Layout = lazy(() => import('./components/layout/Layout'));
const LayoutWithoutSearch = lazy(() => import('./components/layout/LayoutWithoutSearch'));
//MODULES
const DashBoard = lazy(() => import('./components/dashboard/Dashboard'));
// const NewRequest = lazy(() => import('./components/newRequest/NewRequest'));
// const ViewRequest = lazy(() => import('./components/pipelineRequest/ViewRequest'));
// const MultiStepForm = lazy(() => import('./components/pipelineRequest/multi-form/MultiStepWizard'))
// const ReleasedRequest = lazy(() => import('./components/releasedRequest/ReleasedRequest'));
const DatasetInformation = lazy(() => import('./components/releasedRequest/DatasetInformation'));
// const JobEdfProcess = lazy(() => import('./components/jobmanager/JobEdfProcess'));
// const PatientList = lazy(() => import('./components/patientList/PatientList'));
// const PatientDetails = lazy(() => import('./components/patientList/PatientDetails'));
// const Account = lazy(() => import('./components/account/Account'));
// const AccountDetails = lazy(() => import('./components/account/AccountDetails'));
//MASTER MODULES
// const Supplement = lazy(() => import('./components/masterdata/Supplement'));
// const Symptoms = lazy(() => import('./components/masterdata/Symptoms'));
// const Diagnosis = lazy(() => import('./components/masterdata/Diagnosis'));
// const LifeStyle = lazy(() => import('./components/masterdata/LifeStyle'));
// const Medication = lazy(() => inputAdornmentClasses('./components/masterdata/Medication'));
// const Marker = lazy(() => import('./components/masterdata/Marker'));

//GLOBAL & ADVANCED SEARCH
const Search = lazy(() => import('./components/search/search'));
const InterpretationSearch = lazy(() => import('./components/search/InterpretationSearch'));


function App() {
  return (
  
    <div className='App'>
     
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Suspense fallback={<div className='mt-5 text-center'>
        
        {/* <img src={LoadingIcon} alt="loading" width="200" height="150" /> */}
      </div>}>
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/reset-password">
            <ForgotPassword />
          </Route>
          <Route path="/change-password/">
            <ChangePassword />
          </Route>
          <Route path="/reset-request">
            <ResetRequest />
          </Route>
          <Route path="/reset-success">
            <ResetSuccess />
          </Route>
          <Route path="/sign-up">
            <SignupForm />
          </Route>
          <Route path='/patient-form'>
            <PatientForm />
          </Route>
          <Route path="/dashboard">
            <Layout>
              <DashBoard />
            </Layout>
          </Route>
          <Route path="/view-request/:pipeline-request">
            <Layout>
              <MultiStepForm />
            </Layout>
          </Route>
          <Route path='/view-request'>
            <Layout>
              <ViewRequest />
            </Layout>
          </Route>
          <Route path='/new-request'> 
            <Layout>
              <NewRequest />
            </Layout>
          </Route>
          <Route path='/edf-config'>
            <Layout>
              <EdfConfig />
            </Layout>
          </Route>
          <Route path='/job-manager'>
            <Layout>
              <JobManager />
            </Layout>
          </Route>
          <Route path='/edf-processing'>
            <Layout>
              <JobEdfProcess />
            </Layout>
          </Route>
          <Route path='/released-request/:dataset-information'>
            <Layout>
              <DatasetInformation />
            </Layout>
          </Route>
          <Route path='/released-request'>
            <Layout>
              <ReleasedRequest />
            </Layout>
          </Route>
          <Route path="/patient-list/:patient-details">
            <Layout>
              <PatientDetails />
            </Layout>
          </Route>
          <Route path="/patient-list">
            <Layout>
              <PatientList />
            </Layout>
          </Route>
          <Route path="/account/:account-details">
            <Layout>
              <AccountDetails />
            </Layout>
          </Route>
          <Route path="/account">
            <Layout>
              <Account />
            </Layout>
          </Route>
          <Route path='/pageNotFound'>
            <PageNotFound />
          </Route>
          <Route path='/search'>
            <LayoutWithoutSearch>
              <Search />
            </LayoutWithoutSearch>
          </Route>
          <Route path='/interpretation-search'>
            <LayoutWithoutSearch>
              <InterpretationSearch />
            </LayoutWithoutSearch>
          </Route>
          <Route path='/medication'>
            <Layout>
              <Medication />
            </Layout>
          </Route>
          <Route path='/supplement'>
            <Layout>
              <Supplement />
            </Layout>
          </Route>
          <Route path='/symptoms'>
            <Layout>
              <Symptoms />
            </Layout>
          </Route>
          <Route path='/diagnosis'>
            <Layout>
              <Diagnosis />
            </Layout>
          </Route>
          <Route path='/lifestyle'>
            <Layout>
              <LifeStyle />
            </Layout>
          </Route>
          <Route path='/marker-management'>
            <Layout>
              <Marker />
            </Layout>
          </Route>
          <Route path='/inter-master'>
            <Layout>
              <InterpretMaster />
            </Layout>
          </Route>
          <Route path='/amazon-search'>
            <Layout>
              <AmazonSearch />
            </Layout>
          </Route>
          <Route path="/patient-graph">
            <Layout>
              <PatientGraph/>
            </Layout>
          </Route>
          <Redirect to="/login" />

        </Switch>
      </Suspense>
    </div>
  );
}

export default App;
