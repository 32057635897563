import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
// import NeuroToolbar, { modules, formats } from "../tabcontent/subcontent/toolbar/NeuroToolbar";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RecordingAnalysisService from '../../../services/RecordingAnalysis';
import EyesOpenToolbar, { modules,formats } from '../tabcontent/subcontent/toolbar/EyesOpenToolbar';

export default function EyeOpenEditor(props) {
    const [text, setText] = useState({ value: "" });
    const location = useLocation();
    const [descid, setDescID] = useState(0);
    const pipelineReqID = location.state.reqId;

    const notifyNeuro = () => toast.success("Neurofeedback updated successfully!");
    const notifyNeuroErr = () => toast.error("Neurofeedback couldn't be updated!");
    //api
    let rec = new RecordingAnalysisService();

    //function callback
    const neuroCallback = (val) => {
        props.data.neurofeedbackCallback(val);
    }
    function fetchAssociatedNeuroFeedback() {
        const reqData = pipelineReqID;
        rec
            .getNeurofeedbackAll(reqData)
            .then((response) => {
                if (response.data) {
                    setDescID(response.data.data.id);
                    setText({ value: response.data.data.neurofeedback_EO });
                    console.log("NEuro",response.data);
                }
            })
            .catch(function (error) { });
    }
    useEffect(() => {
        let isMounted = true;
        fetchAssociatedNeuroFeedback();

        return () => {
            isMounted = false;
        };
    }, []);
    const onSubmit = () => {
        let reqData = {
            "sr_associate_neurofeedback":descid,
            "service_request":pipelineReqID,
            "description":"null",
            "neurofeedback_EO": text.value,
            // "neurofeedback_EC": "textEC.value",
            // sr_associate_neurofeedback: descid,
            // service_request: pipelineReqID,
            // description: text.value,
        };
        rec
            .saveNeurofeedbackAll(reqData)
            .then((response) => {
                if (response.data) {
                    if (response.data.status === "success") {
                        fetchAssociatedNeuroFeedback();
                        // hideModal("closeneuro");
                        notifyNeuro();
                        neuroCallback(false);
                        props.data.exportPreview();
                        // setMsg(" Updated successfully!");
                        // setTimeout(() => {
                        //   setMsg("");
                        // }, 5000);
                    }
                }
            })
            .catch(function (error) {
                // setErrorMsg(true);
                notifyNeuroErr();
            });
    };

    const handleChange = (value) => {
        setText({ value });
    };
   
    return (
        <div>
          
            <EyesOpenToolbar />
            <ReactQuill
                // theme="snow"
                value={text.value}
                modules={modules}
                formats={formats}
                onChange={handleChange}
            // onChange={this.handleProcedureContentChange}
            >
                {/* <div className="my-editing-area" /> */}
            </ReactQuill>
            
            <div className='text-end my-2'>
                <button type="button" className='btn btn-save ms-auto text-white' onClick={onSubmit}>Save</button>
            </div>
        </div>
    );
}