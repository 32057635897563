import React, {useState} from 'react';
import { useForm } from "react-hook-form";
//API
import MasterDataService from '../../../../services/masterdata';
function AddSymptomsModal(props){

    const { register, handleSubmit,reset, formState: { errors } } = useForm();
    const msds = new MasterDataService();
    const[dataLoading,setDataLoading] = useState(false);
    const [errmsg, setErrorMsg] = useState(false);

    const onSubmit = (data) => {
        setDataLoading(true);
        let reqData =  {
            "mdsymptomsid":0,
            "symptoms_name":data.symptomsName.trim(),
            "symptoms_hint":data.hint
            }

        msds.createSymptoms(reqData)
          .then((response) => {
            if (response.data) {
                if (response.data.status === 'success') {
                    setDataLoading(false);
                hideModal('closeSymptoms');
                msgDisplay(true);
                setErrorMsg(false);
                props.data.getSymptomsData();
              }
            }
          })
          .catch(function (error) {
            setErrorMsg(true);
            setDataLoading(false);
          })
      }
      function hideModal(target) {
        document.getElementById(target).click();
        reset();
      };
      const msgDisplay = (val) => {
        props.data.messageCallback(val);
        
      }  
      function clearForm(){
          reset();
          setErrorMsg(false);
      }
    return(
        <div className="modal" id="createSymptoms" data-bs-backdrop="false"
                tabIndex="-1" >
                <div className="modal-dialog shadow-lg">
                    <div className="modal-content">

                        <div className="modal-header">
                            <h4 className="modal-title">Create Symptoms</h4>
                            <button type="button" className="btn-close"
                            onClick={()=>clearForm()} data-bs-dismiss="modal"></button>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)} >
                        <div className="modal-body p-4">
                        {errmsg ? <div className="err-msg">Symptom name already exist</div> : '' }
                            <div className='row text-start'>
                                <div className="mb-3">
                                    <label htmlFor="symptomsName" className="form-label">Symptoms Name <span className='required-field'>*</span></label>
                                    <input type="text" maxLength="100" className={`form-control p-2 ${errors.symptomsName ? "is-invalid" : ""}`} {...register('symptomsName',{ required: true },{validate: (value) => { return !!value.trim()}})}/>
                                    {(errors.symptomsName && errors.symptomsName.type === 'required') ? <div className="invalid-feedback">This field is required</div> : ''}
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="hint" className="form-label">Hint</label>
                                    <input type="text" className={`form-control p-2 ${errors.hint ? "is-invalid" : ""}`} {...register('hint', { required: false },{validate: (value) => { return !!value.trim()}})} />
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer ">
                            <button type="button" onClick={()=>clearForm()} id="closeSymptoms" className="btn btn-cancel text-white px-2" data-bs-dismiss="modal">Cancel</button>
                            <button type="submit" disabled={dataLoading?true:false} className="btn btn-save text-white px-2">Create </button>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
    );
}

export default AddSymptomsModal;