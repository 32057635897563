import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useHistory, useRouteMatch } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { format } from 'date-fns';
import Select from 'react-select';
import { Row } from 'react-bootstrap';
import '../../assets/styles/newRequest/newRequest.scss';
import RequestService from '../../services/SeriveRequest';
import PatientAccountService from '../../services/Patient';
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import InputMask from 'react-input-mask';
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";
import MasterDataService from '../../services/masterdata';
import { Multiselect } from "multiselect-react-dropdown";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingIcon from '../../assets/img/sample-icons/sandbox.gif';

//TABLE COLUMN FORMATTERS
function DateFormatter(cell, row) {
  if (row) {
    return (
      <span>
        {format(new Date(cell), 'MM/dd/yyyy')}
      </span>
    );
  }
}

const NoDataIndication = () => (
  <div className='spinner d-flex justify-content-center text-center h-100'>
  <img src={LoadingIcon} alt="loading-icon" width="200" height="200"/>
</div>
);

const sortOrderTable = (order, column) => {
  if (!order) return (
    <><span>&nbsp;&nbsp;<FaSort color="#858585" /></span>
    </>);
  else if (order === 'asc') return (<><span>&nbsp;&nbsp;<font color="red"><FaSortUp color="#000" /></font></span>
  </>);
  else if (order === 'desc') return (<><span>&nbsp;&nbsp;<font color="red"><FaSortDown color="#000" /></font></span>
  </>);
  return null;
}

function stateFormatter(cell) {
  if (cell === "Request Init") {
    return (
      <p style={{ color: 'rgb(237 5 5)', marginBottom: '0px' }}>
        {cell}
      </p>
    );
  }

  return (
    <p style={{ color: "#1CBF02" }}>
      {cell}
    </p>
  );
}

//REMOTE TABLE 
const RemoteAll = ({ columns, loading, data, page, sizePerPage, onTableChange, totalSize, rowEvents, noDataIndication }) => (
  <div>
    <BootstrapTable
      pagination={paginationFactory({ page, sizePerPage, totalSize })}
      keyField="id"
      data={data}
      columns={columns}
      noDataIndication={noDataIndication}
      onTableChange={onTableChange}
      remote={{
        pagination: true
      }}
      rowEvents={rowEvents}
    />
  </div>
);

//NEW REQUEST FUNCTION 
function NewRequest() {
  const { url } = useRouteMatch();
  const [requestData, setRequestData] = useState([]);
  const history = useHistory();
  const svc = new RequestService();
  //remote table
  const [data, setData] = useState([]);
  const [totalDataSize, setTotalDataSize] = useState(0);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [sortName, setSortName] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [loading, setLoading] = useState(false);
  const [isDateEmpty, setIsDateEmpty] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [showPatient, setShowPatient] = useState();
  const [dateChanged, setDateChanged] = useState(false);
  const [tdataLoad, setTdataLoad] = useState(true);
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  //account dropdown
  const [accountOption, setAccountOption] = useState();
  const [accSelect, setAccSelect] = useState([]);
  const [accId, setAccId] = useState();
  const [isSelected, setIsSelected] = useState(false);
  //patient dropdown
  const [patientOption, setPatientOption] = useState();
  const [pntSelect, setPntSelect] = useState([]);
  const [pntId, setPntId] = useState();
  //request dropdown
  const [requestOption, setRequestOption] = useState();
  const [reqSelect, setReqSelect] = useState([]);
  const [reqId, setReqId] = useState();
  const [isReqSelected, setIsReqSelected] = useState();
  const userRole = localStorage.getItem('userrole');
  const [inputVal, setInputVal] = useState('');
  //error
  const [accErr, setAccErr] = useState(false);
  const [patientErr, setPatientErr] = useState(false);
  const [reqErr, setReqErr] = useState(false);
  const [isMounted, setIsMounted] = useState(true);
  const [accEmpty, setAccEmpty] = useState(false);
  const [patientEmpty, setPatientEmpty] = useState(false);
  const [reqEmpty, setReqEmpty] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);
  const [accEmp, setAccEmp] = useState(false);
  //MULTISELECT DROPDOWN
  const [sympData, setSympData] = useState([]);
  const [selectedSymptoms, setSelectedSymptoms] = useState('');
  const [symptomsID, setSymptomsID] = useState([]);
  const [isPId, setISPId] = useState(false);
  const [pId, setPId] = useState();
  //api calls
  const pnt = new PatientAccountService();
  const msds = new MasterDataService();
  //TOASTER
  const reqnotify = () => toast.success("Service Request created successfully!");
  const reqnotifyErr = (val) => toast.error(val);

  //TABLE COLUMN
  const columns = [
    {
      dataField: 'sl.no', text: 'S.No', formatter: (cell, row, rowIndex, formatExtraData) => {
        if (page === 1) { return rowIndex + 1; }
        else { return ((page - 1) * sizePerPage) + (rowIndex + 1); }
      },
    },
    { dataField: 'RequestNumber', text: 'Request No', sort: true, sortCaret: sortOrderTable },
    { dataField: 'patient_name', text: 'Patient Name', sort: true, sortCaret: sortOrderTable },
    { dataField: 'dob', text: 'Date of Birth', sort: true, sortCaret: sortOrderTable },
    { dataField: 'gender', text: 'Gender', sort: true, sortCaret: sortOrderTable },
    { dataField: 'account_name', text: 'Account Name', sort: true, sortCaret: sortOrderTable },
    { dataField: 'created_on', text: 'Submitted date', sort: true, formatter: DateFormatter, sortCaret: sortOrderTable },
    { dataField: 'status', text: 'Status', formatter: stateFormatter }
  ];

  //NEW REQUEST TABLE DATA
  const handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    let result = requestData;
    let order;
    //sorting
    if (sortOrder === 'asc') {
      order = data.sort((a, b) => {
        if (a[sortField] > b[sortField]) {
          return 1;
        } else if (b[sortField] > a[sortField]) {
          return -1;
        }
        return 0;
      });
    } else {
      order = data.sort((a, b) => {
        if (a[sortField] > b[sortField]) {
          return -1;
        } else if (b[sortField] > a[sortField]) {
          return 1;
        }
        return 0;
      });
    }
    setSortName(sortField);
    setSortOrder(order);
    //pagination
    const currentIndex = (page - 1) * sizePerPage;
    setPage(page);
    setData(result.slice(currentIndex, currentIndex + sizePerPage));
    setSizePerPage(sizePerPage);
    fetchServiceRequest(page, sizePerPage, inputVal, sortField, sortOrder);
  }

  function fetchServiceRequest(page, sizePerPage, inputVal, sortName, sortOrder) {
    let reqData = {
      SRQInput: {
        status: "Request Init"
      },
      DataFinder: {
        pagesize: sizePerPage,
        currentpage: page,
        sortbycolumn: sortName || "",
        sortby: sortOrder || "",
        searchdata: inputVal || ""
      }
    }
    svc.GetServiceRequest(reqData).then((response) => {
      if (isMounted) {
        if (response.data) {
          if (response.data.ServiceRequestDetail !== [] && response.data.DataFinder.totalrecords !== 0) {
            setTdataLoad(true);
            setRequestData(response.data.ServiceRequestDetail);
            setTotalDataSize(response.data.DataFinder.totalrecords);
            setData(response.data.ServiceRequestDetail.slice(0, sizePerPage));
          }
          else {
            setTdataLoad(false);
            setData([]);
          }
        }
      }
    })
      .catch(function (error) {

        if (error.response.status === 403) {
          history.push('/login');
        }
      })
  }

  useEffect(() => {
    fetchServiceRequest(page, sizePerPage, inputVal, sortName, sortOrder);
    return () => { setIsMounted(false) }
  }, []);

  const addNewRequest = () => {
    getSymptomsData();
    accountType();
    requestType();
  }
  //REQUEST TYPE FUNCTION
  async function requestType() {
    svc.RequestTypeDropdown().then((response) => {
      if (response.data.status === "success") {
        setReqSelect(response.data.data);
      }
    })
  }
  //ACCOUNT TYPE FUNCTION
  async function accountType() {
    pnt.GetAccountType().then((response) => {
      if (response.data.status === "success") {
        setAccSelect(response.data.data);
      }
    })
  }
  //PATIENT TYPE FUNCTION
  async function patientType(accId) {
    svc.PatientDropdown(accId).then((response) => {
      if (response.data.status === "success") {
        setPntSelect(response.data.data);

      }
    })
  }
  //SYMPTOMS MULTI INPUT
  function getSymptomsData() {
    msds.getSymptoms()
      .then((response) => {
        if (response.data) {
          if (response.data.data !== []) {
            setSympData(response.data.data);
          }
        }
      }).catch(function (error) {
        if (error.response.status === 403) {
          history.push('/login');
        }
      })
  }

  const symptomsOption = sympData.map((data) => {
    return {
      label: data.symptoms_name,
      value: data.symptoms_name,
      id: data.id
    }
  });

  const handleSymptomsChange = (selectedSymptoms) => {
    setSelectedSymptoms(selectedSymptoms);
    const values = ([...selectedSymptoms].map(o => o.id));
    setSymptomsID(values);
  };


  const handleSearch = (e) => {
    if (e.key === "Enter") {
      setInputVal(e.target.value);
      fetchServiceRequest(page, sizePerPage, e.target.value);
    }

  }

  const resetSearch = (e) => {
    if (e.key === "Backspace" && !e.target.value) {
      setInputVal("");
      fetchServiceRequest(page, sizePerPage, e.target.value);
    }
  }

  //ADD NEW REQUEST FORM DATA STARTS HERE
  const handleClick = () => {
    if (isSelected === true && isReqSelected === true) {
      setShowPatient(true);
      setPatientOption("");
      setDataLoading(false);
    }
    return;
  }
  //ACCOUNT TYPE OPTIONS
  const options = accSelect.map(function (accSelect) {
    return {
      value: accSelect.acct_name, label: accSelect.acct_name, id: accSelect.id
    }
  })

  //ACCOUNT CHOICEFIELD HANDLECHANGE
  const handleChange = (accountOption) => {
    setAccountOption(accountOption);
    setAccId(accountOption.id);
    patientType(accountOption.id);
    setIsSelected(true);
    setAccErr(true);
  };

  //PATIENT DROPDOWN FUNCTIONS
  const optionPatient = pntSelect.map(function (pntSelect) {
    return {
      id: pntSelect.id,
      value: pntSelect.patientNumber,
      label: pntSelect.patient_name
    }
  });

  const handlePatientChange = (patientOption) => {
    setPatientOption(patientOption);
    setPntId(patientOption.id);
    setDataLoading(false);
    setPatientErr(true);
    setPatientEmpty(false);
  };

  //REQUEST TYPE OPTIONS
  const optionRequest = reqSelect.map(function (reqSelect) {
    return {
      value: reqSelect, label: reqSelect
    }
  });

  //REQUEST TYPE CHANGE
  const handleRequestChange = (requestOption) => {
    setRequestOption(requestOption);
    setReqId(requestOption.value);
    setIsReqSelected(true);
    setReqErr(true);

  };

  const handleDobChange = (date) => {
    setStartDate(format(new Date(date), 'MM/dd/yyyy'));
    setDateChanged(true);
    setDataLoading(false);
  }

  function hideModal(target) {
    document.getElementById(target).click();
  };

  


  const onSubmit = (data) => {
    setDataLoading(true);
    let dob = format(new Date(startDate), 'yyyy-MM-dd');
    if (dateChanged === false) {
      setIsDateEmpty(true);
    }
    let reqData1 = {
      "patientid": 0,
      "first_name": data.fname.trim(),
      "last_name": data.lname.trim(),
      "gender": data.gender,
      "dob": dob,
      "occupation": "",
      "address": "",
      "country": "",
      "contact_number": data.phone,
      "state": "",
      "pnt_contact_email": data.email,
      "city": "",
      "zip": "",
      "status": "Active",
      "accountid": accId,
      "diagnosis": "",
      "undiagnosis": "",
      "pnt_handedness":data.handedness,
      "symptoms": symptomsID.toString(),
      "unsymptoms": "",
      "userid": parseInt(localStorage.getItem('userid'), 10),
    }
    if (dateChanged === true) {
      pnt.CreatePatient(reqData1)
        .then((response) => {
          if (response.data) {
            if (response.data.status === 'success') {
              setDataLoading(false);
              let pntoption = {
                id: response.data.data,
              }
              fetchServiceRequest(page, sizePerPage, inputVal, sortName, sortOrder);
              setPntSelect([pntoption.id]);
              setPntId(pntoption.id);
              setPId(response.data.data);
              setISPId(true);
              setDataLoading(true);
              patientType(accId);
              hideModal('updclose');
              hideModal('deleteclose');
              onClickSubmit(response.data.data);
              handleClear();
              // reqnotify();
            }

          }

        })
        .catch(function (error) {
          setDataLoading(false);
          reqnotifyErr(error.response.data.data);
        })
    } else return;

  };


  const onClickSubmit = (ptId) => {
    console.log(ptId);
    let reqData = {
      "request_type": reqId,
      "accountid": accId,
      "patientid": pntId || ptId
    }
    // if (accErr === true && reqErr === true && accId !== "" && reqId !== "") {
    if (accErr === false) {
      setAccEmpty(true);
      setAccEmp(true);
    }
    else if (showPatient === false && patientErr === false) {
      setPatientEmpty(true);
    }
    else if (reqErr === false) {
      setReqEmpty(true);
    }
    else {
      svc.SaveServiceRequest(reqData).then((response) => {
        if (response.data) {
          if (response.data.status === "success") {
            history.push({
              pathname: `${url}/:pipeline-request`,
              state: {
                reqId: response.data.data.ServiceRequestID,

              }
            })
            setDataLoading(true);
            handleClearForm();
            setPatientEmpty(false);
            fetchServiceRequest(page, sizePerPage, inputVal, sortName, sortOrder);
            hideModal('deleteclose');
            reqnotify();
          }
        }

      })
        .catch(function (error) {

        })
    }
  }

  const handlePatientForm = () => {
    setDataLoading(false);
    setShowPatient(false);
    setStartDate("");
    reset();
    reset({ phone: "" });
  }


  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      if (userRole !== "staff") {
        history.push({
          pathname: `/view-request/:pipeline-request`,
          state: {
            reqId: row.id
          }
        });
      }

    },
  };

  const handleClearForm = () => {
    setDataLoading(false);
    setRequestOption("");
    setReqId();
    setAccId();
    setPntId();
    setPatientErr(false);
    setPatientOption("");
    setAccountOption("");
    setIsSelected(false);
    setIsReqSelected(false);
  }

  function handleClear() {
    setShowPatient(false);
    reset();
    setPntId();
    setPatientErr(false);
    setStartDate("");
    reset({ phone: "" })
  }

  function handleClearAll() {
    handleClearForm();
    handlePatientForm();
    handleClear();
  }

  return (
    <>
      <div className='row g-0 new-request' >
        <div className="err-message ms-auto justify-content-center " style={{ width: '60%' }} >
        </div>
        <div className='row ps-3 pt-3 pe-0'>
          <div className='table-request my-2 me-2'>
            <div className='table-data me-3'>
              <div>
                <div className='row mb-2'>
                  <div className='col text-start ms-1'>
                    <h5>Service Request</h5>
                  </div>
                  <div className='col text-end'>
                    <i className="bi bi-search ms-1 ps-2 mb-2 " id='search-icon'></i>
                    <input
                      // onChange={(e) => handleSearch(e)}
                      onKeyDown={(e) => handleSearch(e)}
                      onKeyUp={(e) => resetSearch(e)}
                      className='py-1 ps-3' placeholder="Search Request" />
                    <button type='button' data-tip data-for="newRequestTip" className='btn-create btn px-3 py-1 ms-3'
                      data-bs-toggle="modal" data-bs-target="#servicesRequest" onClick={addNewRequest}>
                      <i className="bi bi-plus-circle"></i> Add</button>
                    <ReactTooltip id="newRequestTip" place="top" effect="solid">
                      Add new request
                    </ReactTooltip>
                  </div>
                </div>
                <RemoteAll
                  loading={loading}
                  data={data}
                  page={page}
                  columns={columns}
                  sizePerPage={sizePerPage}
                  totalSize={totalDataSize}
                  rowEvents={rowEvents}
                  noDataIndication={() => { return (tdataLoad ? <NoDataIndication /> : "No data available") }}
                  onTableChange={handleTableChange}
                />
              </div>
            </div>
            {/* CREATE SERVICE REQUEST MODAL STARTS HERE */}
            <div className="modal" id="servicesRequest" data-bs-backdrop="false"
              tabIndex="-1" >
              <div className="modal-dialog shadow-lg" style={{ maxWidth: '800px' }}>
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title">New Service Request</h4>
                    <button type="button" className="btn-close" onClick={() => handleClearAll()} data-bs-dismiss="modal"></button>
                  </div>
                  <div className="modal-body p-4">
                    <Row>
                      {showPatient === true ? "" : <>
                        <div className="btn-add-patient btn col-auto ms-auto text-end me-3 p-0 "
                          disabled={isSelected === true && isReqSelected === true}
                          onClick={handleClick}>
                          <i className='bi bi-plus' style={{ background: '#1f98df', color: '#fff' }}
                            data-tip data-for="addPatientTip"></i>
                          <ReactTooltip id="addPatientTip" place="top" effect="solid">
                            {isSelected === true && isReqSelected === true ? "Add Patient" : "Choose request type and account "}
                          </ReactTooltip>
                        </div>
                      </>
                      }
                    </Row>
                    <div className='row text-start'>
                      <div className="col mb-3">
                        <label htmlFor="reqType" className="form-label ms-1">Request Type
                          <span className='required-field'>*</span></label>
                        <Select
                          value={requestOption}
                          onChange={handleRequestChange}
                          options={optionRequest}
                        // styles={customStyles}
                        />
                        {(reqErr === false && reqEmpty === true) ? <div className='text-danger text-left'>This field is required</div> : ""}
                      </div>
                      <div className="col mb-3">
                        <label htmlFor="account" className="form-label ms-1">Account
                          <span className='required-field'>*</span></label>
                        <Select
                          required
                          value={accountOption}
                          onChange={handleChange}
                          options={options}
                        />
                        {(accErr === false && accEmpty === true) ? <div className='text-danger text-left'>This field is required</div> : ""}
                      </div>
                      {isSelected == true && isReqSelected == true && showPatient === true ? null :
                        <div className="col mb-3">
                          <label htmlFor="patient" disabled={showPatient ? true : false} className="form-label ms-1">Patient
                            <span className='required-field'>*</span></label>
                          <Select
                            required
                            value={patientOption}
                            onChange={handlePatientChange}
                            options={optionPatient}
                          >  </Select>
                          {showPatient == true ? null : (patientErr === false && patientEmpty === true && showPatient === false) ? <div className='text-danger text-left'>This field is required</div> : ""}
                        </div>
                      }
                    </div>
                    {isSelected && isReqSelected ?
                      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                        <div className={`add-patient ${showPatient ? "showPatient" : ""}`}>
                          <hr style={{ border: '1px solid lightgray', height: '2px' }} />
                          <Row>
                            <h5 className="modal-title text-start mb-1 col">Patient Details</h5>
                            <div className='btn btn-cancel-patient col-auto btn-cncl ms-auto text-end me-3 p-0' onClick={() => handlePatientForm()}>
                              <i className='bi bi-x' style={{ background: '#1f98df', color: '#fff' }}></i></div>
                          </Row>
                          <div className='row text-start'>
                            <div className="col mb-3">
                              <label htmlFor="fname" className="form-label ms-1">First Name
                                <span className='required-field'>*</span></label>
                              <input type="text" className={`form-control p-2 ${errors.fname ? "is-invalid" : ""}`}{...showPatient == true ? { ...register('fname', { validate: (value) => { return !!value.trim() }, required: true }) } : { ...register('fname', { required: false }) }} />
                              {(errors.fname && errors.fname.type === 'required') ? <div className="invalid-feedback">This field is required</div> : ''}
                            </div>
                            <div className="col mb-3">
                              <label htmlFor="lname" className="form-label ms-1">Last Name
                                <span className='required-field'>*</span></label>
                              <input type="text" className={`form-control p-2 ${errors.lname ? "is-invalid" : ""}`}{...showPatient == true ? { ...register('lname', { validate: (value) => { return !!value.trim() }, required: true }) } : { ...register('lname', { required: false }) }} />
                              {(errors.lname && errors.lname.type === 'required') ? <div className="invalid-feedback">This field is required</div> : ''}
                            </div>
                            <div className="col mb-3">
                              <label htmlFor="gender" className="form-label ms-1">Birth Gender
                                <span className='required-field'>*</span></label>
                              <select className={`form-select p-2 ${errors.gender ? "is-invalid" : ""}`} {...register('gender', { required: true })} >
                                <option value="">-Select-</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Others">Others</option>
                              </select>
                              {(errors.gender && errors.gender.type === 'required') ? <div className="invalid-feedback">This field is required</div> : ''}
                            </div>

                          </div>
                          <div className='row text-start'>
                            <div className="col mb-3">
                              <label htmlFor="dob" className="form-label ms-1">Date Of Birth
                                <span className='required-field'>*</span></label>

                              <DatePicker
                                dateFormat="MM/dd/yyyy"
                                placeholderText="Select date"
                                name="startDate"
                                maxDate={new Date()}
                                value={startDate}
                                onChange={handleDobChange}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                onKeyDown={(e) => {
                                  e.preventDefault();
                                }}

                              />
                              {dateChanged === false && isDateEmpty === true ?
                                <div className="invalid-msg pt-1"
                                  style={{ color: '#d8000c' }} >
                                  This field is required
                                </div>
                                : null}
                            </div>
                            <div className="col mb-3">
                              <label htmlFor="phone" className="form-label ms-1">Contact No</label>
                              <InputMask className={`form-control p-2 ${errors.phone ? "is-invalid" : ""}`} {...register('phone', { required: false })}
                                mask="(999) 999-9999" maskChar="" />
                            </div>
                            <div className="col mb-3">
                              <label htmlFor="email" className="form-label ms-1">Email</label>
                              <input type="email" className={`form-control p-2 ${errors.email ? "is-invalid" : ""}`}  {...showPatient === true ? { ...register('email', { required: false }, { pattern: /^\S+@\S+$/i }) } : { ...register('email', { required: false }, { pattern: /^\S+@\S+$/i }) }} />
                              {(errors.email && errors.email.type === 'pattern') ? <div className="invalid-feedback">Enter a valid email id</div> : ''}
                            </div>
                          </div>
                          <div className="row text-start">
                            <div className="col-md-4 mb-3">
                              <label htmlFor="handedness" className="form-label">
                                Handedness
                              </label>
                              <select
                                className={`form-select p-2 ${errors.handedness ? "is-invalid" : ""
                                  }`}
                                {...register("handedness", { required: false })}
                              >
                                <option value="">-Select-</option>
                                <option value="righthand">Right Hand</option>
                                <option value="lefthand">Left Hand</option>
                              </select>
                            </div>
                            <div className="col-md-4 mb-3">
                              <label htmlFor="symptoms" className="form-label">
                                Symptoms
                              </label>
                              <Multiselect
                                selectedValues={selectedSymptoms}
                                options={symptomsOption}
                                displayValue="value"
                                closeIcon="cancel"
                                onSelect={handleSymptomsChange}
                              />
                            </div>
                          </div>
                        </div>
                        {showPatient ?
                          <div className="modal-footer">
                            <button type="button" id="updclose" className="btn btn-cancel text-white px-3 " onClick={() => handleClear()}>Cancel</button>
                            <button type="submit" disabled={dataLoading ? true : false} className="btn btn-save text-white px-2">Submit</button>
                          </div> : null}
                      </form>
                      : null}
                  </div>
                  {showPatient ? "" :
                    <div className="modal-footer">
                      <button type="button" id="deleteclose" onClick={() => handleClearForm()} className="btn btn-cancel text-white px-2 py-1 " data-bs-dismiss="modal">Cancel</button>
                      {/* {showPatient===false?<button type="button" className="btn btn-save text-white px-2 py-1" disabled={true} onClick={() => onClickSubmit()}>Submit</button>: */}
                      <button type="button" className="btn btn-save text-white px-2 py-1" disabled={dataLoading ? true : false} onClick={() => onClickSubmit()}>Submit</button>
                    </div>
                  }
                </div>
              </div>
            </div>
            {/* SERVICE REQUEST MODAL ENDS HERE */}
          </div>
        </div>
      </div>
    </>
  );
}

export default NewRequest;