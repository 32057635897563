import React, { useEffect, useState } from 'react';
import {useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form";
import AdminAccountService from '../../../services/AdminAccount';
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function CreateUserModal(props) {
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const location = useLocation();
    const [invalidError, setInValidError] = useState([]);
    const [isSuccess, setIsSuccess] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);
    const [passwordShownc, setPasswordShownc] = useState(false);
    const [dataLoading,setDataLoading] = useState(false);
    const [validatePass, setValidatePass] = useState("");
    const [isValid, setIsValid] = useState(false);
    const accs = new AdminAccountService();
    const usernotify = () => toast.success("User created successfully!");
    const usernotifyErr = () => toast.error("User couldn't be created!");

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    }
    const togglePasswordc = () => {
        setPasswordShownc(!passwordShownc);
        
    }
    const msgDisplay = (val) => {
        props.data.messageCallback1(val);
    }

    const onSubmit = (data) => {
        setDataLoading(true);
        let reqData = {
            "userid": 0,
            "username": data.user,
            "email": data.email,
            "first_name": data.firstname,
            "last_name": data.lastname,
            "password": data.newpassword,
            "password2": data.confirmpassword,
            "accountid": location.state.rowId
        }
        if (data.newpassword != data.confirmpassword) {
            setIsValid(true);
            setValidatePass("Passwords do not match");
        } else {
        accs.CreateUser(reqData)
            .then((response) => {
                if (response.data) {
                    if (response.data.status === 'success') {
                        setIsSuccess(false);
                        msgDisplay(true);
                        hideModal('closeUsrModal');
                        handleClear();
                        usernotify();
                        let page = 1;
                        let sizePerPage = 10;
                        props.data.fetchAllUserData(page, sizePerPage);
                    }
                }
            })
            .catch(function (error) {
                setIsSuccess(true);
                setInValidError(error.response.data);
            })
        }
    }
    function hideModal(target) {
        document.getElementById(target).click();  
    };

    function handleClear(){
        reset();
        setDataLoading(false);
    }
    return (
        <div className="modal " id="createUserModal" data-bs-backdrop="false" 
            tabIndex="-1" >
            <div className="modal-dialog shadow-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Create User</h4>
                        <button type="button" className="btn-close"
                        onClick={()=>handleClear()} data-bs-dismiss="modal"></button>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                        <div className="modal-body p-4">
                            <div className='row text-start'>
                                <div className="col mb-3">
                                    <label htmlFor="email" className="form-label">Email <span className='required-field'>*</span></label>
                                    <input type="text" className={`form-control p-2 ${errors.email ? "is-invalid" : ""}`} {...register('email', {  validate: (value) => { return !!value.trim()},required: true }, { pattern: /^\S+@\S+$/i })} />
                                    {(errors.email && errors.email.type === 'required') ? <div className="invalid-feedback">This field is required</div> : ''}
                                    {(errors.email && errors.email.type === 'pattern') ? <div className="invalid-feedback">Enter a valid email id</div> : ''}
                                    {isSuccess ? <p className="text-danger mt-2" style={{ fontSize: '14px' }}>
                                        {invalidError.email}</p>
                                        : null
                                    }
                                </div>
                                <div className="col mb-3">
                                    <label htmlFor="user" className="form-label">Username <span className='required-field'>*</span></label>
                                    <input type="text" defaultValue="" className={`form-control p-2 ${errors.user ? "is-invalid" : ""}`} {...register('user', { validate: (value) => { return !!value.trim()}, required: true })} />
                                    {(errors.user && errors.user.type === 'required') ? <div className="invalid-feedback">This field is required</div> : ''}
                                    {isSuccess ? <p className="text-danger mt-2" style={{ fontSize: '14px' }}>
                                        {invalidError.username}</p>
                                        : null
                                    }
                                </div>
                            </div>
                            <div className='row text-start'>
                                <div className="col mb-3">
                                    <label htmlFor="firstname" className="form-label">First Name <span className='required-field'>*</span></label>
                                    <input type="text" className={`form-control p-2 ${errors.firstname ? "is-invalid" : ""}`} {...register('firstname', { validate: (value) => { return !!value.trim()}, required: true })} />
                                    {(errors.firstname && errors.firstname.type === 'required') ? <div className="invalid-feedback">This field is required</div> : ''}
                                </div>

                                <div className="col mb-3">
                                    <label htmlFor="lastname" className="form-label">Last Name <span className='required-field'>*</span></label>
                                    <input type="text" className={`form-control p-2 ${errors.lastname ? "is-invalid" : ""}`} {...register('lastname', { validate: (value) => { return !!value.trim()}, required: true })} />
                                    {(errors.lastname && errors.lastname.type === 'required') ? <div className="invalid-feedback">This field is required</div> : ''}
                                </div>
                            </div>
                            <div className='row text-start'>
                                <div className="col mb-3">
                                    <label htmlFor="newpassword" className="form-label">Password <span className='required-field'>*</span></label>
                                    <div className='input-icon'>

                                        <input type={passwordShown ? "text" : "password"} defaultValue="" className={`form-control p-2 ${errors.newpassword ? "is-invalid" : ""}`} {...register('newpassword', { validate: (value) => { return !!value.trim()}, required: true })}
                                        autoComplete="new-password" />
                                        <div onClick={togglePassword}>
                                            {passwordShown === true ?
                                                <span><AiFillEye color='#858585' /></span>
                                                : <span><AiFillEyeInvisible color='#858585' /></span>}
                                        </div>
                                    </div>
                                    {(errors.newpassword && errors.newpassword.type === 'required') ? <div className="invalid-feedback">This field is required</div> : ''}
                                </div>
                                <div className="col mb-3">
                                    <label htmlFor="confirmpassword" className="form-label">Confirm Password <span className='required-field'>*</span></label>
                                    <div className='input-icon'>
                                        <input type={passwordShownc ? "text" : "password"} className={`form-control p-2 ${errors.confirmpassword ? "is-invalid" : ""}`} {...register('confirmpassword', { validate: (value) => { return !!value.trim()}, required: true })} />
                                        <div onClick={togglePasswordc}>
                                            {passwordShownc === true ?
                                                <span><AiFillEye color='#858585' /></span>
                                                : <span><AiFillEyeInvisible color='#858585' /></span>}
                                        </div>
                                    </div>
                                    {(errors.confirmpassword && errors.confirmpassword.type === 'required') ? <div className="invalid-feedback">This field is required</div> : ''}
                                </div>
                                {isSuccess ? <p className="text-danger " style={{ fontSize: '14px' }}>
                                    {invalidError.password}</p>
                                    : null
                                }
                                   {validatePass ? <div className='text-danger'>
                                      
                                        {validatePass}</div> : ""}
                            </div>
                        </div>
                        <div className="modal-footer ">
                            <button type="button" id="closeUsrModal" onClick={()=>handleClear()} className="btn btn-cancel text-white px-2" data-bs-dismiss="modal">Cancel</button>
                            <button type="submit" disabled={isSuccess===false&&dataLoading?true:false} className="btn btn-save text-white px-3">Save </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default CreateUserModal;