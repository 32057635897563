import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { ImWarning } from 'react-icons/im';
import DeleteIcon from '../../../../assets/img/sample-icons/delete.gif';
//API
import MasterDataService from '../../../../services/masterdata';

function DeleteMarkerModal(props) {
    //modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [isTrue, setIsTrue] = useState(false);
    const [displayImg, setDisplayImg] = useState(false);
    const id = props.data.id;
    const markerSuccess = () => toast.success("Marker deleted successfully!");
    const markerError = () => toast.error("Marker couldn't be deleted!");

    const msds = new MasterDataService();

    function delMarker(val) {
        let reqData = {
            "conform_data": val,
            "mdmarkerid": id
        }
        msds.DeleteMarker(reqData)
            .then((response) => {
                if (response.data) {
                    setIsTrue(false);
                    setDisplayImg(false);
                    hideModal('closeDelMarker');
                    markerSuccess();
                    props.data.getMarkers();

                }
            }).catch(function (error) {
                // if (error.response.status == 405) {
                    setTimeout(() => {
                        setDisplayImg(true);
                    }, 5000);
                    setIsTrue(true);
                // }
            })
    }
    function hideModal(target) {
        document.getElementById(target).click();
    };

    function handleCancel() {
        setIsTrue(false);
        setDisplayImg(false);
    }
    
    return (
        <>
            <div className="modal" id="deleteMarker" data-bs-backdrop="false"
                tabIndex="-1" >
                <div className="modal-dialog shadow-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            {isTrue ? <>{displayImg ? <h4 className="modal-title text-danger"><ImWarning className='me-2 mb-1 text-danger' />Re-Confirm Delete</h4> : ""}</> : <h4 className="modal-title">Confirm Delete</h4>}
                            <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => handleCancel()}></button>
                        </div>
                        <div className="modal-body p-4 text-center">
                            {/* <img src={DeleteIcon} width="100" height="100" className="mx-auto" /> */}
                            {isTrue ? <>{displayImg ? <h6 className='text-start'>This marker is associated with service requests, do you still want to delete?</h6> : <img src={DeleteIcon} className="ms-auto" alt="delete-icon" width="200" height="200" />}</> :
                                <h6 className='text-start'>Are you sure you want to delete this Marker?</h6>}
                        </div>

                        <div className="modal-footer ">
                         {isTrue? <>{displayImg ? <button type="button" id="closeDelMarker" className="btn btn-save text-white px-3" onClick={() => handleCancel()} data-bs-dismiss="modal">No</button>:""}</>:
                         <button type="button" id="closeDelMarker" className="btn btn-save text-white px-3" onClick={() => handleCancel()} data-bs-dismiss="modal">No</button>}  
                           
                            {isTrue ? <>{displayImg ? <button type="button" className="btn btn-cancel text-white px-3" onClick={() => delMarker("True")}>Yes</button> : ""}</> :
                                <button type="button" className="btn btn-cancel text-white px-3" onClick={() => delMarker("False")}>Yes</button>}
                        </div>

                    </div>
                </div>
            </div>
            {/* DELETE ASSOCIATED PATIENT ACCOUNT MODAL*/}
            {/* <div className="modal" id="deleteCMarker" data-bs-backdrop="false"
                tabIndex="-1" >
                <div className="modal-dialog shadow-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title"><ImWarning className='me-2 mb-1 text-danger' />Re-Confirm Delete</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                        </div>
                        <div className="modal-body p-4 text-start">
                            
                        </div>
                        <div className="modal-footer ">
                            <button type="button" id="closeDelCMarker" className="btn btn-save text-white px-3" data-bs-dismiss="modal">No</button>
                            <button type="button" className="btn btn-cancel text-white px-3" onClick={() => delMarker("True")}>Yes</button>
                        </div>

                    </div>
                </div>
            </div> */}
            {/* <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title><ImWarning className='me-2 mb-1 text-danger' />Re-Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>This marker is associated with service requests, do you still want to delete?</Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                        No
                    </Button>
                    <Button variant="primary" onClick={() => delMarker("True")}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal> */}
        </>
    );
}

export default DeleteMarkerModal;