import React, { useEffect, useState } from 'react';
import '../../../assets/styles/pipelinerequest/tabcontent/associatedocument.scss';
import { useForm } from "react-hook-form";
import { DropzoneArea } from 'material-ui-dropzone';
import edfImage from '../../../assets/img/edfimage.png';
import PipelineRequestService from '../../../services/PipelineRequest';
import { ClipLoader } from 'react-spinners';
import { useLocation } from 'react-router-dom';
import AssociatedDocumentService from '../../../services/AssociatedDocumentService';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Tabs, Tab, TabContent } from "react-bootstrap";
import {format} from 'date-fns';


export default function EdfProcessing() {
    const location = useLocation();
    const [showBox, setShowBox] = useState();
    const { register, handleSubmit,  formState: { errors } } = useForm();
    const [lowband, setLowband] = useState("");
    const [highband, setHighband] = useState("");
    const [selectedFile,setSelectedFile] = useState([]);
    const [unSelectedFile,setUnSelectedFile] = useState([]);
    const [loading, setLoading] = useState(false);
    const pr = new PipelineRequestService();
    const ads = new AssociatedDocumentService();
    const pipelineReqID = location.state.reqId;
    const [selectedCheck,setSelectedCheck] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [file, setFile] = useState(null);
    const [afterfile, setAfterFile] = useState(null);
    const [title, setTitle] = useState("");
    const [data, setData] = useState(null);
    const [overallData, setOverallData] = useState([]);
    const [edfList, setEdfList] = useState([]);
    const [checkedDoc, setCheckedDoc] = useState([]);
    const [selectedOption, setSelectedOption] = useState();
    const [isSelected, setIsSelected] = useState(false);
    const [docType, setDocType] = useState();
    const maxLength = 7;
    const [docChoice, setDocChoice] = useState([]);
    const [docTypeChoice, setDocTypeChoice] = useState([]);
    const [selectedValue, setSelectedValue] = useState("");
    const [processing, setProcessing] = useState(false);
    const[selTab, setSelTab]= useState(null);
    const [filess, uploadsetFiles] = useState([]);

    const documentuploadnotify = () => toast.success("Document uploaded successfully!");
    const documentuploadnotifyErr = () => toast.error("Document couldn't be uploaded!");
    const handleChoiceChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        setDocType(selectedOption.value);
        setIsSelected(true);
        setShowBox(!showBox);
    };
    const optionss = docChoice.map(function (docChoice) {
        return {
            value: docChoice,
            label: docChoice,
        };
    });
    function fetchDocChoiceField() {
        ads.GetDocChoiceField().then((response) => {
            // if (isMounted) {
            if (response.data.status === "success") {
                setDocTypeChoice(response.data.data);
                setDocChoice(response.data.data);
            }
            // }
        })
    }
    useEffect(() => {
        fetchDocChoiceField();
    }, []);

    //UPLOAD DOCUMENT
    const onSubmit = (data) => {
        setIsLoading(true);
        let formData = new FormData();
        for (let i = 0; i < filess.length; i++) {
            formData.append(`File`, filess[i])
        }
        let InputJson = {
            requestid: location.state.reqId,
            userid: localStorage.getItem('userid'),
            doctype: docType
        }
        formData.append('InputJson', JSON.stringify(InputJson));
        ads.SaveMultipleFile(formData).then((response) => {
            if (response.data.status === "success") {
                setIsLoading(false);
                hideModal('uploadclose');
                documentuploadnotify();
            }
        })
            .catch(function (error) {
                hideModal('uploadclose');
                documentuploadnotifyErr();
            })
    }

    function hideModal(target) {
        document.getElementById(target).click();
    };

    const handleCancel = () => {
        setSelectedValue("");
    }
    const onFileChange = (file) => {
        uploadsetFiles(file);

    }

    const getEdf = () => {
        let data = {
            "req_id": pipelineReqID,

        }
        setData(null);
        pr.GetEdfJobdata(data).then((res) => {
            if (res.data) {
                setProcessing(true);
                let val = res.data.Job_info[0].job_id;
                setSelTab(val);
                setData(res.data.Job_info);
                // console.log("wrost day", res.data.Job_info);
                let id = res.data.Job_info[0].Docid;
                setOverallData(res.data.data);
                getEdfJob(id);
                
            }
        })
    }
    console.log("sel",data,selTab);
    useEffect(() => {
        getEdf();
    }, []);

    function docListEdf() {
        setProcessing(false);
        setSelectedCheck(false);
        const reqData = pipelineReqID;
        pr.GetDoclistEdf(reqData)
            .then((response) => {
                if (response.data) {
                    setEdfList(response.data.edf_list);
                    let d=response.data.edf_list;
                    let a1=[];
                    let a2=[];
                    for(let i=0;i<d.length;i++){
                        if(d[i].job_status==true){
                            // setSelectedCheck(true);
                            // setCheckedDoc([...checkedDoc], d[i].docid);   
                            console.log("aaa",d[i]); 
                            a1.push(d[i]);    
                                                
                        }
                        else{
                            // setCheckedDoc([...checkedDoc], d[i].docid);
                            a2.push(d[i]);
                            // setUnSelectedFile(unSelectedFile.push(d[i]));
                        }
                        setSelectedFile(a1);
                        setUnSelectedFile(a2);
                        // else if(d[i].job_status==false){
                        //     setUnCheckedDoc([...uncheckedDoc], d[i].docid); 
                        // }
                    }
                }

            }).catch(function (error) {

            })
    }
    useEffect(() => {
        let mounted = true;
        docListEdf();
        return () => { mounted = false }
    }, [])
   

    const addEdf = () => {
        setProcessing(true);
        setSelectedCheck(true);
        let data = {
            "req_id": pipelineReqID,
            "docid": checkedDoc.toString()
        }
        pr.AddEdfJobdata(data).then((res) => {
            if (res.data) {
                setCheckedDoc(res.data);
                // docListEdf();
                // setUnCheckedDoc(res.data);
               
            }
        })
    }

    function getEdfJob(id) {
        pr.GetEdfJob(id)
            .then((res) => {
                if (res.data) {
                    console.log("abcd", res.data);
                }

            }).catch(function (error) {

            })
    }
console.log("file",file);

    const callEdf = (val) => {
        setFile(val);
        setAfterFile(val);
        // console.log("vallll",val);
        setLoading(true);
        // setFile(null);
        // let data = {
        //     "docid": id,
        //     "Lowbandpass": Number(lowband),
        //     "highbandpass": Number(highband)
        // }
        // pr.getEDFdata(data).then((res) => {
        //     if (res.data) {
        //         setLoading(false);
        //         setFile(res.data.Raw_Graph);
        //     }
        // })
    }

    console.log("aftn",checkedDoc);

    
    const changeDocument = id => e => {
        if (e.target.checked == true) {
            checkedDoc.push(e.target.value);
            setCheckedDoc([...checkedDoc], checkedDoc);
            // setUnCheckedDoc([...uncheckedDoc], uncheckedDoc);
            // setEdfList(current =>
            //     current.map(obj => {
            //         console.log("test",obj.jobid,id);
            //         if (obj.id === id) {
            //             return { ...obj, job_status: true };
            //         }
            //         return obj;
            //     }),
            // );
            

        } 
        else {
            for (let i = 0; i < checkedDoc.length; i++) {
                if (checkedDoc[i] == e.target.value) {
                    setCheckedDoc(checkedDoc.filter((fruit) => fruit !== e.target.value));
                    
                }
            }
        }
        
    }


    return (
        <div className='tab-content p-2 text-start'>
            <div className='bg-light px-2 pt-2 pb-3 row mb-2 mx-1 mt-2'>
                {processing == false ? <>
                    <h6 className='text-bold fw-bold mb-0'>EDF Selection</h6>
                    <div className='ms-3 pe-5 row '>
                        <div className='d-flex px-0'>
                            <label className='col mb-1 fs-6 my-auto'>From available EDF File</label>
                            <div className='col-auto text-end mb-1'>
                                {/* <button type="button" className='btn btn-saveInt p-1 text-white rounded' data-bs-toggle="modal" data-bs-target="#createDoc">Upload New EDF</button> */}
                            </div>
                        </div>
                        <div className='border p-2 bg-white edf-container '>
                            <div className='row p-3'>
                                <div className='col-md'>
                                    {selectedFile.map((o, i) => {
                                        return <div className="form-check text-start" key={i}>
                                            <input className="form-check-input" type="checkbox" value={o.id} id={o.id} checked={o.job_status} disabled={o.job_status } />
                                            <label className="form-check-label" >
                                                {o.filename}
                                            </label>
                                        </div>
                                    })}
                                     {unSelectedFile.map((o, i) => {
                                        return <div className="form-check text-start" key={i}>
                                            <input className="form-check-input" type="checkbox" value={o.id} id={o.id} onChange={changeDocument(o.id)} />
                                            <label className="form-check-label" >
                                                {o.filename}
                                            </label>
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </> :
                    <>
                        {/* {processing == false ? "" :
                            <div className='text-end btn-bg'>
                                <button className=' btn btn-back' onClick={() => docListEdf()}>
                                   <span><BsChevronDoubleLeft color="black" /></span> 
                                </button>
                            </div>
                        } */}
                        <h6 className='text-bold fw-bold mb-0'>EDF Processing</h6>
                        <div className='ms-2 pe-3 row '>
                            <div className='col-md-2 my-auto'>
                                No. of Files:  <a onClick={() => setProcessing(false)} className='bg-green edf-link'>{overallData.total_edf_files}</a>
                                {/* No. of Files: 2 */}
                            </div>
                            <div className='col-md-2 my-auto'>
                                Completed:{overallData.total_completed}
                            </div>
                            <div className='col-md-2 my-auto'>
                                Processing:{overallData.processing_cnt}
                            </div>
                            <div className='col-md-2 my-auto text-danger'>
                                Rejected/Incompleted:{overallData.cnt_job_rejected}
                            </div>
                            <div className='col my-auto fw-bold text-center text-capitalize '>
                               <div className="progress-b">
                                <h6>In Progress</h6>
                                </div>
                                {/* {data.status} */}
                            </div>
                        </div>
                    </>}
            </div>
            {processing == true ? null :
                <div className='row me-4'>
                    <div className='col-md-6 ms-auto pe-3 text-end'>
                        <button type="button" className='btn btn-saveInt p-1 text-white rounded' disabled={checkedDoc.length>0?false:true} onClick={() => addEdf()}>Start EDF Processing</button>
                    </div>
                </div>
            }
            {/* {data[0].job_id} */}
            {processing == true && data !==null ?
                <div className='row py-2 px-3'>
                    <Tabs fill defaultActiveKey={selTab} id="uncontrolled-tab-example" className='w-auto pe-0'>
                        {data.map((d, i) => {
                            return (
                                <Tab
                                    eventKey={d.job_id}
                                    title={d.doc_name.substring(0, maxLength - 2) + "...(Job #) " + d.job_id}
                                    //   className=' me-0 border-bottom' 
                                    mountOnEnter // <<<
                                    unmountOnExit={false} // <<<
                                >
                                    <hr className='mt-0' />
                                    <TabContent className='w-100 p-0' >
                                        {/* <TabPane eventKey={d.job_id}> */}
                                        <div className='row bg-light p-2 mx-1 mt-2'>

                                            <div className='form-group col'>
                                                <label className='fw-bold'>Channels per Frame: </label><br></br><span className='ms-1'>{d.channels_per_frame != null ? d.channels_per_frame : 12} </span>
                                            </div>
                                            <div className='form-group col'>
                                                <label className='fw-bold'>Epochs: </label><br></br><span className='ms-1'>{d.epochs != null ? d.epochs : 1}</span>
                                            </div>
                                            <div className='form-group col'>
                                                <label className='fw-bold'>Sampling Rate (Hz):</label><br></br><span className='ms-1'>{d.sample_rate_hz != null ? d.sample_rate_hz : 256}</span>
                                            </div>
                                            <div className='form-group col'>
                                                <label className='fw-bold'>Epochs Start (sec): </label><br></br><span className='ms-1'>{d.epochs_start != null ? d.epochs_start : 0.000}</span>
                                            </div>
                                            <div className='form-group col'>
                                                <label className='fw-bold'>Epochs End (sec): </label><br></br><span className='ms-1'>{d.epochs_end != null ? d.epochs_end : 488.002}</span>
                                            </div>
                                        </div>
                                        <div className='row  p-2 mx-1 my-2'>

                                            <div className='form-group col'>
                                                <label className='fw-bold'>Job #: </label><span className='ms-1'> {d.job_number}</span>
                                            </div>
                                            <div className='form-group col'>
                                                <label className='fw-bold'>Submitted On: </label><span className='ms-1'> {format(new Date(d.submitted_on),"MM-dd-yyyy")}</span>
                                            </div>
                                            <div className='form-group col'>
                                                <label className='fw-bold'>Submitted by:</label><span className='ms-1'> {overallData.account_name}</span>
                                            </div>
                                            <div className='form-group col'>
                                                <label className='fw-bold'>Overall Status: </label><span className='ms-1 text-capitalize'
                                                >{d.job_status}</span>
                                            </div>

                                        </div>

                                        <div className="table bordered responsive-table px-1 mt-2">
                                            <table className='w-100 bordered'>
                                                <thead>
                                                    <tr>
                                                        <th className='p-2'>Steps</th>
                                                        <th className='p-2 text-center'>Status</th>
                                                        <th className='p-2 text-center'>Output View</th>
                                                        <th className='p-2'>Comments</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className='p-2 heading'><td className="p-2">PreProcessing</td><td></td><td></td><td></td></tr>
                                                    {d.processing_steps.map((item, i) => {
                                                        if (item.heading == "Preprocessing") {
                                                            return (
                                                                <tr key={i} >
                                                                    <td className='p-2'>{item.step_name}</td>
                                                                    <td className={`p-2 text-center `}><div className={`p-1 mx-auto   ${item.step_status}`}>{item.step_status}</div></td>
                                                                    <td className='p-2 text-center'>{item.step_status == "complete" ? <a onClick={() => { setFile(item.before_output);setAfterFile(item.output); setTitle(item.step_name) }} data-bs-toggle="modal" data-bs-target="#filleview" style={{ cursor: 'pointer' }}><img src={edfImage} alt="edf image" width="30" height="20" /></a>
                                                                        : "----"}</td>
                                                                    <td className='p-2'>{item.comments}</td>
                                                                </tr>)
                                                        }
                                                    })}

                                                    <tr className='p-2 heading'><td className="p-2">EEG Feature Extraction</td><td></td><td></td><td></td></tr>
                                                    {d.processing_steps.map((item, i) => {
                                                        if (item.heading == "EEG Feature Extraction") {
                                                            return (
                                                                <tr key={i}>
                                                                    <td className='p-2'>{item.step_name}</td>
                                                                    <td className={`p-2 text-center`}><div className={`p-1 mx-auto  ${item.step_status}`}>{item.step_status}</div></td>
                                                                    <td className='p-2 text-center'>{item.step_status == "complete" ? <a onClick={() => { setFile(item.before_output);setAfterFile(item.output); setTitle(item.step_name) }} data-bs-toggle="modal" data-bs-target="#filleview" style={{ cursor: 'pointer' }}><img src={edfImage} alt="edf image" width="30" height="20" /></a>
                                                                        : "----"}</td>
                                                                    <td className='p-2'>{item.comments}</td>
                                                                </tr>
                                                            )
                                                        }
                                                    })}

                                                </tbody>
                                            </table>
                                        </div>
                                        {/* </TabPane> */}
                                    </TabContent>

                                </Tab>

                            )
                        })}
                    </Tabs>
                </div>
                : null}


            {/* {UPLOAD DOCUMENT START} */}
            <div className="modal" id="createDoc" data-bs-backdrop="false" tabIndex="-1">
                <div className="modal-dialog shadow-lg ">
                    <div className="modal-content">
                        <div className="modal-header border-bottom">
                            <h4 className="modal-title">Upload Associated document(s)</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                            ></button>
                            <hr />
                        </div>
                        <form>
                            <div className="modal-body px-4 py-2">
                                <div className={`mx-3 upload-box showBox`}>
                                    <label>Eye Type</label>
                                    <select className='form-select form-control ' onChange={(e) => setSelectedValue(e.target.value)}>
                                        <option value=""></option>
                                        <option value="eyeopen">Eye Open</option>
                                        <option value="eyeclosed">Eye Closed</option>
                                    </select>
                                    <div className='text-start mt-3 mb-0 pb-0 ps-1' style={{ color: 'red' }}>
                                        <li>Supported document format: edf</li>
                                        <li>Maximum size : 50MB</li>
                                    </div>
                                    <DropzoneArea
                                        {...register("MultiFile")}
                                        onChange={onFileChange}
                                        // acceptedFiles={".edf"}
                                        // onChange={handleChange}
                                        showFileNames
                                        dropzoneText="Drop files to upload"
                                        showAlerts={false}
                                        filesLimit={20}
                                        maxFileSize={52428800}
                                    />
                                </div>
                                {/* {isLoading ? <> <ClipLoader size={15} color="blue" /> <span className='ps-1'>Uploading...</span></> : ""} */}
                            </div>
                            <div className="modal-footer me-4">

                                <div className='group-btn'>
                                    <button type="button" id="uploadclose" className="btn btn-cancel text-white px-2 text-end" data-bs-dismiss="modal"
                                        onClick={() => handleCancel()}>Cancel</button>
                                    <button type="submit" className="btn btn-save text-white px-2 text-end ms-2">Upload </button>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {/* {UPLOAD DOCUMENT END} */}
            <div className="modal" id="s" data-bs-backdrop="false" tabIndex="-1" style={{ width: '400px' }}>
                <div className="modal-dialog shadow-lg " >

                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Upload Edf document(s)</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                onClick={() => handleCancel()}></button>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="modal-body p-4">
                                <div className='row text-start mx-1'>
                                    <label htmlFor="state" className="form-label ps-3">Type</label>
                                    <Select
                                        placeholder="Select"
                                        isDisabled={isSelected === true ? true : false}
                                        value={selectedOption}
                                        onChange={handleChoiceChange}
                                        options={optionss}
                                    />
                                </div>
                                {isSelected ?
                                    <>
                                        <div className={`mx-3 upload-box ${showBox ? "showBox" : ""}`}>
                                            <div className='text-start mt-3 mb-0 pb-0 ps-1' style={{ color: 'red' }}>
                                                <li>Supported document format: edf</li>
                                                {/* <li>Maximum size : 50MB</li> */}
                                            </div>
                                            {/* <DropzoneArea
                                                {...register("MultiFile")}
                                                onChange={onFileChange}
                                                acceptedFiles={[".edf",".xlsx"]}
                                                // onChange={handleChange}
                                                showFileNames
                                                dropzoneText="Drop files to upload"
                                                showAlerts={false}
                                                filesLimit={20}
                                                maxFileSize={52428800}
                                            /> */}
                                        </div>
                                        {isLoading ? <> <ClipLoader size={15} color="blue" /> <span className='ps-1'>Uploading...</span></> : ""}
                                    </>
                                    : null}

                            </div>
                            <div className="modal-footer me-4">
                                {isSelected ?
                                    <div className='group-btn'>
                                        <button type="button" id="uploadclose" className="btn btn-cancel text-white px-2 text-end" data-bs-dismiss="modal"
                                            onClick={() => handleCancel()}>Cancel</button>
                                        <button type="submit" disabled={isLoading ? true : false} className="btn btn-save text-white px-2 text-end ms-2">Upload </button>
                                    </div>
                                    : null}
                            </div>
                        </form>
                    </div>

                </div>
            </div>

            <div className="modal" id="filleview" data-bs-backdrop="false" tabIndex="-1">
                <div className="modal-dialog shadow-lg test">
                    <div className="modal-content">
                        <div className="modal-header border-bottom">
                            <h4 className="modal-title">{title}</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                            ></button>
                            <hr />
                        </div>

                        {/* <div className='text-end p-2'>
                                <button type="button" className="btn-close" onClick={() => setFile(null)}></button>
                            </div> */}
                        {/* <div className='modal-body'>
                                <iframe src={`data:application/pdf;base64,${file}`} width="100%" height="420" />
                            </div> */}

                        {/* <form> */}

                        <div className="modal-body px-4 py-2">
                            {file !== null ?
                            <div className='row'>
                            <div className='col'> 
                            <label className='ps-3 '>Before:</label>
                            <img src={`data:image/png;base64,${file}`} alt="data" width={100} height="100" className='col-md-12' />
                            </div>
                            <div className='col'>
                            <label className='ps-3 '>After:</label>
                            <img src={`data:image/png;base64,${afterfile}`} alt="data" width={100} height="100" className='col-md-12' />
                            </div>
                        </div>
                                 : "" }
                        </div>
                        {loading ? <div className='text-center' style={{ height: '100px' }}> <ClipLoader size={15} color="blue" className="mx-auto" /> </div> : null}
                        <div className="modal-footer me-4">
                            <div className='group-btn'>
                                <button type="button" id="uploadlose" className="btn btn-cancel text-white px-2 text-end" data-bs-dismiss="modal"
                                    onClick={() => { setFile(null); setLoading(false); setLowband(""); setHighband("") }}  >Cancel</button>
                               
                            </div>
                        </div>
                        {/* </form> */}

                    </div>
                </div>
            </div>
        </div>

    )
}