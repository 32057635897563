import { ax } from '../services/base';
import config from '../assets/config';

export default class jobManagerService {
    
    //job manager docrepo/edf_list_md
    getJobManager(payload){
        return ax.post(`${config.apiURL}docrepo/edf_list_md`,payload);
    }

    //docrepo/edf_list_md/117
    GetJobManagerList(id) {
        return ax.get(`${config.apiURL}docrepo/edf_list_md/${id}`); 
    }
    GetEdfJob(reqId) {
        return ax.get(`${config.apiURL}automate/get_edf_job/${reqId}`); 
    }
}