import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
// import NeuroToolbar, { modules, formats } from "../tabcontent/subcontent/toolbar/NeuroToolbar";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RecordingAnalysisService from '../../../services/RecordingAnalysis';
import EyesClosedToolbar, { modules,formats} from '../tabcontent/subcontent/toolbar/EyesClosedToolbar';

export default function EyeClosedEditor(props) {
    const [textEC,setTextEC] = useState({value:""});
    const location = useLocation();
    const [descid, setDescID] = useState(0);
    const pipelineReqID = location.state.reqId;

    const notifyNeuro = () => toast.success("Neurofeedback updated successfully!");
    const notifyNeuroErr = () => toast.error("Neurofeedback couldn't be updated!");
    //api
    let rec = new RecordingAnalysisService();

    //function callback
    const neuroCallback = (val) => {
        props.data.neurofeedbackECCallback(val);
    }

    function fetchAssociatedNeuroFeedback() {
        const reqData = pipelineReqID;
        rec
            .getNeurofeedbackAll(reqData)
            .then((response) => {
                if (response.data) {
                    setDescID(response.data.data.id);
                    setTextEC({value:response.data.data.neurofeedback_EC});
                }
            })
            .catch(function (error) { });
    }
    useEffect(() => {
        let isMounted = true;
        fetchAssociatedNeuroFeedback();

        return () => {
            isMounted = false;
        };
    }, []);
    const onSubmit = () => {
        let reqData = {
            "sr_associate_neurofeedback":descid,
            "service_request":pipelineReqID,
            "description":"null",
            
            "neurofeedback_EC": textEC.value,
            // sr_associate_neurofeedback: descid,
            // service_request: pipelineReqID,
            // description: text.value,
        };
        rec
            .saveNeurofeedbackAll(reqData)
            .then((response) => {
                if (response.data) {
                    if (response.data.status === "success") {
                        fetchAssociatedNeuroFeedback();
                        // hideModal("closeneuro");
                        notifyNeuro();
                        neuroCallback(false);
                        props.data.exportPreview();
                        // setMsg(" Updated successfully!");
                        // setTimeout(() => {
                        //   setMsg("");
                        // }, 5000);
                    }
                }
            })
            .catch(function (error) {
                // setErrorMsg(true);
                notifyNeuroErr();
            });
    };

    const handleECChange = (value) => {
        setTextEC({ value });
    };
    return (
        <div>
           
            <EyesClosedToolbar/>
            <ReactQuill
            value={textEC.value}
            modules={modules}
            formats={formats}
            onChange={handleECChange}
            ></ReactQuill>
            <div className='text-end my-2'>
                <button type="button" className='btn btn-save ms-auto text-white' onClick={onSubmit}>Save</button>
            </div>
        </div>
    );
}
