import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import AdjunctToolbar, { modules1, formats1 } from "../tabcontent/subcontent/toolbar/AdjunctToolbar";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PipelineRequestService from '../../../services/PipelineRequest';

export default function AdjunctTherapiesEditor(props) {
    const [text, setText] = useState({ value: "" });
    const location = useLocation();
    const [descid, setDescID] = useState(0);
    const pipelineReqID = location.state.reqId;
    const notifyAdj = () => toast.success("Adjunct therapy updated successfully!");
    const notifyAdjErr = () => toast.error("Adjunct therapy couldn't be updated!");
    //api
    let pipreq = new PipelineRequestService();

    //callback function
    const adjunctTherapyCallback=(val)=>{
        props.data.adjunctCallback(val);
    }

    function fetchAdjunctTherapy() {
        const reqData = pipelineReqID;
        pipreq.getAdjunctTherapy(reqData)
            .then((response) => {

                if (response.data) {
                    setDescID(response.data.data.id);
                    setText({ value: response.data.data.description });
                }
            }).catch(function (error) {

            })
    }

    useEffect(() => {
        let mounted = true;
        fetchAdjunctTherapy();
        return () => { mounted = false }
    }, [])
    const onSubmit = (data) => {
        let reqData = {
            "sr_associate_adjunctTherapies": descid,
            "service_request": pipelineReqID,
            "description": text.value,
        }

        pipreq.saveAdjunctTherapy(reqData)
            .then((response) => {
                if (response.data) {
                    if (response.data.status === 'success') {

                        fetchAdjunctTherapy();
                        notifyAdj();
                        adjunctTherapyCallback(false);
                        props.data.exportPreview();
                        // setMsg(" Updated successfully!");
                        // setTimeout(() => { setMsg(""); }, 5000);
                    }
                }
            })
            .catch(function (error) {
                // setErrorMsg(true);
                notifyAdjErr();
            })
    }

    const handleChange = value => {
        setText({ value });
    };

    return (
        <div>
            <AdjunctToolbar />
            <ReactQuill
                // theme="snow"
                value={text.value}
                modules={modules1}
                formats={formats1}
                onChange={handleChange}
            // onChange={this.handleProcedureContentChange}
            >
                {/* <div className="my-editing-area" /> */}
            </ReactQuill>
            <div className='text-end my-2'>
                <button type="button" className='btn btn-save ms-auto text-white' onClick={onSubmit}>Save</button>
            </div>
        </div>
    );
}