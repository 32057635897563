import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import { columns, options } from './AssociateData';
import { useForm } from "react-hook-form";
import Select from 'react-select';
import '../../../assets/styles/pipelinerequest/tabcontent/associatedocument.scss';
import paginationFactory from 'react-bootstrap-table2-paginator';
import AssociatedDocumentService from '../../../services/AssociatedDocumentService';
import { DropzoneArea } from 'material-ui-dropzone';
import { ClipLoader, BeatLoader } from "react-spinners";
import { toast } from 'react-toastify';
import { BsFileEarmarkArrowDownFill } from 'react-icons/bs';
import 'react-toastify/dist/ReactToastify.css';
import PipelineRequestService from '../../../services/PipelineRequest';
import { triggerBase64Download } from 'common-base64-downloader-react';
import FileSaver from "file-saver";
import FileViewer from "react-file-viewer";
import * as XLSX from 'xlsx';

function AssociatedDocument() {
    var base64 = require('base-64');
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const location = useLocation();
    const [docData, setDocData] = useState([]);
    const [showBox, setShowBox] = useState();
    const [getDocData, setGetDocData] = useState([]);
    const [isMounted, setIsMounted] = useState(true);
    const [isMounted1, setIsMounted1] = useState(true);
    const [docChoiceField, setDocChoiceField] = useState([]);
    const [isDocLoaded, setIsDocLoaded] = useState(false);
    const [stillLoading, setStillLoading] = useState(false);
    //eyeopen or eye close dropdown
    const [selectedDocOption, setSelectedDocOption] = useState();
    const [docTypeChoice, setDocTypeChoice] = useState([]);
    const [docuType, setDocuType] = useState([]);
    //equipment dropdown
    const [selectedEquip, setSelectedEquip] = useState();
    const [equipChoice, setEquipChoice] = useState([]);
    const [equipType, setEquipType] = useState();
    const [docFile, setDocFile] = useState("");
    //get row id
    const [docId, setDocId] = useState();
    const [msg, setMsg] = useState('');
    const [errmsg, seterrMsg] = useState('');
    const [erMsg, setErMsg] = useState('');
    const ads = new AssociatedDocumentService();
    const idv = new PipelineRequestService();
    const [ext, setExt] = useState();
    const [fileName, setfileName] = useState();
    const [docName, setDocName] = useState('');
    const [fileSize, setFileSize] = useState();
    const [documentName, setDocumentName] = useState('');
    // upload Doc
    const [docChoice, setDocChoice] = useState([]);
    const [selectedOption, setSelectedOption] = useState();
    const [isSelected, setIsSelected] = useState(false);
    const [filess, uploadsetFiles] = useState([]);
    const [docType, setDocType] = useState();
    //loader
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [extCode, setExtCode] = useState("");

    const documentupnotify = () => toast.success("Document updated successfully!");
    const documentupnotifyErr = () => toast.error("Document couldn't be updated!");
    const documentuploadnotify = () => toast.success("Document uploaded successfully!");
    const documentuploadnotifyErr = () => toast.error("Document couldn't be uploaded!");
    const documentdelnotify = () => toast.success("Document deleted successfully!");
    const documentdelnotifyErr = () => toast.error("Document couldn't be deleted!");

    const optionss = docChoice.map(function (docChoice) {
        return {
            value: docChoice,
            label: docChoice,
        };
    });

    const onFileChange = (file) => {
        uploadsetFiles(file);

    }
    const handleChoiceChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        setDocType(selectedOption.value);
        setIsSelected(true);
        setShowBox(!showBox);
    };

    //UPLOAD DOCUMENT
    const onSubmit = (data) => {
        setIsLoading(true);
        let formData = new FormData();
        for (let i = 0; i < filess.length; i++) {
            formData.append(`File`, filess[i])
        }
        let InputJson = {
            requestid: location.state.reqId,
            userid: localStorage.getItem('userid'),
            doctype: docType
        }
        formData.append('InputJson', JSON.stringify(InputJson));
        ads.SaveMultipleFile(formData).then((response) => {
            if (response.data.status === "success") {
                setIsLoading(false);
                FetchDocumentTable();
                hideModal('uploadclose');
                documentuploadnotify();
            }
        })
            .catch(function (error) {
                hideModal('uploadclose');
                documentuploadnotifyErr();
            })
    }

    function downloadDocument(docid) {
        let reqData = {
            docid: docid
        }
        idv.DownloadDoc(reqData).then((response) => {
            if (response.data.status === "success") {
                // if(response.data.data.encodefiledata!==""||response.data.data.encodefiledata!==null || response.data.data.encodefiledata!==[]){
                setStillLoading(false);
                setDocFile(response.data.data.encodefiledata);
                setExtCode(response.data.data.extension[1]);
                setLoading(true);
                setIsDocLoaded(false);
                downloadEx(response.data.data.encodefiledata, response.data.data.fileName);
            }
        })
    }

    const emptyMsg = () => {
        return "No data available in table";
    }

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            setEquipType('');
            setSelectedEquip('');
            setDocId(row.id);
            setIsDocLoaded(true);
            fetchDocumentDetails(row.id);
            downloadDocument(row.id);
            var filename = row.filename;
            var flname = row.orginal_name;
            setDocName(row.orginal_name);
            var fname = flname.split(".");
            setDocumentName(fname[0]);
            var NameofFile = filename.split(".");
            setfileName(NameofFile[0]);
            var fileExt = filename.split(".").pop();
            setExt(fileExt);
            setFileSize(row.size);
        },

    };

    const optionDoc = docTypeChoice.map(function (docTypeChoice) {
        return {
            value: docTypeChoice,
            label: docTypeChoice,

        };
    });
    const handleDocChange = (selectedDocOption) => {
        setSelectedDocOption(selectedDocOption);
        setDocuType(selectedDocOption.value);

    };
    const optionEquip = equipChoice.map(function (equipChoice) {
        return {
            value: equipChoice,
            label: equipChoice,

        };
    });
    const handleEquipChange = (selectedEquip) => {
        setSelectedEquip(selectedEquip);
        setEquipType(selectedEquip.value);

    };
    //Equipment dropdown
    function fetchEquipmentChoiceField() {
        ads.EquipmentChoiceField().then((response) => {
            if (isMounted1) {
                if (response.data.status === "success") {
                    setEquipChoice(response.data.data);

                }
            }

        })
    }
    //Eye open or close dropdown
    function fetchDocChoiceField() {
        ads.GetDocChoiceField().then((response) => {
            if (isMounted) {
                if (response.data.status === "success") {
                    setDocTypeChoice(response.data.data);
                    setDocChoice(response.data.data);
                }
            }
        })
    }
    //update document
    function fetchDocumentDetails(docId) {
        ads.GetDocument(docId).then((response) => {
            if (response.data.status === "success") {
                // setEquipType('');
                setEquipType('');
                setSelectedEquip('');
                setDocuType(response.data.data[0].doc_type);
                setEquipType(response.data.data[0].equipment_type);
                setGetDocData(response.data.data.data);
            }
        })
    }

    useEffect(() => {
        fetchDocChoiceField();
        return () => { setIsMounted(false) }
    }, []);
    useEffect(() => {
        fetchEquipmentChoiceField();
        return () => { setIsMounted1(false) }
    }, []);

    useEffect(() => {
        reset(equipType);
    }, [equipType]);

    const updateDocument = () => {
        let reqData = {
            "docid": docId,
            "doc_type": docuType,
            "equipment_type": equipType
        }
        ads.UpdateDocumentData(reqData).then((response) => {
            if (response.data.status === "success") {
                FetchDocumentTable();
                hideModal('updclose');
                setSelectedEquip('');
                setEquipType('');
                documentupnotify();
            }
        })
            .catch(function (error) {
                documentupnotifyErr();
                setTimeout(() => { seterrMsg("") }, 5000);
            })
    }

    function FetchDocumentTable() {
        let reqData = {
            "service_request": location.state.reqId,
            "DataFinder": {
                "pagesize": 1000,
                "currentpage": 1,
                "sortbycolumn": "created_on",
                "sortby": "desc",
                "searchdata": ""
            }
        }
        ads.GetDocumentTableData(reqData).then((response) => {
            if (response.data) {
                setDocData(response.data.DocumentDetail);
                setDocChoiceField(response.data.DocumentDetail);
            }
        })
    }

    function delDocument() {
        let reqData = {
            docid: docId
        }
        ads.DeleteDocument(reqData).then((response) => {
            if (response.data.status === "success") {
                FetchDocumentTable();
                hideModal('deleteclose');
                documentdelnotify();
            }
        })
            .catch(function (error) {
                documentdelnotifyErr();
                setTimeout(() => { seterrMsg("") }, 5000);
            })
    }

    useEffect(() => {
        FetchDocumentTable();
    }, []);


    function hideModal(target) {
        document.getElementById(target).click();
    };

    const handleCancel = () => {
        setIsSelected(false);
        setIsLoading(false);
        setShowBox(false);
        setSelectedOption("");
    }

    const downloadDoc = () => {
        if (ext === "xlsx" || ext === "xls") {
            triggerBase64Download(`data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${docFile}`, `${documentName}`);

        } else if (ext === "pdf") {
            triggerBase64Download(`data:application/pdf;base64,${docFile}`, `${documentName}`);
        }
        else if (ext === "doc") {
            triggerBase64Download(`data:application/msword;base64,${docFile}`, `${documentName}`);
        }
        else if (ext === "docx") {
            triggerBase64Download(`data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${docFile}`, `${documentName}`);
        }
        else if (ext === "edf" || ext === "EDF") {
            const bytes = new Uint8Array(
                atob(docFile)
                    .split('')
                    .map((char) => char.charCodeAt(0))
            );
            const blob = new Blob([bytes], { type: 'text/plain;charset=utf-8;base64' });
            const url = window.URL.createObjectURL(blob);
            FileSaver.saveAs(blob, `${docName}`);
        }
        else {
            return;
        }
        hideModal('deletecllose');
    }
    function hideModal(target) {
        document.getElementById(target).click();
    };
   
    
    const downloadEx = (docFile, fname) => {
        var name = fname;
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        reader.onload = (docFile) => { // evt = on_file_select event
            /* Parse data */
            const bstr = docFile;
            const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
            /* Get first worksheet */
            // let arr = this.xslToJson(wb);
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            // /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            /* Update state */
            // console.log("Data>>>" + data);
        };
        // if (rABS) reader.readAsBinaryString(docFile);
        // else
         reader.readAsArrayBuffer(docFile);
        // reader.readAsBinaryString(docFile);

    };
    return (
        <div className="tab-content px-4 py-3">
            <div className="err-message mx-auto">
                {msg ? <div className="success-msg">{msg}</div> : ''}
                {erMsg ? <div className="err-msg">{erMsg}</div> : ''}
                {errmsg ? <div className="err-msg">{errmsg}</div> : ''}
            </div>
            <div className='associate-doc'>
                <div className="row g-0 text-end px-2 ">
                    <h6 className='col text-start'>Upload Associated Document(s)</h6>
                    <button type='button' className='btn add-btn mb-3 col-md-4 px-0  text-white' data-bs-toggle="modal" data-bs-target="#createDoc" >
                        <span className='plus-icon mx-1 mb-0 pb-0'>+</span> Add Document
                    </button>
                    <hr className='divider mx-2 pe-2'></hr>
                </div>
                <div className='table-data px-0 mx-0'>
                    <BootstrapTable
                        keyField='id'
                        data={docData}
                        columns={columns}
                        tdStyle={{ whiteSpace: 'normal' }}
                        rowEvents={rowEvents}
                        noDataIndication={emptyMsg}
                        pagination={paginationFactory(options)}
                        wrapperClasses="table-responsive"
                    />
                </div>
            </div>

            {/* {UPLOAD DOCUMENT START} */}
            <div className="modal" id="createDoc" data-bs-backdrop="false" tabIndex="-1">
                <div className="modal-dialog shadow-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Upload Associated document(s)</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                onClick={() => handleCancel()}></button>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="modal-body p-4">
                                <div className='row text-start mx-1'>
                                    <label htmlFor="state" className="form-label ps-3">Type</label>
                                    <Select
                                        placeholder="Select"
                                        isDisabled={isSelected === true ? true : false}
                                        value={selectedOption}
                                        onChange={handleChoiceChange}
                                        options={optionss}
                                    />
                                </div>
                                {isSelected ?
                                    <>
                                        <div className={`mx-3 upload-box ${showBox ? "showBox" : ""}`}>
                                            <div className='text-start mt-3 mb-0 pb-0 ps-1' style={{ color: 'red' }}>
                                                <li>Supported document format: pdf, edf, xls, xlsx, doc, docx</li>
                                                <li>Maximum size : 50MB</li>
                                            </div>
                                            <DropzoneArea
                                                {...register("MultiFile")}
                                                onChange={onFileChange}
                                                acceptedFiles={[".pdf,.xls,.xlsx,.edf,.doc,.docx"]}
                                                // onChange={handleChange}
                                                showFileNames
                                                dropzoneText="Drop files to upload"
                                                showAlerts={false}
                                                filesLimit={20}
                                                maxFileSize={52428800}
                                            />
                                        </div>
                                        {isLoading ? <> <ClipLoader size={15} color="blue" /> <span className='ps-1'>Uploading...</span></> : ""}
                                    </>
                                    : null}

                            </div>
                            <div className="modal-footer me-4">
                                {isSelected ?
                                    <div className='group-btn'>
                                        <button type="button" id="uploadclose" className="btn btn-cancel text-white px-2 text-end" data-bs-dismiss="modal"
                                            onClick={() => handleCancel()}>Cancel</button>
                                        <button type="submit" disabled={isLoading ? true : false} className="btn btn-save text-white px-2 text-end ms-2">Upload </button>
                                    </div>
                                    : null}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {/* {UPLOAD DOCUMENT END} */}

            {/* UPDATE DOCUMENT  STARTS*/}
            <div className="modal" id="editDoc" data-bs-backdrop="false" tabIndex="-1">
                <div className="modal-dialog shadow-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Update Document</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                        </div>

                        <form >
                            <div className="modal-body p-4">
                                <div className='row text-start mb-3'>
                                    <label htmlFor="ectype" className="form-label ms-2">Choose Type</label>
                                    <Select
                                        placeholder="Select"
                                        value={optionDoc.find((item) => {
                                            if (item.value == docuType) {
                                                return (item.value);
                                            }
                                        })}
                                        // value={selectedDocOption}
                                        onChange={handleDocChange}
                                        options={optionDoc}
                                        defaultValue={optionDoc.find((item) => {
                                            if (item.value == docuType) {
                                                return (item.value);
                                            }
                                        })}
                                    />
                                </div>
                                <div className='row text-start '>
                                    <label htmlFor="state" className="form-label ms-2">Choose Equipment Type</label>
                                    <Select
                                        placeholder="Select"
                                        value={optionEquip.find((item) => {
                                            // if(equipType !==null || equipType!==""){
                                            if (item.value == equipType) {
                                                return (item.value || "");
                                            }
                                            // }
                                            // else{
                                            //     return (setEquipType(""));
                                            // }

                                        }) || ""}
                                        // value={selectedEquip}
                                        onChange={handleEquipChange}
                                        options={optionEquip}
                                        defaultValue={optionEquip.find((item) => {
                                            // if(equipType !==null || equipType!==""){
                                            if (item.value == equipType) {
                                                return (item.value || "");
                                            }
                                            // }
                                            // else{
                                            //     return "";
                                            // }
                                        }) || ""}
                                    />
                                </div>
                            </div>
                            <div className="modal-footer ">
                                <div className='group-btn'>
                                    <button type="button" onClick={() => updateDocument()} className="btn btn-save text-white px-4 text-end me-2">Save </button>
                                    <button type="button" id="updclose" className="btn btn-cancel text-white px-3 text-end" data-bs-dismiss="modal">Cancel</button>
                                </div>
                            </div>
                        </form>

                    </div>

                </div>
            </div>
            {/* UPDATE DOCUMENT ENDS HERE */}

            {/* DELETE DOCUMENT STARTS HERE */}
            <div className="modal" id="deleteDoc" data-bs-backdrop="false"
                tabIndex="-1" >
                <div className="modal-dialog shadow-lg">
                    <div className="modal-content">

                        <div className="modal-header">
                            <h4 className="modal-title">Confirm Delete</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                        </div>


                        <div className="modal-body p-4 text-start">
                            <h6>Are you sure you want to delete this Document?</h6>
                        </div>

                        <div className="modal-footer ">
                            <button type="button" id="deleteclose" className="btn btn-save text-white px-4" data-bs-dismiss="modal">No</button>
                            <button type="button" className="btn btn-cancel text-white px-4" onClick={() => delDocument()}>Yes</button>
                        </div>

                    </div>
                </div>
            </div>
            {/* DELETE DOCUMENT ENDS HERE */}
            {/* VIEW DOCUMENT STARTS HERE */}
            <div className="modal" id="viewDoc" data-bs-backdrop="false"
                tabIndex="-1" >
                <div className="modal-dialog shadow-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">View Document</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                        </div>
                        <div className="modal-body p-4 text-start">

                            {/* <FileViewer fileType="xlsx" filePath={`data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${dummy}`}  onError={onError} /> */}
                        </div>
                        {/* filePath="./AgileDefinitions.docx" */}
                        <div className="modal-footer ">
                            <button type="button" id="viewDocClose" className="btn btn-save text-white px-4" data-bs-dismiss="modal">No</button>
                            <button type="button" className="btn btn-cancel text-white px-4">Yes</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* VIEW DOCUMENT ENDS HERE */}
            {/* DOWNLOAD DOCUMENT STARTS HERE*/}
            <div className="modal" id="downloadDoc" data-bs-backdrop="false"
                tabIndex="-1" >
                <div className="modal-dialog shadow-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title downdoc-title">Download Document</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                        </div>
                        <div className="modal-body p-4 text-start">
                            <table className='table mx-auto table-responsive'>
                                <tbody>
                                    <tr>
                                        <th className='p-3'>Document name</th>
                                        <td className='p-3'>{docName}</td>
                                    </tr>
                                    <tr>
                                        <th className='p-3'>Type</th>
                                        <td className='p-3'>{ext}</td>
                                    </tr>
                                    <tr>
                                        <th className='p-3'>Size</th>
                                        <td className='p-3'>{fileSize}</td>
                                    </tr>
                                </tbody>
                            </table>

                            {/* <ReactFilePreviewer
                                file={{
                                    data: {decoded},
                                    mimeType: 'xls',
                                    name: 'sample.xlsx' // for download
                                }}
                            /> */}
                            {/* <object data={decodedFile}/> */}

                            {/* {file} */}
                            {/* <object data={docFile} type="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64" ></object> */}
                            {/* <FileViewer fileType='xlsx' filePath={decodedFile}  onError={onError} /> */}
                            {/* <DocViewer pluginRenderers={DocViewerRenderers} documents={decodedFile} /> */}
                            {isDocLoaded == true || stillLoading ?
                                <div className='text-center'> <ClipLoader size={15} color="blue" className="mx-auto" /> </div>
                                : ""}
                        </div>
                        <div className="modal-footer ">
                            <button type="button" id="deletecllose" className="btn btn-cancel text-white px-3" data-bs-dismiss="modal">Cancel</button>
                            {isDocLoaded === true ? <button type="button" className="btn btn-save text-white px-2" disabled={true}  >Download</button> :
                                <button type="button" className="btn btn-save text-white px-2" onClick={() => downloadDoc()} >Download</button>}
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default AssociatedDocument;