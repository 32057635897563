
import {FaSort,FaSortUp,FaSortDown} from "react-icons/fa";

function actionFormatter(cell, row, rowIndex, formatExtraData){
  return(
<div className="col align-items-center text-center">
<i className="bi bi-pencil me-2"  data-bs-toggle="modal" data-bs-target="#updateDiagnosis" style={{color:"#fff",background:'#3f9b58',padding:'5px',borderRadius:'5px'}}></i>
<i className="bi bi-trash2"  data-bs-toggle="modal" data-bs-target="#deleteDiagnosis" style={{color:"#fff",background:'#f44336',padding:'5px',borderRadius:'5px'}}></i>
</div>
  );
}

const sortOrder=(order,column)=>{
  if (!order) return (<span>&nbsp;&nbsp;<FaSort color="#858585"/></span>);
  else if (order === 'asc') return (<span>&nbsp;&nbsp;<font color="red"><FaSortUp color="#000"/></font></span>);
  else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="red"><FaSortDown color="#000"/></font></span>);
  return null;

}
function statusFormatter(cell) {
    if (cell === "On Review") {
      return (
        <p style={{ color: "#87b880", marginBottom: '0px',fontWeight:'bolder' }}>
          {cell}
        </p>
      );
    }
    return (
      <p style={{ color: "#d25b47", marginBottom: '0px',fontWeight:'bolder' }}>
        {cell}
      </p>
    );
  }

const columns = [
  
  { dataField: 'RequestNumber', text: 'Request Number', sort: true ,sortCaret: sortOrder},
  { dataField: 'patient_name', text: 'Patient Name', sort: true ,sortCaret: sortOrder},
  { dataField: 'gender', text: 'Gender', sort: true, sortCaret: sortOrder},
  { dataField: 'status', text: 'Status', sort: true, sortCaret: sortOrder,formatter:statusFormatter}
//   { dataField: 'action', text: 'Action', headerAlign: 'center',formatter:actionFormatter},
];
const data=[
  {number:"REQ000032",patient_name:"Alice", gender:"Female", status:"Active"},
  {number:"REQ000034",patient_name:"Ryan Joel", gender:"Male", status:"Active"},
  {number:"REQ000035",patient_name:"Michael Vazquez", gender:"Male", status:"Active"},
  {number:"REQ000036",patient_name:"Darryl Walls", gender:"Female", status:"Active"},
  {number:"REQ000038",patient_name:"Rodney Burns", gender:"Female", status:"Active"},
  {number:"REQ000043",patient_name:"Paul Sharp", gender:"Male", status:"Active"},
  {number:"REQ000044",patient_name:"Luis Barber", gender:"Male", status:"Active"},
  {number:"REQ000056",patient_name:"Timothy Cunningham", gender:"Male", status:"Active"},
  {number:"REQ000078",patient_name:"David Johnston", gender:"Male", status:"Active"},
  {number:"REQ000066",patient_name:"James Douglas", gender:"Male", status:"Active"},
  {number:"REQ000098",patient_name:"Robert Pena", gender:"Male", status:"Active"},
]

const options = {
  paginationSize: 4,
  pageStartIndex: 1,
  sizePerPageList: [{
      text: '10', value: 10
  },
  { text: '20', value: 20 },
  { text: '50', value: 50 }

  ],
  prePageText: '<',
  nextPageText: '>',
  nextPageTitle: 'First page',
  prePageTitle: 'Pre page',
  firstPageTitle: 'Next page',
  lastPageTitle: 'Last page',
  showTotal: false,
  alwaysShowAllBtns: true,
  withFirstAndLast: false,
  disablePageTitle: true,
};

export { columns, options,data };