import { deleteTip, editTip } from "../../ToolTip/ToolTip";
import {FaSort,FaSortUp,FaSortDown} from "react-icons/fa";

function actionFormatter(cell, row, rowIndex, formatExtraData){
  return(
<div className="col align-items-center text-center">
  {editTip}{deleteTip}
<i className="bi bi-pencil me-2" data-tip data-for="editTip" data-bs-toggle="modal" data-bs-target="#updateLifestyle" style={{color:"#fff",background:'#3f9b58',padding:'5px',borderRadius:'5px'}}></i>
<i className="bi bi-trash2" data-tip data-for="deleteTip" data-bs-toggle="modal" data-bs-target="#deleteLifestyle" style={{color:"#fff",background:'#f44336',padding:'5px',borderRadius:'5px'}}></i>
</div>
  );
}

const sortOrder=(order,column)=>{
  if (!order) return (<span>&nbsp;&nbsp;<FaSort color="#858585"/></span>);
  else if (order === 'asc') return (<span>&nbsp;&nbsp;<font color="red"><FaSortUp color="#000"/></font></span>);
  else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="red"><FaSortDown color="#000"/></font></span>);
  return null;

}

const columns = [
  { dataField: 'lifestyle_name', text: 'Lifestyle Name', sort: true,sortCaret: sortOrder },
  { dataField: 'lifestyle_hint', text: 'Hint', sort: true,sortCaret: sortOrder },
  {dataField: 'ls_category', text:"Category", sort: true, sortCaret:sortOrder},
  { dataField: 'action', text: 'Action', headerAlign: 'center',formatter:actionFormatter},
];


const options = {
  paginationSize: 4,
  pageStartIndex: 1,
  sizePerPageList: [{
      text: '10', value: 10
  },
  { text: '20', value: 20 },
  { text: '50', value: 50 }

  ],
  prePageText: '<',
  nextPageText: '>',
  nextPageTitle: 'First page',
  prePageTitle: 'Pre page',
  firstPageTitle: 'Next page',
  lastPageTitle: 'Last page', 
  showTotal: false,
  alwaysShowAllBtns: true,
  withFirstAndLast: false,
  disablePageTitle: true,

};

export { columns, options };