import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import { useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form";
import "react-quill/dist/quill.snow.css";
import '../../../../assets/styles/pipelinerequest/toolbar.scss';
import PipelineRequestService from '../../../../services/PipelineRequest';
import DOMPurify from 'dompurify';
import AdjunctToolbar, { modules1, formats1 } from './toolbar/AdjunctToolbar';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function AdjunctTherapy() {
  const { handleSubmit, formState: { errors } } = useForm();
  const location = useLocation();
  const [closeModal, setcloseModal] = useState(true);
  const [text, setText] = useState({ value: "" });
  let pipreq = new PipelineRequestService();
  const [descid, setDescID] = useState(0);
  const pipelineReqID = location.state.reqId;
  const [msg, setMsg] = useState('');
  const [isMounted, setIsMounted] = useState(true);
  const notifyAdj = () => toast.success("Adjunct therapy updated successfully!");
  const notifyAdjErr = () => toast.error("Adjunct therapy couldn't be updated!");

  useEffect(() => {
    const reqData = pipelineReqID;
    pipreq.getAdjunctTherapy(reqData)
      .then((response) => {
        if (isMounted) {
          if (response.data) {
            setDescID(response.data.data.id);
            setText({ value: response.data.data.description });
          }
        }
      }).catch(function (error) {

      })
    return () => { setIsMounted(false) }
  }, [])

  function hideModal(target) {
    document.getElementById(target).click();
  };

  function fetchAdjunctTherapy() {
    const reqData = pipelineReqID;
    pipreq.getAdjunctTherapy(reqData)
      .then((response) => {
        if (response.data) {
          setDescID(response.data.data.id);
          setText({ value: response.data.data.description });
        }
      }).catch(function (error) {

      })
  }
  const onSubmit = (data) => {
    let reqData = {
      "sr_associate_adjunctTherapies": descid,
      "service_request": pipelineReqID,
      "description": text.value,
    }

    pipreq.saveAdjunctTherapy(reqData)
      .then((response) => {
        if (response.data) {
          if (response.data.status === 'success') {

            hideModal("close5");
            fetchAdjunctTherapy();
            notifyAdj();
            // setMsg(" Updated successfully!");
            // setTimeout(() => { setMsg(""); }, 5000);
          }
        }
      })
      .catch(function (error) {
        // setErrorMsg(true);
        notifyAdjErr();
      })
  }

  const handleChange = value => {
    setText({ value });
  };
  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html)
    }
  }

  return (
    <div className="rich-editor">
      <div className="row g-0 p-2">
        <h6 className="col-auto">Adjunct Therapies</h6>
        <div className="col-auto ms-auto icon-btn">
          <i
            className="bi bi-pencil"
            data-bs-toggle="modal"
            data-bs-target="#editAdjunctData"
          ></i>
        </div>
      </div>
      <div className='preview p-2 text-start'>
        <p>Based upon individual's topographic maps, sLORETA images, in consideration of database deviancies and known published references, 
          the following recommendations are made for medications and supplementation when applicable.</p>
          <div className="my-4 fw-bold" dangerouslySetInnerHTML={createMarkup(text.value)}></div>
      </div>
      
      {closeModal ? <div className="modal" id="editAdjunctData" tabIndex="-1" >
        <div className="modal-dialog" style={{ maxWidth: '900px' }}>
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Edit Template</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            <div className="modal-body p-4 text-start">
              {/* <Toolbar/> */}
              <AdjunctToolbar />
              <ReactQuill
                // theme="snow"
                value={text.value}
                modules={modules1}
                formats={formats1}
                onChange={handleChange}
              // onChange={this.handleProcedureContentChange}
              >
                {/* <div className="my-editing-area" /> */}
              </ReactQuill>

            </div>

            <div className="modal-footer ">
              <button
                type="button"
                className="btn btn-cancel text-white px-4"
                data-bs-dismiss="modal" id="close5"
              >
                Cancel
              </button>
              <button type="button" onClick={handleSubmit((onSubmit))} className="btn btn-save text-white px-4" >
                Save
              </button>
            </div>
          </div>
        </div>
      </div> : null}
</div>
  );

}


