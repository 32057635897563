import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import '../../../../assets/styles/pipelinerequest/tabcontent/diagnosistab.scss';
import RecordingAnalysisService from '../../../../services/RecordingAnalysis';
import { BsPlusLg } from 'react-icons/bs';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';


export default function AssociateMedication() {
    const location = useLocation();
    const [active, setActive] = useState();
    const [val, setVal] = useState();
    const [isactive, setIsActive] = useState();
    const [dId, setDId] = useState([]);
    const [checkBoxes, setCheckBoxes] = useState([]);
    const [selectedBox, setSelectedBox] = useState([]);
    const [isMounted, setIsMounted] = useState(true);
    const [ndID, setNdID] = useState([]);
    const [unSelectBx, setUnSelectBx] = useState([]);
    const [selectedMedication, setSelectedMedication] = useState([]);
    const [unmedic, setUnMedic] = useState([]);
    //DYNAMIC
    const [drugName, setDrugName] = useState('');
    const [dbSelect, setDbSelect] = useState([]);
    const [value, setValue] = useState([]);
    //API
    const baseurl = "https://clinicaltables.nlm.nih.gov/api/rxterms/v3/search?terms";
    const ra = new RecordingAnalysisService();
    //toaster
    const [msg, setMsg] = useState('');
    const notifyMedic = () => toast.success("Medication updated successfully!")
    const notifyMedicErr = () => toast.error("Medication couldn't be updated!");
    const notifyMedicRmv = () => toast.success("Medication deleted successfully!")
    const notifyMedicRmvErr = () => toast.error("Medication couldn't be deleted!");
    //unselected checkbox
    const options = checkBoxes.map((checkBoxes) => {
        return {
            value: checkBoxes.medicine_name,
            label: checkBoxes.medicine_name,
            id: checkBoxes.id,
            checked: checkBoxes.ischoices

        }

    }
    );
    const medicationOption = unmedic.map((data, i) => {
        return {
            label: data.medicine_name,
            value: data.medicine_name,
            id: data.id
        }
    })
    const handleMedicationChange = (selectedMedication) => {
        const values = ([...selectedMedication].map(o => o.id));
        setSelectedMedication(selectedMedication);
        setDId(values);
        setNdID(current =>
            current.filter(diag => {
                if (diag.id !== values)
                    return diag.id;
            }));

    };

    function getAssociateMedication() {
        let reqData = {
            "service_request_id": location.state.reqId
        }
        ra.GetMultiCheckBoxes(reqData).then((response) => {
            if (isMounted) {
                if (response.data.medic !== []) {
                    let medic = [];
                    let unmedic = [];

                    for (let i = 0; i < response.data.medic.length; i++) {
                        if (response.data.medic[i].ischoices === true) {

                            dId.push(response.data.medic[i].id);
                            medic.push(response.data.medic[i]);
                        }
                        else {
                            ndID.push(response.data.medic[i].id);
                            unmedic.push(response.data.medic[i]);
                        }
                    }
                    setCheckBoxes(medic);
                    setUnMedic(unmedic);

                }
            }
        })
    }

    function saveMedication() {
        let reqData = {
            "service_request": location.state.reqId,
            "medic_tps": dId.toString(),
            "unmedic_tps": ndID.toString(),
        }
        ra.SaveAssociateMedication(reqData).then((response) => {
            if (response.data.status === "success") {
                handleCancel();
                notifyMedic();
                // setMsg("Medicine updated successfully!");
                // setTimeout(() => { setMsg(""); }, 5000);
                getAssociateMedication();
            }
        })
            .catch(function (error) {
                // setErrorMsg(true);
                notifyMedicErr();
            })
    }
    const handleChange = (i) => (e) => {
        let checked = e.target.checked;
        checkBoxes[i].ischoices = !checkBoxes[i].ischoices;
        if (checked === true) {
            unSelectBx.push(checkBoxes[i]);
            dId.push(checkBoxes[i].id);
            setNdID(ndID.filter(element => element !== checkBoxes[i].id));

        }
        else if (checked === false) {
            // setActive(false);
            ndID.push(checkBoxes[i].id);
            setUnSelectBx(unSelectBx.filter(element => element.id !== checkBoxes[i].id));
            setDId(dId.filter(element => element !== checkBoxes[i].id));

        }
    };

    useEffect(() => {
        getAssociateMedication();
        return () => { setIsMounted(false) }
    }, []);
    const removeMedication = (id) => {
        let reqData = {
            "service_request": location.state.reqId,
            "medic_tps": "",
            "unmedic_tps": id.toString(),
        }
        ra.SaveAssociateMedication(reqData).then((response) => {
            if (response.data.status === "success") {
                getAssociateMedication();
                notifyMedicRmv();

            }
        })
            .catch(function (error) {
                notifyMedicRmvErr();
            })
    };
    const handleCancel = () => {
        setSelectedMedication(null);
    }
    //DYNAMIC FUNCTIONS

    async function medicName(val) {
        axios.get(`${baseurl}=${val}`).then((res) => {
            if (res) {
                setDbSelect(res.data[1]);

            }
        })
    }
    function inputChange(value) {
        medicName(value);

    }
    const option = dbSelect.map(function (dbSelect, i) {
        return {
            value: dbSelect,
            label: dbSelect,
            id: i.toString()
            // id: dbSelect.id,
        };
    });
    function handledChange(drugName) {
        setValue(drugName);
        setDrugName(drugName);
    }

    return (
        <div className="diagnosis-tab mt-3">
            {/* <ToastContainer/> */}
            <div className='row g-0 mb-2'>
                {/* {msg ?
                    <div className='row align-items-center justify-content-center mb-2'>
                        <div className=" success-msg  " style={{ width: '60%' }}>{msg}</div>
                    </div>
                    : null} */}
                <h6 className="text-start col">Possible Appropriate Medication</h6>
                <div className='ms-auto text-end col add-icon' data-bs-toggle="modal" data-bs-target="#addMedication">
                    {/* <button type="button" className="btn btn-primary" onClick={() => saveMedication()}>Save</button> */}
                    <BsPlusLg />
                </div>
            </div>
            <Container className='p-0'>
                <div className="checkbox-container mt-2 ps-1">
                    <p className='text-start'>Based upon our internal studies and available published data, the following medications may be indicated.
                        Please consult with your health care provider for appropriateness and possible interactions with current treatments.
                        The EEG topographical analysis demonstrates trends consistent with reported clinical symptoms. With conflicting qEEG patterns,
                        single medication options may be difficult or contraindicated. Prediction accuracy is reduced without a complete medication washout.<br></br>
                        *Note: with conflicting qEEG patterns, single medication options may be difficult or contraindicated.</p>
                   
                        {/* {checkBoxes.map((tag, i) => {
                            return(
                                <ul key={i} className="mx-5">
                            <li  className="border-0 text-start medic-list w-75">{tag.medicine_name}</li>
                            </ul>
                            );
                        })} */}
                   
                    <div className='row p-2' style={{ height: 'auto' }}>

                        {checkBoxes.map((tag, i) => {
                            return (
                                <ul key={i} className="mx-5">
                                <li  className="border-0 text-start medic-list w-75">{tag.medicine_name}</li>
                                </ul>
                                // <div key={i} className="tag col-auto mx-2 my-1 ps-1 py-0" style={{
                                //     whiteSpace: 'no-wrap',
                                // }}>
                                //     {tag.medicine_name}<div className="ms-auto col-1 p-1 remove-tag" key={tag.id}
                                //         onClick={() => removeMedication(tag.id)}
                                //     >
                                //         <span>x</span>
                                //     </div>
                                // </div>
                            );
                        })}

                    </div>
                </div>
            </Container>
            <div className="modal" id="addMedication"
                tabIndex="-1" >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Add Medication</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => handleCancel()}></button>
                        </div>
                        <div className="modal-body p-4 text-start">
                            {/* <Select
                                isMulti
                                value={drugName}
                                onChange={handledChange}
                                options={option}
                                defaultValue={drugName}
                                onInputChange={inputChange}
                            /> */}
                            <Select
                                isMulti
                                value={selectedMedication}
                                onChange={handleMedicationChange}
                                options={medicationOption}
                            />
                        </div>
                        <div className="modal-footer ">
                            <button type="button" id="closeDelDiag" className="btn btn-save text-white px-3"
                                onClick={() => saveMedication()} data-bs-dismiss="modal">Save</button>
                            <button type="button" className="btn btn-cancel text-white px-3"
                                data-bs-dismiss="modal" onClick={() => handleCancel()}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>


        </div>

    );

}