import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import '../../assets/styles/masterData/symptoms.scss';
import LoadingIcon from '../../assets/img/sample-icons/sandbox.gif';
// Model Box
import AddSymptomsModal from './modal/symptoms/AddSymptomsModal';
import DeleteSymptomsModal from './modal/symptoms/DeleteSymptomsModal'
import { columns, options } from './data/SymptomsData';
//API 
import MasterDataService from "../../services/masterdata";
import { newSymptoms } from "../ToolTip/ToolTip";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const NoDataIndication = () => (
  <div className='spinner d-flex justify-content-center text-center h-100'>
    <img src={LoadingIcon} alt="loading-icon" width="200" height="200" />
  </div>
);

function Symptoms() {
  const history = useHistory();
  const { SearchBar } = Search;
  const [symptomsData, setSymptomsData] = useState([]);
  const [selctedrowID, setSelectedID] = useState();
  const [selctedrowData, setSelectedData] = useState([]);
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [errmsg, setErrorMsg] = useState(false);
  const [isMounted, setIsMounted] = useState(true);
  const [tdataLoaded, setTdataLoaded] = useState(false);
  //CALLBACK VARIABLES
  const [isFlagSet, setIsFlagSet] = useState(false);
  const [isFlagSet1, setIsFlagSet1] = useState(false);
  //API CALL
  const msds = new MasterDataService();
  //TOASTER
  const sympupnotify = () => toast.success("Symptoms created successfully!");
  const sympupnotifyErr = () => toast.error("Symptoms couldn't be updated!");
  const sympupdnotify = () => toast.success("Symptoms updated successfully!");
  const sympdelnotify = () => toast.success("Symptoms deleted successfully!");
  const sympdelnotifyErr = () => toast.error("Symptoms couldn't be deleted!");

  //CALLBACK FUNCTION FOR CREATE SYMPTOMS
  function messageCallback(item) {
    setIsFlagSet(item);
    sympupnotify();
  }

  //CALLBACK FUNCTION FOR DELETE SYMPTOMS
  function messageCallback1(item) {
    setIsFlagSet1(item);
    sympdelnotify();
  }

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      setSelectedID(row.id);
      setSelectedData(row);
    },
  };

  const onSubmit = (data) => {
    let reqData = {
      "mdsymptomsid": selctedrowID,
      "symptoms_name": data.symptomsName,
      "symptoms_hint": data.hint
    }
    msds.createSymptoms(reqData)
      .then((response) => {
        if (response.data) {
          if (response.data.status === 'success') {
            hideModal('closdeUpSymp');
            getSymptomsData();
            sympupdnotify();
          }
        }
      })
      .catch(function (error) {
      })
  }
  function getSymptomsData() {
    msds.getSymptoms()
      .then((response) => {
        if (isMounted) {
          if (response.data) {
            if (response.data.data !== []) {
              setTdataLoaded(true);
              setSymptomsData(response.data.data);
            }
            else {
              setTdataLoaded(false);
            }
          }
        }
      }).catch(function (error) {
        if (error.response.status === 403) {
          history.push('/login');
        }
      })
  }

  useEffect(() => {
    getSymptomsData();
    return () => { setIsMounted(false); }
  }, [])

  useEffect(() => {
    reset(selctedrowData);
  }, [selctedrowData]);

  function hideModal(target) {
    document.getElementById(target).click();
    reset();
  };
  return (
    <div className='col symptoms'>
      <div className='row g-0 ps-3 pt-3'>

        <div className='table-request my-2 me-3'>
          <AddSymptomsModal data={{ isFlagSet: isFlagSet, messageCallback: messageCallback, getSymptomsData: getSymptomsData }} />
          <DeleteSymptomsModal data={{ id: selctedrowID, isFlagSet1: isFlagSet1, messageCallback1: messageCallback1, getSymptomsData: getSymptomsData }} />

          {/* UPDATE SYMPTOMS START */}
          <div className="modal" id="updateSymptoms" data-bs-backdrop="false"
            tabIndex="-1" >
            <div className="modal-dialog shadow-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Update Symptoms</h4>
                  <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <form onSubmit={handleSubmit(onSubmit)} >
                  <div className="modal-body p-4">
                    {errmsg ? <div className="err-msg">Symptoms name already exist</div> : ''}
                    <div className='row text-start'>
                      <div className="mb-3">
                        <label htmlFor="symptomsName" className="form-label">Symptoms Name
                          <span className='required-field'>*</span></label>
                        <input type="text" maxLength="100"
                          defaultValue={selctedrowData.symptoms_name}
                          onChange={(e) => setSymptomsData(e.target.value)}
                          className={`form-control p-2 ${errors.symptomsName ? "is-invalid" : ""
                            }`}
                          {...register(
                            "symptomsName",
                            { required: true }, { validate: (value) => { return !!value.trim() } }
                          )} />
                        {(errors.symptomsName && errors.symptomsName.type === 'required') ? <div className="invalid-feedback">This field is required</div> : ''}
                      </div>
                      <div className="mb-3">
                        <label htmlFor="hint" className="form-label">Hint</label>
                        <input type="text"
                          defaultValue={selctedrowData.symptoms_hint}
                          onChange={(e) => setSymptomsData(e.target.value)}
                          className={`form-control p-2 ${errors.hint ? "is-invalid" : ""
                            }`}
                          {...register(
                            "hint",
                            { required: false }, { validate: (value) => { return !!value.trim() } }
                          )} />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer ">
                    <button type="button" id="closdeUpSymp" className="btn btn-cancel text-white px-2" data-bs-dismiss="modal">Cancel</button>
                    <button type="submit" className="btn btn-save text-white px-2">Update </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/* UPDATE SYMPTOMS END */}

          <div className='table-data me-4'>
            <div>{newSymptoms}</div>
            <ToolkitProvider
              search>
              {
                props => (
                  <div className="pe-3">
                    <div className='row mb-2'>
                      <div className='col text-start ms-1'>
                        <h5>Symptoms</h5>
                      </div>
                      <div className='col text-end'>
                        <button type='button' className='btn-create btn px-3 py-1 ms-3 me-2'
                          data-bs-toggle="modal" data-bs-target="#createSymptoms" data-tip data-for="newSymptomsTip">
                          <i className="bi bi-person-plus-fill"></i>Add</button>
                        <i className="bi bi-search ms-1 ps-2 mb-2 " id='search-icon'></i>
                        <SearchBar placeholder=" Search Symptoms "
                          {...props.searchProps} />
                      </div>
                    </div>
                    <BootstrapTable
                      {...props.baseProps}
                      keyField='id'
                      data={symptomsData}
                      columns={columns}
                      pagination={paginationFactory(options)}
                      rowEvents={rowEvents}
                      wrapperClasses="table-responsive"
                      noDataIndication={() => { return (tdataLoaded ? "No data available" : <NoDataIndication />) }}
                    />
                  </div>
                )
              }
            </ToolkitProvider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Symptoms;