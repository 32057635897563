import React, { useEffect, useState, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useHistory } from "react-router-dom";
import PatientAccountService from "../../services/Patient";
import { useForm } from "react-hook-form";
import { format } from "date-fns";
import Select from "react-select";
import { MaskedInput, createDefaultMaskGenerator } from 'react-hook-mask';
import { useMask } from 'react-mask-field';
import MasterDataService from "../../services/masterdata";
import { Multiselect } from "multiselect-react-dropdown";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const maskGenerator = createDefaultMaskGenerator('(999) 999-9999');

function UpdatePatientModal(props) {
  const history = useHistory();
  const [contactNo, setContactNo] = useState("");
  const [checkContact, setCheckContact] = useState(false);
  const refs = useMask({ mask: ' () -', replacement: { _: /\d/ } });
  const location = useLocation();
  const [startDate, setStartDate] = useState();
  const [patientData, setPatientData] = useState([]);
  const [isMounted, setIsMounted] = useState(true);
  const [isMountedAcType, setIsMountedAcType] = useState(true);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const pId = location.state.patientId;
  const [selectedOption, setSelectedOption] = useState();
  const [dbSelect, setDbSelect] = useState([]);
  const [accId, setAccId] = useState();
  const ref = useRef < HTMLInputElement > (null);
  //MULTISELECT DROPDOWN
  const [diagData, setDiagData] = useState([]);
  const [selectedDiagnosis, setSelectedDiagnosis] = useState([]);
  const [diagnosisID, setDiagnosisID] = useState([]);
  const [unselectedDiagnosis, setUnselectedDiagnosis] = useState([]);
  const [undiagnosisID, setUndiagnosisID] = useState([]);
  const [sympData, setSympData] = useState([]);
  const [selectedSymptoms, setSelectedSymptoms] = useState([]);
  const [symptomsID, setSymptomsID] = useState([]);
  const [unsymptomsID, setUnsymptomsID] = useState([]);
  const [diagnosisMounted, setDiagnosisMounted] = useState(true);
  const [symptomsMounted, setSymptomsMounted] = useState(true);
  const [selectedHand, setSelectedHand] = useState('');

  const acId = location.state.accountID;
  const patientnotify = () => toast.success("Patient updated successfully!");
  const patientnotifyErr = (val) => toast.error(val);
  //API
  const msds = new MasterDataService();
  const pnt = new PatientAccountService();


  async function accountType() {
    pnt.GetAccountType().then((response) => {
      if (isMountedAcType) {
        if (response.data.status === "success") {
          setDbSelect(response.data.data);
        }
      }
    });
  }

  useEffect(() => {
    accountType();
    return () => { setIsMountedAcType(false); }
  }, []);
  const options = dbSelect.map(function (dbSelect) {
    return {
      value: dbSelect.acct_name,
      label: dbSelect.acct_name,
      id: dbSelect.id,
    };
  });
  const optionsHanded = [{
    value: 'righthand',
    label: 'Right Hand',
  },
  {
    value: 'lefthand',
    label: 'Left Hand'
  }]
  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setAccId(selectedOption.id);
  };
  const handleHandedChange = (selectedHand) => {
    setSelectedHand(selectedHand);

  }
  const onDateChange = (date) => {
    setStartDate(format(new Date(date), 'MM/dd/yyyy'));
  }

  function getPatientsDetail() {
    const pId = location.state.patientId;
    pnt.GetPatientDetail(pId)
      .then((response) => {
        if (isMounted) {
          if (response.data.status === "success") {
            setPatientData(response.data.data[0]);
            setAccId(response.data.data[0].accountid);
            setStartDate(format(new Date(response.data.data[0].dob), 'MM/dd/yyyy'));
            setContactNo(response.data.data[0].contact_number);
            setDiagData(response.data.diagnosis);
            setSympData(response.data.symptoms);
            getSelectedDiagnosis(response.data.diagnosis);
            getSelectedSymptoms(response.data.symptoms);
          }
        }
      })
  }

  useEffect(() => {
    getPatientsDetail();
    return () => { setIsMounted(false); }
  }, []);

  useEffect(() => {
    reset(patientData);
  }, [patientData]);

  //GET SELECTED DIAGNOSIS
  const getSelectedDiagnosis = (diag) => {
    if (diag.length !== 0 && selectedDiagnosis.length === 0) {
      for (let i = 0; i < diag.length; i++) {
        if (diag[i].ischoices === true) {
          if (diag[i].id !== selectedDiagnosis.map((dig) => dig.id)) {
            selectedDiagnosis.push({
              name: diag[i].diagnosis_name,
              value: diag[i].diagnosis_name,
              id: diag[i].id
            })
          }
        } else return;
      }

    }
    setSelectedDiagnosis(selectedDiagnosis);
  }
  //GET SELECTED SYMPTOMS
  const getSelectedSymptoms = (symp) => {
    if (symp.length !== 0 && selectedSymptoms.length === 0) {
      for (let i = 0; i < symp.length; i++) {
        if (symp[i].ischoices === true) {
          selectedSymptoms.push({
            name: symp[i].symptoms_name,
            value: symp[i].symptoms_name,
            id: symp[i].id
          })
        } else return;
      }

    }
    setSelectedSymptoms(selectedSymptoms);
  }


  const diagnosisOption = diagData.map((data, i) => {
    return {
      label: data.diagnosis_name,
      value: data.diagnosis_name,
      choices: data.ischoices,
      id: data.id
    }
  })
  const handleDiagnosisChange = (selectedDiagnosis, selectedItem) => {
    setUndiagnosisID(undiagnosisID.filter(element => element !== [...selectedDiagnosis].map(o => o.id)));
    diagnosisID.push(selectedItem.id);
    setDiagnosisID(diagnosisID);
    setSelectedDiagnosis(selectedDiagnosis);
  };

  const handleRemoveDiagnosis = (selectedDiagnosis, removedItem) => {
    setDiagnosisID(diagnosisID.filter(element => element !== removedItem.id));
    undiagnosisID.push(removedItem.id);
  }

  const symptomsOption = sympData.map((data) => {
    return {
      label: data.symptoms_name,
      value: data.symptoms_name,
      choices: data.ischoices,
      id: data.id
    }
  });

  const handleSymptomsChange = (selectedSymptoms, selectedItem) => {
    setUnsymptomsID(unsymptomsID.filter(element => element !== [...selectedSymptoms].map(o => o.id)));
    symptomsID.push(selectedItem.id);
    setSymptomsID(symptomsID);
    setSelectedSymptoms(selectedSymptoms);
  };

  const handleRemoveSymptoms = (selectedSymptoms, removedItem) => {
    setSymptomsID(symptomsID.filter(element => element !== removedItem.id));
    unsymptomsID.push(removedItem.id);
  }

  const msgDisplay = (val) => {
    props.data.messageCallback(val);
  }

  const onSubmit = (data) => {
    let fname = data.fname.trim();
    let lname = data.lname.trim();
    if (contactNo === "" || contactNo === null) {
      setCheckContact(true);
    }
    let dob = format(new Date(startDate), 'yyyy-MM-dd');
    let reqData = {
      patientid: location.state.patientId,
      first_name: fname.toLowerCase(),
      last_name: lname.toLowerCase(),
      gender: data.gender,
      dob: dob,
      email: data.email || "",
      occupation: data.occupation || "",
      address: data.address || "",
      country: data.country || "",
      contact_number: data.phone || "",
      state: data.state,
      city: data.city,
      pnt_handedness: data.pnt_handedness,
      zip: data.zip,
      status: "Active",
      accountid: accId,
      diagnosis: diagnosisID.toString(),
      undiagnosis: undiagnosisID.toString(),
      symptoms: symptomsID.toString(),
      unsymptoms: unsymptomsID.toString(),
      userid: parseInt(localStorage.getItem('userid'), 10),
    };
    console.log('jos', reqData);
    pnt.CreatePatient(reqData)
      .then((response) => {
        if (response.data) {
          if (response.data.status === "success") {
            msgDisplay(true);
            hideModal('closePntUpdModal');
            patientnotify();
            props.data.getPatientsDetail();
          }
        }
      })
      .catch(function (error) {
        patientnotifyErr(error.response.data.data);
      });
  };

  function hideModal(target) {
    document.getElementById(target).click();
  };

  return (
    <div className="modal" id="updatePatient" data-bs-backdrop="false" tabIndex="-1">
      <div className="modal-dialog shadow-lg" style={{ maxWidth: "800px" }}>
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Update Patient</h4>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
            ></button>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <div className="modal-body p-4">
              <div className="row text-start">
                <div className="col mb-3">
                  <label htmlFor="fname" className="form-label">
                    First Name<span className='required-field'>*</span>
                  </label>
                  <input
                    type="text"
                    onChange={(e) => setPatientData(e.target.value)}
                    defaultValue={patientData.first_name}
                    className={`form-control p-2 ${errors.fname ? "is-invalid" : ""
                      }`}
                    {...register("fname", { validate: (value) => { return !!value.trim() }, required: true })}
                  />
                  {errors.fname && errors.fname.type === "required" ? (
                    <div className="invalid-feedback">
                      This field is required
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col mb-3">
                  <label htmlFor="lname" className="form-label">
                    Last Name<span className='required-field'>*</span>
                  </label>
                  <input
                    type="text"
                    onChange={(e) => setPatientData(e.target.value)}
                    defaultValue={patientData.last_name}
                    className={`form-control p-2 ${errors.lname ? "is-invalid" : ""
                      }`}
                    {...register("lname", { validate: (value) => { return !!value.trim() }, required: true })}
                  />
                  {errors.lname && errors.lname.type === "required" ? (
                    <div className="invalid-feedback">
                      This field is required
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col mb-3">
                  <label htmlFor="gender" className="form-label">
                    Birth Gender<span className='required-field'>*</span>
                  </label>
                  <select
                    selected={patientData.gender}
                    className="form-select"
                    {...register("gender", { required: true })}
                  >
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Others">Others</option>
                  </select>
                  {errors.gender && errors.gender.type === "required" ? (
                    <div className="invalid-feedback">
                      This field is required
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row text-start">
                <div className="col mb-3">
                  <label htmlFor="dob" className="form-label">
                    DOB<span className='required-field'>*</span>
                  </label>
                  <DatePicker
                    dateFormat="MM/dd/yyyy"
                    value={startDate}
                    maxDate={new Date()}
                    onChange={onDateChange}
                    defaultValue={startDate}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  />

                  {errors.dob && errors.dob.type === "required" ? (
                    <div className="invalid-feedback">
                      This field is required
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="col mb-3">
                  <label htmlFor="occupation" className="form-label">
                    Occupation
                  </label>
                  <select
                    selected={patientData.occupation}
                    className="form-select"
                    {...register("occupation", { required: false })}>
                    <option value="Physician">Physician</option>
                    <option value="Security">Security</option>
                    <option value="Business man">Business man</option>
                    <option value="Social Worker">Social Worker</option>
                    <option value="Engineer">Engineer </option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div className="col mb-3">
                  <label htmlFor="phone" className="form-label">
                    Contact No
                  </label>

                  <MaskedInput
                    className="form-control p-2"
                    maskGenerator={maskGenerator}
                    value={contactNo}
                    onChange={setContactNo}
                  />
                  {/* {checkContact === true ? <div>This field is required</div> : null} */}
                  {errors.phone && errors.phone.type === "required" ? (
                    <div className="invalid-feedback">
                      This field is required
                    </div>
                  ) : (
                    ""
                  )}
                </div>

              </div>
              <div className="row text-start">
                <div className="col mb-3">
                  <label htmlFor="email" className="form-label ms-1">Email</label>
                  <input type="email" className={`form-control p-2 ${errors.email ? "is-invalid" : ""}`}  {...register('email', { required: false }, { pattern: /^\S+@\S+$/i })} />
                  {(errors.email && errors.email.type === 'pattern') ? <div className="invalid-feedback">Enter a valid email id</div> : ''}
                </div>
                <div className="col mb-3">
                  <label htmlFor="address" className="form-label">
                    Address
                  </label>
                  <input
                    type="text"
                    onChange={(e) => setPatientData(e.target.value)}
                    defaultValue={patientData.address}
                    className="form-control"
                    {...register("address", { required: false })}
                  />
                </div>
                <div className="col mb-3">
                  <label htmlFor="city" className="form-label">
                    City
                  </label>
                  <input
                    type="text"
                    onChange={(e) => setPatientData(e.target.value)}
                    defaultValue={patientData.city}
                    className="form-control"
                    {...register("city", { required: false })}
                  />
                </div>

              </div>
              <div className="row text-start">
                <div className="col mb-3">
                  <label htmlFor="state" className="form-label">
                    State
                  </label>
                  <input
                    type="text"
                    onChange={(e) => setPatientData(e.target.value)}
                    defaultValue={patientData.state}
                    className="form-control"
                    {...register("state", { required: false })}
                  />
                </div>
                <div className="col mb-3">
                  <label htmlFor="zip" className="form-label">
                    Zip
                  </label>
                  <input
                    type="text"
                    onChange={(e) => setPatientData(e.target.value)}
                    defaultValue={patientData.zip}
                    className="form-control"
                    {...register("zip", { required: false })}
                  />
                </div>

                <div className="col mb-3">
                  <label htmlFor="country" className="form-label">
                    Country
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("country", { required: false })}
                  />
                </div>

              </div>

              <div className="row text-start">
                <div className="col-md-4 mb-3">
                  <label htmlFor="handedness" className="form-label">
                    Handedness
                  </label>
                  <select
                    selected={patientData.pnt_handedness}
                    className="form-select"
                    {...register("pnt_handedness", { required: false })}
                  >
                    <option value="righthand">Right Hand</option>
                    <option value="lefthand">Left Hand</option>
                   
                  </select>
                  {errors.gender && errors.gender.type === "required" ? (
                    <div className="invalid-feedback">
                      This field is required
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-4 mb-3">
                  <label htmlFor="diagnosis" className="form-label">
                    Diagnosis
                  </label>
                  <Multiselect
                    selectedValues={selectedDiagnosis.map((item) => {
                      return (item);
                    })}
                    options={diagnosisOption}
                    displayValue="value"
                    closeIcon="cancel"
                    multiple={true}
                    onSelect={handleDiagnosisChange}
                    onRemove={handleRemoveDiagnosis}
                  />
                </div>
                <div className="col-md-4 mb-3">
                  <label htmlFor="symptoms" className="form-label">
                    Symptoms
                  </label>
                  <Multiselect
                    selectedValues={selectedSymptoms.map((item) => {
                      return (item);
                    })}
                    options={symptomsOption}
                    displayValue="value"
                    closeIcon="cancel"
                    onSelect={handleSymptomsChange}
                    onRemove={handleRemoveSymptoms}
                  />
                </div>
              </div>
              <div className="row text-start">
                <div className="col-md-4 mb-3">
                  <label htmlFor="account" className="form-label">
                    Account<span className='required-field'>*</span>
                  </label>

                  <Select
                    value={options.find((item) => {
                      if (item.id == accId) {
                        return (item.id);
                      }
                    })}
                    onChange={handleChange}
                    options={options}
                    defaultValue={options.find((item) => {
                      if (item.id == accId) {
                        return (item.value);
                      }
                    })}
                  />
                </div>
              </div>

            </div>

            <div className="modal-footer ">
              <button
                type="button"
                id="closePntUpdModal"
                className="btn btn-cancel text-white px-2 "
                data-bs-dismiss="modal">
                Cancel
              </button>
              <button type="submit" className="btn btn-save text-white px-2">
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default UpdatePatientModal;
