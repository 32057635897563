import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { columns, options } from './data/SupplementData';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import '../../assets/styles/masterData/supplement.scss';
import LoadingIcon from '../../assets/img/sample-icons/sandbox.gif';
// Model Box
import AddSupplementModal from './modal/supplement/AddSupplementModal';
import DeleteSupplementModal from './modal/supplement/DeleteSupplementModal';
//Api
import MasterDataService from '../../services/masterdata';
import { newSupplement } from "../ToolTip/ToolTip";
import { ScaleLoader } from "react-spinners";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const NoDataIndication = () => (
  <div className='spinner d-flex justify-content-center text-center h-100'>
    <img src={LoadingIcon} alt="loading-icon" width="200" height="200" />
  </div>
);

function Supplement() {
  const history = useHistory();
  const { SearchBar } = Search;
  const [supplementData, setSupplementData] = useState([]);
  const [selctedrowID, setSelectedID] = useState();
  const [selctedrowData, setSelectedData] = useState([]);
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [errmsg, setErrorMsg] = useState(false);
  const [isMounted, setIsMounted] = useState(true);
  const [tdataLoaded, setTdataLoaded] = useState(false);
  //CALLBACK VARIABLES
  const [isFlagSet, setIsFlagSet] = useState(false);
  const [msg, setMsg] = useState('');
  const [isFlagSet1, setIsFlagSet1] = useState(false);
  const [msg1, setMsg1] = useState('');
  const [upMsg, setUpMsg] = useState('');
  //API CALL
  const msds = new MasterDataService();
  //TOASTER
  const suppupnotify = () => toast.success("Supplement created successfully!");
  const suppupnotifyErr = () => toast.error("Supplement couldn't be updated!");
  const suppupdnotify = () => toast.success("Supplement updated successfully!");
  const suppdelnotify = () => toast.success("Supplement deleted successfully!");
  const suppdelnotifyErr = () => toast.error("Supplement couldn't be deleted!");

  //CALLBACK FUNCTION FOR CREATE SUPPLEMENT
  function messageCallback(item) {
    setIsFlagSet(item);
    suppupnotify();
  }

  //CALLBACK FUNCTION FOR DELETE SUPPLEMENT
  function messageCallback1(item) {
    setIsFlagSet1(item);
    suppdelnotify();
  }

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      setSelectedID(row.id);
      setSelectedData(row);
    },
  };

  const onSubmit = (data) => {
    let reqData = {
      "Mdsupplementid": selctedrowID,
      "supplement_name": data.supplementName,
      "supplement_hint": data.hint
    }
    msds.createSupplement(reqData)
      .then((response) => {
        if (response.data) {
          if (response.data.status === 'success') {
            getSupplementData();
            hideModal('closeUpSup');
            suppupdnotify();
          }
        }
      })
      .catch(function (error) {
        setErrorMsg(true);
      })
  }
  function hideModal(target) {
    document.getElementById(target).click();
    reset();
  };

  function getSupplementData() {
    msds.getSupplement()
      .then((response) => {
        if (isMounted) {
          if (response.data) {
            if (response.data.data !== [] || response.data.data !== null || response.data.data !== "") {
              setTdataLoaded(true);
              setSupplementData(response.data.data);
            }
          } else {
            setTdataLoaded(false);
          }
        }
      }).catch(function (error) {
        if (error.response.status === 403) {
          history.push('/login');
        }
      })
  }
  useEffect(() => {
    getSupplementData();
    return () => { setIsMounted(false); }
  }, [])

  useEffect(() => {
    reset(selctedrowData);
  }, [selctedrowData]);

  return (
    <div className='col supplement'>
      <div className='row g-0 ps-3 pt-3'>

        <div className='table-request my-2 me-3'>
          <AddSupplementModal data={{ isFlagSet: isFlagSet, messageCallback: messageCallback, getSupplementData: getSupplementData }} />
          <DeleteSupplementModal data={{ id: selctedrowID, messageCallback1: messageCallback1, getSupplementData: getSupplementData }} />

          {/* UPDATE SUPPLEMENT MODEL START */}
          <div className="modal" id="updateSupplement" data-bs-backdrop="false"
            tabIndex="-1" >
            <div className="modal-dialog shadow-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Update Supplement</h4>
                  <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <form onSubmit={handleSubmit(onSubmit)} >
                  <div className="modal-body p-4">
                    {errmsg ? <div className="err-msg">Supplement name already exist</div> : ''}
                    <div className='row text-start'>
                      <div className="mb-3">
                        <label htmlFor="supplementName" className="form-label">Supplement Name
                          <span className='required-field'>*</span></label>
                        <input type="text" maxLength="100"
                          defaultValue={selctedrowData.supplement_name}
                          onChange={(e) => setSupplementData(e.target.value)}
                          className={`form-control p-2 ${errors.supplementName ? "is-invalid" : ""
                            }`}
                          {...register(
                            "supplementName",
                            { required: true }, { validate: (value) => { return !!value.trim() } }
                          )} />
                        {(errors.supplementName && errors.supplementName.type === 'required') ? <div className="invalid-feedback">This field is required</div> : ''}
                      </div>
                      <div className="mb-3">
                        <label htmlFor="hint" className="form-label">Hint</label>
                        <input type="text"
                          defaultValue={selctedrowData.supplement_hint}
                          onChange={(e) => setSupplementData(e.target.value)}
                          className={`form-control p-2 ${errors.hint ? "is-invalid" : ""
                            }`}
                          {...register(
                            "hint",
                            { required: false }, { validate: (value) => { return !!value.trim() } }
                          )} />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer ">
                    <button type="button" id="closeUpSup" className="btn btn-cancel text-white px-2" data-bs-dismiss="modal">Cancel</button>
                    <button type="submit" className="btn btn-save text-white px-2">Update </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          {/* UPDATE SUPPLEMENT MODEL END */}
          <div className='table-data me-4'>
            <div>{newSupplement}</div>
            <ToolkitProvider
              keyField='id'
              data={supplementData}
              columns={columns}
              search
            >
              {
                props => (
                  <div className="pe-3">
                    <div className='row mb-2'>
                      <div className='col text-start ms-1'>
                        <h5>Supplement</h5>
                      </div>
                      <div className='col text-end'>
                        <button type='button' className='btn-create btn px-3 py-1 ms-3 me-2'
                          data-bs-toggle="modal" data-bs-target="#createSupplement" data-tip data-for="newSupplementTip">
                          <i className="bi bi-person-plus-fill"></i>Add</button>
                        <i className="bi bi-search ms-1 ps-2 mb-2 " id='search-icon'></i>
                        <SearchBar placeholder="  Search Supplement "
                          {...props.searchProps} />
                      </div>
                    </div>
                    <BootstrapTable
                      {...props.baseProps}
                      rowEvents={rowEvents}
                      pagination={paginationFactory(options)}
                      wrapperClasses="table-responsive"
                      noDataIndication={() => { return (tdataLoaded ? "No data available" : <NoDataIndication />) }}
                    />
                  </div>
                )
              }
            </ToolkitProvider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Supplement;