import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import LoadingIcon from '../../assets/img/sample-icons/sandbox.gif';
// API
import jobManagerService from '../../services/jobManagerService';
import 'react-toastify/dist/ReactToastify.css';
import {FaSort,FaSortUp,FaSortDown} from "react-icons/fa";

const NoDataIndication = () => (
  <div className='spinner d-flex justify-content-center text-center h-100'>
    <img src={LoadingIcon} alt="loading-icon" width="200" height="200"/>
  </div>
);

function JobManager() {
 
  const history = useHistory();
  const { SearchBar } = Search;
  const [isMounted, setIsMounted] = useState(true);
  const [tdataLoaded, setTdataLoaded] = useState(false);
  const [data, setData] = useState([]);
  const jm = new jobManagerService();

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      history.push('/edf-processing');
        history.push({
          pathname: `/edf-processing`,
          state: {
            id: row.id
          }
        });
        console.log("doc",row);
    },
  };

  const sortOrder=(order,column)=>{
    if (!order) return (<span>&nbsp;&nbsp;<FaSort color="#858585"/></span>);
    else if (order === 'asc') return (<span>&nbsp;&nbsp;<font color="red"><FaSortUp color="#000"/></font></span>);
    else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="red"><FaSortDown color="#000"/></font></span>);
    return null;
  
  }
  
  function stateFormatter(cell) {
      if (cell === "true") {
        return (
          <p className="text-success">
            {cell}
          </p>
        );
      }
      return (
        <p className="text-warning">
          {cell}
        </p>
      );
    }
  
  const columns = [
      { dataField: 'id', text: 'Job ID', sort: true, sortCaret: sortOrder },
      { dataField: 'filename', text: 'File Name', sort: true, sortCaret: sortOrder },
      { dataField: 'jobnumber', text: 'Job No', sort: true, sortCaret: sortOrder },
      { dataField: 'doc_type', text: 'Document Type', sort: true, sortCaret: sortOrder },
      { dataField: 'job_process_status', text: 'Status', formatter: stateFormatter },
    ];
  
  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    sizePerPageList: [{
        text: '10', value: 10
    },
    { text: '20', value: 20 },
    { text: '50', value: 50 }
  
    ],
    prePageText: '<',
    nextPageText: '>',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: false,
    alwaysShowAllBtns: true,
    withFirstAndLast: false,
    disablePageTitle: true,
  };

   function fetchJobManager() {
    jm.getJobManager().then((response) => {
      if (isMounted) {
        if (response.data) {
          if (response.data.edf_list !== []) {
            setTdataLoaded(true);
            setData(response.data.edf_list);
          } else {
            setTdataLoaded(false);
            setData([]);
          }
        }

      }
    })
      .catch(function (error) {
        if (error.response.status === 403) {
          history.push('/login');
        }
      })
  }
  useEffect(() => {
    fetchJobManager();
    return () => { setIsMounted(false) }
  }, []);

  return (
    <>
    <div className='col '>
      <div className='row g-0 ps-3 pt-3'>
        <div className='table-request my-2 me-3'>
          <div className='table-data me-4'>
            <ToolkitProvider
              keyField='id'
              data={data}
              columns={columns}
              search>
              {
                props => (
                  <div className="pe-3">
                    <div className='row mb-2'>
                      <div className='col text-start ms-1'>
                        <h5>Job Manager</h5>
                      </div>
                      <div className='col text-end'>  
                        <i className="bi bi-search ms-1 ps-2 mb-2 " id='search-icon'></i>
                        <SearchBar placeholder=" Search "
                          {...props.searchProps} />
                      </div>
                    </div>
                    <BootstrapTable
                      {...props.baseProps}
                      pagination={paginationFactory(options)}
                      rowEvents={rowEvents}
                      wrapperClasses="table-responsive"
                      noDataIndication={() => { return (tdataLoaded ? "No data available" : <NoDataIndication />) }} />
                  </div>
                )
              }
            </ToolkitProvider>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default JobManager;