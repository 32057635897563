import { ax } from '../services/base';
import config from '../assets/config';

export default class SearchService {
    GetSearchDetails(payload) {
        return ax.post(`${config.apiURL}service_request/advancesearchsp`,payload);
    }
    InterpretationSearch(payload){
        return ax.post(`${config.apiURL}service_request/interpretationsearch`,payload);
    }
    GetEegSearch(payload){
        return ax.post(`${config.apiURL}search_eeg/advancesearch`,payload);
    }
    GetMarkerDetails(){
        return ax.post(`${config.apiURL}md_management/automate_mdata`);
    }
    GetAllActiveRequest(payload){
        return ax.post(`${config.apiURL}service_request/advancesearchsp`,payload);
    }
    EEGSearch(payload){
        return ax.post(`${config.apiURL}search_eeg/customadsearch`,payload);
    }
}
