import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { columns, options } from './data/LifeStyleData';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import '../../assets/styles/masterData/lifestyle.scss';
import LoadingIcon from '../../assets/img/sample-icons/sandbox.gif';
// Model Box
import AddLifestyleModal from './modal/lifestyle/AddLifestyleModal';
import DeleteLifestyleModal from './modal/lifestyle/DeleteLifestyleModal';
//API
import MasterDataService from "../../services/masterdata";
import { newLifestyle } from "../ToolTip/ToolTip";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const NoDataIndication = () => (
  <div className='spinner d-flex justify-content-center text-center h-100'>
    <img src={LoadingIcon} alt="loading-icon" width="200" height="200" />
    {/* <ScaleLoader size={60} color="#79a3bb" style={{ marginTop: '50px' }} /> */}
  </div>
);


function LifeStyle() {
  const history = useHistory();
  const { SearchBar } = Search;
  const [lifestyleData, setLifestyleData] = useState([]);
  const [selctedrowID, setSelectedID] = useState();
  const [selctedrowData, setSelectedData] = useState([]);
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [errmsg, setErrorMsg] = useState(false);
  const [isMounted, setIsMounted] = useState(true);
  const [tdataLoaded, setTdataLoaded] = useState(true);
  //CALLBACK VARIABLES
  const [isFlagSet, setIsFlagSet] = useState(false);
  const [isFlagSet1, setIsFlagSet1] = useState(false);
  //API CALL
  const msds = new MasterDataService();
  const lyfupnotify = () => toast.success("Lifestyle created successfully!");
  const lyfupnotifyErr = () => toast.error("Lifestyle couldn't be created!");
  const lyfupdnotify = () => toast.success("Lifestyle updated successfully!");
  const lyfupdnotifyErr = () => toast.error("Lifestyle couldn't be updated!");
  const lyfdelnotify = () => toast.success("Lifestyle deleted successfully!");
  //CALLBACK FUNCTION FOR CREATE LIFESTYLE
  function messageCallback(item) {
    setIsFlagSet(item);
    lyfupnotify();
  }

  //CALLBACK FUNCTION FOR DELETE LIFESTYLE
  function messageCallback1(item) {
    setIsFlagSet1(item);
    lyfdelnotify();
  }

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      setSelectedID(row.id);
      setSelectedData(row);
    },
  };

  const onSubmit = (data) => {
    let reqData = {
      "mdlifestyleid": selctedrowID,
      "lifestyle_name": data.lifestyleName.trim(),
      "lifestyle_hint": data.hint,
      "ls_category": data.ls_category
    }
    msds.createLifestyle(reqData)
      .then((response) => {
        if (response.data) {
          if (response.data.status === 'success') {
            getLifestyleData();
            hideModal('closeUpLyfModal');
            lyfupdnotify();
          }
        }
      })
      .catch(function (error) {
        setErrorMsg(true);
      })
  }
  function getLifestyleData() {
    msds.getLifestyle()
      .then((response) => {
        if (isMounted) {
          if (response.data) {
            if (response.data.data !== [] || response.data.data !== "") {
              setTdataLoaded(false);
              setLifestyleData(response.data.data);
            } else {
              setTdataLoaded(false);
            }
          }
        }
      }).catch(function (error) {
        if (error.response.status === 403) {
          history.push('/login');
        }
      })
  }
  useEffect(() => {
    getLifestyleData();
    return () => { setIsMounted(false); }
  }, []);

  function hideModal(target) {
    document.getElementById(target).click();
    reset();
  };

  useEffect(() => {
    reset(selctedrowData);
  }, [selctedrowData]);

  return (
    <div className='col lifestyle'>
      <div className='row g-0 ps-3 pt-3'>
        <div className='table-request my-2 me-3'>
          <AddLifestyleModal data={{ isFlagSet: isFlagSet, messageCallback: messageCallback, getLifestyleData: getLifestyleData }} />
          <DeleteLifestyleModal data={{ id: selctedrowID, isFlagSet1: isFlagSet1, messageCallback1: messageCallback1, getLifestyleData: getLifestyleData }} />
          {/* UPDATE LIFESTYLE START */}
          <div className="modal" id="updateLifestyle" data-bs-backdrop="false"
            tabIndex="-1" >
            <div className="modal-dialog shadow-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Update Lifestyle</h4>
                  <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <form onSubmit={handleSubmit(onSubmit)} >
                  <div className="modal-body p-4">
                    {errmsg ? <div className="err-msg">Lifestyle name already exist</div> : ''}
                    <div className='row text-start'>
                      <div className="mb-3">
                        <label htmlFor="lifestyleCategory" className="form-label">Lifestyle Category
                          <span className='required-field'>*</span></label>
                        <select className={`form-select p-2 ${errors.lifestyleCategory ? "is-invalid" : ""
                          }`}
                          selected={selctedrowData.ls_category}
                          {...register(
                            "ls_category",
                            { required: true })}>
                          <option value="">Select</option>
                          <option value="High Theta">High Theta</option>
                          <option value="High Beta">High Beta</option>
                          <option value="High Alpha">High Alpha</option>
                          <option value="High delta/Overwhelm">High delta/Overwhelm</option>
                        </select>
                        {(errors.lifestyleCategory && errors.lifestyleCategory.type === 'required') ? <div className="invalid-feedback">This field is required</div> : ''}
                      </div>
                      <div className="mb-3">
                        <label htmlFor="lifestyleName" className="form-label">Lifestyle Name
                          <span className='required-field'>*</span></label>
                        <input type="text"
                          defaultValue={selctedrowData.lifestyle_name}
                          maxLength="100"
                          onChange={(e) => setLifestyleData(e.target.value)}
                          className={`form-control p-2 ${errors.lifestyleName ? "is-invalid" : ""
                            }`}
                          {...register(
                            "lifestyleName",
                            { required: true }, { validate: (value) => { return !!value.trim() } }
                          )} />
                        {(errors.lifestyleName && errors.lifestyleName.type === 'required') ? <div className="invalid-feedback">This field is required</div> : ''}
                      </div>
                      <div className="mb-3">
                        <label htmlFor="hint" className="form-label">Hint</label>
                        <input type="text"
                          defaultValue={selctedrowData.lifestyle_hint}
                          onChange={(e) => setLifestyleData(e.target.value)}
                          className={`form-control p-2 ${errors.hint ? "is-invalid" : ""
                            }`}
                          {...register(
                            "hint",
                            { required: false }, { validate: (value) => { return !!value.trim() } }
                          )} />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer ">
                    <button type="button" id="closeUpLyfModal" className="btn btn-cancel text-white px-2" data-bs-dismiss="modal">Cancel</button>
                    <button type="submit" className="btn btn-save text-white px-2">Update </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/* UPDATE LIFESTYLE END */}
          <div className='table-data me-4'>
            <div>{newLifestyle}</div>
            <ToolkitProvider
              keyField='id'
              data={lifestyleData}
              columns={columns}
              search>
              {
                props => (
                  <div className="pe-3">
                    <div className='row mb-2'>
                      <div className='col text-start ms-1'>
                        <h5>Lifestyle</h5>
                      </div>
                      <div className='col text-end'>
                        <button type='button' className='btn-create btn px-3 py-1 ms-3 me-2'
                          data-bs-toggle="modal" data-bs-target="#createLifestyle" data-tip data-for="newLifestyleTip">
                          <i className="bi bi-person-plus-fill" ></i>Add </button>
                        <i className="bi bi-search ms-1 ps-2 mb-2 " id='search-icon'></i>
                        <SearchBar placeholder=" Search Lifestyle "
                          {...props.searchProps} />
                      </div>
                    </div>
                    <BootstrapTable
                      {...props.baseProps}
                      pagination={paginationFactory(options)}
                      rowEvents={rowEvents}
                      wrapperClasses="table-responsive"
                      noDataIndication={() => { return (tdataLoaded ? <NoDataIndication /> : "No data available") }}
                    />
                  </div>
                )
              }
            </ToolkitProvider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LifeStyle;