import React, { useEffect, useState } from "react";
import { Row } from 'react-bootstrap';
import "../../../assets/styles/pipelinerequest/pipelinetabs/interpretScreen.scss";
import Select from "react-select";
import PipelineRequestService from "../../../services/PipelineRequest";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RecordingAnalysisService from "../../../services/RecordingAnalysis";
import AssociatedDocumentService from "../../../services/AssociatedDocumentService";
import { Multiselect } from "multiselect-react-dropdown";
import { tempData } from "./interpretJson";
import { MdOutlineAdd } from 'react-icons/md';
import { Modal, Button } from 'react-bootstrap';
import MasterDataService from "../../../services/masterdata";


export default function InterpretationUpdate(props) {
    const location = useLocation();
    const [isMounted, setIsMounted] = useState(true);
    //SELECT BOX
    const [eyeOpenData, setEyeOpenData] = useState([]);
    const [selectedEyeOpen, setSelectedEyeOpen] = useState('');
    const [eyeCloseData, setEyeCloseData] = useState([]);
    const [selectedEyeClosed, setSelectedEyeClosed] = useState('');
    const [eyeOpenID, setEyeOpenID] = useState(0);
    const [eyeCloseID, setEyeCloseID] = useState(0);
    // const [impIO, setImpIO] = useState([]);
    const impIO = props.data.intData;
    const [interpretData, setInterpretData] = useState([]);
    //SELECTION BOX
    const [isExcess, setIsExcess] = useState(false);
    const [isLowAmplitude, setIsLowAmplitude] = useState(false);
    const [isPosterior, setIsPosterior] = useState(false);
    const [isFrontal, setIsFrontal] = useState(false);
    const [isTemporal, setIsTemporal] = useState(false);
    const [isCentral, setIsCentral] = useState(false);
    const [isDiffuse, setIsDiffuse] = useState(false);
    const [isDelta, setIsDelta] = useState(false);
    const [isTheta, setIsTheta] = useState(false);
    const [isAlpha, setIsAlpha] = useState(false);

    //checkbox
    const [ischecked, setIschecked] = useState(false);
    const [markLength, setMarkLength] = useState(false);
    const {
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    const recupnotify = () =>
        toast.success("Interpretation updated successfully!");
    const recupnotifyErr = () =>
        toast.error("Interpretation couldn't be updated!");
    const recmarknotify = () => toast.success("Marker updated successfully!");
    const recmarkErrnotify = () => toast.error("Marker should not be empty!");
    const recmarknotifyErr = () => toast.error("Marker couldn't be updated!");

    //MARKER
    const [markerLength, setMarkerLength] = useState();
    const [markerData, setMarkerData] = useState([]);
    const [checkMarkId, setCheckMarkId] = useState(false);
    const [markerType, setMarkerType] = useState("");
    const [keyVal, setKeyVal] = useState("");
    //get interpretation values

    const [checkedValue, setCheckedValue] = useState([]);
    const [dataLength, setDataLength] = useState(false);
    //Existing marker
    const [exMarker, setExMarker] = useState([]);

    const [eyeClosed, setEyeClosed] = useState([]);
    const [eyeOpened, setEyeOpened] = useState([]);
    const [EClen, setEClen] = useState(false);
    const [EOlen, setEOlen] = useState(false);
    //lk;
    const [alphaData, setAlphaData] = useState();
    const [thetaCData, setThetaCData] = useState();
    const [thetaOData, setThetaOData] = useState();
    const [postDomData, setPostDomData] = useState();
    const [selectedDropdown, setSelectedDropdown] = useState("");
    const [err, setErr] = useState(false);
    const [err1, setErr1] = useState(false);
    const [asymEC, setAsymEC] = useState();
    const [modalData, setModalData] = useState([]);

    const [checkedValues, setCheckedValues] = useState([]);
    const [show, setShow] = useState(false);

    //API
    const ds = new RecordingAnalysisService();
    const ads = new AssociatedDocumentService();
    const msds = new MasterDataService();

    const selectionOption = []

    //DATA CALCULATId
    function FetchDocumentTable() {
        let reqData = {
            "service_request": location.state.reqId,
            "DataFinder": {
                "pagesize": 1000,
                "currentpage": 1,
                "sortbycolumn": "created_on",
                "sortby": "desc",
                "searchdata": ""
            }
        }
        ads.GetDocumentTableData(reqData).then((response) => {
            if (response.data) {
                let eyeopen = [];
                let eyeclose = [];

                if (response.data.DocumentDetail.length > 0) {
                    for (let i = 0; i < response.data.DocumentDetail.length; i++) {
                        var fileExt = response.data.DocumentDetail[i].filename.split(".").pop();
                        if (response.data.DocumentDetail[i].doc_type == "Eye Open" && (fileExt === "xlsx" || fileExt === "xls")) {
                            eyeopen.push(response.data.DocumentDetail[i]);
                        }
                        else if (fileExt === "xlsx" || fileExt === "xls") {
                            eyeclose.push(response.data.DocumentDetail[i]);
                        }
                    }
                }
                setEyeOpenData(eyeopen);
                setEyeCloseData(eyeclose);
            }
        })
    }

    useEffect(() => {
        FetchDocumentTable();
    }, []);

    const options = eyeOpenData.map(function (docData) {
        return {

            value: docData.filename,
            label: docData.filename,
            id: docData.id,
        };
    });

    const handleEyeOpenChange = (selectedEyeOpen) => {
        setSelectedEyeOpen(selectedEyeOpen);
        setEyeOpenID(selectedEyeOpen.id);
        // console.log(selectedEyeOpen.id);
    }

    const options2 = eyeCloseData.map(function (docData) {
        return {
            value: docData.filename,
            label: docData.filename,
            id: docData.id
        }
    })
    const handleEyeCloseChange = (selectedEyeClosed) => {
        setSelectedEyeClosed(selectedEyeClosed);
        setEyeCloseID(selectedEyeClosed.id);
        // console.log(selectedEyeClosed.id);
    }
    // handle input change
    const handleInputChange = (e, index, id) => {

        let checked = e.target.checked;
        let val = checkedValue;

        if (checked === true) {
            checkedValue.push(id);
        }
        else if (checked === false) {
            checkedValue.pop(id);
        }
        setCheckedValue(checkedValue);
        // setCheckedValue(val);  
    };


    const modalClose = () => setShow(false);
    const modalShow = (id, marker, val) => {
        setModalData(current =>
            current.map(obj => {
                if (obj.fieldName) {
                    return { ...obj, ischecked: false };
                }
                return obj;
            }),
        );

        setMarkerType(val);
        setCheckedValues([]);
        setShow(true);
        let arr = [];
        if (val == "eyeopen") {
            let b = marker.eyeopen;
            arr.push(b.split(","));
        } else if (val == "eyeclosed") {
            let b = marker.eyeclosed;
            arr.push(b.split(","));
        }

        let c = arr[0];
        setCheckedValues(arr[0]);
        for (let i = 0; i < c.length; i++) {
            setModalData(current =>
                current.map(obj => {
                    if (obj.fieldName === c[i]) {
                        return { ...obj, ischecked: true };
                    }
                    return obj;
                }),
            );
        }
        setMarkerItemID(marker.id);
    }

    async function GetMarkerData() {
        let reqData = {
            sr_interpretation: location.state.reqId,
        };
        ds.GetInterpretMarket(reqData).then((response) => {
            if (isMounted) {
                if (response.data) {
                    if (response.data.status === "success") {
                        const d = response.data.data.length;
                        setMarkerLength(d);
                        if (d > 0) {
                            setDataLength(false);
                        }
                        else {
                            setDataLength(true);
                        }
                        setMarkerData(response.data.data);
                        for (let i = 0; i < response.data.data.length; i++) {
                            if (response.data.data[i].markername == "dfaa") {
                                setSelectedDropdown([{ label: response.data.data[i].eyeopen, value: response.data.data[i].eyeopen, id: response.data.data[i].id }]);
                            }
                        }

                        // setSelectedDropdown()
                    }
                }
            }
        });
    }
    useEffect(() => {
        GetMarkerData();
        return () => { setIsMounted(false); }
    }, []);
    console.log("marker", markerData);
    //AUTOMATE INTERPRETATION
    function AutomateInterpretation() {
        let reqData = {
            "EO_docid": eyeOpenID,
            "EC_docid": eyeCloseID
        }
        ds.UpdateAutomateInterpretation(reqData).then((response) => {
            if (response.data) {
                if (response.data.status === "success") {
                    setEyeClosed(response.data.req_list_EC);
                    let ec = response.data.req_list_EC;
                    let eo = response.data.req_list_EO;

                    if (ec.length > 0 && markerData.length > 0) {
                        for (let i = 0; i < ec.length; i++) {
                            for (let j = 0; j < markerData.length; j++) {
                                if (ec[i].fieldName == markerData[j].mfieldname) {
                                    let eyeclosed = ec[i].fieldValue.toString();
                                    setMarkerData(current =>
                                        current.map(obj => {
                                            if (obj.mfieldname == ec[i].fieldName) {
                                                return { ...obj, eyeclosed: eyeclosed };
                                            }
                                            return obj;

                                        }));
                                }
                                else {
                                    setMarkerData(current =>
                                        current.map(obj => {
                                            if (obj.mfieldname == ec[i].fieldName) {
                                                return { ...obj, eyeclosed: ec[i].fieldValue };
                                            }
                                            return obj;

                                        }));
                                }
                            }
                        }
                    }
                    if (eo.length > 0 && markerData.length > 0) {
                        for (let i = 0; i < eo.length; i++) {
                            for (let j = 0; j < markerData.length; j++) {
                                if (eo[i].fieldName === markerData[j].mfieldname) {
                                    let eyeopen = eo[i].fieldValue.toString();
                                    setMarkerData(current =>
                                        current.map(obj => {
                                            if (obj.mfieldname === eo[i].fieldName) {
                                                return { ...obj, eyeopen: eyeopen };
                                            }
                                            return obj;

                                        }));
                                }

                            }
                            // if (response.data.req_list_EC[i].fieldName === "Alpha/beta ratio") {
                            //     setAlphaCData(response.data.req_list_EC[i].fieldValue);

                            //     //    setNoAlpha(true);
                            //     //    console.log('ts',response.data.req_list_EC);
                            // } else if (response.data.req_list_EC[i].fieldName === "Posterior dominant rhythm") {
                            //     setPostCDomData(response.data.req_list_EC[i].fieldValue);

                            //     //    setNoAlpha(true);
                            //     //    console.log('ts',response.data.req_list_EC);
                            // } else if (response.data.req_list_EC[i].fieldName === "Theta/beta ratio") {
                            //     setThetaCData(response.data.req_list_EC[i].fieldValue)
                            // }
                            // else {
                            //     // setNoAlpha(false);
                            // }

                        }
                    }

                }
                if (response.data.req_list_EC.length > 0) {
                    setEClen(true);
                    setErr(false);
                }
                else {
                    setEClen(false);
                    setErr(true);
                }
                if (response.data.req_list_EO.length > 0) {
                    setEOlen(true);
                    setErr1(false);
                }
                else {
                    setEOlen(false);
                    setErr1(true);
                }
                setEyeOpened(response.data.req_list_EO);
                // console.log(response.data.req_list_EC.Alpha/beta ratio);
            }
        })
    }


    async function getExistingInterpretation() {
        let reqData = {
            sr_interpretation: location.state.reqId,
        };
        ds.GetExistingInterpretation(reqData).then((response) => {
            if (response.data) {
                if (response.data.status === "success") {

                    if (response.data.data.length !== 0) {
                        setMarkLength(false);
                    }
                    else {
                        setMarkLength(true);
                    }
                    setIschecked(false);
                    GetMarkerData();
                    setExMarker(response.data.data);
                }
            }
        });
    }
    useEffect(() => {
        let mounted = true;
        getExistingInterpretation();
        return () => { mounted = false; }
    }, []);

    function saveExistingInterpretation() {
        let reqData = {
            markerid: checkedValue.toString(),
            requestid: location.state.reqId,
        };
        if (checkedValue == "" || checkedValue == [] || checkedValue == null) {
            recmarkErrnotify();
        }
        else {
            ds.SaveExistingInterpretation(reqData).then((response) => {
                if (response.data) {
                    if (response.data.status === "success") {
                        setMarkerData([]);
                        GetMarkerData();
                        getExistingInterpretation();
                        recmarknotify();

                    }
                }
            });
        }
    }

    const onSubmit = (data) => {

        let fdata = [];

        markerData.forEach((items, i) => {
            if (items.id != 0) {
                // if (items.mfieldname === "Alpha/beta ratio") {
                //     fdata.push({
                //         id: items.id,
                //         checked: "true",
                //         eyeopen: alphaData || items.eyeopen || "",
                //         eyeclosed: alphaCData || items.eyeclosed || ""
                //     })
                // } else if (items.mfieldname === "Theta/beta ratio") {
                //     fdata.push({
                //         id: items.id,
                //         checked: "true",
                //         eyeopen: thetaOData || items.eyeopen || "",
                //         eyeclosed: thetaCData || items.eyeclosed || ""
                //     })
                // } else if (items.mfieldname === "Posterior dominant rhythm") {
                //     fdata.push({
                //         id: items.id,
                //         checked: "true",
                //         eyeopen: postDomData || items.eyeopen || "",
                //         eyeclosed: postDomCData || items.eyeclosed || ""
                //     })
                // } else if (items.mfieldname === "Asymmetry Present") {
                //     fdata.push({
                //         id: items.id,
                //         checked: "true",
                //         eyeopen: items.eyeopen || "",
                //         eyeclosed: items.eyeclosed || ""
                //     })
                // }
                // else {
                fdata.push({
                    id: items.id,
                    checked: "true",
                    eyeopen: items.eyeopen || "",
                    eyeclosed: items.eyeclosed || ""
                });
                // }
            }
        });

        ds.SaveInterpretMarketData(fdata).then((response) => {
            if (response.data) {
                if (response.data.status === "success") {
                    recupnotify();
                    GetMarkerData();
                }
            }
        });
    };
    //IMPRESSION EYE OPENED
    const IioOptions = impIO.map(function (item) {
        return {
            value: item,
            label: item,
            id: ""
        };
    });
    const saveMarker = () => {
        console.log('save', markerItemID, markerType);
        console.log("markd", modalData);
        let b = [];
        for (let i = 0; i < modalData.length; i++) {
            if (modalData[i].ischecked == true) {
                b.push(modalData[i].fieldName);
                // setCheckedValues([...checkedValues, modalData[i].fieldName]);
            }
        }
        setCheckedValues(b);
        console.log('b', b);
        let markData = markerData;
        if (markerType == "eyeopen") {
            for (let i = 0; i < markData.length; i++) {
                if (markData[i].id == markerItemID) {
                    markData[i].eyeopen = b.join(",");
                }
            }
            // markData(current =>
            //     current.map(obj => {
            //         if (obj.id === markerItemID) {
            //             return { ...obj, eyeopen: b.join(",") };
            //         }
            //         return obj;
            //     }),
            // );
        } else if (markerType == "eyeclosed") {
            for (let i = 0; i < markData.length; i++) {
                if (markData[i].id == markerItemID) {
                    markData[i].eyeclosed = b.join(",");
                }
            }
            // markData(current =>
            //     current.map(obj => {
            //         if (obj.id === markerItemID) {
            //             return { ...obj, eyeclosed: b.join(",") };
            //         }
            //         return obj;
            //     }),
            // );
        } else return;
        setMarkerData(markData);
        setKeyVal(Date.now());



        modalClose();
        // getInterpretionGridData();
        let c = [];
        // modalData.forEach((items)=>{
        //    c.push({
        //     id: items.id,
        //     fieldName:items.fieldName,
        //     category:items.category,
        //     ischecked:false
        //    })
        // })
        // setModalData(c);
        // setMarkerData(markerData.find)
    }


    const onEyeOpenSelect = id => {
        // console.log('test', e.value);
        // let arr = [];
        // let val = e;
        // if (e.length > 0) {
        //     for (let i = 0; i < e.length; i++) {
        //         arr.push(e[i].value);
        //     }
        // }
        // console.log('arr', arr);
        // let b = arr.join("|");
        // console.log("b", b);

        setMarkerData(current =>
            current.map(obj => {
                if (obj.id === id) {
                    return { ...obj, eyeopen: checkedValues.join("|") };
                }
                return obj;
            }),
        );

        // setMarkerData(markerData.find)
    }

    const changeEyeOpen = (id) => (selectedDropdown) => {

        let val = selectedDropdown;
        setSelectedDropdown();
        // setMarkerData(current =>
        //     current.map((obj )=> {
        //         if (obj.id === id) {
        //             return { ...obj, eyeopen: arr};
        //         }
        //         return obj;
        //     }),
        // );
    }

    const onEyeClosedSelect = id => e => {
        setMarkerData(current =>
            current.map(obj => {
                if (obj.id === id) {
                    return { ...obj, eyeclosed: e.target.value };
                }

                return obj;
            }),
        );
        // setMarkerData(markerData.find)
    }

    const handleRadioChange = id => e => {
        if (e.target.checked === true) {
            setMarkerData(current =>
                current.map(obj => {
                    if (obj.id === id) {
                        return { ...obj, eyeclosed: "true", eyeopen: "false" };
                    }
                    return obj;
                }),
            );

        }
        else return null;
    }

    const handleRadioChangeOpened = id => e => {
        if (e.target.checked === true) {
            setMarkerData(current =>
                current.map(obj => {
                    if (obj.id === id) {
                        return { ...obj, eyeclosed: "false", eyeopen: "true" };
                    }
                    return obj;
                }),
            );
        }
        else return null;
    }

    const changeInputField = id => e => {
        setMarkerData(current =>
            current.map(obj => {
                if (obj.id === id) {
                    return { ...obj, eyeopen: e.target.value };
                }

                return obj;
            }),
        );
    }
    const changeInputFieldClosed = id => e => {

        setMarkerData(current =>
            current.map(obj => {
                if (obj.id === id) {
                    return { ...obj, eyeclosed: e.target.value };
                }

                return obj;
            }),
        );
    }

    const calculateData = () => {
        if (eyeOpenID !== 0 && eyeCloseID !== 0) {
            setAlphaData(tempData[0].req_list_EC.Alphabeta_ratio_EC[0]);
            setThetaOData(tempData[0].req_list_EC.Theta_beta_ratio_EO[0]);
            setThetaCData(tempData[0].req_list_EC.Theta_beta_ratio_EC[0]);
            setPostDomData(tempData[0].req_list_EC.Posterior_dominant_rhythm_EC);
            setAsymEC(tempData[0].req_list_EC.amplitude_asymmetry_EC);
            setErr(false);
        }
        else {
            setErr(true);
        }

    }
    const onSelectionChange = (e) => {
        let c = checkedValues;
        if (e.target.checked == true) {
            let d = e.target.value;
            setCheckedValues([...checkedValues, d]);
        }
        else {
            setCheckedValues(current =>
                current.filter(val => {
                    if (val !== e.target.value)
                        return val;
                }));
        }
    }

    const [markerItemID, setMarkerItemID] = useState('');
    const data = "";
    function getInterpretionGridData() {

        msds.getInterpretationGrid()

            .then((response) => {
                if (isMounted) {
                    if (response.data) {
                        if (response.data.data !== [] || response.data.data !== null || response.data.data !== "") {

                            let data1 = response.data.data;
                            // setModalData([]);
                            if (modalData.length > 0) {
                                return modalData;
                            } else {
                                data1.forEach((items) => {
                                    modalData.push({
                                        id: items.id,
                                        category: items.ls_category,
                                        fieldName: items.interpretationchoicefield_name,
                                        ischecked: false
                                    });
                                })
                            }

                        }
                    }
                }
            }).catch(function (error) {

            })
    }

    useEffect(() => {
        getInterpretionGridData();
        return () => { setIsMounted(false); }
    }, [modalData]);

    const modalDataChange = (e, val) => {

        if (e.target.checked == true) {
            setModalData(current =>
                current.map(obj => {
                    if (obj.id === val.id) {
                        return { ...obj, ischecked: true };
                    }
                    return obj;
                }),
            );
        } else {
            setModalData(current =>
                current.map(obj => {
                    if (obj.id === val.id) {
                        return { ...obj, ischecked: false };
                    }
                    return obj;
                }),
            );
        }
        setCheckedValues([...checkedValues, val.fieldName]);

        // let b = checkedValues;
        // for(let i=0;i<modalData.length;i++){
        //     if(modalData[i].ischecked==true){
        //         b.push(modalData[i].fieldName);
        //         // setCheckedValues([...checkedValues, modalData[i].fieldName]);
        //     }
        // }
        // setCheckedValues([...checkedValues,b]);
        // if (e.target.checked == true){
        //     let d = e.target.value;
        //     setCheckedValues([...checkedValues, d]);
        // }
        // else {
        //     setCheckedValues(current =>
        //         current.filter(val => {
        //             if (val !== e.target.value)
        //                 return val;
        //         }));
        // }
        // for(let i=0;i<checkedValues.length;i++){
        //     for(let j=0;j<modalData.length;j++){
        //         if(modalData)
        //         if(checkedValues[i]==modalData[j].fieldName){
        //             return;
        //         }else{

        //         }
        //     }
        // }

    }

    return (
        <>
            <div className="tab-content mb-3 ">
                <h6 className="text-start mb-3 ps-3  pt-3"></h6>
                <div className="row g-0 ">
                    <div className=" ps-4 pe-4 mb-2 ">
                        <div className="data-calc me-3 ms-0 px-4 py-3 row">
                            <h6 className="text-start fw-bold ps-1 mb-3">Data file used for calculation</h6>
                            <div className="col-md-5">
                                <Row>
                                    <label className="col-auto text-start pe-2 ps-1 my-auto">Eye Open</label>
                                    <Select
                                        className="col text-start"
                                        value={selectedEyeOpen}
                                        onChange={handleEyeOpenChange}
                                        options={options}
                                    />

                                </Row>

                            </div>
                            <div className="col-md-5 ps-3">
                                <Row>
                                    <label className="col-auto text-start pe-0 my-auto">Eye Closed</label>
                                    <Select
                                        className="col  text-start"
                                        value={selectedEyeClosed}
                                        onChange={handleEyeCloseChange}
                                        options={options2}
                                    />

                                </Row>
                                {/* {err ? <div className="text-danger">This field is required</div> : ""} */}
                            </div>

                            <button type="button" className="col ms-auto btn btn-primary " onClick={AutomateInterpretation}>Get Value</button>
                        </div>
                        {err && err1 ? <div className="text-danger">Both fields are required</div> : ""}

                    </div>
                    <div className="col-md-7 ms-4 px-4 interpretForm py-3 ms-4 mb-3">
                        <form>
                            {markerData.map((marker, i) => {
                                return (

                                    <div className="text-start mb-3" key={i}>
                                        {marker.mfieldtype === "dropdown" || marker.mfieldtype === "Dropdown" ? <>

                                            <label className="mb-1 title col">
                                                {marker.mfieldname}
                                            </label>


                                            <div className="row int-content mx-0 pb-3 pt-2">
                                                {marker.EO_isactive === true ?
                                                    <div className="col-md-6">
                                                        <div className="row">
                                                            <label className="col mb-1">Eyes Opened</label>
                                                            <div className='col-auto text-end' >
                                                                <MdOutlineAdd className='add-srch-btn' onClick={() => modalShow(marker.id, marker, "eyeopen")} />
                                                            </div>
                                                        </div>

                                                        <input type="text" className="form-control" key={keyVal} defaultValue={marker.eyeopen} onChange={changeInputField(marker.id)} />
                                                    </div>
                                                    : ""}
                                                {marker.EC_isactive === true ?
                                                    <div className="col-md-6">
                                                        <div className="row">
                                                            <label className="mb-1 col">Eyes Closed</label>
                                                            <div className='col-auto text-end' >
                                                                <MdOutlineAdd className='add-srch-btn' onClick={() => modalShow(marker.id, marker, "eyeclosed")} />
                                                            </div>
                                                        </div>
                                                        <input type="text" className="form-control" key={keyVal} defaultValue={marker.eyeclosed} onChange={changeInputFieldClosed(marker.id)} />
                                                        {/* <select defaultValue={marker.eyeclosed || ""}
                                                        onChange={onEyeClosedSelect(marker.id)}
                                                        className="form-select p-2"
                                                    >
                                                        <option value="">Select </option>
                                                        {IioOptions.map((item, i) => (
                                                            <option key={i} value={item.value}>
                                                                {item.value}
                                                            </option>
                                                        ))}

                                                    </select> */}
                                                        {/* <Select
                                                        value={IicOptions.find((item) => {
                                                            if (item.value === marker.eyeclosed) {
                                                                return item.value;
                                                            }
                                                        })}
                                                        onChange={handleIICChange}
                                                        options={IicOptions}
                                                        defaultValue={IicOptions.find((item) => {
                                                            if (item.value === marker.eyeclosed) {
                                                                return item.value;
                                                            }
                                                        })}
                                                    /> */}
                                                    </div>
                                                    : ""}
                                            </div>
                                        </> : marker.mfieldtype === "textbox" || marker.mfieldtype === "Textbox" ?
                                            <>
                                                <label className="mb-1 title">
                                                    {marker.mfieldname}
                                                </label>

                                                <div className="row int-content mx-0 pb-3 pt-2">
                                                    {marker.EO_isactive === true ?
                                                        <div className="col-md-6">

                                                            <div className="row g-0">
                                                                <label className="col-auto mb-1">
                                                                    Eyes Opened
                                                                </label>
                                                                <span className="text-danger col-auto ms-auto mt-1">
                                                                    (Expected 8.0-12.0)
                                                                </span>
                                                            </div>
                                                            {/* {EOlen ? <div>

                                                            {eyeOpened.map((ey, id) => {
                                                                if (marker.mfieldname === ey.fieldName && ey.fieldType === "eyeopen") {
                                                                    return (
                                                                        <div key={id}>

                                                                            <input
                                                                                type="number"
                                                                                className={`form-control p-2 `}
                                                                                onChange={changeInputFieldClosed(marker.id)}
                                                                                defaultValue={ey.fieldValue || marker.eyeopen}
                                                                            />

                                                                        </div>

                                                                    );
                                                                }
                                                                else {
                                                                    return (
                                                                        <input
                                                                            type="number"
                                                                            className={`form-control p-2 `}
                                                                            onChange={changeInputFieldClosed(marker.id)}
                                                                            defaultValue={marker.eyeopen}
                                                                        />
                                                                    )
                                                                }
                                                            })}</div> : */}
                                                            <input
                                                                type="number"
                                                                className={`form-control p-2 `}
                                                                onChange={changeInputField(marker.id)}
                                                                value={marker.eyeopen || ""}
                                                            />
                                                        </div>
                                                        : ""}
                                                    {marker.EC_isactive === true ?
                                                        <div className="col-md-6">
                                                            <div className="row g-0">
                                                                <label className="col-auto mb-1">
                                                                    Eyes Closed
                                                                </label>
                                                                <span className="text-danger col-auto ms-auto ">
                                                                    (Expected 8.0-12.0)
                                                                </span>
                                                            </div>
                                                            {/* {EClen ? <>
                                                            {eyeClosed.map((ey, ids) => {

                                                                return (
                                                                    <div key={ids}>
                                                                        {marker.mfieldname === ey.fieldName ?

                                                                            <input
                                                                                type="number"
                                                                                className={`form-control p-2 `}
                                                                                onChange={changeInputFieldClosed(marker.id)}
                                                                                defaultValue={ey.fieldValue || marker.eyeclosed}
                                                                            />
                                                                            : ""
                                                                        }
                                                                    </div>

                                                                );

                                                            })}

                                                        </> : */}
                                                            <input
                                                                type="number"
                                                                className={`form-control p-2 `}
                                                                onChange={changeInputFieldClosed(marker.id)}
                                                                value={marker.eyeclosed || ""}
                                                            />
                                                        </div>
                                                        : ""}
                                                </div>
                                                {/* :
                                                          <div className="row int-content mx-0 pb-3 pt-2"> 
                                                       <div className="col-md-6">
                                                           <div className="row g-0">
                                                               <label className="col-auto mb-1">
                                                                   Eyes Opened
                                                               </label>
                                                               <span className="text-danger col-auto ms-auto mt-1">
                                                                   (Expected 0-3.0)
                                                               </span>
                                                           </div>
                                                           <input
                                                               type="number"
                                                               className={`form-control p-2`}
                                                               onChange={changeInputField(marker.id)}
                                                               defaultValue={marker.eyeopen}
                                                           // defaultValue={alphaData}
                                                           />
                                                       </div>
                                                       <div className="col-md-6">
                                                           <div className="row g-0">
                                                               <label className="col-auto mb-1">
                                                                   Eyes Closed
                                                               </label>
                                                               <span className="text-danger col-auto ms-auto ">
                                                                   (Expected 0-3.0)
                                                               </span>
                                                           </div>
                                                           <input
                                                               type="number"
                                                               className={`form-control p-2 `}
                                                               onChange={changeInputFieldClosed(marker.id)}
                                                               defaultValue={marker.eyeclosed}
                                                           />
                                                       </div>
                                                   </div>  }</> 
                                                          );
    
                                                    })}
                                                    </> */}
                                                {/* :<div className="row int-content mx-0 pb-3 pt-2"> 
                                                       <div className="col-md-6">
                                                           <div className="row g-0">
                                                               <label className="col-auto mb-1">
                                                                   Eyes Opened
                                                               </label>
                                                               <span className="text-danger col-auto ms-auto mt-1">
                                                                   (Expected 0-3.0)
                                                               </span>
                                                           </div>
                                                           <input
                                                               type="number"
                                                               className={`form-control p-2`}
                                                               onChange={changeInputField(marker.id)}
                                                               defaultValue={marker.eyeopen}
                                                           // defaultValue={alphaData}
                                                           />
                                                       </div>
                                                       <div className="col-md-6">
                                                           <div className="row g-0">
                                                               <label className="col-auto mb-1">
                                                                   Eyes Closed
                                                               </label>
                                                               <span className="text-danger col-auto ms-auto ">
                                                                   (Expected 0-3.0)
                                                               </span>
                                                           </div>
                                                           <input
                                                               type="number"
                                                               className={`form-control p-2 `}
                                                               onChange={changeInputFieldClosed(marker.id)}
                                                               defaultValue={marker.eyeclosed}
                                                           />
                                                       </div>
                                                   </div> */}

                                            </>
                                            : marker.mfieldtype === "radio" || marker.mfieldtype === "Radio" ?
                                                marker.mfieldname === "Asymmetry Present" ?
                                                    <>
                                                        <label className="mb-1 title">
                                                            {marker.mfieldname}
                                                        </label>
                                                        <div className="row int-content mx-0 pb-3 pt-2">
                                                            <div className="col form-check align-items-center">

                                                                <input className="form-check-input mt-2" type="radio" name={`check${marker.markerid}`} id={`check${marker.markerid}+${i}`}
                                                                    onChange={handleRadioChange(marker.id)}
                                                                    value={asymEC === 1 ? true : marker.eyeopen === "true" ? true : false}
                                                                    checked={asymEC === 1 ? true : marker.eyeopen === "true" ? true : false} />
                                                                <label className="form-check-label" htmlFor={`check${marker.markerid}+${i}`}>
                                                                    Eyes Closed
                                                                </label>
                                                                {/* <input
                                                                name="check"
                                                                type="radio"
                                                                // {...register("check1", { required: false })}
                                                                id={`check${marker.id}`}
                                                                className="me-1 mt-2"
                                                                onChange={(e) =>handleRadioChange(e,marker.markerid)}
                                                                value={marker.eyeclosed === "true" ? true : false}
                                                                checked={marker.eyeclosed === "true" ? true : false}
                                                            />
                                                            <label
                                                                htmlFor="check1"
                                                                className="form-check-label"
                                                            >
                                                                Eyes Closed
                                                            </label> */}
                                                            </div>

                                                            <div className="col form-check">
                                                                <input className="form-check-input mt-2" type="radio" name={`check${marker.markerid}`} id={`check${marker.markerid}+${i + 1}`}
                                                                    onChange={handleRadioChangeOpened(marker.id)}
                                                                    value={marker.eyeopen === "true" ? true : false}
                                                                    checked={marker.eyeopen === "true" ? true : false} />
                                                                <label className="form-check-label" htmlFor={`check${marker.markerid}+${i + 1}`}>
                                                                    Eyes Opened
                                                                </label>
                                                                {/* <input
                                                                name="check1"
                                                                type="radio"
                                                                // {...register("check2", { required: false })}
                                                                className="me-1 mt-2"
                                                                id="check2"
                                                                onChange={(e) => {
                                                                    setCheck2(true);
                                                                    setCheck1(false);
                                                                }}
                                                                value={marker.eyeopen === "true" ? true : false}
                                                                checked={marker.eyeopen === "true" ? true : false}
                                                            />
                                                            <label
                                                                className="form-check-label mb-1"
                                                                htmlFor="  flexCheckChecked"
                                                            >
                                                                Eyes Opened
                                                            </label> */}
                                                            </div>
                                                        </div>
                                                    </> :
                                                    <>
                                                        <label className="mb-1 title">
                                                            {marker.mfieldname}
                                                        </label>
                                                        <div className="row int-content mx-0 pb-3 pt-2">
                                                            <div className="col form-check align-items-center">

                                                                <input className="form-check-input mt-2" type="radio" name={`check${marker.markerid}`} id={`check${marker.markerid}+${i}`}
                                                                    onChange={handleRadioChange(marker.id)}
                                                                    value={marker.eyeclosed === "true" ? true : false}
                                                                    checked={marker.eyeclosed === "true" ? true : false} />
                                                                <label className="form-check-label" htmlFor={`check${marker.markerid}+${i}`}>
                                                                    Eyes Closed
                                                                </label>
                                                                {/* <input
                                                             name="check"
                                                             type="radio"
                                                             // {...register("check1", { required: false })}
                                                             id={`check${marker.id}`}
                                                             className="me-1 mt-2"
                                                             onChange={(e) =>handleRadioChange(e,marker.markerid)}
                                                             value={marker.eyeclosed === "true" ? true : false}
                                                             checked={marker.eyeclosed === "true" ? true : false}
                                                         />
                                                         <label
                                                             htmlFor="check1"
                                                             className="form-check-label"
                                                         >
                                                             Eyes Closed
                                                         </label> */}
                                                            </div>

                                                            <div className="col form-check">
                                                                <input className="form-check-input mt-2" type="radio" name={`check${marker.markerid}`} id={`check${marker.markerid}+${i + 1}`}
                                                                    onChange={handleRadioChangeOpened(marker.id)}
                                                                    value={marker.eyeopen === "true" ? true : false}
                                                                    checked={marker.eyeopen === "true" ? true : false} />
                                                                <label className="form-check-label" htmlFor={`check${marker.markerid}+${i + 1}`}>
                                                                    Eyes Opened
                                                                </label>
                                                                {/* <input
                                                             name="check1"
                                                             type="radio"
                                                             // {...register("check2", { required: false })}
                                                             className="me-1 mt-2"
                                                             id="check2"
                                                             onChange={(e) => {
                                                                 setCheck2(true);
                                                                 setCheck1(false);
                                                             }}
                                                             value={marker.eyeopen === "true" ? true : false}
                                                             checked={marker.eyeopen === "true" ? true : false}
                                                         />
                                                         <label
                                                             className="form-check-label mb-1"
                                                             htmlFor="  flexCheckChecked"
                                                         >
                                                             Eyes Opened
                                                         </label> */}
                                                            </div>
                                                        </div>
                                                    </> : ""
                                        }
                                    </div>
                                );
                            })}
                        </form>
                    </div>
                    <div className="col-md-4 ms-4 posterior-content p-1 mb-3">
                        <h6 className="text-dark text-start mt-3 p-2 text-bold">
                            Available Markers for Analysis
                        </h6>
                        <div className="posterior-cont bg-white p-2 mx-1 mt-2  mb-0">
                            {markLength ? <div className="align-items-center justify-content-center mt-5"><h6 className="text-bold">No markers available</h6></div> : <>
                                {exMarker.map((mark, i) => {
                                    return (
                                        <div className="form-check mt-2 text-start" key={mark.id}>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                value={mark.markerid}
                                                autoComplete="off"
                                                id={mark.markerid}
                                                // checked={ischecked}
                                                defaultChecked={false}
                                                // checked={mark.markerid === 1 ? checkedMarker1 : mark.markerid === 2 ? checkedMarker2 : mark.markerid === 3 ?
                                                //     checkedMarker3 : mark.markerid === 4 ? checkedMarker4 : mark.markerid === 5 ? checkedMarker5 : mark.markerid === 6 ?
                                                //         checkedMarker6 : mark.markerid === 7 ? checkedMarker7 : false}
                                                onChange={(e) => handleInputChange(e, i, mark.id)}
                                            // checked={checkedMarker1}
                                            // onChange={() => setCheckedMarker1(!checkedMarker1)}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor={`${mark.markerid}`}
                                            >
                                                {mark.mfieldname}
                                            </label>
                                        </div>
                                    );
                                })}
                            </>
                            }

                            {/* <div className="form-check mt-2 text-start">
                                <input className="form-check-input" type="checkbox" value="2" id="flexCheckChecked" checked={checkedMarker2}
                                    onChange={() => setCheckedMarker2(!checkedMarker2)} />
                                <label className="form-check-label " htmlFor="flexCheckChecked">
                                    Marker 2
                                </label>
                            </div>
                            <div className="form-check mt-2 text-start">
                                <input className="form-check-input" type="checkbox" value="3" id="flexCheckChecked" checked={checkedMarker3}
                                    onChange={() => setCheckedMarker3(!checkedMarker3)} />
                                <label className="form-check-label " htmlFor="flexCheckChecked">
                                    Marker 3
                                </label>
                            </div> */}

                            {/* {inputList.map((x, marker,i) => {
                                if(markerLength!=7){
                                    return null
                                }
                                else{
                                return (
                                    
                                    <div className="form-check mt-2 text-start" key={i}>
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            name="marker"
                                            id={markerLength + marker}
                                            value={markerLength+ marker}
                                            checked={x.marker}
                                            onChange={(e) => handleInputChange(e, i, e.target.checked)}
                                        />
                                        <label className="form-check-label">Marker {markerLength+1}</label>
                                    </div>
                                );
                                }
                            })} */}
                        </div>
                        <div className="footer bg-white p-2 mx-1 text-end">
                            {markLength ? "" : <button
                                className="btn btn-addMark"
                                // onClick={() => handleAddClick()}
                                onClick={() => saveExistingInterpretation()}
                            >
                                Add to input
                            </button>}

                        </div>
                    </div>
                    <button
                        className="btn btn-saveInt text-white  col-auto ms-auto me-3 mb-2 py-1"
                        onClick={handleSubmit((onSubmit))}>
                        Save
                    </button>
                </div>
                {/* interpret modal popup */}
                <Modal show={show} onHide={modalClose}>
                    <Modal.Dialog className="m-0">
                        <Modal.Header closeButton>
                            <Modal.Title>Choose Types</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>

                            <div className="row">

                                <div className="col">
                                    <label className="fw-bold mb-2">Selection 1</label>
                                    {/* <Select
                                            className="col text-start"
                                            value={selectedEyeOpen}
                                            onChange={handleEyeOpenChange}
                                            options={selectionOption}
                                        /> */}
                                    {modalData.map(item => {
                                        return (
                                            <>
                                                {item.category == "Selection 1" ?
                                                    < div >
                                                        <input type="checkbox" value={item.fieldName} checked={item.ischecked} onChange={(e) => modalDataChange(e, item)} />
                                                        <label className='ms-1 mb-1 col'> {item.fieldName}</label>
                                                    </div>
                                                    : ""}

                                            </>
                                        );
                                    })}


                                    {/* <div>
                                            <input type="checkbox" value="Low Amplitude" onChange={(e) => onSelectionChange(e)} />
                                            <label className='ms-1 mb-1'> Low Amplitude</label>
                                        </div> */}
                                </div>
                                <div className="col">
                                    <label className="fw-bold mb-2">Selection 2</label>
                                    {modalData.map(item => {
                                        return (
                                            <>
                                                {item.category == "Selection 2" ?
                                                    < div >
                                                        <input type="checkbox" value={item.fieldName} checked={item.ischecked} onChange={(e) => modalDataChange(e, item)} />
                                                        <label className='ms-1 mb-1 col'> {item.fieldName}</label>
                                                    </div>
                                                    : ""}
                                            </>
                                        );
                                    })}

                                    {/* <div>
                                            <input type="checkbox" value="Frontal" onChange={(e) => onSelectionChange(e)} />
                                            <label className='ms-1 mb-1'> Frontal</label>
                                        </div>
                                        <div>
                                            <input type="checkbox" value="Temporal" onChange={(e) => onSelectionChange(e)} />
                                            <label className='ms-1 mb-1'> Temporal</label>
                                        </div>
                                        <div>
                                            <input type="checkbox" value="Central" onChange={(e) => onSelectionChange(e)} />
                                            <label className='ms-1 mb-1'> Central</label>
                                        </div>
                                        <div>
                                            <input type="checkbox" value="Diffuse" onChange={(e) => onSelectionChange(e)} />
                                            <label className='ms-1 mb-1'> Diffuse</label>
                                        </div> */}
                                </div>
                                <div className="col">
                                    <label className="fw-bold mb-2">Selection 3</label>
                                    {modalData.map(item => {
                                        return (
                                            <>
                                                {item.category == "Selection 3" ?
                                                    < div >
                                                        <input type="checkbox" value={item.fieldName} checked={item.ischecked} onChange={(e) => modalDataChange(e, item)} />
                                                        <label className='ms-1 mb-1 col'> {item.fieldName}</label>
                                                    </div>
                                                    : ""}

                                            </>
                                        );
                                    })}

                                    {/* <div>
                                            <input type="checkbox" value="Theta" onChange={(e) => onSelectionChange(e)} />
                                            <label className='ms-1 mb-1'> Theta </label>
                                        </div>
                                        <div>
                                            <input type="checkbox" value="Alpha" onChange={(e) => onSelectionChange(e)} />
                                            <label className='ms-1 mb-1'> Alpha </label>
                                        </div> */}
                                </div>
                            </div>

                        </Modal.Body>

                        <Modal.Footer>
                            <Button className="btn-cancel border-0" onClick={() => modalClose()}>Close</Button>
                            <Button className="btn-save border-0" onClick={() => saveMarker()} >Save</Button>
                        </Modal.Footer>
                    </Modal.Dialog>
                </Modal>

            </div>
        </>
    );
}
