import ReactTooltip from 'react-tooltip';
const editTip = <ReactTooltip id="editTip" place="top" effect="solid">
Edit
</ReactTooltip>;
const viewTip = <ReactTooltip id="viewTip" place="top" effect="solid">
View
</ReactTooltip>;
const deleteTip = <ReactTooltip id="deleteTip" place="top" effect="solid">
Delete
</ReactTooltip>;
const newRequest = <ReactTooltip id="newRequestTip" place="top" effect="solid">
Add new request
</ReactTooltip>;
const advacedSearch = <ReactTooltip id="advancedSerachTip" place="top" effect="solid">
Advanced Search
</ReactTooltip>;
const newPatient = <ReactTooltip id="newPatientTip" place="top" effect="solid">
Add new patient
</ReactTooltip>;

const newAccount = <ReactTooltip id="newAccountTip" place="top" effect="solid">
Add new account
</ReactTooltip>;
const newMedication = <ReactTooltip id="newMedicationTip" place="top" effect="solid">
Add new medication
</ReactTooltip>;
const newSupplement = <ReactTooltip id="newSupplementTip" place="top" effect="solid">
Add new supplement
</ReactTooltip>;
const newLifestyle = <ReactTooltip id="newLifestyleTip" place="top" effect="solid">
Add new lifestyle
</ReactTooltip>;
const newDiagnosis = <ReactTooltip id="newDiagnosisTip" place="top" effect="solid">
Add new diagnosis
</ReactTooltip>;
const newSymptoms = <ReactTooltip id="newSymptomsTip" place="top" effect="solid">
Add new symptoms
</ReactTooltip>;

export {editTip,
    viewTip,
    deleteTip,
    newRequest,
    advacedSearch,
    newPatient,
    newAccount,
    newMedication,
    newSupplement,
    newLifestyle,
    newDiagnosis,
    newSymptoms
}