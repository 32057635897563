import React, { useEffect, useState } from 'react';
import MaleImage from '../../../assets/img/male-avatar-icon.jpeg';
import '../../../assets/styles/pipelinerequest/pipelinetabs/patientInfoCard.scss';
import { FiPlus } from 'react-icons/fi';
import PipelineRequestService from '../../../services/PipelineRequest';
import { useHistory, useLocation } from 'react-router-dom';
import RecordingAnalysisService from '../../../services/RecordingAnalysis';
import PatientAccountService from '../../../services/Patient';
import { Container } from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function PatientInfoCard(props) {
    const history = useHistory();
    const location = useLocation();
    const requestData = props.data.reqData;
    // const [requestData, setRequestData] = useState([]);
    const [isMounted1, setIsMounted1] = useState(true);
    const [dbSelect, setDbSelect] = useState([]);
    const [selectedTag, setSelectedTag] = useState('');
    const [saveDbTags, setSaveDbTags] = useState([]);
    //diagnosis and symptoms
    const [dsMounted, setDsmounted] = useState(true);
    const [tags, setTags] = useState([]);
    const [dbTags, setDbTags] = useState([]);
    const [isMountedAllTag, setIsMountedAllTag] = useState(true);
    // SAVE DIAGNOSIS
    const [dId, setDId] = useState([]);
    const [checkBoxes, setCheckBoxes] = useState([]);
    const [ndID, setNdID] = useState([]);
    const [selectedDiag, setSelectedDiag] = useState('');
    const [undiag, setUndiag] = useState([]);
    //SAVE SYMPTOMS
    const [sId, setSId] = useState([]);
    const [unSymptoms, setUnSymptoms] = useState([]);
    const [checkBoxes1, setCheckBoxes1] = useState([]);
    const [sndID, setSndID] = useState([]);
    const [selectedSymp, setSelectedSymp] = useState('');
    const [unsymp, setUnsymp] = useState([]);
    const [isMounted, setIsMounted] = useState(true);
    //API
    const ds = new PipelineRequestService();
    const ra = new RecordingAnalysisService();
    const pnt = new PatientAccountService();
    const pId = localStorage.getItem('patientid');
    //toaster
    const notifyDiag = () => toast.success("Diagnosis updated successfully!");
    const notifySymp = () => toast.success("Symptoms updated successfully!");
    const notifyTag = () => toast.success("Tag updated successfully!");
    const notifyDelTag = () => toast.success('Tag deleted successfully!');
    const notifySympDel = () => toast.success("Symptoms removed successfully!");
    const notifyDiagDel = () => toast.success("Diagnosis removed successfully!");


    function getDiagnosis() {
        let reqData = {
            service_request_id: location.state.reqId,
        };
        ra.GetMultiCheckBoxes(reqData).then((response) => {
            if (dsMounted) {
                if (response.data.diagnosis !== []) {
                    let diag = [];
                    let undiag = [];
                    // setCheckBoxes(response.data.diagnosis);
                    for (let i = 0; i < response.data.diagnosis.length; i++) {
                        if (response.data.diagnosis[i].ischoices === true) {
                            dId.push(response.data.diagnosis[i].id);
                            diag.push(response.data.diagnosis[i]);
                        }
                        else {
                            ndID.push(response.data.diagnosis[i].id);
                            undiag.push(response.data.diagnosis[i]);
                        }
                    }
                    setDId(dId);
                    setNdID(ndID);
                    setCheckBoxes(diag);
                    setUndiag(undiag);
                }
            }
        });

    }
    useEffect(() => {
        getDiagnosis();
        return () => { setDsmounted(false); }
    }, []);
    function getSymptoms() {
        let reqData = {
            "service_request_id": location.state.reqId
        }
        ra.GetMultiCheckBoxes(reqData).then((response) => {
            if (isMounted) {
                let unsymp = [];
                let symp = [];
                if (response.data.symptoms !== []) {

                    for (let i = 0; i < response.data.symptoms.length; i++) {
                        if (response.data.symptoms[i].ischoices === true) {
                            sId.push(response.data.symptoms[i].id);
                            symp.push(response.data.symptoms[i]);
                        }
                        else {
                            sndID.push(response.data.symptoms[i].id);
                            unsymp.push(response.data.symptoms[i]);
                        }
                    }
                }
                setCheckBoxes1(symp);
                setUnSymptoms(unsymp);
            }
        })
    }
    useEffect(() => {
        getSymptoms();
    }, []);

    const options = dbSelect.map(function (dbSelect) {
        return {
            value: dbSelect.TagName,
            label: dbSelect.TagName,
            id: dbSelect.id,
        };
    });
    const handleTagChange = (selectedTag) => {
        const values = ([...selectedTag].map(o => o.value));
        setSelectedTag(selectedTag);
        setSaveDbTags(values);
    };

    function fetchAllTags() {
        pnt.GetPatientTag(pId).then((response) => {
            if (isMountedAllTag) {
                if (response.data.status === "success") {
                    setDbTags(response.data.data);
                    fetchAllPatientTags(response.data.data);
                }
                else return;
            }
        })
    }

    useEffect(() => {
        fetchAllTags();
    }, []);

    function fetchAllPatientTags(arr) {
        pnt.GetAllPatientTag().then((response) => {
            //   if (isMounted) {
            if (response.data) {
                const val = response.data.data;
                const output = [...new Map(val.map(o => [o.TagName, o])).values()];
                setDbSelect(output.filter(ar => !arr.find(rm => (rm.TagName === ar.TagName))));
            }
            else return;
            //   }
        })
    }

    //SAVE DIAGNOSIS
    const diagnosisOption = undiag.map((data, i) => {
        return {
            label: data.diagnosis_name,
            value: data.diagnosis_name,
            id: data.id
        }
    });

    const handleDiagnosisChange = (selectedDiag) => {
        const values = ([...selectedDiag].map(o => o.id));
        setSelectedDiag(selectedDiag);
        setDId(values);
        setNdID(current =>
            current.filter(diag => {
                if (diag.id !== values)
                    return diag.id;
            }));
    };
    const removeDiagnosis = (id) => {
        let reqData = {
            "service_request_id": location.state.reqId,
            "diagnosis_tps": "",
            "undiagnosis_tps": id.toString(),
            "symptoms_tps": "",
            "unsymptoms_tps": "",
        }
        ra.SaveMultiCheckBoxes(reqData).then((response) => {
            if (response.data.status === "success") {
                getDiagnosis();
                notifyDiagDel();
            }
        })
    }
    function savediagnosis() {
        let reqData = {
            "service_request_id": location.state.reqId,
            "diagnosis_tps": dId.toString(),
            "undiagnosis_tps": "",
            "symptoms_tps": "",
            "unsymptoms_tps": "",
        }
        // console.log(reqData);
        ra.SaveMultiCheckBoxes(reqData).then((response) => {
            if (response.data.status === "success") {
                handleCancel();
                getDiagnosis();
                notifyDiag();
            }
        })
    }

    const handleCancel = () => {
        setSelectedDiag('');
    }

    //symptoms
    const symptomsOption = unSymptoms.map((data, i) => {
        return {
            label: data.symptoms_name,
            value: data.symptoms_name,
            id: data.id
        }
    });

    const handleSymptomsChange = (selectedSymp) => {
        const values = ([...selectedSymp].map(o => o.id));
        setSId(values);
        console.log('test', selectedSymp);
        setSelectedSymp(selectedSymp);
    };
    function saveSymptoms() {
        let reqData = {
            "service_request_id": location.state.reqId,
            "symptoms_tps": sId.toString(),
            "unsymptoms_tps": "",
            "diagnosis_tps": "",
            "undiagnosis_tps": "",
        }
        ra.SaveMultiCheckBoxes(reqData).then((response) => {
            if (response.data.status === "success") {
                handleSympCancel();
                getSymptoms();
                notifySymp();

            }
        })
    }

    const handleSympCancel = () => {
        setSelectedSymp('');
    }
    const removeSymptoms = (id) => {
        let reqData = {
            "service_request_id": location.state.reqId,
            "symptoms_tps": "",
            "unsymptoms_tps": id.toString(),
            "diagnosis_tps": "",
            "undiagnosis_tps": "",
        }
        ra.SaveMultiCheckBoxes(reqData).then((response) => {
            if (response.data.status === "success") {
                getSymptoms();
                notifySympDel();
            }
        })
    }

    //ADD TAG
    const addTag = (e) => {
        if (e.key === "Enter") {
            if (tags.find(tags => tags.toLowerCase() === e.target.value.toLowerCase())) {
                return alert("No duplicate value allowed")
            }
            else if (e.target.value.length > 0) {
                setTags([...tags, e.target.value]);
                e.target.value = "";
            }
        }
    };

    const removeTag = (removedTag) => {
        const newTags = tags.filter((tag) => tag !== removedTag);
        setTags(newTags);
    };
    function removeDbTag(id) {
        pnt.RemovePatientTag(id).then((response) => {
            if (response.data.status === "success") {
                fetchAllTags();
                notifyDelTag();
            }
        })
    }
    function clearTags() {
        setTags([]);
    }
    const onSubmit = () => {
        let reqData = {
            "tags": saveDbTags.toString(),
            "patientid": location.state.patientid
        }
        pnt.CreatePatientTag(reqData)
            .then((response) => {
                if (response.data.status === "success") {
                    hideModal('closeTag');
                    fetchAllTags();
                    notifyTag();
                    setSelectedTag('');
                }
            })
    }
    function hideModal(target) {
        document.getElementById(target).click();
        setTags([]);
    };

    return (
        <div className='bg-white rounded mt-3 p-2 pnt-card mb-0' >
            <div className='img-container '>
                <img src={MaleImage} alt="user-image" className='rounded-circle w-25 pt-2' />
                <div className='name-content text-center mt-2 pb-2'>
                    <h6 className='mb-0 text-capitalize'>{requestData.patient_name}</h6>
                    <p className='mb-0'>{requestData.gender}-{requestData.dob}</p>
                </div>
            </div>
            <div className='row g-0 mt-3'>
                <div className='col-auto text-start me-5'>
                    <label>Request ID</label>
                    <p >{requestData.RequestNumber}</p>
                </div>
                <div className='col-auto text-start'>
                    <label>Account Name </label>
                    <p className='text-capitalize'>{requestData.account_name}</p>
                </div>
                <hr />
            </div>
            <div className='ass-tag'>
                <div className='row g-0'>
                    <h6 className='col-auto text-start mb-0'>Diagnosis</h6>
                    <FiPlus className='col-auto ms-auto add-icon' data-bs-toggle="modal" data-bs-target="#addPntDiagnosis" />
                    <Container className='p-0 '>
                        <div className="checkbox-container mt-2 ps-1">
                            <div className='row '>
                                {checkBoxes.map((tag, i) => {
                                    return (
                                        <div key={i} className="tag col-auto mx-2 my-1 ps-1 py-0" style={{ whiteSpace: 'no-wrap' }}>
                                            {tag.diagnosis_name}<div className="ms-auto col-1 p-1 remove-tag" key={tag.id}
                                                onClick={() => removeDiagnosis(tag.id)}
                                            >
                                                <span>x</span>
                                            </div>
                                        </div>
                                    );
                                })}

                            </div>
                        </div>
                    </Container>
                </div>

            </div>
            <hr />
            <div className='ass-tag'>
                <div className='row g-0'>
                    <h6 className='col-auto text-start mb-0'>Symptoms</h6>
                    <FiPlus className='col-auto ms-auto add-icon' data-bs-toggle="modal" data-bs-target="#addPntSymptoms" />
                    <Container className='p-0'>
                        <div className="checkbox-container mt-2 ps-1">
                            <div className='row'>
                                {checkBoxes1.map((tag, i) => {
                                    return (
                                        <div key={i} className="tag col-auto mx-2 my-1 ps-1 py-0" style={{
                                            whiteSpace: 'no-wrap',
                                        }}>
                                            {tag.symptoms_name}<div className="ms-auto col-1 p-1" key={tag.id}
                                                onClick={() => removeSymptoms(tag.id)}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <span>x</span>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </Container>
                </div>

            </div>
            <hr />
            <div className='ass-tag pb-2'>
                <div className='row g-0'>
                    <h6 className='col-auto text-start mb-2 '>Associated Tags</h6>
                    <FiPlus className='col-auto ms-auto add-icon' data-bs-toggle="modal" data-bs-target="#addPntTag" />
                    <Container className='px-0 pt-0 pb-2'>
                        <div className="checkbox-container ps-1">
                            <div className='row'>
                                {dbTags.map((tag, i) => {
                                    return (
                                        <div key={i} className="tag col-auto mx-2 my-1 ps-1 py-0" style={{
                                            whiteSpace: 'no-wrap',
                                        }}>
                                            {tag.TagName}<div className="ms-auto col-1 p-1" key={tag.id}
                                                onClick={() => removeDbTag(tag.id)}
                                            >
                                                <span>x</span>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </Container>
                </div>
            </div>

            {/* DIAGNOSIS  */}
            <div className="modal" id="addPntDiagnosis" data-bs-backdrop="false"
                tabIndex="-1" >
                <div className="modal-dialog shadow-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Add Diagnosis</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => handleCancel()}></button>
                        </div>
                        <div className="modal-body p-4 text-start">
                            <Select
                                isMulti
                                defaultValue={selectedDiag}
                                onChange={handleDiagnosisChange}
                                options={diagnosisOption}
                            />
                        </div>
                        <div className="modal-footer ">
                            <button type="button" id="closeDelDiag" className="btn btn-save text-white px-3"
                                onClick={() => savediagnosis()} data-bs-dismiss="modal">Save</button>
                            <button type="button" className="btn btn-cancel text-white px-3"
                                data-bs-dismiss="modal" onClick={() => handleCancel()}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* ADD SYMPTOMS */}
            <div className="modal" id="addPntSymptoms" data-bs-backdrop="false"
                tabIndex="-1" >
                <div className="modal-dialog shadow-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Add Symptoms</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                onClick={() => handleSympCancel()}></button>
                        </div>
                        <div className="modal-body p-4 text-start">
                            <Select
                                isMulti
                                value={selectedSymp}
                                onChange={handleSymptomsChange}
                                options={symptomsOption}
                            />
                        </div>
                        <div className="modal-footer ">
                            <button type="button" id="closeDelDiag" className="btn btn-save text-white px-3"
                                onClick={() => saveSymptoms()} data-bs-dismiss="modal">Save</button>
                            <button type="button" className="btn btn-cancel text-white px-3"
                                data-bs-dismiss="modal" onClick={() => handleSympCancel()}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* ADD TAG */}
            <div className="modal" id="addPntTag" data-bs-backdrop="false">
                <div className="modal-dialog shadow-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Add Tag</h4>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                onClick={() => clearTags()}
                            ></button>
                        </div>
                        <div className="modal-body">
                            {/* <div className="tag-box"> */}
                            <div className="tag-container text-start">
                                <CreatableSelect
                                    isMulti
                                    className="col-md-12"
                                    value={selectedTag}
                                    onChange={handleTagChange}
                                    options={options} />
                            </div>
                            {/* </div> */}
                        </div>
                        <div className="modal-footer ">
                            <button
                                type="button"
                                className="btn btn-cancel text-white px-2"
                                data-bs-dismiss="modal"
                                id="closeTag"
                                onClick={() => clearTags()}>
                                Cancel
                            </button>
                            <button
                                type="button"
                                className="btn btn-save text-white px-3"
                                onClick={() => onSubmit()}>
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}