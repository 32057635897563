import { ax } from '../services/base';
import config from '../assets/config';

export default class AssociatedDocumentService {


GetDocumentTableData(payload){
    return ax.post(`${config.apiURL}docrepo/doclist`,payload);
}
GetDocChoiceField(){
    return ax.get(`${config.apiURL}service_request/doctypes`);
}
SaveMultipleFile(payload){
    return ax.post(`${config.apiURL}docrepo/docupload`,payload);
}
UpdateDocumentData(payload){
    return ax.post(`${config.apiURL}docrepo/updatedoc`,payload);
}
EquipmentChoiceField(){
    return ax.get(`${config.apiURL}service_request/eqtypes`);
}
GetDocument(docId){
    return ax.get(`${config.apiURL}docrepo/getdoc/${docId}`);
}
// GetDocument(docId){
//     return ax.get(`${config.apiURL}docrepo/doclist/${docId}`);
// }
DeleteDocument(payload){
    return ax.post(`${config.apiURL}docrepo/docdelete`,payload);
}
}