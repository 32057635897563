import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import '../../../../assets/styles/pipelinerequest/tabcontent/diagnosistab.scss';
import RecordingAnalysisService from '../../../../services/RecordingAnalysis';
import { BsPlusLg } from 'react-icons/bs';
import Select from 'react-select';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function DiagnosisTab(props) {
    const location = useLocation();
    const [dId, setDId] = useState([]);
    const [checkBoxes, setCheckBoxes] = useState([]);
    const [isMounted, setIsMounted] = useState(true);
    const [ndID, setNdID] = useState([]);
    const [selectedDiagnosis, setSelectedDiagnosis] = useState('');
    const [undiag, setUndiag] = useState([]);
    const notifyDiag = () => toast.success("Diagnosis updated successfully!");
    const notifyDiagDel = () => toast.success("Diagnosis deleted successfully!");
    const ra = new RecordingAnalysisService();
    const [msg, setMsg] = useState('');

    const diagnosisOption = undiag.map((data, i) => {
        return {
            label: data.diagnosis_name,
            value: data.diagnosis_name,
            id: data.id
        }
    })
    const handleDiagnosisChange = (selectedDiagnosis) => {
        const values = ([...selectedDiagnosis].map(o => o.id));
        setSelectedDiagnosis(selectedDiagnosis);
        setDId(values);
        setNdID(current =>
            current.filter(diag => {
                if (diag.id !== values)
                    return diag.id;
            }));
    };

    function getDiagnosis() {
        let reqData = {
            "service_request_id": location.state.reqId
        }
        ra.GetMultiCheckBoxes(reqData).then((response) => {
            if (isMounted) {
                if (response.data.diagnosis !== []) {
                    let diag = [];
                    let undiag = [];
                    for (let i = 0; i < response.data.diagnosis.length; i++) {
                        if (response.data.diagnosis[i].ischoices === true) {
                            dId.push(response.data.diagnosis[i].id);
                            diag.push(response.data.diagnosis[i]);
                        }
                        else {
                            ndID.push(response.data.diagnosis[i].id);
                            undiag.push(response.data.diagnosis[i]);
                        }
                    }
                    setDId(dId);
                    setNdID(ndID);
                    setCheckBoxes(diag);
                    setUndiag(undiag);
                }
            }
        })
    }
    const removeDiagnosis = (removedTag) => {
        let reqData = {
            "service_request_id": location.state.reqId,
            "diagnosis_tps": '',
            "undiagnosis_tps": removedTag.toString(),
            "symptoms_tps": "",
            "unsymptoms_tps": "",
        }
        ra.SaveMultiCheckBoxes(reqData).then((response) => {
            if (response.data.status === "success") {
                getDiagnosis();
                props.data.getDiagnosis();
                notifyDiagDel();
                setMsg("Diagnosis deleted successfully!");
                setTimeout(() => { setMsg(""); }, 5000);
            }
        })
    };


    function savediagnosis() {
        let reqData = {
            "service_request_id": location.state.reqId,
            "diagnosis_tps": dId.toString(),
            "undiagnosis_tps": ndID.toString(),
            "symptoms_tps": "",
            "unsymptoms_tps": "",
        }
        ra.SaveMultiCheckBoxes(reqData).then((response) => {
            if (response.data.status === "success") {
                handleCancel();
                props.data.getDiagnosis();
                getDiagnosis();
                notifyDiag();
                setMsg("Diagnosis updated successfully!");
                setTimeout(() => { setMsg(""); }, 5000);
            }
        })
    }
    useEffect(() => {
        getDiagnosis();
        return () => { setIsMounted(false) }
    }, []);
    const handleCancel = () => {
        setSelectedDiagnosis('');
    }

    return (
        <div className="diagnosis-tab mt-3">
            <div className='row g-0 mb-2'>
                <h6 className="text-start col">Diagnosis</h6>
                <div className='ms-auto text-end col-auto me-1 add-icon' data-bs-toggle="modal" data-bs-target="#addDiagnosis">
                    <BsPlusLg />
                </div>
            </div>
            <Container className='p-0 '>
                <div className="checkbox-container mt-2 ps-1">
                    <div className='row p-2' style={{ height: 'auto' }}>
                        {checkBoxes.map((tag, i) => {
                            return (
                                <div key={i} className="tag col-auto mx-2 my-1 ps-1 py-0" style={{ whiteSpace: 'no-wrap' }}>
                                    {tag.diagnosis_name}<div className="ms-auto col-1 p-1 remove-tag" key={tag.id}
                                        onClick={() => removeDiagnosis(tag.id)}
                                    >
                                        <span>x</span>
                                    </div>
                                </div>
                            );
                        })}

                    </div>
                </div>
            </Container>
            {/* ADD DIAGNOSIS */}
            <div className="modal" id="addDiagnosis"
                tabIndex="-1" >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Add Diagnosis</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => handleCancel()}></button>
                        </div>
                        <div className="modal-body p-4 text-start">
                            <Select
                                isMulti
                                value={selectedDiagnosis}
                                onChange={handleDiagnosisChange}
                                options={diagnosisOption}
                            />
                        </div>
                        <div className="modal-footer ">
                            <button type="button" id="closeDelDiag" className="btn btn-save text-white px-3"
                                onClick={() => savediagnosis()} data-bs-dismiss="modal">Save</button>
                            <button type="button" className="btn btn-cancel text-white px-3"
                                data-bs-dismiss="modal" onClick={() => handleCancel()}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
