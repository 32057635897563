import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactQuill from 'react-quill';
import SummaryToolbar, { modules, formats } from "../tabcontent/subcontent/toolbar/SummaryToolbar";
import "react-quill/dist/quill.snow.css";
import { toast } from 'react-toastify';
import RecordingAnalysis from '../tabcontent/RecordingAnalysis';
import RecordingAnalysisService from '../../../services/RecordingAnalysis';

export default function SummaryOfFindings(props) {
    const [text, setText] = useState({
        value: ``
    });
    const location = useLocation();
    const [descid, setDescID] = useState(0);
    const notifyAp = () => toast.success("Summary of Findings updated successfully!");
    const notifyApErr = () => toast.error("Summary of Findings couldn't be updated!");

    const rec = new RecordingAnalysisService();

    //API
    // const ra = new RecordingAnalysisService();
    // let pipreq = new PipelineRequestService();


    const handleChange = value => {
        setText({ value });
    };

    function getSummaryofFindings() {

        rec.getSummaryofFindings(location.state.reqId).then((response) => {
            if (response.data) {
                if(response.data.data !==null || response.data.data!==""){
                setDescID(response.data.data.id);
                setText({ value: response.data.data.description });
                }
            }

        })
    }
    useEffect(() => {
        getSummaryofFindings();
    }, []);

    //CALLBACK FUNCTION
    const msgDisplay = (val) => {
        props.data.summaryCallback(val);
    }

    const onSubmit = () => {
        let reqData = {
            "sr_sum_of_finding": descid,
            "service_request": location.state.reqId,
            "description": text.value,
        }
        rec.saveSummaryofFindings(reqData)
            .then((response) => {
                if (response.data) {
                    if (response.data.status === 'success') {
                        // fetchAnalysisProcedure();
                        props.data.exportPreview();
                        notifyAp();
                        msgDisplay(false);
                    }
                }
            })
            .catch(function (error) {
                notifyApErr();
            })
    }



    return (
        <div>
            <SummaryToolbar />
            <ReactQuill
                theme="snow"
                modules={modules}
                formats={formats}
                value={text.value}
                onChange={handleChange}
            >
            </ReactQuill>
            <div className='text-end my-2'>
                <button type="button" className='btn btn-save ms-auto text-white' onClick={onSubmit} >Save</button>
            </div>
        </div>
    );
}