import { ax } from '../services/base';
import config from '../assets/config';

export default class MasterDataService {

    // Supplements api
    createSupplement(payload)
    {
    return ax.post(`${config.apiURL}patient/add-supplement/`,payload)
    }
    getSupplement(){
        return ax.post(`${config.apiURL}patient/supplement_mdata/`)
    }
    removeSupplement(payload){
        return ax.post(`${config.apiURL}patient/remove-supplement`,payload)
    }
    getSupplementById(reqID)
    {
        return ax.get(`${config.apiURL}patient/supplementmd/${reqID}`)
    }

    // LifeStyle api

    createLifestyle(payload)
    {
    return ax.post(`${config.apiURL}patient/add-lifestyle/`,payload)
    }
    getLifestyle(){
        return ax.post(`${config.apiURL}patient/lifestyle_mdata/`)
    }
    getLifeStyleWithCategory(payload){
        return ax.post(`${config.apiURL}patient/lifestyle_mdata/`,payload);
    }
    removeLifestyle(payload){
        return ax.post(`${config.apiURL}patient/remove-lifestyle`,payload);
    }
    getLifestyleById(reqID)
    {
        return ax.get(`${config.apiURL}patient/lifestylemd/${reqID}`)
    }


    // Diagnosis api

    createDiagnosis(payload)
    {
    return ax.post(`${config.apiURL}patient/add-diagnosis/`,payload)
    }
    getDiagnosis(){
        return ax.post(`${config.apiURL}patient/diagnosis_mdata/`)
    }
    removeDiagnosis(payload){
        return ax.post(`${config.apiURL}patient/remove-diagnosis`,payload);
    }
    getDiagnosisById(reqID)
    {
        return ax.get(`${config.apiURL}patient/diagnosismd/${reqID}`)
    }

    // Symptoms api

    createSymptoms(payload)
    {
    return ax.post(`${config.apiURL}patient/add-symptoms/`,payload)
    }
    getSymptoms(){
        return ax.post(`${config.apiURL}patient/symptoms_mdata/`)
    }
    removeSymptoms(payload){
        return ax.post(`${config.apiURL}patient/remove-symptoms`,payload);
    }
    getSymptomsById(reqID)
    {
        return ax.get(`${config.apiURL}patient/symptomsmd/${reqID}`)
    }

        // Medication api

        createMedication(payload)
        {
        return ax.post(`${config.apiURL}patient/add-medic/`,payload)
        }
        getMedication(){
            return ax.get(`${config.apiURL}patient/medic_mdata/`)
        }
        removeMedication(payload){
            return ax.post(`${config.apiURL}patient/remove-medic`,payload)
        }

    //MARKER API
    getMarker(){
        return ax.post(`${config.apiURL}service_request/marker_mdata`);
    }
    saveMarker(payload){
        return ax.post(`${config.apiURL}service_request/save-marker`,payload); 
    }
    getMarkerDetail(markerID){
        return ax.get(`${config.apiURL}service_request/marker_mdata/${markerID}`);
    }
    DeleteMarker(payload){
        return ax.post(`${config.apiURL}service_request/remove-marker`,payload);
    }
    //Interpretation choicefield
    addInterpretationGrid(payload){
        return ax.post(`${config.apiURL}md_management/add-interpretationchoicefield`,payload);
    }
    getInterpretationGrid(){
        return ax.post(`${config.apiURL}md_management/interpretationchoicefield_mdata`);
    }
    getInterpretationField(intChoiceID){
        return ax.get(`${config.apiURL}md_management/interpretationchoicefield_mdata/${intChoiceID}`)
    }
}
