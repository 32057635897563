import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import { options } from "./PatientDetailsData";
import paginationFactory from "react-bootstrap-table2-paginator";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../assets/styles/patientList/patientlist.scss";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "../../assets/styles/patientList/patientdetails.scss";
import UpdatePatientModal from "./UpdatePatientModal";
import DeletePatientModal from "./DeletePatientMedicine";
import CreatePatientMedication from "./AddPatientMedicine";
import PatientAccountService from "../../services/Patient";
import { useForm } from "react-hook-form";
import Tag from './Tag.js';
import maleImage from "../../assets/img/male-avatar-icon.jpeg";
import femaleImage from "../../assets/img/female-avatar-icon.jpeg";
import { format } from 'date-fns';
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";
import { ScaleLoader } from "react-spinners";
import { toast } from 'react-toastify';
import ReactTooltip from "react-tooltip";
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import Select from 'react-select';
import LoadingIcon from '../../assets/img/sample-icons/sandbox.gif';

const NoDataIndication = () => (
  <div className='spinner d-flex justify-content-center text-center h-100'>
  <img src={LoadingIcon} alt="loading-icon" width="200" height="200"/>
  {/* <ScaleLoader size={60} color="#79a3bb" style={{ marginTop: '50px' }} /> */}
</div>
);


function PatientDetails() {
  const history = useHistory();
  const location = useLocation();
  const [tdataLoad, setTdataLoad] = useState(false);
  const { SearchBar } = Search;
  const [selected, setSelected] = useState([""]);
  const [medicineData, setMedicineData] = useState([]);
  const [cardData, setCardData] = useState([]);
  const [dob, setDob] = useState();
  const [patientData, setPatientData] = useState([]);
  const [tableData, setTableData] = useState(false);
  const [msg2, setMsg2] = useState('');
  const [flag2, setFlag2] = useState(false);
  const [msg3, setMsg3] = useState('');
  const [flag3, setFlag3] = useState(false);
  const [isFlag3, setIsFlag3] = useState(false);
  const [tagMsg, setTagMsg] = useState('');
  const [pntID, setPntID] = useState();
  const [isMounted, setIsMounted] = useState(true);
  const [isMounted1, setIsMounted1] = useState(true);
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const pid = location.state.patientId;
  //drug
  const [drugName, setDrugName] = useState('');
  const [drugSelect, setDrugSelect] = useState([]);
  const [value, setValue] = useState([]);
  const [dosage, setDosage] = useState([]);
  const [dosageVal,setDosageVal] = useState();
  const [dose, setDose] = useState('');
  const [doseCode, setDoseCode] = useState([]);
  const [dcode, setDcode] = useState();
  const[ex,setEx] = useState('');
  //API Call
  const pnt = new PatientAccountService();
  const baseurl = "https://clinicaltables.nlm.nih.gov/api/rxterms/v3/search?terms";

  const patientMedicnotify = () => toast.success("Medicine updated successfully!");
  const patientMedicnotifyErr = () => toast.error("Medicine couldn't be updated!");
  const patientMdelnotify = () => toast.success("Medicine deleted successfully!");
  const patientMdelnotifyErr = () => toast.error("Medicine couldn't be deleted!");

  //CALLBACK FUNCTION
  const [isFlagSet, setIsFlagSet] = useState(false);
  const [msg, setMsg] = useState('');
  function messageCallback(item) {
    setIsFlagSet(item);

    // setMsg("Patient updated successfully!");
    // setTimeout(() => { setMsg(""); setIsFlagSet(false) }, 5000);
  }

  //CALLBACK FUNCTION FOR PATIENT MEDICATION
  const [isFlagSet1, setIsFlagSet1] = useState(false);
  const [msg1, setMsg1] = useState('');
  function messageCallback1(item) {
    setIsFlagSet1(item);
    setMsg1("Medicine created successfully!");
    setTimeout(() => { setMsg1(""); setIsFlagSet1(false) }, 5000);
  }

  //CALLBACK FUNCTION FOR TAG MEDICATION
  const [isFlagSet2, setIsFlagSet2] = useState(false);
  const [msgTag, setMsgTag] = useState('');
  function messageCallbackTag(item) {
    setIsFlagSet2(item);
    setMsgTag("Tag created successfully!");
    setTimeout(() => { setMsgTag(""); setIsFlagSet2(false) }, 5000);
  }

  function messageCallbackTag1(item) {
    setIsFlag3(item);
    setTagMsg("Tag deleted successfully!");
    setTimeout(() => { setTagMsg(""); setIsFlag3(false) }, 5000);
  }
  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      setPntID(row.id);
      // setEx(row.dosage);
      fetchMedicationDetail(row.id);

    },
  };

  function actionFormatter(cell, row, rowIndex, formatExtraData) {
    const pntID = row.id;
    return (
      <div className="col align-items-center" >
        <i className="bi bi-pencil edit-icon" data-bs-toggle="modal" data-bs-target="#updatePatientMedicine"></i>
        <i className="bi bi-trash2 del-icon"
          data-bs-toggle="modal" data-bs-target="#deleteMedication"></i>
      </div>
    );
  }
  function statusFormatter(cell) {
    if (cell === "Present") {
      return (
        <p style={{ color: "#87b880", marginBottom: '0px',fontWeight:'bolder' }}>
          {cell}
        </p>
      );
    }
    return (
      <p style={{ color: "#d25b47", marginBottom: '0px',fontWeight:'bolder' }}>
        {cell}
      </p>
    );
  }

  const sortOrder = (order, column) => {
    if (!order) return (<span>&nbsp;&nbsp;<FaSort color="#858585" /></span>);
    else if (order === 'asc') return (<span>&nbsp;&nbsp;<font color="red"><FaSortUp color="#000" /></font></span>);
    else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="red"><FaSortDown color="#000" /></font></span>);
    return null;
  }

  const columns = [
    { dataField: 'medicine_name', text: 'Medicine Name', sort: true, sortCaret: sortOrder },
    { dataField: 'dosage', text: 'Dosage', sort: true, sortCaret: sortOrder },
    { dataField: 'medication_started_on', text: 'Taken From', sort: true, sortCaret: sortOrder },
    { dataField: 'medication_ended_on', text: 'Stopped', sort: true, sortCaret: sortOrder },
    { dataField: 'status', text: 'Status', formatter: statusFormatter, sort: true, sortCaret: sortOrder },
    { dataField: 'action', text: 'Action', formatter: actionFormatter },
  ];

  async function deleteMedication() {
    const patientId = pntID;
    pnt.DeletePatientMedication(patientId)
      .then((response) => {
        if (response.data.status === "success") {
          hideModal('closeDel');
          fetchPatientMedicationData();
          setFlag3(true);
          patientMdelnotify();
        }
      })
      .catch(function (error) {
        patientMdelnotifyErr();
      });
  }

  function fetchMedicationDetail(medicineId) {
    pnt.GetMedication(medicineId).then((response) => {
      if (response.data.status === "success" && response.data.data !== []) {
        setTableData(true);
        setMedicineData([]);
        setMedicineData(response.data.data[0]);
        medicName(response.data.data[0].medicine_name);
        getDosage(response.data.data[0].medicine_name);
        setDrugName({label:response.data.data[0].medicine_name,value:response.data.data[0].medicine_name});
        setDose({label:response.data.data[0].dosage,value:response.data.data[0].dosage});
        setDcode({label:response.data.data[0].drug_code,value:response.data.data[0].drug_code});
        if(response.data.data[0].medicine_name.length>0 && response.data.data[0].dosage.length>0){
          getRxCode(response.data.data[0].medicine_name,response.data.data[0].dosage);
        }else setDoseCode([]);
       
        // console.log('medic',response.data.data[0]);
        
      }
      else {
        setTableData(false);
      }
    });
  }
// console.log('d',dcode);
  function getPatientsDetail() {
    const pId = pid;
    pnt
      .GetPatientDetail(pId)
      .then((response) => {
        if (isMounted1) {
          if (response.data.status === "success") {
            setCardData(response.data.data[0]);
            setDob(format(new Date(response.data.data[0].dob), 'MM/dd/yyyy'));
          }
        }
      })
  }


  async function fetchPatientMedicationData() {
    let patientId = location.state.patientId;
    pnt.PatientMedicationDetails(patientId)
      .then((response) => {
        if (isMounted) {
          if (response.data.status === "success") {
            setTdataLoad(true);
            setPatientData(response.data.data);
          }
          else {
            setTdataLoad(false);
          }
        }

      })
      .catch(function (error) {
        if (error.response.status === 403) {
          history.push('/login');
        }
      })
  }


  useEffect(() => {
    reset(medicineData);
  }, [medicineData]);
  useEffect(() => {
    fetchPatientMedicationData();
    return () => { setIsMounted(false); }
  }, []);
  useEffect(() => {
    getPatientsDetail();
    return () => { setIsMounted1(false); }
  }, []);

  async function medicName(val) {

    axios.get(`${baseurl}=${val}`).then((res) => {
      if (res) {

        setDrugSelect(res.data[1]);     

      }
    })
  }

  const options = drugSelect.map(function (dbSelect, i) {
    return {
      value: dbSelect,
      label: dbSelect,
      id: i.toString()
    };
  });
  const dosageOptions = dosage.map(function (dosage) {
    return {
      value: dosage,
      label: dosage,
   
    }
  });
  const doseCodeOptions = doseCode.map(function (dosage, i) {
    return {
      value: dosage,
      label: dosage,
      id: i
    }
  });

  function getDosage(val) {
    axios.get(`${baseurl}=${val}&ef=STRENGTHS_AND_FORMS,RXCUIS`).then((res) => {
      if (res) {
        if(res.data[2].STRENGTHS_AND_FORMS.length>0){
          setDosage(res.data[2].STRENGTHS_AND_FORMS[0]);

        }
        else return null;
      
      }
    })
  }
  
  function getRxCode(drugName,dosename) {
    axios.get(`${baseurl}=${drugName}&ef=STRENGTHS_AND_FORMS=${dosename},RXCUIS`).then((res) => {
      if (res) {
        if(res.data[2].RXCUIS.length>0){
        setDoseCode(res.data[2].RXCUIS[0]);
        }
        else return null;
      }
    })
  }
  function handledChange(drugName) {
    setValue(drugName);
    setDrugName(drugName);
    getDosage(drugName.value);
    // medicName(e.target.value);
  }

  const handleDosageChange = (dose) => {
    setDose(dose);
    getRxCode(drugName.value,dose.value);
  };
  const handleDoseCodeChange = (dcode) => {
    setDcode(dcode);
  };
  function handleSelectChange(val) {
    setValue(val);
    setDrugName(val);
    getDosage(val);
  }
  const onSubmit = (data) => {
    let patientId = location.state.patientId;
    let mID = pntID;
    let reqData = {
      "medicationid": mID,
      "medicine_name": drugName.value,
      "dosage": dose.value,
      "drug_code": dcode.value,
      "patientid": patientId,
      "medication_started_on": data.takenfrom,
      "medication_ended_on": data.stopped,
      "notes": data.notes,
      "status": data.status
    }
   
    pnt.AddMedication(reqData)
      .then((response) => {
        if (response.data.status === "success") {
          setFlag2(true);
          fetchPatientMedicationData();
          hideModal('closeMedicUpdate');
          patientMedicnotify();

        }
      })
      .catch(function (error) {
        patientMedicnotifyErr();
        
      })


  }
  function inputChange(value){
    medicName(value);
    setDose(null);
    setDcode(null);

  }
  function hideModal(target) {
    document.getElementById(target).click();
    reset();
  };

  return (
    <div className="col patient-details me-4">
      <div className="row g-0 ps-3 pt-3">
        <div className="col-md-9 table-request my-2 pe-0 ">
          <UpdatePatientModal data={{ isFlagSet: isFlagSet, messageCallback: messageCallback, getPatientsDetail: getPatientsDetail }} />
          <DeletePatientModal />
          <CreatePatientMedication data={{ isFlagSet1: isFlagSet1, messageCallback1: messageCallback1, fetchPatientMedicationData: fetchPatientMedicationData }} />
          <div className="table-data me-3">
            <ToolkitProvider search>
              {(props) => (
                <div>
                  <div className="row mb-2">
                    <div className="col-auto text-start">
                      <h5>Patient Medication Details</h5>
                    </div>
                    <div className='col text-end'>
                      <button type='button' className='btn-create btn px-3 py-1 ms-3 me-2'
                        data-bs-toggle="modal" data-bs-target="#createPatientMedicine" data-tip data-for="medicineTip">
                        <i className="bi bi-person-plus-fill"></i>Create</button>
                      <ReactTooltip id="medicineTip" place="top" effect="solid">
                        Create Medication
                      </ReactTooltip>
                      <i className="bi bi-search ms-1 ps-2 mb-2 " id='search-icon'></i>
                      <SearchBar placeholder=" Search Medicine "
                        {...props.searchProps} />
                    </div>
                  </div>
                  <BootstrapTable
                    {...props.baseProps}
                    keyField="id"
                    data={patientData}
                    columns={columns}
                    rowEvents={rowEvents}
                    noDataIndication={() => { return (tdataLoad ? "No data available" : <NoDataIndication />) }}
                    pagination={paginationFactory(options)}
                    wrapperClasses="table-responsive" />
                </div>
              )}
            </ToolkitProvider>
          </div>
          {/*UPDATE MEDICATION STARTS */}
          <div className="modal" id="updatePatientMedicine" data-bs-backdrop="false" tabIndex="-1">
            <div className="modal-dialog shadow-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Update Patient Medicine</h4>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                  ></button>
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="modal-body p-4">
                    <div className="row text-start">
                      <div className="mb-3 drug-name">
                        <label htmlFor="medicineName" className="form-label">
                          Medicine Name<span className='required-field'>*</span>
                        </label>
                        <Select
                               
                               value={drugName}
                                onChange={handledChange}
                                options={options}
                                defaultValue={drugName}
                                onInputChange={inputChange}
                            />
                      
                        {/* <input
                          type="text"
                          defaultValue={medicineData.medicine_name}
                          onChange={(e) => setMedicineData(e.target.value)}
                          className={`form-control p-2 ${errors.medicineName ? "is-invalid" : ""}`} {...register('medicineName', { required: true })} />
                        {(errors.medicineName && errors.medicineName.type === 'required') ? <div className="invalid-feedback">This field is required</div> : ''} */}
                      </div>
                      <div className="mb-3">
                        <label htmlFor="dosage" className="form-label">
                          Dosage
                        </label>
                        <Select     
                              //  defaultValue={d}  
                              value={dose}     
                          onChange={handleDosageChange}
                          options={dosageOptions}
                          defaultValue={dose}

                       
                        />
                        {/* <input type="text"
                          defaultValue={medicineData.dosage}
                          onChange={(e) => setMedicineData(e.target.value)}
                          className={`form-control p-2 ${errors.dosage ? "is-invalid" : ""}`} {...register('dosage', { required: true })} >
                        </input>{(errors.dosage && errors.dosage.type === 'required') ? <div className="invalid-feedback">This field is required</div> : ''} */}
                      </div>
                      <div className="mb-3">
                  <label htmlFor="dosage" className="form-label">
                    Code
                  </label>
                  <Select
                  //  value={dosageVal}
                    value={dcode}
                    onChange={handleDoseCodeChange}
                    options={doseCodeOptions}
                    defaultValue={dcode}
                  />
                 
                </div>
                      <div className="mb-3">
                        <label htmlFor="takenfrom" className="form-label">
                          Medication Started On
                        </label>
                        <input type="text"
                          // key={index}
                          defaultValue={medicineData.medication_started_on}
                          onChange={(e) => setMedicineData(e.target.value)}
                          className={`form-control p-2 ${errors.takenfrom ? "is-invalid" : ""}`} {...register('takenfrom', { required: false })} />
                        {(errors.takenfrom && errors.takenfrom.type === 'required') ? <div className="invalid-feedback">This field is required</div> : ''}
                      </div>
                      <div className="mb-3">
                        <label htmlFor="stopped" className="form-label">
                          Medication Ended On
                        </label>
                        <input type="text"
                          defaultValue={medicineData.medication_ended_on}
                          onChange={(e) => setMedicineData(e.target.value)}
                          className={`form-control p-2 ${errors.stopped ? "is-invalid" : ""}`} {...register('stopped', { required: false })} />
                        {(errors.stopped && errors.stopped.type === 'required') ? <div className="invalid-feedback">This field is required</div> : ''}
                      </div>
                      <div className="mb-3">
                        <label htmlFor="notes" className="form-label">
                          Notes
                        </label>
                        <textarea
                          defaultValue={medicineData.notes}
                          onChange={(e) => setMedicineData(e.target.value)}
                          className="form-control" rows="5"{...register('notes', { required: false })} />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="status" className="form-label">
                          Status
                        </label>
                        <select selected={medicineData.status}
                          className={`form-select p-2 ${errors.status ? "is-invalid" : ""}`} {...register('status', { required: true })} >
                          <option value="Present">PRESENT</option>
                          <option value="Past">PAST</option>
                        </select>
                        {(errors.gender && errors.gender.type === 'required') ? <div className="invalid-feedback">This field is required</div> : ''}
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer ">
                    <button
                      type="button"
                      id="closeMedicUpdate"
                      className="btn btn-cancel text-white px-2 "
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-save text-white px-2">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/*UPDATE MEDICATION ENDS */}

          {/* DELETE MEDICATION STARTS*/}
          <div className="modal" id="deleteMedication" data-bs-backdrop="false"
            tabIndex="-1" >
            <div className="modal-dialog shadow-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Confirm Delete</h4>
                  <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div className="modal-body p-4 text-start">
                  <h6>Are you sure you want to delete this Medication?</h6>
                </div>
                <div className="modal-footer ">
                  <button type="button" id="closeDel" className="btn btn-save text-white px-4" data-bs-dismiss="modal">Cancel</button>
                  <button type="submit" className="btn btn-cancel text-white px-4"
                    onClick={deleteMedication}>Delete</button>
                </div>

              </div>
            </div>
          </div>
          {/*DELETE MEDICATION ENDS */}
        </div>
        <div className="user-information col-md-3 pe-0 me-0">
          <div className="user bg-white p-3">
            <div className="info align-items-center">
              <div
                className="col-md-1 ms-auto editPatient "
                data-bs-toggle="modal"
                data-bs-target="#updatePatient"
              >
                <i className="bi bi-pencil"></i>
              </div>
              {patientData.gender === "Female" ? <img
                className="rounded-circle w-25 mb-2"
                src={femaleImage}
                alt={patientData.first_name}
              /> : <img
                className="rounded-circle w-25 mb-2"
                src={maleImage}
                alt={patientData.first_name}
              />}
              <div className="user-info">
                <h6 className="mb-0 text-capitalize">{cardData.first_name}&nbsp;{cardData.last_name}</h6>
                <p className="information">{patientData.gender}</p>
                <hr
                  className="mt-1"
                  style={{ height: "1px", color: "#838383" }}
                />
              </div>
            </div>
            <div className="other-info text-start">
              <div className="basic-info mb-3">
                <label>Patient ID</label>
                <p className="information">{cardData.patientNumber}</p>
              </div>
              <div className="basic-info mb-3">
                <label>Date of Birth</label>
                <p>{dob}</p>
              </div>
              <div className="basic-info mb-3">
                <label >Gender</label>
                <p className="information">{cardData.gender}</p>
              </div>
              <div className="basic-info mb-3">
                <label>Account Name</label>
                <p className="information">{cardData.account_name}</p>
              </div>
              <hr style={{ marginTop: "1px solid lightgray" }} />
              <div>
                <Tag data={{ isFlagSet2: isFlagSet2, messageCallbackTag: messageCallbackTag, isFlag3: isFlag3, messageCallbackTag1: messageCallbackTag1 }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PatientDetails;

