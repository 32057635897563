import React, { useState } from 'react';
import { Modal, Button } from "react-bootstrap";
import { ImWarning } from 'react-icons/im';
import DeleteIcon from '../../../../assets/img/sample-icons/delete.gif';
//Api
import MasterDataService from '../../../../services/masterdata';

function DeleteInterpretMasterModal(props) {
  //modal
  const [isTrue, setIsTrue] = useState(false);
  const [displayImg, setDisplayImg] = useState(false);
  //api
  const msds = new MasterDataService();
  const id = props.data.id;

  const msgDisplay = (val) => {
    props.data.messageCallback1(val);

  }
  function delRecord(val) {
    let reqData = {
      "conform_data": val,
      "interpretationchoicefieldid": id
    }
    msds.removeSupplement(reqData)
      .then((response) => {
        if (response.data) {
          setIsTrue(false);
          setDisplayImg(false);
          hideModal('closeDelSup');
          msgDisplay(true);
          props.data.getInterpretionGridData();
        }
      }).catch(function (error) {
        setTimeout(() => {
          setDisplayImg(true);
        }, 5000);
        setIsTrue(true);
      })
  }
  function hideModal(target) {
    document.getElementById(target).click();
  }

  function handleCancel() {
    setIsTrue(false);
    setDisplayImg(false);
  }

  return (
    <>
      <div className="modal" id="deleteInterpretMaster" data-bs-backdrop="false"
        tabIndex="-1" >
        <div className="modal-dialog shadow-lg">
          <div className="modal-content">
            <div className="modal-header">
            {isTrue ? <>{displayImg ? <h4 className="modal-title text-danger"><ImWarning className='me-2 mb-1 text-danger' />Re-Confirm Delete</h4> : ""}</> : <h4 className="modal-title">Confirm Delete</h4>}
                            <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => handleCancel()}></button>
              {/* {isTrue ? <h4 className="modal-title text-danger"><ImWarning className='me-2 mb-1 text-danger' />Re-Confirm Delete</h4> : <h4 className="modal-title">Confirm Delete</h4>}
              <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => setIsTrue(false)}></button> */}
            </div>
            <div className="modal-body p-4 text-center">
            {isTrue ? <>{displayImg ? <h6 className='text-start'>This Interpretation choicefield is associated with service requests, do you still want to delete?</h6> : <img src={DeleteIcon} className="ms-auto" alt="delete-icon" width="200" height="200" />}</> :
                                <h6 className='text-start'>Are you sure you want to delete this Interpretation choicefield?</h6>}
              {/* {isTrue ? <h6 >This supplement is associated with service requests, do you still want to delete?</h6> : <h6>Are you sure you want to delete this Supplement?</h6>} */}
            </div>
            <div className="modal-footer ">
            {isTrue? <>{displayImg ? <button type="button" id="closeDelSup" className="btn btn-save text-white px-3" onClick={() => handleCancel()} data-bs-dismiss="modal">No</button>:""}</>:
                         <button type="button" id="closeDelSup" className="btn btn-save text-white px-3" onClick={() => handleCancel()} data-bs-dismiss="modal">No</button>}  
                           
                            {isTrue ? <>{displayImg ? <button type="button" className="btn btn-cancel text-white px-3" onClick={() => delRecord("True")}>Yes</button> : ""}</> :
                                <button type="button" className="btn btn-cancel text-white px-3" onClick={() => delRecord("False")}>Yes</button>}
              {/* <button type="button" id="closeDelSup" className="btn btn-save text-white px-3" onClick={() => setIsTrue(false)} data-bs-dismiss="modal">No</button>
              {isTrue ? <button type="button" className="btn btn-cancel text-white px-3" onClick={() => delRecord("True")}>Yes</button> :
                <button type="button" className="btn btn-cancel text-white px-3" onClick={() => delRecord("False")}>Yes</button>} */}
            </div>

          </div>
        </div>
      </div>

    </>
  );
}

export default DeleteInterpretMasterModal;