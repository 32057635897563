import React, { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/styles/patientList/patientlist.scss';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import PatientModal from './PatientModal.js';
import PatientAccountService from '../../services/Patient.js';
import { newPatient, viewTip, deleteTip } from '../ToolTip/ToolTip.js';
import { format } from 'date-fns';
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";
import { ScaleLoader } from 'react-spinners';
import * as XLSX from 'xlsx';
import { Modal, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { ImWarning } from 'react-icons/im';
import 'react-toastify/dist/ReactToastify.css';
import LoadingIcon from '../../assets/img/sample-icons/sandbox.gif';


const NoDataIndication = () => (
  <div className='spinner d-flex justify-content-center text-center h-100'>
    <img src={LoadingIcon} alt="loading-icon" width="200" height="200" />
    {/* <ScaleLoader size={60} color="#79a3bb" style={{ marginTop: '50px' }} /> */}
  </div>
);

const RemoteAllPatient = ({ columns, data, page, sizePerPage, onTableChange, totalSize, rowEvents, noDataIndication }) => (
  <div>
    <BootstrapTable
      remote={true}
      keyField="id"
      data={data}
      columns={columns}
      noDataIndication={noDataIndication}
      pagination={paginationFactory({ page, sizePerPage, totalSize })}
      onTableChange={onTableChange}
      rowEvents={rowEvents}
    />
  </div>
);


function PatientList() {

  const { url } = useRouteMatch();
  const history = useHistory();
  const [patientData, setPatientData] = useState([]);
  const pnt = new PatientAccountService();
  const [pntID, setPntID] = useState();
  //remote table
  const [data, setData] = useState([]);
  const [tdataLoad, setTdataLoad] = useState(true);
  const [totalDataSize, setTotalDataSize] = useState(0);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [inputVal, setInputVal] = useState("");
  const [sortName, setSortName] = useState();
  const [sortOrder, setSortOrder] = useState();
  const [delMsg, setDelMsg] = useState('');
  const [sname, setSname] = useState("");
  const [isMounted, setIsMounted] = useState(true);
  const [exportData, setExportData] = useState([]);
  //CALLBACK FUNCTION
  const [isFlagSet, setIsFlagSet] = useState(false);
  const [msg, setMsg] = useState('');
  const [msgErr, setmsgErr] = useState('');
  const [startDate, setStartDate] = useState();
  const [sDate, setSdate] = useState('');
  const [eDate, setEdate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dat, setDat] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  //PATIENT MODAL VARIABLE
  const [accntType, setAccntType] = useState([]);
  //modal
  const [isTrue, setIsTrue] = useState(false);
  // const meta = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';
  // const datas= meta +

  const patientDelnotify = () => toast.success("Patient deleted successfully!");
  const patientdelnotifyErr = () => toast.error("Patient couldn't be deleted!");

  function messageCallback(item) {
    setIsFlagSet(item);
    setMsg("Patient created successfully !");
    setTimeout(() => { setMsg(""); setIsFlagSet(false) }, 5000);
  }

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      setPntID(row.id);

    },
  };

  const sortOrderTable = (order, column) => {
    if (!order) return (
      <><span>&nbsp;&nbsp;<FaSort color="#858585" /></span>
      </>);
    else if (order === 'asc') return (<><span>&nbsp;&nbsp;<font color="red"><FaSortUp color="#000" /></font></span>
    </>);
    else if (order === 'desc') return (<><span>&nbsp;&nbsp;<font color="red"><FaSortDown color="#000" /></font></span>
    </>);
    return null;

  }

  function DateFormatter(cell, row) {
    if (row) {
      return (
        <span>
          {format(new Date(cell), 'MM/dd/yyyy')}
        </span>
      );
    }
  }



  function actionFormatter(cell, row, rowIndex, formatExtraData) {
    const pId = row.id;
    localStorage.setItem('patientId', row.id);
    const navigateTo = () => history.push({
      pathname: `${url}/:patient-details`,
      state: {
        patientId: row.id,
        accountID: row.accountid
      }

    })
    return (
      <div className="col action-icons ">
        {viewTip}{deleteTip}
        <i className="bi bi-eye me-2 view-icon py-0" data-tip data-for="viewTip" onClick={navigateTo}></i>
        <i className="bi bi-trash2 del-icon py-0" data-tip data-for="deleteTip" data-bs-toggle="modal" data-bs-target="#deletePatientDetail" ></i>
      </div>
    );
  }

  const columns = [
    {
      dataField: 'sl.no', text: 'S.No', formatter: (cell, row, rowIndex, formatExtraData) => {
        if (page === 1) { return rowIndex + 1; }
        else { return ((page - 1) * sizePerPage) + (rowIndex + 1); }
      },
    },
    { dataField: 'patientNumber', text: 'Patient Number', sort: true, sortCaret: sortOrderTable },
    {
      dataField: 'first_name', text: 'Patient Name', sort: true, formatter: (cell, row) => {
        return <div>{`${row.first_name} ${row.last_name}`}</div>;
      }, sortCaret: sortOrderTable
    },
    { dataField: 'dob', text: 'Date of Birth', formatter: DateFormatter },
    { dataField: 'gender', text: 'Birth Gender', },
    { dataField: 'address', text: 'Address', sort: true, sortCaret: sortOrderTable },
    { dataField: 'contact_number', text: 'Contact', sort: true, sortCaret: sortOrderTable },
    { dataField: 'action', text: 'Action', formatter: actionFormatter }
  ];

  const handleDateChange = (date) => {
    setStartDate(format(new Date(date), 'MM/dd/yyyy'));
    setSdate(format(new Date(date), "yyyy-MM-dd"));
    // setDateRangeFlag(false);
  };

  const handleEndDateChange = (date) => {
    setEndDate(format(new Date(date), 'MM/dd/yyyy'));
    setEdate(format(new Date(date), "yyyy-MM-dd"));
    // setDateRangeFlag(false);
  };
  const handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    const currentIndex = (page - 1) * sizePerPage;
    let order;
    //sort
    if (sortOrder === 'asc') {
      order = data.sort((a, b) => {
        if (a[sortField] > b[sortField]) {
          return 1;
        } else if (b[sortField] > a[sortField]) {
          return -1;
        }
        return 0;
      });
    } else {
      order = data.sort((a, b) => {
        if (a[sortField] > b[sortField]) {
          return -1;
        } else if (b[sortField] > a[sortField]) {
          return 1;
        }
        return 0;
      });
    }
    setSortName(sortField);
    setSortOrder(order);
    //REMOTE PAGINATION
    setPage(page);
    setData(data.slice(currentIndex, currentIndex + sizePerPage));
    setSizePerPage(sizePerPage);
    fetchPatientDetails(page, sizePerPage, inputVal, sortField, sortOrder);
  }

  function fetchPatientDetails(page, sizePerPage, inputVal, sortName, sortOrder) {
    let reqData = {
      PntInput: {
        "status": "Active",
        "acctid": 0
      },
      DataFinder: {
        "pagesize": sizePerPage,
        "currentpage": page,
        "sortbycolumn": sortName || "",
        "sortby": sortOrder || "",
        "searchdata": inputVal || ""
      }
    }
    pnt.getAllPatientDetails(reqData).then((response) => {
      if (isMounted) {
        if (response.data) {
          if (response.data.PatientsDetail !== [] && response.data.DataFinder.totalrecords !== 0) {
            setTdataLoad(true);
            setPatientData(response.data.PatientsDetail);
            setTotalDataSize(response.data.DataFinder.totalrecords);
            setData(response.data.PatientsDetail.slice(0, sizePerPage));
          } else {
            setTdataLoad(false);
            setData([]);
          }
        }

      }
    })
      .catch(function (error) {
        if (error.response.status === 403) {
          history.push('/login');
        }
      })
  }
  useEffect(() => {
    fetchPatientDetails(page, sizePerPage, inputVal, sortName, sortOrder);
    return () => { setIsMounted(false) }
  }, []);

  async function deletePatient() {
    const patientId = pntID;
    pnt.DeletePatient(patientId)
      .then((response) => {
        if (response.data.status === "success") {
          fetchPatientDetails(page, sizePerPage);
          hideModal('closeDelPnt');
          patientDelnotify();
          // setDelMsg("Patient deleted successfully!");
          // setTimeout(() => { setDelMsg("") }, 5000);
        }
      })
      .catch(function (error) {
        patientdelnotifyErr();
      })
  }
  const deletePnt = () => {
    setIsDelete(true);
  }
  function confirmDeletePatient(val) {

    let reqData = {
      "conform_data": val,
      "patientid": pntID

    }
    pnt.ConfirmDeletePatient(reqData).then((response) => {
      if (response.data) {
        if (response.data.status === "success") {
          fetchPatientDetails(page, sizePerPage);
          hideModal('closeDelPnt');
          patientDelnotify();
          setIsTrue(false);

        }
      }
    })
      .catch(function (error) {
        if (error.response) {
          setIsTrue(true);
        }
      })
  }


  function hideModal(target) {
    document.getElementById(target).click();
  };

  async function exportPatient() {
    setDat(true);
    let reqData = {
      "list": "all",
      "start_on": sDate,
      "end_on": eDate
    }
    pnt.ExportPatient(reqData).then((response) => {
      if (response.data) {
        if (response.data.status === "success") {
          setExportData(response.data.data);
          setDat(false);
        }
      }
    })
  }

  //ADD PATIENT
  const addPatientClick = () => {
    accountType();
  }
  // ACCOUNT TYPE FUNCTION
  async function accountType() {
    pnt.GetAccountType().then((response) => {
      // if (isMounted) {
      if (response.data.status === "success") {
        setAccntType(response.data.data);
      }
      // }
    });
  }
  // useEffect(() => {
  //   accountType();
  //   // return () => { setIsMounted(false) }
  // }, []);

  //SEARCH
  const handleSearch = (e) => {
    if (e.key === "Enter") {
      setInputVal(e.target.value);
      fetchPatientDetails(page, sizePerPage, e.target.value);
    }
  }

  const resetSearch = (e) => {
    if (e.key === "Backspace" && !e.target.value) {
      setInputVal("");
      fetchPatientDetails(page, sizePerPage, e.target.value);
    }
  }

  const downloadExcel = (exportData) => {
    // const worksheet = XLSX.utils.json_to_sheet(exportData);
    var worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1", true);
    // var new_name = XLSX.utils.book_append_sheet(workbook, worksheet, name, true);
    XLSX.writeFile(workbook, "PatientData.xlsx");
    exportPatient();

  };

  return (
    <div className='col patient-list'>
      <div>{newPatient}</div>
      <div className='row g-0 ps-3 pt-3'>
        {/* {isFlagSet === true ? <div className='row align-items-center justify-content-center mb-2'>
          <div className=" success-msg  " style={{ width: '60%' }}>{msg}</div>
        </div> : null} */}
        {/* {delMsg ? <div className='row align-items-center justify-content-center mb-2'>
          <div className=" success-msg  " style={{ width: '60%' }}>{delMsg}</div></div> : null} */}
        <div className='table-request my-2 me-3'>
          <PatientModal data={{ isFlagSet: isFlagSet, messageCallback1: messageCallback, fetchPatientDetails: fetchPatientDetails, accntType: accntType }} />
          <div className='table-data me-3'>
            <div className='pe-4'>
              <div className='row mb-2'>
                <div className='col-auto text-start ms-1'>
                  <h5>List of Patient</h5>
                </div>
                {/* <div className='row ps-0 pe-0'> */}

                {/* </div> */}
                <div className='col text-end'>
                  <i className="bi bi-search ms-1 ps-2 mb-5 " id='search-icon'></i>
                  <input
                    onKeyDown={(e) => handleSearch(e)}
                    onKeyUp={(e) => resetSearch(e)}
                    className='py-1 ps-3' placeholder="Search Patient" />
                  <button type='button' className='btn-create btn px-2 py-1 ms-3'
                    data-tip data-for="newPatientTip" data-bs-toggle="modal" data-bs-target="#patientModal" onClick={addPatientClick}
                  >
                    <i className="bi bi-plus-circle"></i> Add</button>
                  {/* <button className='btn btn-export rounded ms-2 text-white'
                    data-bs-toggle="modal" data-bs-target="#showGrid">Export</button> */}
                </div>
              </div>
              <RemoteAllPatient
                data={data}
                page={page}
                columns={columns}
                rowEvents={rowEvents}
                sizePerPage={sizePerPage}
                totalSize={totalDataSize}
                noDataIndication={() => { return (tdataLoad ? <NoDataIndication /> : "No data available") }}
                onTableChange={handleTableChange}
              />
            </div>
          </div>
        </div>
        {/* <iframe src={datas}
        width="450px"
        height="450px"
        id="myId"
        className="myClassname"
        display="initial"
        position="relative"/> */}
        {/* <iframe src={`data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${exportData}`} width="100%" height="100%"/> */}
      </div>
      {/* DELETE PATIENT MODAL*/}
      <div className="modal" id="deletePatientDetail" data-bs-backdrop="false"
        tabIndex="-1" >
        <div className="modal-dialog shadow-lg">
          <div className="modal-content">
            <div className="modal-header">
              {isTrue ? <h4 className="modal-title text-danger"><ImWarning className='me-2 mb-1 text-danger' />Re-Confirm Delete</h4> : <h4 className="modal-title">Confirm Delete</h4>}
              <button type="submit" className="btn-close" data-bs-dismiss="modal" onClick={() => setIsTrue(false)}></button>
            </div>
            <div className="modal-body p-4 text-start">
              {isTrue ? <h6 >This Patient is associated with service requests, do you still want to delete?</h6> : <h6>Are you sure you want to delete this Patient?</h6>}
            </div>
            <div className="modal-footer ">
              <button type="button" id='closeDelPnt' className="btn btn-save text-white px-4" data-bs-dismiss="modal" onClick={() => setIsTrue(false)}>Cancel</button>
              {isTrue ? <button type="button" className="btn btn-cancel text-white px-3" onClick={() => confirmDeletePatient("true")}>Yes</button> :
                <button type="button" className="btn btn-cancel text-white px-3" onClick={() => confirmDeletePatient("false")}>Yes</button>}

            </div>
          </div>
        </div>
      </div>

      {/* EXPORT PATIENT DATA MODAL*/}
      <div className="modal" id="showGrid"
        tabIndex="-1" >
        <div className="modal-dialog">
          <div className="modal-content">

            <div className="modal-header">
              <h4 className="modal-title">Export Patient</h4>
              <button type="submit" className="btn-close" data-bs-dismiss="modal"></button>
            </div>
            <div className="modal-body p-4 text-start">
              <div className='col-md-12 text-start'>
                <label className='form-label'>Select date</label>
                <div className='row ps-0 pe-0'>
                  <Col className="me-0">
                    <DatePicker
                      className='form-control col-auto'
                      dateFormat="MM/dd/yyyy"
                      autoComplete='off'
                      placeholderText="Start Date"
                      name="startDate"
                      maxDate={new Date()}
                      value={startDate}
                      onChange={handleDateChange}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    /></Col>
                  to
                  <Col>
                    <DatePicker
                      className='form-control col-auto'
                      dateFormat="MM/dd/yyyy"
                      placeholderText="End date"
                      name="endDate"
                      autoComplete='off'
                      minDate={new Date(startDate)}
                      maxDate={new Date()}
                      value={endDate}
                      onChange={handleEndDateChange}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    /></Col>
                </div>
              </div>
            </div>
            <div className="modal-footer ">
              <button type="button" id='closeDelPnt' className="btn btn-save text-white px-4" data-bs-dismiss="modal">Cancel</button>
              <button type="submit" className="btn btn-cancel text-white px-4"
                onClick={() => downloadExcel(exportData)} >Save</button>
            </div>
          </div>
        </div>
      </div>

    </div>

  );
}

export default PatientList;