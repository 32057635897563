import {format} from 'date-fns';
import {FaSort,FaSortUp,FaSortDown} from "react-icons/fa";

function DateFormatter(cell, row)
{
if (row) {
return (
<span>
{ format(new Date(cell), 'MM/dd/yyyy hh:mm a') }
</span>
);
}
}

function actionFormatter(cell, row, rowIndex, formatExtraData) {
    return (
      <div className="col action-icons">
        <i className="bi bi-pencil edit-icon " data-bs-toggle="modal" data-bs-target="#editDoc" ></i>
        {/* <i className="bi bi-eye view-icon bg-warning" data-bs-toggle="modal" data-bs-target="#viewDoc"></i> */}
        <i className="bi bi-download download-icon" data-bs-toggle="modal" data-bs-target="#downloadDoc"></i>
        <i className="bi bi-trash2 del-icon" data-bs-toggle="modal" data-bs-target="#deleteDoc"></i>
      </div>);
  }

  function EquipmentTypeFormat(cell, row, rowIndex, formatExtraData) {
      return (
      <div className="text-center">
      {(cell)? cell :'---'}
      </div>
      );
     
  }
  function fileNameFormatter(cell,row,rowIndex,formatExtraData){
    return(
      <div className='text-start' style={{wordBreak:'break-all'}}>
        {(cell)?cell:'---'}
      </div>
    )
  }

  const sortOrder=(order,column)=>{
    if (!order) return (<span>&nbsp;&nbsp;<FaSort color="#858585"/></span>);
    else if (order === 'asc') return (<span>&nbsp;&nbsp;<font color="red"><FaSortUp color="#000"/></font></span>);
    else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="red"><FaSortDown color="#000"/></font></span>);
    return null;
  }


const columns = [
    { dataField: 'sl.no', text: 'S.No', formatter: (cell, row, rowIndex, formatExtraData) => {
      return rowIndex + 1;
    },},
    { dataField: 'filename', text: 'Document Name', sort: true ,sortCaret: sortOrder,formatter:fileNameFormatter },
    { dataField: 'equipment_type', text: 'Equipment Type', sort: true,formatter: EquipmentTypeFormat,sortCaret: sortOrder },
    { dataField: 'size', text: 'Document size ', sort: true ,sortCaret: sortOrder},
    { dataField: 'created_on', text: 'Upload On', sort: true ,formatter: DateFormatter,sortCaret: sortOrder},
    { dataField: 'action', text: 'Action',formatter: actionFormatter },

];

const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    sizePerPageList: [{
      text:'10', value:10},
      { text:'20', value:20},
      { text:'50', value:50}
    ],
    prePageText: '<',
    nextPageText: '>',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    alwaysShowAllBtns: true,
    withFirstAndLast: false,
    disablePageTitle: true,

};

export { columns ,options};