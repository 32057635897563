import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
//API
import MasterDataService from '../../../../services/masterdata';

export default function AddMarkerModal(props) {

    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const msds = new MasterDataService();
    const [eyeType, setEyeType] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);
    const [errmsg, setErrorMsg] = useState(false);
    const markerSuccess = () => toast.success("Marker created successfully!");
    const markerError = (errmsg) => toast.error(errmsg);
    const eyeTypeErr=()=>toast.error("Eye type is required");

    const onSubmit = (data) => {
        setDataLoading(true);
        let reqData = {
            "mdmarkerid": 0,
            "markername": data.markerName.trim(),
            "mfieldname": data.markerName,
            "mfieldtype": data.fieldType,
            "status": "Active",
            "EC_isactive": data.eyeclosed || false,
            "EO_isactive": data.eyeopen || false
        }
        if(eyeType==false){
            eyeTypeErr();
            setDataLoading(false);
        }else{
        msds.saveMarker(reqData).then((response) => {
            if (response.data) {
                if (response.data.status === "success") {
                    setDataLoading(false);
                    hideModal('closeMrkrModal');
                    props.data.getMarkers();
                    clearForm();
                    setEyeType(false);
                    markerSuccess();
                }
            }
        })
            .catch(function (error) {
                markerError(error.response.data.data);
                setDataLoading(false);
            })
        }

    }
    function hideModal(target) {
        document.getElementById(target).click();
        reset();
    };

    const msgDisplay = (val) => {
        props.data.messageCallback(val);
    }

    function clearForm() {
        reset();
        setDataLoading(false);
    }
    const handleEyeTypeChange = (e) => {
        console.log("E", e.target.checked);
        if (e.target.checked == true) {
            setEyeType(true);
        } else {
            setEyeType(false);
        }
    }
    return (
        <div className="modal" id="createMarker" data-bs-backdrop="false"
            tabIndex="-1" >
            <div className="modal-dialog shadow-lg">
                <div className="modal-content">

                    <div className="modal-header">
                        <h4 className="modal-title">Create Marker</h4>
                        <button type="button" className="btn-close"
                            onClick={() => clearForm()} data-bs-dismiss="modal"></button>
                    </div>

                    <form onSubmit={handleSubmit(onSubmit)} >
                        <div className="modal-body p-4">
                            {/* {errmsg ? <div className="err-msg">Lifestyle name already exist</div> : ''} */}
                            <div className='row text-start'>
                                <div className="mb-3">
                                <label htmlFor="markerName" className="form-label ">Marker Name
                                <span className='required-field'>*</span></label>
                              <input type="text" maxLength="100" className={`form-control p-2 ${errors.markerName ? "is-invalid" : ""}`} { ...register('markerName', { validate: (value) => { return !!value.trim() }, required: true }) } />
                              {(errors.markerName && errors.markerName.type === 'required') ? <div className="invalid-feedback">This field is required</div> : ''}
                                  
                                </div>
                                <div className="mb-3">
                                <label htmlFor="fieldType" className="form-label ms-1">Field Type
                                <span className='required-field'>*</span></label>
                              <select className={`form-select p-2 ${errors.fieldType ? "is-invalid" : ""}`} {...register('fieldType', { required: true })} >
                              <option value="">Select</option>
                                        <option value="dropdown">Dropdown</option>
                                        <option value="textbox">Textbox</option>
                                        <option value="radio">Radio</option>
                              </select>
                              {(errors.fieldType && errors.fieldType.type === 'required') ? <div className="invalid-feedback">This field is required</div> : ''}
                            

                                </div>
                                <div className="mb-3">
                                    <label htmlFor="status" className="form-label">Type<span className='required-field text-danger'>*</span></label>

                                    <div className='form-group row'>
                                        <div className='col-md-3'>
                                            <input type="checkbox" id="eyeopen"  {...register('eyeopen', { required: false })} onChange={(e) => handleEyeTypeChange(e)} />
                                            <label className='ms-1 mb-1'>Eye Open</label>
                                        </div>
                                        <div className='col-md-3'>
                                            <input type="checkbox" id='eyeclosed'  {...register('eyeclosed', { required: false })} onChange={(e) => handleEyeTypeChange(e)} />
                                            <label className='ms-1 mb-1'>Eye Closed</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer ">
                            <button type="button"
                                onClick={() => clearForm()} id="closeMrkrModal" className="btn btn-cancel text-white px-2" data-bs-dismiss="modal">Cancel</button>
                            <button type="submit" disabled={dataLoading ? true : false} className="btn btn-save text-white px-2">Create </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}