import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import AccountChart from "./AccountChart";
//Do not remove this below import
import { Modal } from "bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Tabs, Tab } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "../../assets/styles/account/accountDetails.scss";
import AccountPieChart from "./AccountPieChart";
import { FiCopy } from "react-icons/fi";
import CreateUserModal from "./modals/CreateUserModal.js";
import UpdateAccountModal from "./modals/UpdateAccountModal.js";
import { useForm } from "react-hook-form";
import AdminAccountService from "../../services/AdminAccount";
import { ScaleLoader } from "react-spinners";
import maleImage from "../../assets/img/male-avatar-icon.jpeg";
import { IoMdCheckmarkCircle } from 'react-icons/io';
import { IoCloseCircleSharp } from "react-icons/io5";
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";
import QRCode from "qrcode-react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingIcon from '../../assets/img/sample-icons/sandbox.gif';

function stateFormatter(cell, row, rowIndex, formatExtraData) {
  if (cell === true) {
    return (
      <IoMdCheckmarkCircle color="#209b5f" size={25} className="ps-2" />
    );
  }
  else if (cell === false) {
    return (
      <IoCloseCircleSharp color="#c04444" size={25} className="ps-2" />
    );
  }
}

const NoDataIndication = () => (
  <div className='spinner d-flex justify-content-center text-center h-100'>
  <img src={LoadingIcon} alt="loading-icon" width="200" height="200"/>
  {/* <ScaleLoader size={60} color="#79a3bb" style={{ marginTop: '50px' }} /> */}
</div>
);

const RemoteAccountDetail = ({ noDataIndication, columns, data, page, sizePerPage, onTableChange, totalSize, rowEvents }) => (
  <div>
    <BootstrapTable
      remote
      pagination={paginationFactory({ page, sizePerPage, totalSize })}
      keyField="id"
      data={data}
      columns={columns}
      onTableChange={onTableChange}
      noDataIndication={noDataIndication}
      rowEvents={rowEvents}
      multiColumnSort={6}
    />
  </div>
);

function AccountDetails() {
  const history = useHistory();
  const location = useLocation();
  const act = new AdminAccountService();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [userData, setUserData] = useState([]);
  const [accountData, setAccountData] = useState([]);
  const [accName, setAccName] = useState();
  const [accData, setAccData] = useState();
  const [accNum, setAccNum] = useState();
  const [isactive, setIsActive] = useState();
  const [address, setAddress] = useState();
  const [userID, setUserID] = useState();
  const [setActive, setInActive] = useState();
  const uid = location.state.uid;
  const url = window.origin;
  const urlValue = `${url}/patient-form/${uid}`;
  const [textCopied, setTextCopied] = useState(false);
  const [copyMsg, setCopyMsg] = useState("");
  const userRole = localStorage.getItem('userrole');

  //remote table
  const [data, setData] = useState([]);
  const [totalDataSize, setTotalDataSize] = useState(0);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [inputVal, setInputVal] = useState("");
  const [sortName, setSortName] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [tableData, setTableData] = useState(true);
  const [sname, setSname] = useState("");
  const [flag1, setFlag1] = useState(false);
  const [isMounted, setIsMounted] = useState(true);
  const [isupMounted, setIsUpMounted] = useState(true);
  const [msgs, setMsgs] = useState();
  //CALLBACK VARIABLES
  const [isFlagSet, setIsFlagSet] = useState(false);
  const [msg, setMsg] = useState('');
  const [isFlagSet1, setIsFlagSet1] = useState(false);
  const [msg1, setMsg1] = useState('');
  const [modalOpened, setModalOpened] = useState(false);
  const usernotify = () => toast.success("User updated successfully!");
  const usernotifyErr = () => toast.error("User couldn't be updated!");

  //CALLBACK FUNCTION UPDATE ACCOUNT
  function messageCallback(item) {
    setIsFlagSet(item);
    setMsg("Account successfully updated!");
    setTimeout(() => { setMsg(""); setIsFlagSet(false) }, 5000);
  }

  //CALLBACK FUNCTION FOR CREATE USER
  function messageCallback1(item) {
    setIsFlagSet1(item);
    setMsg1("User created successfully!");
    setTimeout(() => { setMsg1(""); setIsFlagSet1(false) }, 5000);
  }

  function copyText() {
    navigator.clipboard.writeText(urlValue);
    setTextCopied(true);
    setCopyMsg("Url copied successfully");
    setTimeout(() => { setCopyMsg("") }, 3000);
  }
  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      setUserID(row.id);
      getUserData(row.id);
    },
  };
  useEffect(() => {
    if (!location.search.includes('modalOpened=true')) {
      setModalOpened(false);
    }
  }, [location]);
  const openModal = () => {
    history.push(history.location.pathname + '?modalOpened=true');
    setModalOpened(true);
  }
//  useEffect(()=>{
//   window.onpopstate=e=>{
//    document.getElementById('#modal').modal('hide');
//     // document.getElementById('#modal').on('hidden.bs.modal', function(){
//     //   //remove the backdrop
//     //   document.getElementsByClassName('.modal-backdrop').remove();
//     // })
//   }
//  },[]);

  const sortOrderTable = (order, column) => {
    if (!order) return (<span>&nbsp;&nbsp;<FaSort color="#858585" /></span>);
    else if (order === 'asc') return (<span>&nbsp;&nbsp;<font color="red"><FaSortUp color="#000" /></font></span>);
    else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="red"><FaSortDown color="#000" /></font></span>);
    return null;
  }
  function actionFormatter(cell, row, rowIndex, formatExtraData) {
    return (
      <div className="col align-items-center justify-content-center" data-bs-toggle="modal" data-bs-target="#updateUser" >
        <i className="bi bi-pencil" style={{ color: "#fff", background: '#0399f5', fontSize: '12px', padding: '3px', borderRadius: '4px' }}></i>
      </div>
    );
  }

  function EmailFormatter(cell, row, rowIndex, formatExtraData) {
    return (
      <div className="text-left">
        {(cell) ? <p style={{ textTransform: "none", marginBottom: '0' }}>{cell}</p> : '---'}
      </div>
    );
  }

  const accDetailsColumns = [
    {
      dataField: 'sl.no', text: 'S.No', formatter: (cell, row, rowIndex, formatExtraData) => {
        if (page === 1) { return rowIndex + 1; }
        else { return ((page - 1) * sizePerPage) + (rowIndex + 1); }
      },
    },
    { dataField: 'username', text: 'Username', sort: true, sortCaret: sortOrderTable },
    { dataField: 'first_name', text: 'First Name', sort: true, sortCaret: sortOrderTable },
    { dataField: 'last_name', text: 'Last Name', sort: true, sortCaret: sortOrderTable },
    { dataField: 'email', text: 'Email', formatter: EmailFormatter },
    { dataField: 'is_active', text: 'Status', formatter: stateFormatter },
    { dataField: 'action', text: 'Action', formatter: actionFormatter },
  ];

  const handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, data }) => {
    let order;
    //SORT
    if (sortOrder === 'asc') {
      order = data.sort((a, b) => {
        if (a[sortField] > b[sortField]) {
          return 1;
        } else if (b[sortField] > a[sortField]) {
          return -1;
        }
        return 0;
      });
    } else {
      order = data.sort((a, b) => {
        if (a[sortField] > b[sortField]) {
          return -1;
        } else if (b[sortField] > a[sortField]) {
          return 1;
        }
        return 0;
      });
    }
    setSortName(sortField);
    setSortOrder(order);
    //PAGINATION
    const currentIndex = (page - 1) * sizePerPage;
    setPage(page);
    setData(data.slice(currentIndex, currentIndex + sizePerPage));
    setSizePerPage(sizePerPage);
    fetchAllUserData(page, sizePerPage, inputVal, sortField, sortOrder);
  }

  function fetchAccountDetailData() {
    let userid = location.state.rowId;
    act.getAccountDetail(userid)
      .then((response) => {
        if (isupMounted) {
          if (response.data) {
            setAccData(response.data.data);
            setAccNum([response.data.data.account_number]);
            setAccName([response.data.data.account_name]);
            setIsActive([response.data.data.status]);
            setAddress([response.data.data.contact_phone]);
          }
        } else return;
      })
      .catch(function (err) {
        if (err.response.status === 403) {
          history.push('/login');
        }
      });
  }

  async function fetchAllUserData(page, sizePerPage, inputVal, sortName, sortOrder) {
    if (sortName === "first_name") {
      setSname("FirstName");
    } else if (sortName === "last_name") {
      setSname("LastName");
    } else {
      setSname("username");
    }
    let reqData = {
      UserInput: {
        acctid: location.state.rowId,
      },
      DataFinder: {
        pagesize: sizePerPage,
        currentpage: page,
        sortbycolumn: sortName || "",
        sortby: sortOrder || "",
        searchdata: inputVal || "",
      },
    };

    act.getAllUserDetail(reqData)
      .then((response) => {
        if (isMounted) {
          if (response.data) {
            if (response.data.UserDetail !== [] && response.data.DataFinder.totalrecords !== 0) {
              setTableData(true);
              setAccountData(response.data.UserDetail);
              setTotalDataSize(response.data.DataFinder.totalrecords);
              setData(response.data.UserDetail.slice(0, sizePerPage));
            } else {
              setTableData(false);
              setData([]);
            }
          }
        }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          history.push('/login');
        }
      })
  }

  const handleSearch = (e) => {
    if (e.key === "Enter") {
      setInputVal(e.target.value);
      fetchAllUserData(page, sizePerPage, e.target.value, sortName);
    }
  }
  const resetSearch = (e) => {
    if (e.key === "Backspace" && !e.target.value) {
      setInputVal("");
      fetchAllUserData(page, sizePerPage, e.target.value);
    }
  }
  useEffect(() => {
    fetchAccountDetailData();
    return () => { setIsUpMounted(false); }
      ;
  }, []);


  useEffect(() => {
    fetchAllUserData(page, sizePerPage, inputVal);
    return () => { setIsMounted(false); }
  }, []);

  function getUserData(userID) {
    act.getUserDetail(userID).then((response) => {
      if (response.data.status === "success") {
        setUserData([]);
        setUserData(response.data.data);
        setInActive(response.data.data.is_active);
      }
    })
      .catch(function (error) {
        if (error.response.status === 403) {
          history.push('/login');
        }
      })
  }
  useEffect(() => {
    reset(userData)
  }, [userData]);

  const onSubmit = (data) => {
    let reqData = {
      "userid": userID,
      "username": data.username,
      "email": data.email,
      "first_name": data.firstname,
      "last_name": data.lastname,
      "is_active": setActive,
    };
    act.CreateUser(reqData)
      .then((response) => {
        if (response.data) {
          if (response.data.status === "success") {
            hideModal('closeUpdUser');
            fetchAllUserData(page, sizePerPage);
            setFlag1(true);
            usernotify();
            // setMsgs("User updated successfully!");
            // setTimeout(() => { setMsgs(""); setFlag1(false) }, 5000);
          }
        }
      })
      .catch(function (error) {
      });
  };


  // const location = useLocation();
  // const nav = useHistory();


  function hideModal(target) {
    document.getElementById(target).click();

  };

  //   function ensureHash(newHash) {
  //     if (window.location.hash) {
  //         return window.location.href.substring(0, window.location.href.lastIndexOf(window.location.hash)) + newHash;
  //     }
  //     return window.location.hash + newHash
  // }
  // window.location.href = ensureHash("#foo=bar");
  return (
    <div className="col acc-details">

      <div className="row g-0 me-5">
        <div className="row account-details m-3">
          <h5 className="text-start pt-2">Account Information</h5>
          <div className="col-md-5 ms-3 mb-2 account-info">
            <div className="row acc-row">
              <div
                className="edit-btn text-end pt-1"
                data-bs-toggle="modal"
                data-bs-target="#updateModal"
              >
                {userRole !== 'staff' ? <i className="bi bi-pencil"></i> : ""}
              </div>
              <div className="col align-items-center justify-content-center pt-3 mb-2">
                <div className="user-details">
                  <img
                    className="rounded-circle w-50 mb-2"
                    src={maleImage}
                    alt={accName} />
                  <div className="name-info mt-2 mb-2 pt-1">
                    <h6 className="mb-1">Account Name</h6>
                    <p>{accName}</p>
                  </div>
                </div>
              </div>
              <div className="col contact-info text-start pt-3">
                <div className="row">
                  <div>
                    <h6>Account #</h6>
                    <p>{accNum}</p>
                  </div>
                  <div className="mt-4">
                    <h6>Contact Person</h6>
                    <p>{address}</p>
                  </div>
                </div>
              </div>
              <div className="col text-start pt-3 status-info">
                <div className="row">
                  <div>
                    <h6>Active From</h6>
                    <p>Jan 10, 2022</p>
                  </div>
                  <div className="mt-4">
                    <h6>Status</h6>
                    <p>{isactive}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 chart-info ms-3 ps-0 pb-0 mb-2">
            <AccountChart />
          </div>
          <div className="col-md-2 ms-0 ps-0 ms-3 pe-0 me-3 pb-0 pie-chart-info mb-2">
            <AccountPieChart />
          </div>
          {/* TAB CONTENT STARTS HERE */}
          <div className="tabpage row pt-3 pe-0">
            <Tabs
              id="user-information-grid"
              defaultActiveKey="user-list"
              transition={false}
              className="mb-4 py-2 px-2"
            >
              <Tab eventKey="user-list" title="Users List" className="user-lists">
                <div className="arrow"></div>
                <div className="table-request my-2 me-0 p-3 ">
                  <div className="table-data pe-0">

                    <div>
                      <div className="row mb-2">
                        <div className="col text-start">
                          {/* <h6>Users List</h6> */}
                        </div>
                        <div className="col text-end ">
                          <i className="bi bi-search ms-1 ps-2 mb-3"
                            id="search-icon"
                          ></i>
                          <input
                            onKeyDown={(e) => handleSearch(e)}
                            // onChange={handleSearch}
                            onKeyUp={(e) => resetSearch(e)}
                            className='py-1 ps-3 srch-bar' placeholder="Search Request" />
                          <button
                            type="button"
                            className="btn-create btn px-3 py-1 ms-3"
                            data-bs-toggle="modal"
                            data-bs-target="#createUserModal"
                          >
                            <i className="bi bi-person-plus-fill"></i>create
                          </button>
                        </div>
                      </div>
                      <RemoteAccountDetail
                        data={data}
                        page={page}
                        columns={accDetailsColumns}
                        sizePerPage={sizePerPage}
                        totalSize={totalDataSize}
                        rowEvents={rowEvents}
                        noDataIndication={() => { return (tableData ? <NoDataIndication /> : "No data available") }}
                        onTableChange={handleTableChange}
                      />
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="patient-form" title="Patient Form" className="patient-form">
                <div className="arrow"></div>
                <div className="p-3 ">
                  <h6 className="text-start mt-2 mb-3">Public form QrCode Access :</h6>
                  <div className="url-container mt-4">
                    {copyMsg ? <div className="success-msg col-md-4 py-1">{copyMsg}</div> : ""}
                    <div className="w-50 input-group my-4">
                      <input type="text" className="form-control " value={urlValue} disabled={true} />
                      <span className="input-group-text btn-copy" onClick={() => copyText()}>
                        <FiCopy color="#fff" />
                      </span>
                    </div>
                    <div className="qr-container text-start mt-3">
                      <QRCode
                        value={`${url}/patient-form/${uid}`}
                        size={156}
                        bgColor={"#fff"}
                        fgColor={"#000"}
                      // logo={require("../../assets/img/qr-logo.jpg")}
                      // logoWidth={30}
                      // logoHeight={25}
                      />
                    </div>
                  </div>
                </div>
              </Tab>
            </Tabs>
            <CreateUserModal data={{ isFlagSet1: isFlagSet1, messageCallback1: messageCallback1, fetchAllUserData: fetchAllUserData }} />
            <UpdateAccountModal data={{ isFlagSet: isFlagSet, messageCallback: messageCallback, fetchAccountDetailData: fetchAccountDetailData }} />
            {/* UPDATE USER MODEL START */}
            <div className="modal " id="updateUser" data-bs-backdrop="false" tabIndex="-1">
              <div className="modal-dialog shadow-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title">Update User</h4>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                    ></button>
                  </div>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="modal-body p-4">
                      <div className="row text-start">
                        <div className="col mb-3">
                          <label htmlFor="email" className="form-label">
                            Email
                          </label>
                          <input
                            type="text"
                            defaultValue={userData.email}
                            onChange={(e) => setUserData(e.target.value)}
                            className={`form-control p-2 ${errors.email ? "is-invalid" : ""
                              }`}
                            {...register(
                              "email",
                              { required: true },
                              { pattern: /^\S+@\S+$/i }
                            )}
                          />
                          {errors.email && errors.email.type === "required" ? (
                            <div className="invalid-feedback">
                              This field is required
                            </div>
                          ) : (
                            ""
                          )}
                          {errors.email && errors.email.type === "pattern" ? (
                            <div className="invalid-feedback">
                              Enter a valid email id
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col mb-3">
                          <label htmlFor="username" className="form-label">
                            Username
                          </label>
                          <input
                            type="text"
                            defaultValue={userData.username}
                            onChange={(e) => setUserData(e.target.value)}
                            className={`form-control p-2 ${errors.username ? "is-invalid" : ""
                              }`}
                            {...register("username", { required: true })}
                          />
                          {errors.username && errors.username.type === "required" ? (
                            <div className="invalid-feedback">
                              This field is required
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="row text-start">
                        <div className="col mb-3">
                          <label htmlFor="firstname" className="form-label">
                            First Name
                          </label>
                          <input
                            type="text"
                            defaultValue={userData.first_name}
                            onChange={(e) => setUserData(e.target.value)}
                            className={`form-control p-2 ${errors.firstname ? "is-invalid" : ""
                              }`}
                            {...register("firstname", { required: true })}
                          />
                          {errors.firstname && errors.firstname.type === "required" ? (
                            <div className="invalid-feedback">
                              This field is required
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col mb-3">
                          <label htmlFor="lastname" className="form-label">
                            Last Name
                          </label>
                          <input
                            type="text"
                            defaultValue={userData.last_name}
                            onChange={(e) => setUserData(e.target.value)}
                            className={`form-control p-2 ${errors.lastname ? "is-invalid" : ""
                              }`}
                            {...register("lastname", { required: true })}
                          />
                          {errors.lastname && errors.lastname.type === "required" ? (
                            <div className="invalid-feedback">
                              This field is required
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="row text-start">
                        <label>Status</label>
                        <div
                          className="col-md-6 btn"
                          onClick={() => setInActive(!setActive)}
                        >
                          {setActive ? (
                            <button type="button" className="btn btn-active w-100 " value="Active">
                              Active
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btn w-100 "
                              style={{ border: "1px solid red", color: "red" }}
                              value="InActive"
                            >
                              InActive
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer ">
                      <button
                        type="button"
                        id="closeUpdUser"
                        className="btn btn-cancel text-white px-3"
                        data-bs-dismiss="modal">
                        Cancel
                      </button>
                      <button type="submit" className="btn btn-save text-white px-2">
                        Update
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {/* UPDATE USER MODEL ENDS HERE*/}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountDetails;

