import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import PatientAccountService from "../../services/Patient";
import "../../assets/styles/patientList/tags.scss";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CreatableSelect from 'react-select/creatable';

export default function Tag(props) {
  const [tags, setTags] = useState([]);
  const [dbTags, setDbTags] = useState([]);
  const [dbSelect, setDbSelect] = useState([]);
  const [selectedTag, setSelectedTag] = useState('');
  const [saveDbTags, setSaveDbTags] = useState([]);
  const pnt = new PatientAccountService();
  const colors = ["#fff2f3", "#f3f4fe", "#eef9ff", "#fff8eb"];
  const location = useLocation();
  const patientId = location.state.patientId;
  const [isMounted, setIsMounted] = useState(true);
  const [isMountedAllTag, setIsMountedAllTag] = useState(true);
  // const[colors,setColors] = useState(['#000','#f1f1f1','#333']);
  const tagcreatenotify = () => toast.success("Tag created successfully!");
  const tagcreatenotifyErr = () => toast.error("Tag couldn't be created!");
  const Tagdelnotify = () => toast.success("Tag deleted successfully!");
  const TagdelnotifyErr = () => toast.error("Tag couldn't be deleted!");

  const options = dbSelect.map(function (dbSelect) {
    return {
      value: dbSelect.TagName,
      label: dbSelect.TagName,
      id: dbSelect.id,
    };
  });
  const handleTagChange = (selectedTag) => {
    const values = ([...selectedTag].map(o => o.value));
    setSelectedTag(selectedTag);
    setSaveDbTags(values);
  };
  const getUniqueFromRange = (min, max) => {
    return Math.floor(min + Math.random() * (max));
  };
  const getRandomItem = items => {
    return items[getUniqueFromRange(0, items.length)];
  };
  const addTag = (e) => {
    if (e.key === "Enter") {
      if (tags.find(tags => tags.toLowerCase() === e.target.value.toLowerCase())) {
        return alert("No duplicate value allowed")
      }
      else if (e.target.value.length > 0) {
        setTags([...tags, e.target.value]);
        e.target.value = "";
      }
    }
  };

  function fetchAllTags() {
    pnt.GetPatientTag(patientId).then((response) => {
      if (isMountedAllTag) {
        if (response.data.status === "success") {
          setDbTags(response.data.data);
          fetchAllPatientTags(response.data.data);
        }
        else return;
      }
    })
  }
  function fetchAllPatientTags(arr) {
    pnt.GetAllPatientTag().then((response) => {
      // if (isMounted) {
        if (response.data) {
          const val = response.data.data;
          const output = [...new Map(val.map(o => [o.TagName, o])).values()];
          setDbSelect(output.filter(ar => !arr.find(rm => (rm.TagName === ar.TagName))));
        }
        else return;
      // }
    })
  }

  const msgDisplay = (val) => {
    props.data.messageCallbackTag(val);
  }

  function hideModal(target) {
    document.getElementById(target).click();
    setTags([]);
  };

  const onSubmit = () => {
    let reqData = {
      "tags": saveDbTags.toString(),
      "patientid": patientId
    }

    pnt.CreatePatientTag(reqData)
      .then((response) => {
        if (response.data.status === "success") {
          msgDisplay(true);
          hideModal('closeTag');
          tagcreatenotify();
          setSelectedTag('');
          fetchAllTags();
        }
      })
      .catch(function (error) {
        tagcreatenotifyErr();
      })
  }
  const msgDisplay1 = (val) => {
    props.data.messageCallbackTag1(val);
  }
  function removeDbTag(id) {
    pnt.RemovePatientTag(id).then((response) => {
      if (response.data.status === "success") {
        msgDisplay1(true);
        fetchAllTags();
        Tagdelnotify();
      }
    })
      .catch(function (error) {
        TagdelnotifyErr();
      })
  }
  useEffect(() => {
    fetchAllTags();
    return () => { setIsMountedAllTag(false); }
  }, []);

  const removeTag = (removedTag) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    setTags(newTags);
  };

  function clearTags() {
    setTags([]);
  }

  return (
    <>
      <div className="row g-0">
        <h6 className="col mb-3"> Tags </h6>
        <div
          className="btn btn-tag col-sm-auto p-0 me-3"
          data-bs-toggle="modal"
          data-bs-target="#addTag"
        >
          <i
            className="bi bi-plus"
            style={{ background: "#1f98df", color: "#fff" }}
          ></i>
        </div>
        <div className="row g-0 mt-2">
          {dbTags.map((tag, i) => {
            return (
              <div key={i} className="tag col-md-5 mx-2 my-1 p-1" style={{
                background: getRandomItem(colors),
                fontSize: '14px',
                whiteSpace: 'no-wrap',
                wordBreak: 'break-word',
                height: 'auto'
              }}>
                #{tag.TagName}<div className="ms-auto col-1" key={tag.id}
                  onClick={() => removeDbTag(tag.id)} style={{ cursor: 'pointer' }}
                ><span>x</span></div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="modal" id="addTag" data-bs-backdrop="false">
        <div className="modal-dialog shadow-lg" style={{ maxWidth: "800px" }}>
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Add Tag</h4>
              <button
                type="button"
                className="btn btn-close"
                data-bs-dismiss="modal"
                onClick={() => clearTags()}
              ></button>
            </div>
            <div className="modal-body">
              {/* <div className="tag-box"> */}
              <div className="tag-container">
                {/* {tags.map((tag, index) => {
                    return (
                      <div key={index} className="tag" style={{
                        background: getRandomItem(colors),
                        // height: getUniqueFromRange(100, 200)
                      }}>
                        {tag} <span className="ms-2" onClick={() => removeTag(tag)}>x</span>
                      </div>
                    );
                  })} */}
                <CreatableSelect
                  isMulti
                  className="col-md-12"
                  value={selectedTag}
                  onChange={handleTagChange}
                  options={options} />
                {/* <input list="allTags" onKeyDown={addTag} placeholder="Enter tag"/>
                  <datalist id="allTags">
                    <select style={{ display: 'none' }}>
                      {options.map((option) => (
                        <option key={option.id} value={option.value} >{option.label}</option>
                      ))}
                    </select>
                  </datalist> */}
                {/* </div> */}
              </div>
            </div>
            <div className="modal-footer ">
              <button
                type="button"
                className="btn btn-cancel text-white px-2"
                data-bs-dismiss="modal"
                id="closeTag"
                onClick={() => clearTags()}>
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-save text-white px-3"
                onClick={() => onSubmit()}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
