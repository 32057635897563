import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import '../../assets/styles/account/account.scss';
import AccountModal from './modals/AccountModal';
import AdminAccountService from '../../services/AdminAccount';
import { newAccount } from '../ToolTip/ToolTip.js';
import { ScaleLoader } from "react-spinners";
import { Row } from 'react-bootstrap';
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";
import LoadingIcon from '../../assets/img/sample-icons/sandbox.gif';

function stateFormatter(cell) {
  if (cell === "Active") {
    return (
      <p style={{ color: "#369c52" }}>
        {cell}
      </p>
    );
  }
  return (
    <p style={{ color: "#F91005" }}>
      {cell}
    </p>
  );
}

const NoDataIndication = () => (
  <div className='spinner d-flex justify-content-center text-center h-100'>
    <img src={LoadingIcon} alt="loading-icon" width="200" height="200"/>
    {/* <ScaleLoader size={60} color="#79a3bb" style={{ marginTop: '50px' }} /> */}
  </div>
);

const RemoteAllAccount = ({ columns, data, page, sizePerPage, onTableChange, totalSize, rowEvents, noDataIndication }) => (
  <div>
    <BootstrapTable
      remote
      pagination={paginationFactory({ page, sizePerPage, totalSize })}
      keyField="id"
      data={data}
      columns={columns}
      onTableChange={onTableChange}
      noDataIndication={noDataIndication}
      rowEvents={rowEvents}
    />
  </div>
);

function Account() {
  const { url } = useRouteMatch();
  const history = useHistory();
  const acn = new AdminAccountService();
  const [accData, setAccData] = useState([]);
  const [isStatus, setIsStatus] = useState('Active');
  const [isMounted, setIsMounted] = useState(true);
  const [tdataLoad, setTdataLoad] = useState(true);
  const userRole = localStorage.getItem('userrole');
  //remote table
  const [data, setData] = useState([]);
  const [totalDataSize, setTotalDataSize] = useState(0);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [inputVal, setInputVal] = useState("");
  const [sortName, setSortName] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [sname, setSname] = useState("");
  //TOASTER

  //CALLBACK FUNCTION
  const [isFlagSet, setIsFlagSet] = useState(false);
  const [msg, setMsg] = useState('');
  

  function messageCallback(item) {
    setIsFlagSet(item);
    // setMsg("Account successfully created!");
    // setTimeout(() => { setMsg(""); setIsFlagSet(false) }, 5000);
  }

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      history.push({
        pathname: `${url}/:account-details`,
        state: {
          rowId: row.id,
          uid: row.Acc_GUID
        }
      })
    },
  };

  const sortOrderTable = (order, column) => {
    if (!order) return (
      <><span>&nbsp;&nbsp;<FaSort color="#858585" /></span>
      </>);
    else if (order === 'asc') return (<><span>&nbsp;&nbsp;<font color="red"><FaSortUp color="#000" /></font></span>
    </>);
    else if (order === 'desc') return (<><span>&nbsp;&nbsp;<font color="red"><FaSortDown color="#000" /></font></span>
    </>);
    return null;
  }

  const accountColumns = [
    {
      dataField: 'sl.no', text: 'S.No', formatter: (cell, row, rowIndex, formatExtraData) => {
        if (page === 1) { return rowIndex + 1; }
        else { return ((page - 1) * sizePerPage) + (rowIndex + 1); }
      },
    },
    {
      dataField: 'account_number', text: 'Account No', sort: true, sortCaret: sortOrderTable,
    },
    { dataField: 'account_name', text: 'Account Name', sort: true, sortCaret: sortOrderTable },
    {
      dataField: 'first_name', text: 'Name', sort: true, sortCaret: sortOrderTable, formatter: (cell, row) => {
        return <div>{`${row.first_name} ${row.last_name}`}</div>;
      },
    },
    { dataField: 'contact_address', text: 'Address', sort: true, sortCaret: sortOrderTable },
    { dataField: 'contact_phone', text: 'Phone No', sort: true, sortCaret: sortOrderTable },
    { dataField: 'contact_fax', text: 'Fax', sort: true, sortCaret: sortOrderTable },
    { dataField: 'status', text: 'Status', formatter: stateFormatter }
  ];

  const handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, data }) => {
    let result = accData;
    let order;
    //sort
    if (sortOrder === 'asc') {
      order = result.sort((a, b) => {
        if (a[sortField] > b[sortField]) {
          return 1;
        } else if (b[sortField] > a[sortField]) {
          return -1;
        }
        return 0;
      });
    } else {
      order = result.sort((a, b) => {
        if (a[sortField] > b[sortField]) {
          return -1;
        } else if (b[sortField] > a[sortField]) {
          return 1;
        }
        return 0;
      });
    }
    setSortName(sortField);
    setSortOrder(order);
    //pagination
    const currentIndex = (page - 1) * sizePerPage;
    setPage(page);
    setData(result.slice(currentIndex, currentIndex + sizePerPage));
    setSizePerPage(sizePerPage);
    getAccountDetails(page, sizePerPage, inputVal, isStatus, sortField, sortOrder);
  }

  async function getAccountDetails(page, sizePerPage, inputVal, isStatus, sortName, sortOrder) {
    let reqData = {
      AcctInput: {
        status: isStatus||"Active"
      },
      DataFinder: {
        pagesize: sizePerPage,
        currentpage: page,
        sortbycolumn: sortName || "",
        sortby: sortOrder || "",
        searchdata: inputVal || ""

      }
    }
    acn.getAllAccountDetails(reqData)
      .then((response) => {
        if (isMounted) {
          if (response.data) {
            if(response.data.AccountDetail !==[] && response.data.DataFinder.totalrecords !==0){
            setTdataLoad(true);
            setAccData(response.data.AccountDetail);
            setTotalDataSize(response.data.DataFinder.totalrecords);
            setData(response.data.AccountDetail.slice(0, sizePerPage));
          }else {
            setTdataLoad(false);
            setData([]);
          }
        }
          
        }
      }).catch(function (error) {
        if (error.response.status === 403) {
          history.push('/login');
        }
      })
  }
  useEffect(() => {
    getAccountDetails(page, sizePerPage, inputVal, isStatus);
    return () => { setIsMounted(false) }
  }, []);

  const handleSearch = (e) => {
    if(e.key === "Enter"){
    setInputVal(e.target.value);
    getAccountDetails(page, sizePerPage, e.target.value, isStatus);
    }
  }

  const resetSearch = (e) => {
    if (e.key === "Backspace" && !e.target.value) {
      setInputVal("");
      getAccountDetails(page, sizePerPage, e.target.value, isStatus);
    }
  }

  const handleStatusChange = (e) => {
    setIsStatus(e.target.value);
    getAccountDetails(page, sizePerPage, inputVal, e.target.value, sortName, sortOrder);
  }

  return (
    <div className='col account'>
      {newAccount}
      <div className='row g-0 ps-3 pt-3'>
        <div className='table-request my-2 me-3'>
          <AccountModal data={{ isFlagSet: isFlagSet, messageCallback: messageCallback, getAccountDetails: getAccountDetails }} />
          <div className='table-data me-4'>
            <div className='pe-3'>
              <div className='row mb-2'>
               
                <div className='col text-start ms-1'>
                  <Row>
                    <h5 className='col-auto'>List of Account</h5>
                    {userRole !== "staff" ?
                      <select className='form-select col-sm-2 px-2 py-0 status-select'
                        onChange={handleStatusChange}>
                        <option value="Active">Active</option>
                        <option value="InActive">InActive</option>
                      </select> : ""}
                  </Row>
                </div>
                <div className='col-md-6 text-end ms-auto'>
                  <div className="row">
                    {userRole !== "staff" ?
                     <div className='col-md-9 pe-0 ms-3' >
                     <i className="bi bi-search ms-1 ps-2 " id='search-icon'></i>
                     <input
                       onKeyDown={(e) => handleSearch(e)}
                       onKeyUp={(e)=>resetSearch(e)}
                       className='py-1 ps-3' placeholder="Search Account" />
                   </div>
                    :
                      <div className='col-md-12 pe-3' >
                        <i className="bi bi-search ms-1 ps-2 " id='search-icon'></i>
                        <input
                          onChange={(e) => handleSearch(e)}
                          onKeyUp={(e)=>resetSearch(e)}
                          className='py-1 ps-3' placeholder="Search Account" />
                      </div>
                    }
                    {userRole !== "staff" ?
                      <button type='button' className='btn-create btn px-3 py-1 ms-3 col-auto'
                        data-bs-toggle="modal" data-bs-target="#accountModal">
                        <i className="bi bi-person-plus-fill" data-tip data-for="newAccountTip"></i>Add
                      </button> : ""}
                  </div>
                </div>
              </div>
              <RemoteAllAccount
                data={data}
                page={page}
                columns={accountColumns}
                sizePerPage={sizePerPage}
                totalSize={totalDataSize}
                rowEvents={rowEvents}
                noDataIndication={() => { return (tdataLoad ?  <NoDataIndication />:"No data available" ) }}
                onTableChange={handleTableChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Account;