import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { useLocation, useHistory } from 'react-router-dom';
import AuthService from '../../services/auth';


function AccountPieChart() {
    const location = useLocation();
    const history = useHistory();
    const [isMounted, setIsMounted] = useState(true);
    const [male, setMale] = useState(0);
    const [female, setFemale] = useState(0);
    const [others,setOthers] = useState(0);
    const ls = new AuthService();

    function fetchGenderData() {
        let reqData = {
            AccountID: location.state.rowId,
            userid:localStorage.getItem('userid')
        }
        ls.dashboard(reqData)
            .then((response) => {
                if (isMounted) {
                    if (response.data.status == 'success') {
                        let grpData = response.data.Genderpie;
                        setMale(Number(grpData.pnt_gender_male));
                        setFemale(Number(grpData.pnt_gender_female));
                        setOthers(Number(grpData.pnt_gender_others));
                    }
                }
            })
            .catch(function (error) {
                if(error.response.status === 403){
                    history.push('/login');
                }
            })
    }

    useEffect(() => {
        fetchGenderData();
        return()=>{setIsMounted(false);}
    }, []);

    const genderAccOptions = {
        labels: ["Male Patient", "Female Patient","Other Patient"],
        colors: ["#ffa902", "#7a6efe","#24a8fa"],
        dataLabels: {
            enabled: false
        },
        title: {
            text: "Gender",
            align: 'left',
            margin: 10,
            floating: false,
            style: {
                fontSize: '14px',
                fontWeight: 'bold',
                FontFamily: 'Lato',
            }
        },
        legend: {
            show: true,
            fontSize: '10px',
            position: 'bottom',
            align: 'right',
            horizontalAlign: 'left',
            markers: {
                width: 8,
                height: 8,
                radius: 5,
                offsetX: -5
            }
        },
        stroke: {
            width: 0
        },
        plotOptions: {
            pie: {
                expandOnClick: false,
                donut: {
                    labels: {
                        show: false,
                        total: {
                            show: false,
                        },
                    }
                }
            }
        }
    }
    
    const genderAccSeries = [male, female,others];

    return (
        <>
            <Chart options={genderAccOptions} series={genderAccSeries} type='pie' width='100%' height='100%' />
        </>
    );
}

export default AccountPieChart;