import config from '../assets/config';

var base64 = require('base-64');
var utf8 = require('utf8');
const axios = require('axios');

export const ax = axios.create({
    baseURL: config.apiURL,
    client_id: config.client_id,
    client_secret: config.client_secret,

});

// Add a request interceptor
ax.interceptors.request.use(
    config => {
        let user = localStorage.getItem('username');
        let pass = localStorage.getItem('password');
        var text = user + ':' + pass;
        var bytes = utf8.encode(text);
        const token = base64.encode(bytes);
        if ((!config.url.includes('auth/login')) && (!config.url.includes('auth/password_reset'))
            && (!config.url.includes('auth/reset-password/verify-token'))
            && (!config.url.includes('auth/password_reset/confirm/')) && (!config.url.includes('account/getpublicaccount'))
            && (!config.url.includes('patient/addpublicpatient'))) {
            if (token) {
                config.headers['Authorization'] = 'Basic ' + token;
                config.headers['Content-Type'] = 'application/json';
                config.headers['Accept'] = 'application/json';
            }
        }
        else {
            //config.headers['Authorization'] = 'Basic M2EzNzIwNjEyMGNiM2RkOWY4MDM6M2EzNzIwNjEyMGNiM2RkOWY4MDM=';
        }
        return config;
    },
    error => {
        Promise.reject(error)
    }
);

// ax.interceptors.request.use(
//     config => {
//         var text = user+':'+pass;
//         var bytes = utf8.encode(text);
//         const token = base64.encode(bytes);
//     if(!config.url.includes('docrepo/consentdocupload')) {
//     if (token) {
//         //config.headers['Authorization'] = 'Bearer ' + token;
//         config.headers['Authorization'] = 'Basic ' + token;
//         config.headers['Content-Type']= 'multipart/form-data';
//         // config.headers['Accept']= 'application/json';
//         }
//     }
//     else {
//     //config.headers['Authorization'] = 'Basic M2EzNzIwNjEyMGNiM2RkOWY4MDM6M2EzNzIwNjEyMGNiM2RkOWY4MDM=';
//     }
//     return config;
//     },
//     error => {
//     Promise.reject(error)
//     }
//     );