import React ,{useState,useEffect} from 'react';
import ReactQuill from 'react-quill';
import { useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form";
// import QuillToolbar, { modules, formats } from './QuillToolbar';
import "react-quill/dist/quill.snow.css";
import PipelineRequestService from '../../../../services/PipelineRequest';
import DOMPurify from 'dompurify';
import  InterpretToolbar,{modules1,formats1}  from './toolbar/InterpretToolbar';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function InterpretationOfFindings() {
    const { handleSubmit, formState: { errors } } = useForm();
    const location = useLocation();
    const [closeModal, setcloseModal] = useState(true);
    const [text, setText] = useState({value:""});
    let pipreq = new PipelineRequestService();
    const [descid, setDescID] = useState(0);
    const[msg,setMsg] = useState('');
    const notifyIF = () => toast.success("Interpretation of Findings updated successfully!");
    const notifyIFErr = () => toast.error("Interpretation of Findings couldn't be updated!");
   
    useEffect(() => {
      let isMounted = true;
        const reqData = location.state.reqId;
        pipreq.getAssociatedInterpretation(reqData)
          .then((response) => {
            if(isMounted){
            if (response.data) {
              setDescID(response.data.data.id);
              setText({value:response.data.data.description});
            
            }
          }
          }).catch(function (error) {
    
          })
          return () => { isMounted = false; }
      }, [])
    
      function hideModal (target) {
        document.getElementById(target).click();
      };

      function fetchAssociatedInterpretation()
      {
        const reqData = location.state.reqId;
        pipreq.getAssociatedInterpretation(reqData)
          .then((response) => {
            if (response.data) {
              setDescID(response.data.data.id);
              setText({value:response.data.data.description});
            
            }
          }).catch(function (error) {
    
          })
      }
    const handleChange = value => {
        setText({ value });
      };
      const createMarkup = (html) => {
        return  {
          __html: DOMPurify.sanitize(html)
        }
      }
      const onSubmit = (data) => {
        let reqData = {
          "sr_associate_interpretation":descid,
          "service_request":location.state.reqId,
          "description":text.value,
        }
                  
        pipreq.saveAssociatedInterpretation(reqData)
          .then((response) => {
            if (response.data) {
                if (response.data.status === 'success') {
                  fetchAssociatedInterpretation();
                  hideModal("close3");
                  notifyIF();
                  // setMsg(" Updated successfully!");
                  // setTimeout(() => { setMsg(""); }, 5000);
              }
            }
          })
          .catch(function (error) {
           // setErrorMsg(true);
           notifyIFErr();
          })
      }
    return (
        <div className="analysis-procedure rich-editor">
            <div className="row g-0 p-2">
            {/* {msg ? <div className='row align-items-center justify-content-center mb-2'>
            <div className=" success-msg  " style={{ width: '60%' }}>{msg}</div>
          </div> : null} */}
                <h6 className="col-auto">Interpretation Of Findings</h6>
                <div className="col-auto ms-auto icon-btn">
                    <i
                        className="bi bi-pencil"
                        data-bs-toggle="modal"
                        data-bs-target="#editInterpretationData"
                    ></i>
                </div>
            </div>
            <div className='preview p-2'>
           <p >The quantitative EEG topographical analysis demonstrates trends consistent with reported clinical symptoms of</p>
            <div className='my-5 fw-bold' dangerouslySetInnerHTML={createMarkup(text.value)}></div>
            <p >While there are numerous aberrant patterns present, healthy adaptive strategies, skills, and environments may allow for great success in a variety of settings. 
              Treatments addressing specific presenting concerns should account for patterns reflective of symptomatology. Repeat testing may be done to monitor treatment progress.</p>
              </div>
            {closeModal ? <div className="modal" id="editInterpretationData" tabIndex="-1" >
                <div className="modal-dialog" style={{ maxWidth: '900px' }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Edit Template</h4>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                            ></button>
                        </div>

                        <div className="modal-body p-4 text-start">
                            {/* <Toolbar/> */}
                            <InterpretToolbar/>
                            <ReactQuill
                                // theme="snow"
                                value={text.value}
                                modules={modules1}
                                formats={formats1}
                                onChange={handleChange}
                                // onChange={this.handleProcedureContentChange}
                            >
                                {/* <div className="my-editing-area" /> */}
                           </ReactQuill>

                        </div>

                        <div className="modal-footer ">
                            <button
                                type="button"
                                className="btn btn-cancel text-white px-4"
                                data-bs-dismiss="modal" id="close3"
                            >
                                Cancel
                            </button>
                            <button type="button" onClick={handleSubmit((onSubmit))} className="btn btn-save text-white px-4" >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div> : null}
        </div>
    );

}

