import React, { useEffect, useState } from 'react';
import { set, useForm } from "react-hook-form";

import Select from 'react-select';
//API
import MasterDataService from '../../../../services/masterdata';

function CreateMedicineModal(props) {
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const [formValues, setFormValues] = useState([{ reference: "", url: "" }])
    const [errmsg, setErrorMsg] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);

    //api
    const msds = new MasterDataService();


    let handleChange = (i, e) => {
        let newFormValues = [...formValues];
        newFormValues[i][e.target.name] = e.target.value;
        setFormValues(newFormValues);
        setDataLoading(false);
    }

    function addFormFields() {
        setFormValues([...formValues, { reference: "", url: "" }]);
        setDataLoading(false);
    }

    let removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setDataLoading(false);
        setFormValues(newFormValues)
    }

    const msgDisplay = (val) => {
        props.data.messageCallback(val);
    }

   
    
    const onSubmit = (data) => {
        setDataLoading(true);
        const refval = formValues.map(function (elem) { return elem.reference; }).join("|");
        const urlval = formValues.map(function (elem) { return elem.url; }).join("|");
        let reqData = {
            "Mdmedicid": 0,
            "medicine_name": data.medicineName.trim(),
            "ref_name": refval,
            "ref_url": urlval
        }
        msds.createMedication(reqData)
            .then((response) => {
                if (response.data) {
                    if (response.data.status === 'success') {
                        setDataLoading(false);
                        hideModal('closeMedic');
                        msgDisplay(true);
                        reset();
                        props.data.getMedicationData();
                        clearForm();
                    }
                }
            })
            .catch(function (error) {
                setErrorMsg(true);
                setDataLoading(false);
            })
    }

    function hideModal(target) {
        document.getElementById(target).click();
    };

    function clearForm() {
        reset({ medicineName: "" });
        setFormValues([{ reference: "", url: "" }]);
        setDataLoading(false);
        setErrorMsg(false);
    }
   

    return (
        <div className="modal" id="createMedicine" data-bs-backdrop="false" tabIndex="-1" >
            <div className="modal-dialog shadow-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Create Medicine </h4>
                        <button type="button" className="btn-close"
                            data-bs-dismiss="modal"></button>
                    </div>
                    <div className="modal-body p-4">
                        {errmsg ? <div className="err-msg">Medicine name already exist</div> : ''}
                        <div className='row text-start'>
                           
                            <div className="mb-3">
                                <label className="form-label">Medicine Name <span className='required-field'>*</span></label>
                                {/* <Select
                    value={drugName}
                    onChange={handledChange}
                    options={options}
                    defaultValue={drugName}
                    onInputChange={inputChange}
                  /> */}
                                <input type="text" maxLength="100" className={`form-control p-2 ${errors.medicineName ? "is-invalid" : ""}`} {...register('medicineName',{ required: true },{validate: (value) => { return !!value.trim()}})}
                                    onChange={() => setDataLoading(false)} />
                                {(errors.medicineName && errors.medicineName.type === 'required') ? <div className="invalid-feedback">This field is required</div> : ''}
                            </div>
                            {formValues.map((element, index) => (
                                <div className="form-inline" style={{ boxShadow: '0 4px 2px -4px grey', padding: '12px' }} key={index}>
                                    <label className="col form-label">Reference</label>
                                    {
                                        index ?
                                            <button onClick={() => removeFormFields(index)} style={{ padding: '5px', color: '#ffffff', backgroundColor: 'rgb(242 75 65)', borderRadius: '3px', margin: '0px 0px 10px 0px', float: 'right', width: '35px' }}> <i className="bi bi-dash-square"></i> </button>
                                            : null
                                    }
                                    <textarea className='form-control py-2 px-4' name="reference" value={element.reference || ""} onChange={e => handleChange(index, e)} rows='3' />
                                    <label className="col form-label">URL</label>
                                    <input type="text" className="form-control" name="url" value={element.url || ""} onChange={e => handleChange(index, e)} />
                                </div>
                            ))}
                            <div className="button-section mt-3">
                                <button onClick={() => addFormFields()} style={{ padding: '5px', color: '#ffffff', background: '#1f98df', borderRadius: '3px', width: '35px' }}> <i className="bi bi-plus-square"></i>  </button>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" id="closeMedic" onClick={() => clearForm()} className="btn btn-cancel text-white px-2 " data-bs-dismiss="modal">Cancel</button>
                        <button type="button" disabled={dataLoading ? true : false} className="btn btn-save text-white px-2" onClick={handleSubmit((onSubmit))} >Create</button>
                    </div>
                </div>
            </div>
        </div>
    );
}



export default CreateMedicineModal;