import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import "../../../../assets/styles/pipelinerequest/tabcontent/diagnosistab.scss";
import RecordingAnalysisService from "../../../../services/RecordingAnalysis";
import { BsPlusLg } from "react-icons/bs";
import CreatableSelect from "react-select/creatable";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';

export default function AssociateLifestyle() {
  const location = useLocation();
  const [active, setActive] = useState();
  const [val, setVal] = useState();
  const [isactive, setIsActive] = useState();
  const [dId, setDId] = useState([]);
  const [checkBoxes, setCheckBoxes] = useState([]);
  const [selectedBox, setSelectedBox] = useState([]);
  const [isMounted, setIsMounted] = useState(true);
  // const [hasData, setHasData] = useState(false);
  const [ndID, setNdID] = useState([]);
  const [unSelectBx, setUnSelectBx] = useState([]);
  const [unLifestyle, setUnlifestyle] = useState([]);
  const [selectedLifestyle, setSelectedLifestyle] = useState("");
  const [selectedCategory,setSelectedCategory]= useState("");
  const ra = new RecordingAnalysisService();
  //toaster
  const [msg, setMsg] = useState("");
  const notifyLife=()=>toast.success("Lifestyle updated successfully!")
  const notifyLifeErr = () => toast.error("Lifestyle couldn't be updated!");
  const notifyRemove=()=>toast.success("Lifestyle deleted successfully!");
  const notifyRemoveErr =()=>toast.error("Lifestyle couldn't be deleted");

  // //unselected checkbox
  // const options = checkBoxes.map((checkBoxes) => {
  //   return {
  //     value: checkBoxes.lifestyle_name,
  //     label: checkBoxes.lifestyle_name,
  //     id: checkBoxes.id,
  //     checked: checkBoxes.ischoices,
  //   };
  // });
  const lifestyleOption = unLifestyle.map((data, i) => {
    return {
      label: data.lifestyle_name,
      value: data.lifestyle_name,
      id: data.id,
    };
  });
  const handleCategory=(e)=>{
    setSelectedCategory(e.target.value);
    getLifestyleTab(e.target.value);
  }
  function getLifestyleTab(category) {
    let reqData = {
      service_request_id: location.state.reqId,
    };
    ra.GetMultiCheckBoxes(reqData).then((response) => {
      if (isMounted) {
        if (response.data.lifestyle !== []) {
          let lifestyle = [];
          let unlifestyle = [];
          setCheckBoxes(response.data.lifestyle);
          if(category==""){
          for (let i = 0; i < response.data.lifestyle.length; i++) {
            if (response.data.lifestyle[i].ischoices === true) {
              dId.push(response.data.lifestyle[i].id);
              lifestyle.push(response.data.lifestyle[i]);
            } else {
              ndID.push(response.data.lifestyle[i].id);
              unlifestyle.push(response.data.lifestyle[i]);
            }
          }
        }else{
          for(let i=0;i<response.data.lifestyle.length;i++){
            if(response.data.lifestyle[i].ls_category==category){
              // setCheckBoxes(response.data.lifestyle);
              if(response.data.lifestyle[i].ischoices===true){
                dId.push(response.data.lifestyle[i].id);
                lifestyle.push(response.data.lifestyle[i]);
              }else {
                ndID.push(response.data.lifestyle[i].id);
                unlifestyle.push(response.data.lifestyle[i]);
              }
            }
          }
        }
          setCheckBoxes(lifestyle);
          setUnlifestyle(unlifestyle);
        }
      }
    });
  }

  function saveLifestyle() {
    let reqData = {
      service_request: location.state.reqId,
      lifestyle_tps: dId.toString(),
      unlifestyle_tps: ndID.toString(),
    };
    ra.SaveLifeStyleTab(reqData).then((response) => {
      if (response.data.status === "success") {
        notifyLife();
        setSelectedCategory("");
        let category="";
        getLifestyleTab(category);
        handleCancel();
        // setMsg("Lifestyle updated successfully!");
        // setTimeout(() => {
        //   setMsg("");
        // }, 5000);
      }
    })
    .catch(function(err){
      notifyLifeErr();
    })
  }
  
  useEffect(() => {
    
    getLifestyleTab(selectedCategory);
    return () => {
      setIsMounted(false);
    };
  }, []);
  const handleLifestyleChange = (selectedLifestyle) => {
    const values = [...selectedLifestyle].map((o) => o.id);
    setSelectedLifestyle(selectedLifestyle);
    setDId(values);
    setNdID((current) =>
      current.filter((diag) => {
        if (diag.id !== values) return diag.id;
      })
    );
  };

  const removeLifestyle =(id)=>{
    let reqData = {
      service_request: location.state.reqId,
      lifestyle_tps: "",
      unlifestyle_tps: id.toString(),
    };
    ra.SaveLifeStyleTab(reqData).then((response) => {
      if (response.data.status === "success") {
        let category="";
        getLifestyleTab(category);
        notifyRemove();
        // setMsg("Lifestyle deleted successfully!");
        // setTimeout(() => {
        //   setMsg("");
        // }, 5000);
      }
    })
    .catch(function(error){
      notifyRemoveErr();
    })
  }
  const handleCancel = () =>{
    setSelectedLifestyle(null);
    setSelectedCategory("");
  }
 
  return (
    <div className="diagnosis-tab mt-3">
      {/* <ToastContainer/> */}
      <div className="row g-0 mb-2">
        {/* {msg ? (
          <div className="row align-items-center justify-content-center mb-2">
            <div className=" success-msg  " style={{ width: "60%" }}>
              {msg}
            </div>
          </div>
        ) : null} */}
        <h6 className="text-start col">Lifestyle Interventions</h6>
        <div className="ms-auto text-end col add-icon" data-bs-toggle="modal" data-bs-target="#addLifestyle" onClick={()=>setSelectedCategory("")}>
          {/* <button type="button" className="btn btn-primary" onClick={() => saveLifestyle()}>Save</button> */}
          <BsPlusLg />
        </div>
      </div>
      <Container className="p-0">
        <div className="checkbox-container mt-3 ps-1">
          <div className="row p-2" style={{ height: "auto" }}>
            {checkBoxes.map((tag, i) => {
              return (
                <div
                  key={i}
                  className="tag col-auto mx-2 my-1 ps-1 py-0"
                  style={{
                    whiteSpace: "no-wrap",
                     }}
                >
                  {tag.lifestyle_name}
                  <div
                    className="ms-auto col-1 p-1 remove-tag"
                    key={tag.id}
                    onClick={() => removeLifestyle(tag.id)}
                  >
                    <span>x</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Container>
      <div className="modal" id="addLifestyle" tabIndex="-1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Add Lifestyle</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                onClick={()=>handleCancel()}
              ></button>
            </div>
            <div className="modal-body p-4 text-start">
              <label className="form-label">Select Category</label>
              <select className="form-select form-control mb-3" onChange={(e)=>handleCategory(e)}>
                <option value=""></option>
                <option value="High Beta">High Beta</option>
                <option value="High Theta">High Theta</option>
                <option value="High Alpha">High Alpha</option>
                <option value="High delta/Overwhelm">High delta/Overwhelm</option>
              </select>
              <label className="form-label">Select LifeStyle</label>
              <Select
                isMulti
                value={selectedLifestyle}
                onChange={handleLifestyleChange}
                options={lifestyleOption}
              />
            </div>
            <div className="modal-footer ">
              <button
                type="button"
                id="closeDelDiag"
                className="btn btn-save text-white px-3"
                onClick={() => saveLifestyle()}
                data-bs-dismiss="modal"
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-cancel text-white px-3"
                data-bs-dismiss="modal"
                onClick={()=>handleCancel()}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
