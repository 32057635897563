import React, {useEffect, useState} from 'react';
import '../assets/styles/pipelinerequest/pipelinetabs/patientInfoCard.scss';
import MaleImage from '../assets/img/male-avatar-icon.jpeg';
import EEGgraph from '../assets/img/eeg-graph.png';
import BrainGraph from '../assets/img/brain-graph.png';



// function useEDF(file) {
//     const [chartData, setChartData] = useState(null);
//     const [data, setData] = useState([]);

//     useEffect(() => {
//       if (!file) return;
//       EDF.parse(file)
//         .then((data) => {
//           const channelsData = data.signals.map(signal => signal.samples);
//           const chartData = {
//             labels: Array.from({length: channelsData[0].length}, (_, i) => i),
//             datasets: channelsData.map((channel, i) => ({
//               label: `Channel ${i + 1}`,
//               data: channel,
//               fill: false,
//               borderColor: '#'+(Math.random()*0xFFFFFF<<0).toString(16),
//             }))
//           };
//           setChartData(chartData);
//         })
//         .catch((error) => {
//           console.error(error);
//         });
//     }, [file]);
  
//     return chartData;
//   }
export default function PatientGraph() {
    // const file = require(sample);
    // console.log("file",file);
    const [data, setData] = useState([]);
    const [pdfFile, setPdfFile] = useState(null);
    console.log("edfFile",pdfFile);
    // const [data, setData] = useState([]);

    // useEffect(() => {
    //     if(pdfFile!==null){
    //     const parser = new EdfParser();
    //     parser.parse(pdfFile, (err, data) => {
    //       if (err) throw err;
    //       setData(data.signals[0].samples);
    //     });
    // }
    // }, [pdfFile]);
    // console.log("Data",data);
//    const chartData=useEDF(pdfFile);
    // parse(file).then((data) => {
    //     console.log(data);
    //   }).catch((error) => {
    //     console.error(error)
    //   })
    // const fp = fs.readFileSync(file);


    return (
        <div className='row m-3 px-3'>
            <div className="col-md-8  ps-2 pe-3">
                <div className='bg-white rounded pt-4'>
                {/* {chartData && <Line data={chartData} />} */}
                    {/* <Line data={chartData}
                    options={{
                        responsive: true,
                        maintainAspectRatio: false,
                    }}/> */}
                    {/* <Line
                        data={chartData}
                        options={{
                            responsive: true,
                            maintainAspectRatio: false,
                            scales: {
                                y: {
                                    label: 'Amplitude'
                                },
                                x: {
                                    label: 'Time'
                                }
                            }
                        }}
                    /> */}
                    <img src={EEGgraph} alt="eeg-graph"></img>
                    <img src={BrainGraph} alt="brain graph"></img>
                </div>
            </div>
            
            <div className="col-md-4 ps-2 bg-white rounded" >
                <div className='bg-white rounded mt-3 p-2  mb-0' >
                    <label>Upload EDF file</label>
                    <div className=' form-group'>
                        <div className="drop-container dropzone">
                            <p className="mt-3">
                                <label className="upload-button ">
                                    <input type="file" className="mx-2" value={""} accept=".edf" onChange={e=>setPdfFile(e.target.files)}
                                    // onClick={encodeImageBase64(selectetdFile[0])}
                                    />  Drag a file here or browse to upload.
                                </label>

                            </p>

                        </div>
                    </div>
                    <hr />
                    {/* <div className='img-container mt-4'> */}
                    {/* <img src={MaleImage} alt="user-image" className='rounded-circle w-25 pt-2' /> */}

                    {/* </div> */}
                    <div className='row g-0 mt-4'>
                        <div className='name-content text-start col-auto me-5'>
                            <label>Patient Name</label>
                            <p >Galilio Thomas</p>
                            {/* <h6 className='mb-0 text-capitalize'>Galilio Thomas</h6>
                            <p className='mb-0'>Male- 12/07/1898</p> */}
                        </div>
                        <div className='col-auto text-start me-5'>
                            <label>Request ID</label>
                            <p >REQ000023</p>
                        </div>
                        <div className='col-auto text-start'>
                            <label>Account Name </label>
                            <p className='text-capitalize'>Wholeness Center</p>
                        </div>
                       
                    </div>

                </div>
            </div>
        </div>
    );
}