import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PatientAccountService from "../../services/Patient.js";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { format, parseISO } from "date-fns";
import InputMask from "react-input-mask";
import MasterDataService from "../../services/masterdata.js";
import { useHistory } from "react-router-dom";
import { Multiselect } from "multiselect-react-dropdown";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function PatientModal(props) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const history = useHistory();
  const [isDateEmpty, setIsDateEmpty] = useState(false);
  const pnt = new PatientAccountService();
  const [selectedOption, setSelectedOption] = useState();
  const dbSelect = props.data.accntType;
  const [accId, setAccId] = useState();
  const [isMounted, setIsMounted] = useState(true);
  const [diagnosisMounted, setDiagnosisMounted] = useState(true);
  const [symptomsMounted, setSymptomsMounted] = useState(true);
  //MULTISELECT DROPDOWN
  const [diagData, setDiagData] = useState([]);
  const [selectedDiagnosis, setSelectedDiagnosis] = useState('');
  const [diagnosisID, setDiagnosisID] = useState([]);
  const [sympData, setSympData] = useState([]);
  const [selectedSymptoms, setSelectedSymptoms] = useState('');
  const [symptomsID, setSymptomsID] = useState([]);

  //err indication
  const [accErr, setAccErr] = useState(false);
  const [accEmpty, setAccEmpty] = useState(false);
  const [dateChanged, setDateChanged] = useState(false);
  const [startDate, setStartDate] = useState(null);
  //loading
  const [dataLoading, setDataLoading] = useState(false);
  //API
  const msds = new MasterDataService();
  const patientnotify = () => toast.success("Patient created successfully!");
  const patientnotifyErr = (val) => toast.error(val);

  const options = dbSelect.map(function (dbSelect) {
    return {
      value: dbSelect.acct_name,
      label: dbSelect.acct_name,
      id: dbSelect.id,
    };
  });
  const handleDateChange = (date) => {
    setStartDate(format(new Date(date), 'MM/dd/yyyy'));
    setDataLoading(false);
    // setStartDate(date);
    // setStartDate(parseISO(new Date(date),'MM/dd/yyyy'))
    setDateChanged(true);
  };

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setAccId(selectedOption.id);
    setDataLoading(false);
    setAccErr(true);
  };

  // async function accountType() {
  //   pnt.GetAccountType().then((response) => {
  //     if (isMounted) {
  //       if (response.data.status === "success") {
  //         setDbSelect(response.data.data);
  //       }
  //     }
  //   });
  // }
  // useEffect(() => {
  //   accountType();
  //   return () => { setIsMounted(false) }
  // }, []);

  //SYMPTOMS MULTI INPUT
  function getSymptomsData() {
    msds.getSymptoms()
      .then((response) => {
        if (symptomsMounted) {
          if (response.data) {
            if (response.data.data !== []) {
              setSympData(response.data.data);
            }
          }

        }
      }).catch(function (error) {
        if (error.response.status === 403) {
          history.push('/login');
        }
      })
  }

  useEffect(() => {
    getSymptomsData();
    return () => { setSymptomsMounted(false); }
  }, []);

  const symptomsOption = sympData.map((data) => {
    return {
      label: data.symptoms_name,
      value: data.symptoms_name,
      id: data.id
    }
  });

  const handleSymptomsChange = (selectedSymptoms) => {
    setSelectedSymptoms(selectedSymptoms);
    const values = ([...selectedSymptoms].map(o => o.id));
    setSymptomsID(values);
  };

  //CALLBACK FUNCTION
  const msgDisplay = (val) => {
    props.data.messageCallback1(val);
  }

  const onSubmit = (data) => {
    let dob = format(new Date(startDate), 'yyyy-MM-dd');
    let fname = data.fname.trim();
    let lname = data.lname.trim();
    if (dateChanged === false) {
      setIsDateEmpty(true);
      setDataLoading(false);
    }
    else {
      setDataLoading(true);
    }
    if (accErr === false) {
      setAccEmpty(true);
      setDataLoading(false);
    }
    else {
      setDataLoading(true);
    }
    setDataLoading(true);
    let reqData = {
      patientid: 0,
      first_name: fname.toLowerCase(),
      last_name: lname.toLowerCase(),
      gender: data.gender,
      dob: dob,
      email: data.email || "",
      occupation: data.occupation,
      address: data.address,
      country: data.country,
      contact_number: data.phone,
      state: data.state,
      city: data.city,
      zip: data.zip,
      pnt_handedness:data.handedness,
      status: "Active",
      accountid: accId,
      diagnosis: diagnosisID.toString(),
      undiagnosis: "",
      symptoms: symptomsID.toString(),
      unsymptoms: "",
      userid: parseInt(localStorage.getItem('userid'), 10),
    };
    if (dateChanged === true && accErr === true && accId !== "") {
      pnt.CreatePatient(reqData)
        .then((response) => {
          if (response.data) {
            if (response.data.status === "success") {
              setDataLoading(false);
              msgDisplay(true);
              handleCancel();
              setAccId();
              hideModal('closePntModal');
              patientnotify();
              let page = 1;
              let sizePerPage = 10;
              props.data.fetchPatientDetails(page, sizePerPage);
            }
          }
        })
        .catch(function (error) {
          setDataLoading(false);
          // console.log(error.response.data);
          patientnotifyErr(error.response.data.data);

        })
    }
    else return;
  };

  function hideModal(target) {
    document.getElementById(target).click();
  };

  function handleCancel() {
    setSelectedOption("");
    setStartDate("");
    reset();
    setAccId('');
    setSelectedDiagnosis('');
    setSelectedSymptoms('');
    setDataLoading(false);
    reset({ phone: "" });
  }

  return (
    <div className="modal" id="patientModal" data-bs-backdrop="false" tabIndex="-1">
      <div className="modal-dialog shadow-lg" style={{ maxWidth: "800px" }}>
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Create Patient</h4>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              onClick={() => handleCancel()}
            ></button>
          </div>
          <form autoComplete="off">
            <div className="modal-body p-4">
              <div className="row text-start">
                <div className="col mb-3">
                  <label htmlFor="fname" className="form-label">
                    First Name  <span className='required-field'>*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control p-2 ${errors.fname ? "is-invalid" : ""
                      }`}
                    {...register("fname", { validate: (value) => { return !!value.trim() }, required: true })}
                  />
                  {errors.fname && errors.fname.type === "required" ? (
                    <div className="invalid-feedback">
                      This field is required
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col mb-3">
                  <label htmlFor="lname" className="form-label">
                    Last Name  <span className='required-field'>*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control p-2 ${errors.lname ? "is-invalid" : ""
                      }`}
                    {...register("lname", { validate: (value) => { return !!value.trim() }, required: true })}
                  />
                  {errors.lname && errors.lname.type === "required" ? (
                    <div className="invalid-feedback">
                      This field is required
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col mb-3">
                  <label htmlFor="gender" className="form-label">
                    Birth Gender  <span className='required-field'>*</span>
                  </label>
                  <select
                    className={`form-select p-2 ${errors.gender ? "is-invalid" : ""
                      }`}
                    {...register("gender", { required: true })}
                  >
                    <option value="">-Select-</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Others">Others</option>
                  </select>
                  {errors.gender && errors.gender.type === "required" ? (
                    <div className="invalid-feedback">
                      This field is required
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row text-start">
                <div className="col mb-3">
                  <label htmlFor="dob" className="form-label">
                    DOB <span className='required-field'>*</span>
                  </label>

                  <DatePicker
                    dateFormat="MM/dd/yyyy"
                    placeholderText="Select date"
                    name="startDate"
                    maxDate={new Date()}
                    value={startDate}
                    onChange={handleDateChange}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  />
                  {dateChanged === false && isDateEmpty === true ?
                    <div className="invalid-msg text-danger pt-1">
                      This field is required
                    </div>
                    : null}
                </div>

                <div className="col mb-3">
                  <label htmlFor="occupation" className="form-label">
                    Occupation
                  </label>
                  <select
                    className="form-select"
                    {...register("occupation", { required: false })}
                  >
                    <option value="">-Select-</option>
                    <option value="Physician">Physician</option>
                    <option value="Security">Security</option>
                    <option value="Business man">Business man</option>
                    <option value="Social Worker">Social Worker</option>
                    <option value="Engineer">Engineer </option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div className="col mb-3">
                  <label htmlFor="phone" className="form-label">
                    Contact No
                  </label>
                  <InputMask className={`form-control p-2 ${errors.phone ? "is-invalid" : ""
                    }`}
                    {...register("phone", { required: false })}
                    mask="(999) 999-9999" maskChar="" />
                </div>

              </div>
              <div className="row text-start">
                <div className="col mb-3">
                  <label htmlFor="email" className="form-label ms-1">Email</label>
                  <input type="email" className={`form-control p-2 ${errors.email ? "is-invalid" : ""}`}{...register('email', { required: false }, { pattern: /^\S+@\S+$/i })} />
                  {(errors.email && errors.email.type === 'pattern') ? <div className="invalid-feedback">Enter a valid email id</div> : ''}
                </div>
                <div className="col mb-3">
                  <label htmlFor="address" className="form-label">
                    Address
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("address", { required: false })}
                  />
                </div>
                <div className="col mb-3">
                  <label htmlFor="city" className="form-label">
                    City
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("city", { required: false })}
                  />
                </div>

              </div>
              <div className="row text-start">
                <div className="col mb-3">
                  <label htmlFor="state" className="form-label">
                    State
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("state", { required: false })}
                  />
                </div>
                <div className="col mb-3">
                  <label htmlFor="country" className="form-label">
                    Country
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("country", { required: false })}
                  />
                </div>
                <div className="col mb-3">
                  <label htmlFor="zip" className="form-label">
                    Zip
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    {...register("zip", { required: false })}
                  />
                </div>

              </div>
              <div className="row text-start">
                <div className="col-md-4 mb-3">
                  <label htmlFor="handedness" className="form-label">
                    Handedness
                  </label>
                  <select
                    className={`form-select p-2 ${errors.handedness ? "is-invalid" : ""
                      }`}
                    {...register("handedness", { required: false })}
                  >
                    <option value="">-Select-</option>
                    <option value="righthand">Right Hand</option>
                    <option value="lefthand">Left Hand</option>
                  </select>
                 
                </div>

                {/* <div className="col mb-3">
                  <label htmlFor="diagnosis" className="form-label">
                    Diagnosis
                  </label>
                  <Multiselect
                    selectedValues={selectedDiagnosis}
                    options={diagnosisOption}
                    displayValue="value"
                    closeIcon="cancel"
                    multiple={true}
                    onSelect={handleDiagnosisChange}
                  />
                </div> */}
                <div className="col-md-4 mb-3">
                  <label htmlFor="symptoms" className="form-label">
                    Symptoms
                  </label>
                  <Multiselect
                    selectedValues={selectedSymptoms}
                    options={symptomsOption}
                    displayValue="value"
                    closeIcon="cancel"
                    onSelect={handleSymptomsChange}
                  />
                </div>
                <div className="col-md-4 mb-3">
                  <label htmlFor="account" className="form-label">
                    Account  <span className='required-field'>*</span>
                  </label>
                  <Select
                    value={selectedOption}
                    onChange={handleChange}
                    options={options}
                  />
                  {accErr === false && accEmpty === true ? <div className='text-danger text-left'>This field is required</div> : ""}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                id="closePntModal"
                className="btn btn-cancel text-white px-2 py-1 "
                data-bs-dismiss="modal"
                onClick={() => handleCancel()}
              >
                Cancel
              </button>
              <button type="button" className="btn btn-save text-white px-2 py-1"
                onClick={handleSubmit((onSubmit))}
                disabled={dataLoading ? true : false}>
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default PatientModal;
