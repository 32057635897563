import { ax } from '../services/base';
import config from '../assets/config';

export default class AuthService {

login(payload) {
return ax.post(`${config.apiURL}auth/login`,payload)
}

dashboard(payload) {
    return ax.post(`${config.apiURL}account/dashboard`,payload)
}

account(payload){
    return ax.post(`${config.apiURL}account/saveAccount`,payload)
}
//reset password
resetPassword(payload){
    return ax.post(`${config.apiURL}auth/password_reset`,payload);
}
//change password
changePassword(payload){
    return ax.put(`${config.apiURL}auth/change-password`,payload);
}
//change password after login
// changePasswordAfterLogin(payload){
//     return ax.put(`${config.apiURL}auth/change-password`,payload);
// }
//verify token
VerifyToken(payload){
    return ax.post(`${config.apiURL}auth/reset-password/verify-token`,payload);
}
//confirm reset password
ConfirmResetPassword(payload){
    return ax.post(`${config.apiURL}auth/password_reset/confirm/`,payload);
}
//menu service
GetMenuService(payload){
    return ax.post(`${config.apiURL}auth/menuservice`,payload);
}
}