import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToHTML } from 'draft-convert';
import DOMPurify from 'dompurify';
import "../../../../assets/styles/pipelinerequest/tabcontent/analysisProcedure.scss";
import SummaryToolbar, { modules, formats } from './toolbar/SummaryToolbar';
import ReactQuill from "react-quill";
import { toast } from 'react-toastify';
import RecordingAnalysisService from "../../../../services/RecordingAnalysis";

export default function SummaryFindings() {
  const [text, setText] = useState({
    value: ` <p> * Impression-EC: Mildly elevated frontal high beta. EO: Mildly elevated frontal high beta. </p>
  <p> * Posterior Dominant Rhythm - 13.0. </p>
  <p> * Theta/beta ratio - Eyes Closed - Null. Eyes Opened - Null. </p>
  <p> * Alpha/beta ratio - Eyes Closed - Null. Eyes Opened - Null. </p>
  <p> * Relative power - EC: Mildly elevated frontal high beta. EO: Mildly elevated frontal high beta </p>
  <p> * Asymmetry present - Eyes Closed - NO . Eyes Opened - NO. </p>`});
  const rec = new RecordingAnalysisService();
  const notifyAp = () => toast.success("Summary of Findings updated successfully!");
  const notifyApErr = () => toast.error("Summary of Findings couldn't be updated!");
  const location = useLocation();
  const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty(),
  );
  const [descID, setDescID] = useState(0);


  const [convertedContent, setConvertedContent] = useState(null);
  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  }
  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
  }
  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html)
    }
  }
  const handleChange = (value) => {
    setText({ value });
  };

  function getSummaryofFindings() {

    rec.getSummaryofFindings(location.state.reqId).then((response) => {
      if (response.data) {
        setDescID(response.data.data.id);
        setText({ value: response.data.data.description });
      }

    })
  }
  useEffect(() => {
    getSummaryofFindings();
  }, []);

  const onSubmit = () => {
    let reqData = {
      "sr_sum_of_finding": 0,
      "service_request": location.state.reqId,
      "description": text.value,
    }
    rec.saveSummaryofFindings(reqData)
      .then((response) => {
        if (response.data) {
          if (response.data.status === 'success') {
            // fetchAnalysisProcedure();
            // props.data.exportPreview();
            hideModal('close41');
            notifyAp();
            getSummaryofFindings();

            // msgDisplay(false);
          }
        }
      })
      .catch(function (error) {
        notifyApErr();
      })
  }
  function hideModal(target) {
    document.getElementById(target).click();

  };
  // const newState = createMarkup(convertedContent);
  return (
    <div className="analysis-procedure rich-editor">
      <div className="row g-0 p-2">
        <h6 className="col-auto">Summary of Findings: </h6>
        <div className="col-auto ms-auto icon-btn">
          <i
            className="bi bi-pencil"
            data-bs-toggle="modal"
            data-bs-target="#editSummary"
          ></i>
        </div>
      </div>
      <div className="summary-finding p-2 preview text-start fw-bold" dangerouslySetInnerHTML={createMarkup(text.value)}></div>
      {/* <div className="summary-finding p-2 preview">
        <p> * Impression-EC: Mildly elevated frontal high beta. EO: Mildly elevated frontal high beta. </p>
        <p> * Posterior Dominant Rhythm - 13.0. </p>
        <p> * Theta/beta ratio - Eyes Closed - Null. Eyes Opened - Null. </p>
        <p> * Alpha/beta ratio - Eyes Closed - Null. Eyes Opened - Null. </p>
        <p> * Relative power - EC: Mildly elevated frontal high beta. EO: Mildly elevated frontal high beta </p>
        <p> * Asymmetry present - Eyes Closed - NO . Eyes Opened - NO. </p>

      </div> */}
      <div className="modal" id="editSummary" tabIndex="-1">
        <div className="modal-dialog" style={{ maxWidth: "900px" }}>
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Edit Template</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            <div className="modal-body p-4 text-start">
              {/* <Toolbar/> */}
              <SummaryToolbar />
              <ReactQuill
                // theme="snow"
                value={text.value}
                modules={modules}
                formats={formats}
                onChange={handleChange}
              // onChange={this.handleProcedureContentChange}
              >
                {/* <div className="my-editing-area" /> */}
              </ReactQuill>
            </div>

            <div className="modal-footer ">
              <button
                type="button"
                className="btn btn-cancel text-white px-4"
                data-bs-dismiss="modal"
                id="close41"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={() => onSubmit()}
                className="btn  btn-save text-white px-4"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}
