import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { columns, options } from './data/DiagnosisData';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import '../../assets/styles/masterData/diagnosis.scss';
import { newDiagnosis } from "../ToolTip/ToolTip";
import { useHistory } from "react-router-dom";
import LoadingIcon from '../../assets/img/sample-icons/sandbox.gif';
// MODAL BOX
import AddDiagnosisModal from './modal/diagnosis/AddDiagnosisModal';
import DeleteDiagnosisModal from './modal/diagnosis/DeleteDiagnosisModal';
// API
import MasterDataService from "../../services/masterdata";
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const NoDataIndication = () => (
  <div className='spinner d-flex justify-content-center text-center h-100'>
    <img src={LoadingIcon} alt="loading-icon" width="200" height="200"/>
    {/* <ScaleLoader size={60} color="#79a3bb" style={{ marginTop: '50px' }} /> */}
  </div>
);

function Diagnosis() {
  const history = useHistory();
  const { SearchBar } = Search;
  const [diagnosisData, setDiagnosisData] = useState([]);
  const [selctedrowID, setSelectedID] = useState();
  const [selctedrowData, setSelectedData] = useState([]);
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [errmsg, setErrorMsg] = useState(false);
  const [isMounted, setIsMounted] = useState(true);
  const [tdataLoaded, setTdataLoaded] = useState(false);
  //CALLBACK VARIABLES
  const [isFlagSet, setIsFlagSet] = useState(false);
  const [isFlagSet1, setIsFlagSet1] = useState(false);
  const msds = new MasterDataService();
  const diagupnotify = () => toast.success("Diagnosis updated successfully!");
  const diagupnotifyErr = () => toast.error("Diagnosis couldn't be updated!");
  const[showErr,setShowErr] = useState([]);

  //CALLBACK FUNCTION FOR CREATE SUPPLEMENT
  function messageCallback(item) {
    setIsFlagSet(item);
  }

  //CALLBACK FUNCTION FOR DELETE SUPPLEMENT
  function messageCallback1(item) {
    setIsFlagSet1(item);
  }

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      setSelectedID(row.id);
      setSelectedData(row);
    },
  };

  const onSubmit = (data) => {
    let reqData = {
      "mddiagnosisid": selctedrowID,
      "diagnosis_name": data.diagnosisName,
      "diagnosis_hint": data.hint
    }
    msds.createDiagnosis(reqData)
      .then((response) => {
        if (response.data) {
          if (response.data.status === 'success') {
            hideModal('closeDiagUpModal');
            getDiagnosisData();
            diagupnotify();
          }
        }
      })
      .catch(function (error) {
        setErrorMsg(true);
        // console.log(error.response.data);
        setShowErr(error.response.data.diagnosis_name);
      })
  }

  function hideModal(target) {
    document.getElementById(target).click();
    reset();
  };

  function getDiagnosisData() {
    msds.getDiagnosis()
      .then((response) => {
        if (isMounted) {
          if (response.data.status=="success") {
            setTdataLoaded(true);
            setDiagnosisData(response.data.data);
          }
          else {
            setTdataLoaded(false);
          }
        }
      }).catch(function (error) {
        if (error.response.status === 403) {
          history.push('/login');
        }
      })
  }
  useEffect(() => {
    getDiagnosisData();
    return () => { setIsMounted(false); }
  }, []);

  useEffect(() => {
    reset(selctedrowData);
  }, [selctedrowData]);

  return (
    <div className='col diagnosis'>
     
      <div className='row g-0 ps-3 pt-3'>
       
        <div className='table-request my-2 me-3'>
          <AddDiagnosisModal data={{ isFlagSet: isFlagSet, messageCallback: messageCallback, getDiagnosisData: getDiagnosisData }} />
          <DeleteDiagnosisModal data={{ id: selctedrowID, isFlagSet1: isFlagSet1, messageCallback1: messageCallback1, getDiagnosisData: getDiagnosisData }} />

          {/* UPDATE DIAGNOSIS START */}
          <div className="modal" id="updateDiagnosis" data-bs-backdrop="false"
            tabIndex="-1" >
            <div className="modal-dialog shadow-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Update Diagnosis</h4>
                  <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <form onSubmit={handleSubmit(onSubmit)} >
                  <div className="modal-body p-4">
                    {errmsg ? <div className="err-msg">Diagnosis name already exist</div> : ''}
                    <div className='row text-start'>
                      <div className="mb-3">
                        <label htmlFor="diagnosisName" className="form-label">Diagnosis Name
                          <span className='required-field'>*</span></label>
                        <input type="text"
                          defaultValue={selctedrowData.diagnosis_name}
                          onChange={(e) => setDiagnosisData(e.target.value)}
                          maxLength="100"
                          className={`form-control p-2 ${errors.diagnosisName ? "is-invalid" : ""
                            }`}
                          {...register(
                            "diagnosisName",
                            { required: true },{validate: (value) => { return !!value.trim()}}
                          )} />
                        {(errors.diagnosisName && errors.diagnosisName.type === 'required') ? <div className="invalid-feedback">This field is required</div> : ''}
                      </div>
                      <div className="mb-3">
                        <label htmlFor="hint" className="form-label">Hint</label>
                        <input type="text" 
                          defaultValue={selctedrowData.diagnosis_hint}
                          onChange={(e) => setDiagnosisData(e.target.value)}
                          className={`form-control p-2 ${errors.hint ? "is-invalid" : ""
                            }`}
                          {...register(
                            "hint",
                            { required: false },{validate: (value) => { return !!value.trim()}}
                          )} />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer ">
                    <button type="button" id="closeDiagUpModal" className="btn btn-cancel text-white px-2 " data-bs-dismiss="modal">Cancel</button>
                    <button type="submit" className="btn btn-save text-white px-2">Update </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/* UPDATE DIAGNOSIS END */}

          <div className='table-data me-4'>
            <div>{newDiagnosis}</div>
            <ToolkitProvider
              keyField='id'
              data={diagnosisData}
              columns={columns}
              search>
              {
                props => (
                  <div className="pe-3">
                    <div className='row mb-2'>
                      <div className='col text-start ms-1'>
                        <h5>Diagnosis</h5>
                      </div>
                      <div className='col text-end'>
                        <button type='button' className='btn-create btn px-3 py-1 ms-3 me-2'
                          data-bs-toggle="modal" data-bs-target="#createDiagnosis" 
                          // data-tip data-for="newDiagnosisTip"
                          >
                          <i className="bi bi-person-plus-fill"></i>Add</button>
                        <i className="bi bi-search ms-1 ps-2 mb-2 " id='search-icon'></i>
                        <SearchBar placeholder=" Search Diagnosis "
                          {...props.searchProps} />
                      </div>
                    </div>
                    <BootstrapTable
                      {...props.baseProps}
                      pagination={paginationFactory(options)}
                      rowEvents={rowEvents}
                      wrapperClasses="table-responsive"
                      noDataIndication={() => { return (tdataLoaded ? "No data available" : <NoDataIndication />) }} />
                  </div>
                )
              }
            </ToolkitProvider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Diagnosis;