import { ax } from '../services/base';
import config from '../assets/config';

export default class AdminAccountService {

getAllAccountDetails(payload) {
    return ax.post(`${config.apiURL}account/getAccount`,payload)
}
//account graph
getAccountGraph(){
    return ax.get(`${config.apiURL}account/dashboardgraph`);
}

CreateAccount(payload)
{
    return ax.post(`${config.apiURL}account/saveAccount`,payload)
}

CreateUser(payload)
{
    return ax.post(`${config.apiURL}account/saveUser`,payload)
}
getAccountDetail(AccId){
    return ax.get(`${config.apiURL}account/getAccount/${AccId}`)
}
getUserDetail(userId){
    return ax.get(`${config.apiURL}account/getUser/${userId}`)
}
getAllUserDetail(payload){
    return ax.post(`${config.apiURL}account/getUser`,payload);
}
//patient public form
VerifyPatientForm(payload){
    return ax.post(`${config.apiURL}account/getpublicaccount`,payload);
}
//add patient form
SavePatientForm(payload){
    return ax.post(`${config.apiURL}patient/addpublicpatient`,payload);
}
//patient/publicpatient_intakeform
SavePatientIntakeForm(payload){
    return ax.post(`${config.apiURL}patient/publicpatient_intakeform`,payload);
}
}