import React, {
  useState, useEffect
} from 'react';
import Stepper from 'react-stepper-horizontal';
import { useLocation, useHistory } from 'react-router-dom';
import AssociatedDocument from '../tabcontent/AssociatedDocument';
import RecordingAnalysis from '../tabcontent/RecordingAnalysis';
import PipelineRequestService from '../../../services/PipelineRequest';
import '../../../assets/styles/pipelinerequest/multistepwizard.scss';
import RequestInit from '../pipelineTabs/RequestInit';
import PatientInfoCard from '../pipelineTabs/PatientInfoCard';
import Template from '../pipelineTabs/Template';
import InterpretationUpdate from '../pipelineTabs/InterpretationUpdate';
import RecordingAnalysisService from '../../../services/RecordingAnalysis';
import EdfProcessing from '../pipelineTabs/EdfProcessing';
// import EdfProcessing from '../pipelineTabs/EdfProcessing';


const MultiStepForm = () => {
  const location = useLocation();
  const history = useHistory();
  const [isVisibleNext, setIsVisibleNext] = useState(false);
  const [requestData, setRequestData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [wizardActive, setWizardActive] = useState([]);
  const [asdoc, setAsdoc] = useState(false);
  const [recAnalysis, setRecAnalysis] = useState(false);
  const [reqInit, setReqInit] = useState(true);
  const [interpret, setInterpret] = useState(false);
  const [isMounted, setIsMounted] = useState(true);
  const [isMounted1, setIsMounted1] = useState(true);
  const [appRec, setAppRec] = useState(false);
  //INTERPRETATION VARIABLES
  const [intData, setIntData] = useState([]);
  //PATIENTCARD VARIABLES
  const [reqData, setReqData] = useState([]);
  const [dsMounted, setDsmounted] = useState(true);
  const [diagnosisID,setDiagnosisID] = useState([]);
  const [unDiagnosisID, setUnDiagnosisID] = useState([]);
  const [diagnosis,setDiagnosis] = useState([]);
  const [unDiagnosis,setUnDiagnosis] = useState([]);


  //CALLBACK VARIABLE DECLARATION
  const [isFlagSet, setIsFlagSet] = useState(false);
  const [skip, setSkip] = useState(false);
  //API
  const ds = new PipelineRequestService();
  const ra = new RecordingAnalysisService();


  function getWizardActive() {
    let reqId = location.state.reqId;
    ds.GetWizardActiveStep(reqId).then((response) => {
      if (isMounted) {
        if (response.data) {
          if (response.data.status === "success") {
            if (response.data.data !== null) {
              setAsdoc(response.data.data.associated_documents);
              setWizardActive(response.data.data);
              setReqInit(response.data.data.request_initation);
              setInterpret(response.data.data.Interpretation);
              setRecAnalysis(response.data.data.recording_analysis);
            }
            else return;
          }
        }
      }
    })

  }
  useEffect(() => {
    let isMounted = true;
    const fetchInterpretationData = async () => {
      let reqData =
      {
        "sr_interpretation": location.state.reqId
      }
      ds.GetInterPretation(reqData).then((response) => {
        if (isMounted) {
          if (response.data.status === "success") {
            if (response.data.data && response.data.data !== null && response.data.data !== "") {
              setIsVisibleNext(true);
            }
          }
        }
      })
    }
    fetchInterpretationData();
    return () => { isMounted = false }
  }, []);


  function fetchDataset() {
    let reqId = location.state.reqId;
    ds.GetPipelineDataset(reqId).then((response) => {
      if (isMounted1) {
        if (response.data.status === "success") {
          setRequestData(response.data.data[0]);
        }
      }
    })
      .catch(function (error) {
        if (error.response.status === 403) {
          history.push('/login');
        }
      })
  }

  useEffect(() => {
    getWizardActive();
    return () => { setIsMounted(false) }
  }, []);
  useEffect(() => {
    fetchDataset();
    return () => { setIsMounted1(false) }
  }, []);

  const sections = [
    {
      title: 'Request Information', onClick: () => {
        setCurrentPage(1);
        setReqInit(true);
      }
    },
    {
      title: 'Associated Document', onClick: () => {
        setCurrentPage(2);
        InterpretationChoiceField();
        setAsdoc(true);
      }
    },
    {
      title:"EDF Processing", onClick: () => {
        setCurrentPage(3);
      }
    },
    {
      title: 'Interpretation', onClick: () => {
        setCurrentPage(4);
        setInterpret(true);
      }
    },
    {
      title: 'Recording Analysis', onClick: () => {
        setCurrentPage(5);
        setRecAnalysis(true);
      }
    },
    {
      title: 'Approve Request', onClick: () => {
        setCurrentPage(6);
        setAppRec(true);
      }
    },
  ];


  const next = () => {
    setCurrentPage((prev) => prev + 1);
    if (currentPage == 2) {
      InterpretationChoiceField();
    }
    onSubmit();
  };
  const skipNext = () => {
    setCurrentPage((prev) => prev + 1);
    setSkip(true);
    onSubmit();
  };
  const prev = () => {
    setCurrentPage((prev) => prev - 1);
  };
  function onSubmit() {
    if (currentPage === 1) {
      setAsdoc(true);
    }
    else if (currentPage === 2) {
      setInterpret(true);
    }
    else if (currentPage === 3) {
      setRecAnalysis(true);
    }
    else if (currentPage === 4) {
      setAppRec(true);
    }

    let reqData = {
      "servicerequest_id": location.state.reqId,
      "request_initation": reqInit,
      "associated_documents": asdoc,
      "interpretation": interpret,
      "recording_analysis": recAnalysis,
      "approve_request": appRec,
      "userid": localStorage.getItem('userid')
    }
    ds.SaveActiveStep(reqData).then((response) => {
      if (response.data.status === "success") {
      }
    });
  }

  //GET INTERPRETATION CHOICEFIELD
  async function InterpretationChoiceField() {
    ds.GetInterpretationChoiceField().then((response) => {
      if (response.data.status === "success") {
        setIntData(response.data.data);
      }
    });
  }
  //PATIENT INFO CARD FUNCTIONS
  function fetchDataset() {
    let reqId = location.state.reqId;
    ds.GetPipelineDataset(reqId).then((response) => {

      if (response.data.status === "success") {
        setReqData(response.data.data[0]);

      }
    })
      .catch(function (error) {
        // if (error.response.status === 403) {
        //     history.push('/login');
        // }
      })
  }

  useEffect(() => {
    let mounted = true;
    fetchDataset();
    return () => { mounted = false }
  }, []);

  function getDiagnosis() {
    let reqData = {
        service_request_id: location.state.reqId,
    };
    ra.GetMultiCheckBoxes(reqData).then((response) => {
        if (dsMounted) {
            if (response.data.diagnosis !== []) {
                let diag = [];
                let undiag = [];
                // setCheckBoxes(response.data.diagnosis);
                for (let i = 0; i < response.data.diagnosis.length; i++) {
                    if (response.data.diagnosis[i].ischoices === true) {
                      diagnosisID.push(response.data.diagnosis[i].id);
                      diag.push(response.data.diagnosis[i]);
                  }
                  else {
                      unDiagnosisID.push(response.data.diagnosis[i].id);
                      undiag.push(response.data.diagnosis[i]);
                  }
              }
              setDiagnosisID(diagnosisID);
              setUnDiagnosisID(unDiagnosisID);
              setDiagnosis(diag);
              setUnDiagnosis(undiag);
            }
        }
    });

}
useEffect(() => {
    getDiagnosis();
    return () => { setDsmounted(false); }
}, []);
  //CALLBACK FUNCTION
  // function test(item) {
  //   setIsFlagSet(item);
  // }
  // function skipForm(item1) {
  //   setSkip(item1);
  // }

  function released() {
    const reqData = {
      "ServiceRequestid": location.state.reqId,
    }
    ds.Released(reqData).then((response) => {
      if (response.data.status === "success") {
        hideModal('closeReleasedModal');
        history.push("/view-request");
      }
    })
  }
  function hideModal(target) {
    document.getElementById(target).click();
  };

  return (
    <div className="col pipeline">
      <div className="row g-0 ps-3 pt-4 me-5">
        <div className="heading text-start mb-2">
          <h5>Service Requests in Pipeline</h5>
        </div>
        {/* <div className="row mt-3"> */}
        <div className="tabs">
          <div>
            {/* <form onSubmit={handleSubmit}> */}
            <div className='step-counter mb-0'>
              {recAnalysis === true ?
                <Stepper
                  steps={sections}
                  activeStep={4}
                  // disabledSteps={ [3] }
                  activeColor="red"
                  defaultBarColor="red"
                  completeColor="green"
                  completeBarColor="green"

                /> :
                interpret === true ?
                  <Stepper
                    steps={sections}
                    activeStep={3}
                    // disabledSteps={ [3] }
                    activeColor="red"
                    defaultBarColor="red"
                    completeColor="green"
                    completeBarColor="green"

                  /> :

                  asdoc === true ?
                    <Stepper
                      steps={sections}
                      activeStep={2}
                      // disabledSteps={ [3] }
                      activeColor="red"
                      defaultBarColor="red"
                      completeColor="green"
                      completeBarColor="green"

                    /> :


                    <Stepper
                      steps={sections}
                      activeStep={1}
                      // disabledSteps={ [3] }
                      activeColor="red"
                      defaultBarColor="red"
                      completeColor="green"
                      completeBarColor="green"

                    />
              }

            </div>

            {currentPage === 1 && (
              <>
                <div className='row g-0'>
                  <div className=" pe-3 pt-3 pb-0 col-md-8 ">
                    <RequestInit />

                  </div>

                  <div className=' user-information col-md-4 mb-3'>
                    <PatientInfoCard data={{reqData:reqData,diagnosisID:diagnosisID,diagnosis:diagnosis,unDiagnosisID:unDiagnosisID,unDiagnosis:unDiagnosis,getDiagnosis:getDiagnosis}}/>
                  </div>
                </div>
                <div className='text-end btn-bg col-md-8 pe-3 my-3' >
                  <button className='btn btn-next px-4' onClick={next}>Next</button>
                </div>
                {/* <RequestInitation /> */}

              </>
            )}

            {currentPage === 2 && (

              <>
                <div className='row g-0' >
                  <div className=" pe-3 pt-3 pb-0 col-md-8 ">
                    <AssociatedDocument />

                  </div>
                  <div className=' user-information mb-3 col-md-4'>
                    <PatientInfoCard data={{reqData:reqData}}/>

                  </div>
                </div>
                <div className='btn-bg d-flex justify-content-between col-md-8 pe-3 my-3'>
                  <button className='btn btn-back px-4' onClick={prev}>Back</button>
                  <button className='btn btn-next px-4' onClick={next}>Next</button>
                </div>

                {/* <AssociatedDocument />
                <div className='btn-bg d-flex justify-content-between'>
                  <button className='btn btn-back px-4' onClick={prev}>Back</button>
                  <button className='btn btn-next px-4' onClick={next}>Next</button>
                </div> */}
              </>
            )}
             {currentPage === 3 && (
              <>

                <div className='row g-0'>
                  <div className=" pe-3 pt-3 pb-0  col-md-8 ">

                    <EdfProcessing />
                  </div>

                  <div className=' user-information mb-3 col-md-4'>
                    <PatientInfoCard data={{reqData:reqData}}/>
                  </div>
                </div>
                <div className='btn-bg d-flex justify-content-between col-md-8 pe-3 my-3'>
                  <button className='btn btn-back px-4' onClick={prev}>Back</button>
                  <button className='btn btn-next px-4' onClick={next}>Next</button>
                </div>
              </>
            )}

            {currentPage === 4 && (
              <>

                <div className='row g-0'>
                  <div className=" pe-3 pt-3 pb-0  col-md-8 ">

                    <InterpretationUpdate data={{ intData: intData }} />
                  </div>

                  <div className=' user-information mb-3 col-md-4'>
                    <PatientInfoCard data={{reqData:reqData}}/>
                  </div>
                </div>
                <div className='btn-bg d-flex justify-content-between col-md-8 pe-3 my-3'>
                  <button className='btn btn-back px-4' onClick={prev}>Back</button>
                  <button className='btn btn-next px-4' onClick={next}>Next</button>
                </div>
              </>
            )}
            {currentPage === 5 && (
              <>
                {/* <div className='row g-0'> */}
                <div className=" pe-0 pt-3 pb-0 mb-3 col-md-12">
                  <RecordingAnalysis data={{ prev: prev, next: next }} />
                </div>

              </>
            )}
            {currentPage === 6 && (
              <>
                <div className='row g-0 ' >
                  <div className=" pe-3 pt-3 col-md-8 ">
                    <Template />

                  </div>
                  <div className=' user-information col-md-4 pb-3' >

                    <PatientInfoCard data={{reqData:reqData}}/>

                  </div>
                </div>
                <div className='btn-bg d-flex justify-content-between col-md-8 pe-3 my-3'>
                  <button className='btn btn-back px-4' onClick={prev}>Back</button>
                  {/* <button className='btn btn-next px-4' data-bs-toggle="modal" data-bs-target="#releasedPatient">Released</button> */}
                </div>

              </>
            )}

          </div>
        </div>

        {/* RELEASED MODAL STARTS HERE */}
        <div className="modal" id="releasedPatient"
          tabIndex="-1" >
          <div className="modal-dialog">
            <div className="modal-content">

              <div className="modal-header">
                <h4 className="modal-title">Confirm Release</h4>
                <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
              </div>


              <div className="modal-body p-4 text-start">
                <h6>Are you sure you want to release?</h6>
              </div>

              <div className="modal-footer ">
                <button type="button" id="closeReleasedModal" className="btn btn-save text-white px-4" data-bs-dismiss="modal">No</button>
                <button type="button" className="btn btn-cancel text-white px-4" onClick={released}>Yes</button>
              </div>

            </div>
          </div>
        </div>
        {/* RELEASED ENDS HERE */}
        {/* <div className="user-information col w-25 ps-4 pe-5 me-0">
          <div className="user bg-white p-3">
            <div className="info align-items-center">
              {requestData.gender === "Female" ? <img
                className="rounded-circle w-25 mb-2"
                src={femaleImage}
                alt="Jone Martin"
              /> : <img
                className="rounded-circle w-25 mb-2"
                src={maleImage}
                alt="Jone Martin"
              />}
              <div className="user-info">
                <h6 className="mb-0 text-capitalize">{requestData.patient_name}</h6>
                <p>{requestData.gender}</p>
                <hr
                  className="mt-1"
                  style={{ height: "1px", color: "#838383" }}
                />
              </div>
            </div>
            <div className="other-info text-start">
              <div className="basic-info mb-3">
                <label>Request ID</label>
                <p>{requestData.RequestNumber}</p>
              </div>
              <div className="basic-info mb-3">
                <label>Date of Birth</label>
                <p>{requestData.dob}</p>
              </div>
              <div className="basic-info mb-3">
                <label>Gender</label>
                <p>{requestData.gender}</p>
              </div>
              <div className="basic-info mb-3">
                <label>Account Name</label>
                <p>{requestData.account_name}</p>
              </div>
              <hr style={{ marginTop: "1px solid lightgray" }} />
              <div className='color-palette p-3'>
                <div className='row mb-1'>
                  <div className='col w-25 h-25 m-1 rounded px-2 py-5 top-left'></div>
                  <div className='col w-25 h-25 m-1 px-2 py-5  rounded top-right'></div>
                </div>
                <div className='row'>
                  <div className='col w-25 h-25 px-2 py-5 m-1 rounded bottom-left'></div>
                  <div className='col w-25 h-25 m-1 px-2 py-5 rounded bottom-right'></div>
                </div>
              </div>
            </div>

          </div>
        </div> */}
      </div>
    </div>

  );
}
export default MultiStepForm;