import { ax } from '../services/base';
import config from '../assets/config';

export default class PipelineRequestService {

    GetWizardActiveStep(reqId){
        return ax.get(`${config.apiURL}service_request/get_wizard_pipelinestage/${reqId}`);
    }
    SaveActiveStep(payload){
        return ax.post(`${config.apiURL}service_request/save_wizard_pipelinestage`,payload);
    }
    GetPipelineRequest(payload){
        return ax.post(`${config.apiURL}service_request/getServicerequestpip`,payload);
    }
    GetPipelineDataset(reqId){
        return ax.get(`${config.apiURL}service_request/getdataServicerequest/${reqId}`);
    }
    SaveClinicalHistoryData(payload){
        return ax.post(`${config.apiURL}service_request/savedataset`,payload);
    }
    GetClinicalHistoryData(reqId){
        return ax.get(`${config.apiURL}service_request/getdataset/${reqId}`);
    }
    SendEmailToReq(payload){
        return ax.post(`${config.apiURL}docrepo/mail-consult`,payload);
    }
    UploadConsentDocument(formData){
        return ax.post(`${config.apiURL}docrepo/consentdocupload`,formData);
    }
    PreviewConsentDocument(payload){
        return ax.post(`${config.apiURL}docrepo/consentdocdownload`,payload);
    }
    //Interpretation
    AddInterpretation(payload){
        return ax.post(`${config.apiURL}service_request/add_interpretation`,payload);
    }
    GetInterPretation(payload){
        return ax.post(`${config.apiURL}service_request/get_interpretation`,payload);
    }
    GetInterpretationChoiceField(){
        return ax.get(`${config.apiURL}service_request/get_interpretation_choicefield`);
    }
    GetDocChoiceField(reqId){
        return ax.get(`${config.apiURL}docrepo/doclist/${reqId}`);
    }
    DownloadDoc(payload){
        return ax.post(`${config.apiURL}docrepo/docdownload`,payload);
    }
   
    
    // Analysis Procedure 
    getAnalysisProcedure(reqId)
    {
        return ax.get(`${config.apiURL}service_request/get_associate_analysisprocedures/${reqId}`);
    }
    saveAnalysisProcedure(payload)
    {
        return ax.post(`${config.apiURL}service_request/add_associate_analysisprocedures`,payload);
    }

    //Associate Interpretation

    getAssociatedInterpretation(reqId)
    {
        return ax.get(`${config.apiURL}service_request/get_associate_Interpretation/${reqId}`);
    }
    saveAssociatedInterpretation(payload)
    {
        return ax.post(`${config.apiURL}service_request/add_associate_Interpretation`,payload);
    }

    //Associate Interpretation

    getAssociatedNeuroFeedback(reqId)
    {
        return ax.get(`${config.apiURL}service_request/get_neurofeedback/${reqId}`);
    }
    saveAssociatedNeuroFeedback(payload)
    {
        return ax.post(`${config.apiURL}service_request/add_neurofeedback`,payload);
    }

    //Associate Interpretation
    getAdjunctTherapy(reqId)
    {
        return ax.get(`${config.apiURL}service_request/get_adjuncttherapies/${reqId}`);
    }
    saveAdjunctTherapy(payload)
    {
        return ax.post(`${config.apiURL}service_request/add_adjuncttherapies`,payload);
    }

    Released (payload)
    {
        return ax.post(`${config.apiURL}service_request/savestatusdataset`,payload);
    }

    //interpretation excel data
    ExcelList(payload){
        return ax.post(`${config.apiURL}docrepo/xlsheetlist`,payload);
    }
    ExcelData(payload){
        return ax.post(`${config.apiURL}docrepo/xlsheetdata`,payload);
    }

    //edf processing
    getEDFdata(payload){
        return ax.post(`${config.apiURL}automate/get_raw_graph`,payload);
    }
    saveEdfConfig(payload){
        return ax.post(`${config.apiURL}md_management/add-jobconfig`,payload);
    }
    //md_management/jobconfig_mdata
    EdfJobConfig(payload){
        return ax.post(`${config.apiURL}md_management/jobconfig_mdata`,payload);
    }

    getEdfConfig(configID){
        return ax.get(`${config.apiURL}md_management/jobconfig_mdata/${configID}`);
    }
    GetDoclistEdf(reqId) {
        return ax.get(`${config.apiURL}docrepo/doclist_edf/${reqId}`); 
    }
    //automate/add_edf_job
    AddEdfJobdata(payload){
        return ax.post(`${config.apiURL}automate/add_edf_job`,payload);
    }
    //automate/get_edf_job
    GetEdfJobdata(payload){
        return ax.post(`${config.apiURL}automate/get_edf_job`,payload);
    }
    //automate/get_edf_job/1002
    GetEdfJob(reqId) {
        return ax.get(`${config.apiURL}automate/get_edf_job/${reqId}`); 
    }

    // docrepo/doclist_edf
    getDoclist(payload){
        return ax.post(`${config.apiURL}docrepo/doclist_edf`,payload);
    }
}