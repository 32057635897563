import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form";
import '../../../assets/styles/pipelinerequest/pipelinetabs/requestInit.scss';
import "react-quill/dist/quill.snow.css";
import ClinicalToolbar, { modulesclinic, formatsclinic } from './textEditorToolbar/clinicalHisToolbar';
import PipelineRequestService from '../../../services/PipelineRequest';
import { BeatLoader, ClipLoader } from "react-spinners";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Row } from 'react-bootstrap';
import { AiFillCloseCircle } from 'react-icons/ai';
import { MdErrorOutline } from 'react-icons/md';

export default function RequestInit(props) {
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const location = useLocation();
    const [clinicalHistory, setClinicalHistory] = useState({ value: "" });
    const [objectiveAssesment, setObjectiveAssesment] = useState({ value: "" });
    const [isMounted, setIsMounted] = useState(true);
    const [msg, setMsg] = useState('');
    const [errmsg, seterrMsg] = useState('');
    const [reqdData, setReqdData] = useState([]);
    const [emailId, setEmailId] = useState();
    const [loading, setLoading] = useState(false);
    const [fileSize, setFileSize] = useState(false);
    const [isPdf, setIspdf] = useState(false);
    const [displayDoc, setDisplayDoc] = useState();
    const [hasConsent, setHasConsent] = useState([]);
    const [isMounted1, setIsMounted1] = useState(true);
    const [isMounted2, setIsMounted2] = useState(true);
    const [showConsent, setShowConsent] = useState(false);
    const [isEmpty,setIsEmpty] = useState(true);
    const [isEmpty1,setIsEmpty1] = useState(true);
    const [validMail, setValidMail] = useState(true);
    const notify = () => toast.success("Updated successfully!");
    const notify1 = () => toast.error("Couldn't be updated!");
    const patientnotify = () => toast.success("Email sent successfully!");
    const patientnotifyErr = () => toast.error("Email couldn't be sent!");
    const documentnotify = () => toast.success("Document uploaded successfully!");
    const documentnotifyErr = () => toast.error("Document couldn't be uploaded!");
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    // const [notify,setNotify] = useState('');
    //API CALL
    const ds = new PipelineRequestService();

    async function fetchReqdDataset() {
        let reqId = location.state.reqId;
        ds.GetClinicalHistoryData(reqId).then((response) => {
            if (isMounted) {
                if (response.data.status === "success") {
                    setClinicalHistory({ value: response.data.data.clinical_history });
                    setObjectiveAssesment({ value: response.data.data.obj_assessment });
                }
            }
        })
    }

    useEffect(() => {
        fetchReqdDataset();
        return () => { setIsMounted(false); }
    }, []);
    function fetchDataset() {
        let reqId = location.state.reqId;
        ds.GetPipelineDataset(reqId).then((response) => {
            if (isMounted1) {
                if (response.data.status === "success") {
                    setHasConsent(response.data.data[0].consentdoc);
                    localStorage.setItem('patientid', response.data.data[0].patientid);
                }
            }
        })
            .catch(function (error) {
                // if (error.response.status === 403) {
                //     history.push('/login');
                // }
            })
    }
    useEffect(() => {
        fetchDataset();
        return () => { setIsMounted1(false); }
    }, []);

    function saveClHistory() {
        let reqData = {
            id: location.state.reqId,
            clinicalhistory: clinicalHistory.value || "",
            objectiveassesment: objectiveAssesment.value || ""
        }
        ds.SaveClinicalHistoryData(reqData).then((response) => {
            if (response.data.status === "success") {
                notify();
                // previewConsentDoc();
            }
        })
            .catch(function (error) {
                notify1();
            })
    }

    //SEND EMAIL
    function sendEmail() {
        let validEMail = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(emailId);
        setValidMail(validEMail);
        let reqData = {
            email: emailId
        }
        if (validEMail === true) {
            ds.SendEmailToReq(reqData).then((response) => {
                if (response.data.status === "success") {
                    patientnotify();
                }
            })
                .catch(function (error) {
                    patientnotifyErr();
                })
        } else return;
    }

    //GET CLINICAL HISTORY DATA
    const handleClinicalHistoryChange = value => {
        setClinicalHistory({ value });
        if(value.length!==0){
            setIsEmpty(false);
        }
        else{
            setIsEmpty(true);
        }
       
    };

    //GET OBJECTIVE ASSESSMENT DATA
    const handleObjAssessmentChange = value => {
        setObjectiveAssesment({ value });
        if(value.length!==0){
            setIsEmpty1(false);
        }
        else{
            setIsEmpty1(true);
        }
      
    };

    //FILE UPLOAD
    const onSubmit = (data) => {
        let formData = new FormData();
        let file = data.picture[0].name;
        const lastSegment = file.substring(file.lastIndexOf(".") + 1);
        //check whether pdf file
        if (lastSegment !== "pdf") {
            setIspdf(true);
        }
        else {
            setIspdf(false);
        }
        // let currentFile = selectedFiles;
        let myFile = data.picture;
        if (myFile.length > 0) {
            for (let i = 0; i <= myFile.length - 1; i++) {
                let fsize = myFile.item(i).size;
                let file = Math.round((fsize / 1024));
                // The size of the file.52428800
                if (file >= 52428800) {
                    setFileSize(true);
                }
                else {
                    setFileSize(false);
                }
            }
        }
        let InputJson = {
            requestid: location.state.reqId,
            userid: localStorage.getItem('userid')
        }
        formData.append('File', data.picture[0]);
        formData.append('InputJson', JSON.stringify(InputJson));

        if (fileSize === false && lastSegment === 'pdf') {
            ds.UploadConsentDocument(formData).then((response) => {
                if (response.data.status === "success") {
                    setIspdf(false);
                    documentnotify();
                    previewConsentDoc();
                    fetchDataset();
                    setShowConsent(false);
                }
            })
                .catch(function (error) {
                    setIspdf(true);
                    documentnotifyErr();
                })
        } else return;
    };

    //PREVIEW CONSENT FORM
    function previewConsentDoc() {
        let reqData = {
            requestid: location.state.reqId
        }
        ds.PreviewConsentDocument(reqData).then((response) => {
            if(isMounted2){
            if (response.data) {
                setDisplayDoc(response.data.data);
                setLoading(true);
            }
            }
        })
    }
    useEffect(() => {
        previewConsentDoc();
        return () => { setIsMounted2(false); }
    }, []);

    const showConsentForm = () => {
        setShowConsent(true);
    }

    return (
        <div className='tab-content'>
            <h6 className='text-start mb-3 ps-4  pt-3'>Request Details</h6>
            <div className='row px-4 mb-4'>
                <div className='col-md-7 px-2'>
                    {/* <div className='text-editor px-3 pt-3 pb-2 mb-2'>
                        <p className='text-start'>Objective Assessment</p>
                        <div className='bg-white px-3 py-2 rich-content' >
                            <AssessToolbar />
                            <ReactQuill
                                theme="snow"
                                value={objectiveAssesment.value}
                                modules={modulesassess}
                                formats={formatsassess}
                                onChange={handleObjAssessmentChange}
                            >
                            </ReactQuill>
                            <div className='text-end'>
                                <button type="button" className="btn btn-save text-white px-3 py-0 mt-3"
                                 disabled={isEmpty1?true:false}   onClick={() => saveClHistory()}>Save </button>
                            </div>
                        </div>
                    </div> */}
                    <div className='text-editor px-3 pt-3 pb-2 mb-2'>
                        <p className='text-start'>Clinical History</p>
                        <div className='bg-white px-3 py-2 rich-content'>
                            <ClinicalToolbar />
                            <ReactQuill
                                theme="snow"
                                value={clinicalHistory.value}
                                modules={modulesclinic}
                                formats={formatsclinic}
                                onChange={handleClinicalHistoryChange}
                            >
                            </ReactQuill>
                            <div className='text-end'>
                                <button type="button" onClick={() => saveClHistory()}
                                disabled={isEmpty?true:false}  className="btn btn-save text-white px-3 py-0 mt-3 ">Save </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col patient-consent p-2 mb-2 me-2'>
                    <Row className='pe-1 mb-2'>
                        <p className='col-auto text-start mb-1 mt-3'>Patient Consent</p>
                        {hasConsent === "NO" || showConsent === true ? "" : <button className='col-auto btn text-danger pb-0 ms-auto btn-sm closebtn'
                            onClick={showConsentForm}>
                            <AiFillCloseCircle size={20} />
                        </button>}
                    </Row>
                    <div className='bg-white p-2 card-content' >
                        {hasConsent === "NO" || showConsent === true ? <>
                            <div className='row text-start p-3'>
                                <label className='ps-0 py-2'>Enter email to request for consent form:</label>
                                <div className="input-group mb-1 px-0">
                                    <input type="text" className="form-control"
                                        onChange={(e) => setEmailId(e.target.value)}
                                        defaultValue={emailId} pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                        placeholder="Patient email" aria-label="Patient email" aria-describedby="button-addon2" />
                                    <button className="btn btn-save text-white px-3" type="button"
                                        onClick={() => sendEmail()}
                                        id="button-addon2">Send</button>

                                </div>
                                {validMail === true ? "" : <div className='text-danger text-small ps-0'>
                                    <MdErrorOutline className='mb-1 me-1' />
                                    Enter valid Email</div>}

                            </div>
                            <div className='row text-start mb-5 px-3'>
                                <label className='ps-0 py-2 upload-consent text-start'>Upload patient consent form</label>
                                <div className="input-group p-0">
                                    <input {...register("picture")} type="file" className="form-control" accept="application/pdf" />
                                    <button className="btn upload-btn btn-save text-white px-2" onClick={handleSubmit((onSubmit))} type="button">Upload</button>
                                </div>
                                <div className='text-start mt-1 mb-0 pb-0 text-danger supported-doc'>
                                    <li>Supported document format : pdf</li>
                                    {fileSize ? <li>This file is too big, select a file less than 50 Mb</li> : ""}
                                    {isPdf ? <li>This file format is not supported. Upload only pdf</li> : ""}
                                </div>
                            </div>
                            <a className='text-start text-black ps-0 view-consent' data-bs-toggle="modal" data-bs-target="#previewConsentForm"
                                style={{ cursor: 'pointer' }} > View the consent form template</a>
                        </>
                            :
                            <div>
                                {loading === false ? <div className='mt-5'>
                                    <ClipLoader size={15} color="blue" />
                                    <span className='ps-1'> Loading</span><BeatLoader size={5} color="black" /></div> :
                                    <>
                                        <iframe id="consentForm" src={`data:application/pdf;base64,${displayDoc}`} width="100%" height="420" />
                                    </>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>

            {/* PREVIEW CONSENT TEMPLATE */}
            <div className='modal' id="previewConsentForm" data-bs-backdrop="false" >
                <div className="modal-dialog shadow-lg" style={{ maxWidth: '500px' }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Preview consent Template</h4>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                            >
                            </button>
                        </div>
                        <div className="modal-body">

                            {loading === false ? <div>
                                <ClipLoader size={15} color="blue" />
                                <span className='ps-1'>Loading</span><BeatLoader size={5} color="black" /></div> :
                                <>    <iframe id="consentForm" src={`data:application/pdf;base64,${displayDoc}`} width="100%" height="380" />
                                </>
                            }
                            
                        </div>
                        <div className="modal-footer ">
                            <button
                                type="button"
                                className="btn btn-save text-white "
                                data-bs-dismiss="modal">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}