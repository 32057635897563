import React, { useEffect, useState, useRef } from 'react';
import '../../../assets/styles/pipelinerequest/pipelinetabs/template.scss';
import { useLocation, useHistory } from 'react-router-dom';
import RecordingAnalysisService from '../../../services/RecordingAnalysis';
import PipelineRequestService from '../../../services/PipelineRequest';
import { format } from "date-fns";
import { toast } from 'react-toastify';
import Template1Logo from '../../../assets/img/template-icons/template1-icon.png';
import { BiEditAlt } from 'react-icons/bi';
import RecordingAnalysisEditor from '../resultPreviewEditors/RecordingAnalysisEditor';
import DOMPurify from 'dompurify';
import InterpretationEditor from '../resultPreviewEditors/InterpretationEditor';
import { useReactToPrint } from 'react-to-print';
import { BsDot } from 'react-icons/bs';
import NeurofeedbackEditor from '../resultPreviewEditors/NeurofeedbackEditor';
import AdjunctTherapiesEditor from '../resultPreviewEditors/AdjunctTherapiesEditor';
import SummaryFindingsEditor from '../resultPreviewEditors/SummaryOfFindingsEditor';
import Template2Logo from '../../../assets/img/template-icons/template2-logo.png';
import EyeOpenEditor from '../resultPreviewEditors/EyeOpenEditor';
import EyeClosedEditor from '../resultPreviewEditors/EyeClosedEditor';


export default function Template() {
    const history = useHistory();
    const ra = new RecordingAnalysisService();
    const [patientDemo, setPatientDemo] = useState([]);
    const [tempValue, setTempValue] = useState('');
    const [date, setDate] = useState('');
    const location = useLocation();
    const [requestData, setRequestData] = useState([]);
    const [isMounted1, setIsMounted1] = useState(true);
    //array conversion
    const [medicationArray, setMedicationArray] = useState([]);
    const [supplementArray, setSupplementArray] = useState([]);
    const [lifestyleArray, setLifestyleArray] = useState([]);
    //enable editor
    const [enableRc, setEnableRc] = useState(false);
    const [enableInt, setEnableInt] = useState(false);
    const [enableNeuro, setEnableNeuro] = useState(false);
    const [enableAdjunct, setEnableAdjunct] = useState(false);
    const [enableIcon, setEnableIcon] = useState(true);
    const [enableSummary, setEnableSummary] = useState(false);
    const [enableNeuroEC,setEnableNeuroEC] = useState(false);
    //print
    const printTemplateRef = useRef();

    const ds = new PipelineRequestService();
    const releasednotify = () => toast.success("Service Request released successfully!");

    //function to print template
    const handlePrint = useReactToPrint({
        content: () => printTemplateRef.current,
    });
    //patient-demograpyhy
    function fetchPatientDemography() {
        let reqId = location.state.reqId;
        ds.GetPipelineDataset(reqId).then((response) => {
            if (isMounted1) {
                if (response.data.status === "success") {
                    setRequestData(response.data.data[0]);
                }
            }
        })
    }
    useEffect(() => {
        fetchPatientDemography();
        return () => { setIsMounted1(false) }
    }, []);

    function handleChangeTemplate(e) {
        setTempValue(e.target.value);
        // previewTemplate(e.target.value);
    }
    function released() {
        const reqData = {
            "ServiceRequestid": location.state.reqId,
        }
        ds.Released(reqData).then((response) => {
            if (response.data.status === "success") {
                hideModal('closeReleasedModal');
                releasednotify();
                history.push("/view-request");
            }
        })
    }
    function hideModal(target) {
        document.getElementById(target).click();
    };

   
    function exportPreview() {
        let reqId = location.state.reqId;
        ra.ExportExcelPreview(reqId).then((response) => {
            if (response.data) {
                if (response.data.status === "success") {
                    let formatDate = (response.data.data.created_on);
                    setPatientDemo(response.data.data);
                    let diagnosis = response.data.data.MdMedic_TherapiesData;
                    if (diagnosis !== null && diagnosis.length > 1) {
                        setMedicationArray(diagnosis.split(','));
                    }
                    else {
                        setMedicationArray([]);
                    }
                    let supplement = response.data.data.Mdsupplement_TherapiesData;
                    if (supplement !== null && supplement.length > 1) {
                        setSupplementArray(supplement.split(','));
                    }
                    else {
                        setSupplementArray([]);
                    }
                    let lifestyle = response.data.data.Mdlifestyle_TherapiesData;
                    if (lifestyle !== null && lifestyle.length > 1) {
                        setLifestyleArray(lifestyle.split(','));
                    }
                    else {
                        setLifestyleArray([]);
                    }
                    setDate(format(new Date(formatDate), 'MM/dd/yyyy'));
                }
            }
        })
    }
    useEffect(() => {
        exportPreview();
    }, []);

    //converting html 
    const createMarkup = (html) => {
        return {
            __html: DOMPurify.sanitize(html)
        }
    }
    //enable editor functions
    const enableRecordingAnalysis = () => {
        setEnableRc(!enableRc);
    }
    const enableSummaryFindings = () => {
        setEnableSummary(!enableSummary);
    }
    const enableInterpretation = () => {
        setEnableInt(!enableInt);
    }
    const enableNeurofeedback = () => {
        setEnableNeuro(!enableNeuro);
    }
    const enableNeuroFeedbackEC=()=>{
        setEnableNeuroEC(!enableNeuroEC);
    }
    const enableAdjunctTherapy = () => {
        setEnableAdjunct(!enableAdjunct);
    }
    const hideEditIcon = () => {

        setEnableIcon(false);
        setEnableRc(false);
        setEnableInt(false);
        setEnableNeuro(false);
        setEnableAdjunct(false);
        setTimeout(() => { handlePrint(); }, 1000);
        setTimeout(() => { setEnableIcon(true) }, 2000);
    }

    //callback function
    function recanalysisCallback(item) {
        setEnableRc(item);
    }
    function interpretationCallback(item) {
        setEnableInt(item);
    }
    function neurofeedbackCallback(item) {
        setEnableNeuro(item);
    }
    function neurofeedbackECCallback(item){
        setEnableNeuroEC(item);
    }
    function adjunctCallback(item) {
        setEnableAdjunct(item);
    }
    function summaryCallback(item) {
        setEnableSummary(item);
    }
    const [enableBtn, setEnableBtn] = useState(true);

    function handleApprover(e) {
        console.log(e.target.value);
        if (e.target.value === "0") {
            setEnableBtn(true);
        }
        else {
            setEnableBtn(false);
        }
    }
    return (
        <div className="tab-content px-4 py-3 mb-3">
            <div className='template-preview'>

                <div className='preview-content w-75 p-3 mt-4 mx-auto' >
                    <div className='row g-0' >

                        <h6 className='col-auto mb-0 pt-2'>Result Preview
                            {/* <MdOutlineModeEditOutline data-bs-toggle="modal" data-bs-target="#resultPreview" /> */}
                        </h6>
                        <div className='ms-auto col-md-4 me-2'>
                            <select className="form-select "
                                onChange={handleChangeTemplate}>
                                <option value="Temp1">Template 1</option>
                                <option value="Temp2">Template 2</option>
                            </select>
                        </div>
                        <button className='col-auto btn btn-preview text-white' onClick={hideEditIcon}>Preview</button>
<div className='preview-template mt-2 mb-3 bg-white p-3' ref={printTemplateRef}>
                            {tempValue == "Temp2" ?
                                <>
                                    <div className='template-page1'>
                                        <div className="container">
                                            <table style={{ width: '100%' }}>
                                                <tbody>
                                                    <tr style={{ background: '#5a53b2', height: '45px' }}>
                                                        <th style={{ width: '6%' }}></th>
                                                        <th style={{ width: '80%', color: '#5a53b2', height: '25px' }}></th>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ background: '#5a53b2', height: '2.5vw' }}>

                                                        </td>
                                                        <td >
                                                            <div style={{ width: '240px', height: '80px', marginLeft: 'auto', marginTop: '-19px', marginRight: '-20px', background: '#fff', transform: 'rotate(-14deg)' }}></div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ background: '#5a53b2', height: '2.5vw' }}>
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ height: '7vw' }}>
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td >
                                                        </td>
                                                        <td>
                                                            <img src={Template2Logo} alt="template-logo" width="80%" height="400" style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                                                        </td>
                                                    </tr>
                                                    <tr style={{ height: '10vw' }}>
                                                        <td>
                                                        </td>
                                                        <td >
                                                            <h3 style={{ fontWeight: 'bolder', fontSize: "50px", fontFamily: 'Lato, sans-serif', textAlign: 'center' }}>QEEG Report</h3>
                                                            <h6 style={{ fontFamily: 'Lato,sans-serif', fontSize: '25px', textAlign: 'center' }}>Axon EEG Solutions</h6>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br />
                                            <br />
                                            <div className="pagebreak"> </div>
                                            <table style={{ width: '100%' }}>
                                                <tbody>
                                                    <tr style={{ background: '#5a53b2', height: '45px' }}>
                                                        <th style={{ width: '35%' }}></th>
                                                        <th style={{ width: '65%', color: '#5a53b2', height: '25px' }}></th>
                                                    </tr>
                                                    <tr >
                                                        <td style={{ background: '#5a53b2', height: '2.5vw' }}>
                                                            <h6 className='eeg-title' style={{ color: "#fff", fontSize: "18px", fontWeight: 'bolder', padding: '2px' }}>AXON EEG SOLUTIONS</h6>
                                                        </td>
                                                        <td style={{ paddingLeft: '10px' }}>
                                                            <div style={{ width: '240px', borderBottomLeftRadius: '90%', height: '80px', marginLeft: 'auto', fontWeight: 'bolder', marginTop: '-19px', marginRight: '-20px', background: '#fff', transform: 'rotate(-14deg)' }}></div>
                                                            <h6 className="text-start" style={{ fontSize: "18px", marginRight: 'auto', fontWeight: 'bolder' }}>QEEG Report:</h6>
                                                            <hr style={{ color: '#5a53b2', marginTop: '0px' }} />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ background: '#5a53b2', height: '2.5vw', paddingLeft: '15px' }}>
                                                            <h6 style={{ color: '#fff', fontWeight: 'bolder', fontSize: '16px', textAlign: 'left' }}>CONTACT</h6>
                                                        </td>
                                                        <td className="text-start" style={{ paddingLeft: '10px' }}>
                                                            <p style={{ fontWeight: 'bolder', fontSize: '14px', color: '#000' }}>Quantitative Elecroencephalograph and standardized Low Resolution
                                                                Electromagnetic Tomography Evaluation with Medication, Supplementation and
                                                                Neurofeedback Recommendations
                                                            </p>

                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ background: '#5a53b2', height: '2.5vw', paddingLeft: '15px' }}>
                                                            <h6 style={{ color: '#fff', fontWeight: 'bolder', fontSize: '16px', textAlign: 'left' }}>Telephone:
                                                                <br />
                                                                <span style={{ marginTop: '5px', fontWeight: '500' }}> 970-221-1106</span>
                                                            </h6>
                                                        </td>
                                                        <td className="text-start" style={{ paddingLeft: '10px' }}>
                                                            <p style={{ fontSize: '14px', color: '#000' }}>
                                                                Patient:
                                                                {patientDemo.patient_name}
                                                                <br />
                                                                Date: {date}
                                                                <br />
                                                                Birth date: {patientDemo.dob}
                                                                <br />
                                                                Sex: {patientDemo.gender}
                                                                <br />
                                                                Patient ID: {patientDemo.patientid}
                                                                <br />
                                                                Diagnosis: {patientDemo.diagnosis_name}
                                                                <br />
                                                                Medications: {patientDemo.medications_current}
                                                                <br />
                                                            </p>

                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ background: '#5a53b2', height: '2.5vw', paddingLeft: '15px', verticalAlign: 'text-top', paddingTop: '15px' }}>
                                                            <h6 style={{ color: '#fff', fontWeight: 'bolder', fontSize: '16px', textAlign: 'left' }}>Web:
                                                                <br />
                                                                <span style={{ marginTop: '5px', fontSize: '14px' }}>https://axoneegsolutions.com</span>
                                                            </h6>
                                                            <br />
                                                            <br />
                                                            <h6 style={{ color: '#fff', fontWeight: 'bolder', fontSize: '16px', textAlign: 'left' }}>Address:
                                                                <br />
                                                                <span style={{ paddingTop: '10px', fontWeight: '500', fontSize: '14px' }}>2620 E Prospect Suite 190 <br />Fort collins, CO 80528</span>
                                                            </h6>
                                                        </td>
                                                        <td className="text-start" style={{ paddingLeft: '10px', height: '40vh', verticalAlign: 'text-top', paddingTop: '15px' }}>
                                                            <h6>Recording and Analysis Procedures:{enableIcon ? <BiEditAlt className="ms-2 edit-txt" onClick={enableRecordingAnalysis} /> : ""}</h6>
                                                            {enableRc ? <RecordingAnalysisEditor data={{ exportPreview: exportPreview, recanalysisCallback: recanalysisCallback }} /> :
                                                                <div className="html-text" dangerouslySetInnerHTML={createMarkup(patientDemo.RTAnalysisProcedures)} style={{ minHeight: '200px' }}></div>
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr style={{ marginTop: '-2px', height: '10px' }}>
                                                        <td style={{ background: '#5a53b2', height: '2.5vw', paddingLeft: '15px', borderTopRightRadius: '0px', borderTopLeftRadius: '0px', borderBottomRightRadius: '20px', borderBottomLeftRadius: '20px' }}>

                                                        </td>
                                                        <td className="text-start" style={{ paddingLeft: '10px' }}>
                                                            <hr />
                                                            Signature:<br />
                                                            <img src={Template2Logo} className="sign-img" alt="logo" width="100" height="200" style={{ marginLeft: '0px' }} />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div className="pagebreak"> </div>
                                            <br />
                                            <br />
                                            <table style={{ width: '100%' }}>
                                                <tbody>
                                                    <tr style={{ background: '#5a53b2', height: '45px' }}>
                                                        <th style={{ width: '35%' }}></th>
                                                        <th style={{ width: '65%', color: '#5a53b2', height: '25px' }}></th>
                                                    </tr>
                                                    <tr style={{ zIndex: -1 }}>
                                                        <td style={{ background: '#5a53b2', height: '2.5vw' }}>

                                                        </td>
                                                        <td style={{ zIndex: -8 }}>
                                                            <div style={{ width: '240px', height: '75px', marginLeft: 'auto', borderBottomLeftRadius: '90%', marginTop: '-19px', zIndex: -9, marginRight: '-20px', background: '#fff', transform: 'rotate(-14deg)' }}></div>
                                                        </td>
                                                    </tr>
                                                    <tr style={{ zIndex: 2 }}>
                                                        <td style={{ background: '#5a53b2', height: '2.5vw', paddingLeft: '15px', verticalAlign: 'text-top' }}>
                                                            <h6 style={{ color: '#fff', fontWeight: 'bolder', fontSize: '16px', textAlign: 'left' }}>SUMMARY OF FINDINGS: {enableIcon ? <BiEditAlt className="ms-2 edit-txt" onClick={enableSummaryFindings} /> : ""}</h6>
                                                        </td>
                                                        <td className="text-start" style={{ paddingLeft: '15px', zIndex: 2 }}>
                                                            {enableSummary ? <SummaryFindingsEditor data={{ exportPreview: exportPreview, summaryCallback: summaryCallback }} /> :
                                                                <div className="html-text" dangerouslySetInnerHTML={createMarkup(patientDemo.RTsum_of_finding)}></div>
                                                               
                                                            }
                                                            </td>
                                                    </tr>

                                                    <tr style={{ zIndex: 2 }}>
                                                        <td style={{ background: '#5a53b2', height: '2.5vw', paddingTop: '15px', paddingLeft: '15px', verticalAlign: 'text-top' }}>
                                                            <h6 style={{ color: '#fff', fontWeight: 'bolder', fontSize: '16px', textAlign: 'left' }}>INTERPRETATION OF FINDINGS:{enableIcon ? <BiEditAlt className="ms-2 edit-txt" onClick={enableInterpretation} /> : ""}</h6>
                                                        </td>
                                                        <td className="text-start" style={{ paddingLeft: '15px', height: '30vh', verticalAlign: 'text-top', paddingTop: '15px' }}>
                                                            {enableInt ? <> <p>The quantitative EEG topographical analysis demonstrates trends consistent with reported clinical symptoms of</p>
                                                                <InterpretationEditor data={{ exportPreview: exportPreview, interpretationCallback: interpretationCallback }} />
                                                                <p>While there are numerous aberrant patterns present, healthy adaptive strategies, skills, and environments may allow for great success in a variety of settings.
                                                                    Treatments addressing specific presenting concerns should account for patterns reflective of symptomatology. Repeat testing may be done to monitor treatment progress.</p></> :
                                                                <><p>The quantitative EEG topographical analysis demonstrates trends consistent with reported clinical symptoms of</p>
                                                                    <div className="html-text" dangerouslySetInnerHTML={createMarkup(patientDemo.RTInterpretation)}></div>
                                                                    <p>While there are numerous aberrant patterns present, healthy adaptive strategies, skills, and environments may allow for great success in a variety of settings.
                                                                        Treatments addressing specific presenting concerns should account for patterns reflective of symptomatology. Repeat testing may be done to monitor treatment progress.</p>
                                                                </>
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr >
                                                        <td style={{ background: '#5a53b2', height: '2.5vw', paddingLeft: '15px', verticalAlign: 'text-top', paddingTop: '15px', borderBottomRightRadius: '20px', borderBottomLeftRadius: '20px' }}>
                                                            <h6 style={{ color: '#fff', fontWeight: 'bolder', fontSize: '16px', textAlign: 'left' }}>NEUROFEEDBACK RECOMMENDATIONS:
                                                                {/* {enableIcon ? <BiEditAlt className="ms-2 edit-txt" onClick={enableNeurofeedback} /> : ""} */}
                                                            </h6>
                                                        </td>
                                                        <td className="text-start" style={{ paddingLeft: '15px', height: '30vh', verticalAlign: 'text-top', paddingTop: '15px' }}>
                                                            <p>Approx. 40 sessions amplitude training.

                                                                Based upon the clinical information presented along with the individual's topographic maps, sLORETA images and in consideration of database deviancies,
                                                                the following recommendations are made for neurofeedback training.</p>
                                                            </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div className="pagebreak"> </div>
                                            <br />
                                            <br />
                                            {/* eyeopen */}
                                            <table style={{ width: '100%' }}>
                                                <tbody>
                                                    <tr style={{ background: '#5a53b2', height: '45px' }}>
                                                        <th style={{ width: '35%' }}></th>
                                                        <th style={{ width: '65%', color: '#5a53b2', height: '25px' }}></th>
                                                    </tr>
                                                    <tr style={{ zIndex: -1 }}>
                                                        <td style={{ background: '#5a53b2', height: '2.5vw' }}>

                                                        </td>
                                                        <td style={{ zIndex: -8 }}>
                                                            <div style={{ width: '240px', height: '80px', borderBottomLeftRadius: '90%', marginLeft: 'auto', marginTop: '-19px', zIndex: -9, marginRight: '-20px', background: '#fff', transform: 'rotate(-14deg)' }}></div>
                                                        </td>
                                                    </tr>
                                                    <tr style={{ zIndex: 2 }}>
                                                        <td style={{ background: '#5a53b2', height: '2.5vw', paddingLeft: '15px', verticalAlign: 'text-top' }}>
                                                            <h6 style={{ color: '#fff', fontWeight: 'bolder', fontSize: '16px', textAlign: 'left' }}>WITH EYE OPEN CONDITION:
                                                                {enableIcon ? <BiEditAlt className="ms-2 edit-txt" onClick={enableNeurofeedback} /> : ""}</h6>
                                                        </td>
                                                        <td className="text-start" style={{ paddingLeft: '15px', zIndex: 2, height: '20vh', verticalAlign: 'text-top' }}>
                                                            <div className="summary-finding preview text-start">
                                                                {enableNeuro ? <EyeOpenEditor data={{ exportPreview: exportPreview, neurofeedbackCallback: neurofeedbackCallback }} /> :
                                                                    <div className="html-text" dangerouslySetInnerHTML={createMarkup(patientDemo.neurofeedback_EO)}></div>
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr style={{ zIndex: 2 }}>
                                                        <td style={{ background: '#5a53b2', height: '2.5vw', paddingTop: '15px', paddingLeft: '15px', verticalAlign: 'text-top' }}>
                                                            <h6 style={{ color: '#fff', fontWeight: 'bolder', fontSize: '16px', textAlign: 'left' }}>WITH EYE CLOSED CONDITION:
                                                                {enableIcon ? <BiEditAlt className="ms-2 edit-txt" onClick={enableNeuroFeedbackEC} /> : ""}
                                                            </h6>
                                                        </td>
                                                        <td className="text-start" style={{ paddingLeft: '15px', height: '30vh', verticalAlign: 'text-top', paddingTop: '15px' }}>
                                                            <div className="summary-finding preview text-start">
                                                                {enableNeuroEC ? <EyeClosedEditor data={{ exportPreview: exportPreview, neurofeedbackECCallback: neurofeedbackECCallback }} /> :
                                                                    <div className="html-text" dangerouslySetInnerHTML={createMarkup(patientDemo.neurofeedback_EC)}></div>
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr >
                                                        <td style={{ background: '#5a53b2', height: '2.5vw', paddingLeft: '15px', verticalAlign: 'text-top', paddingTop: '15px', borderBottomRightRadius: '20px', borderBottomLeftRadius: '20px' }}>
                                                            <h6 style={{ color: '#fff', fontWeight: 'bolder', fontSize: '16px', textAlign: 'left' }}>ADJUNCT THERAPIES:{enableIcon ? <BiEditAlt className="ms-2 edit-txt" onClick={enableAdjunctTherapy} /> : ""}</h6>
                                                        </td>
                                                        <td className="text-start" style={{ paddingLeft: '15px', height: '30vh', verticalAlign: 'text-top', paddingTop: '15px' }}>
                                                            {enableAdjunct ? <><p>Based upon individual's topographic maps, sLORETA images, in consideration of database deviancies and known published references,
                                                                the following recommendations are made for medications and supplementation when applicable.</p><AdjunctTherapiesEditor data={{ exportPreview: exportPreview, adjunctCallback: adjunctCallback }} /></> :
                                                                <><p>Based upon individual's topographic maps, sLORETA images, in consideration of database deviancies and known published references,
                                                                    the following recommendations are made for medications and supplementation when applicable.</p> <div className="html-text" dangerouslySetInnerHTML={createMarkup(patientDemo.RTAdjunctTherapies)}></div>
                                                                </>
                                                            }
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div className='pagebreak'></div>
                                            <br />
                                            <br />
                                            <table style={{ width: '100%' }}>
                                                <tbody>
                                                    <tr style={{ background: '#5a53b2', height: '45px' }}>
                                                        <th style={{ width: '35%' }}></th>
                                                        <th style={{ width: '65%', color: '#5a53b2', height: '25px' }}></th>
                                                    </tr>
                                                    <tr style={{ zIndex: -1 }}>
                                                        <td style={{ background: '#5a53b2', height: '2.5vw' }}>

                                                        </td>
                                                        <td style={{ zIndex: -8 }}>
                                                            <div style={{ width: '240px', height: '80px', borderBottomLeftRadius: '90%', marginLeft: 'auto', marginTop: '-19px', zIndex: -9, marginRight: '-20px', background: '#fff', transform: 'rotate(-14deg)' }}></div>
                                                        </td>
                                                    </tr>
                                                    <tr style={{ zIndex: 2 }}>
                                                        <td style={{ background: '#5a53b2', height: '2.5vw', paddingLeft: '15px', verticalAlign: 'text-top' }}>
                                                            <h6 style={{ color: '#fff', fontWeight: 'bolder', fontSize: '16px', textAlign: 'left' }}>POSSIBLE APPROPRIATE MEDICATION OR CLASSES:</h6>
                                                        </td>
                                                        <td className="text-start" style={{ paddingLeft: '15px', zIndex: 2, height: '20vh', verticalAlign: 'text-top' }}>
                                                            <p>Based upon our internal studies and available published data, the following medications may be indicated.
                                                                Please consult with your health care provider for appropriateness and possible interactions with current treatments.
                                                                The EEG topographical analysis demonstrates trends consistent with reported clinical symptoms. With conflicting qEEG patterns,
                                                                single medication options may be difficult or contraindicated. Prediction accuracy is reduced without a complete medication washout.
                                                                *Note: with conflicting qEEG patterns, single medication options may be difficult or contraindicated.</p>
                                                            <div className="summary-finding preview text-start">
                                                                {medicationArray.map((item, i) => {
                                                                    return (<p className='mb-0' key={i}><BsDot size="18" />{item}</p>)
                                                                })}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr style={{ zIndex: 2 }}>
                                                        <td style={{ background: '#5a53b2', height: '2.5vw', paddingTop: '15px', paddingLeft: '15px', verticalAlign: 'text-top' }}>
                                                            <h6 style={{ color: '#fff', fontWeight: 'bolder', fontSize: '16px', textAlign: 'left' }}>POSSIBLE APPROPRIATE SUPPLEMENTATION:</h6>
                                                        </td>
                                                        <td className="text-start" style={{ paddingLeft: '15px', height: '20vh', verticalAlign: 'text-top', paddingTop: '15px' }}>
                                                            <p>Based upon our internal studies and available published data, the following nutritional supplements may be indicated.
                                                                Please consult with your health care provider for appropriateness and possible interactions with current treatments.</p>
                                                            <div className="summary-finding preview text-start">
                                                                {supplementArray.map((item, i) => {
                                                                    return (<p className='mb-0' key={i}><BsDot size="18" />{item}</p>)
                                                                })}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr style={{ height: '35vh' }}>
                                                        <td style={{ background: '#5a53b2', height: '2.5vw', paddingLeft: '15px', verticalAlign: 'text-top', paddingTop: '15px', borderBottomRightRadius: '20px', borderBottomLeftRadius: '20px' }}>
                                                            <h6 style={{ color: '#fff', fontWeight: 'bolder', fontSize: '16px', textAlign: 'left' }}>POSSIBLE APPROPRIATE LIFESTYLE:</h6>
                                                        </td>
                                                        <td className="text-start" style={{ paddingLeft: '15px', height: '20vh', verticalAlign: 'text-top', paddingTop: '15px' }}>
                                                            <div className="summary-finding  preview text-start">
                                                                {lifestyleArray.map((item, i) => {
                                                                    return (<p className='mb-0' key={i}><BsDot size="18" />{item}</p>)
                                                                })}

                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div className="pagebreak">
                                            </div>
                                            <br />
                                            <br />
                                            <table style={{ width: '100%' }}>
                                                <tbody>
                                                    <tr style={{ background: '#5a53b2', height: '45px' }}>
                                                        <th style={{ width: '35%' }}></th>
                                                        <th style={{ width: '65%', color: '#5a53b2', height: '25px' }}></th>
                                                    </tr>
                                                    <tr style={{ zIndex: -1 }}>
                                                        <td style={{ background: '#5a53b2', height: '2.5vw' }}>

                                                        </td>
                                                        <td style={{ zIndex: -8 }}>
                                                            <div style={{ width: '240px', borderBottomLeftRadius: '90%', height: '70px', marginLeft: 'auto', marginTop: '-19px', zIndex: -9, marginRight: '-20px', background: '#fff', transform: 'rotate(-14deg)' }}></div>
                                                        </td>
                                                    </tr>

                                                    <tr style={{ height: '83vh', marginTop: '-5px' }}>
                                                        <td style={{ background: '#5a53b2', height: '2.5vw', paddingLeft: '15px', verticalAlign: 'text-top', paddingTop: '15px', borderBottomRightRadius: '20px', borderBottomLeftRadius: '20px' }}>
                                                            <h6 style={{ color: '#fff', fontWeight: 'bolder', fontSize: '16px', textAlign: 'left' }}>CONCLUSION:</h6>
                                                        </td>
                                                        <td className="text-start" style={{ paddingLeft: '15px', height: '30vh', verticalAlign: 'text-top', paddingTop: '15px' }}>
                                                            <p style={{ fontSize: '14px', color: '#000', fontWeight: '400' }}>These suggestions are offered as a starting point for Neurofeedback traiing and treatments.
                                                                Individual patient responses will vary. The specific treatments, frequencies, montages and the sequencing
                                                                of sessions will likely require modification based on clinical response. Suggestions may be implemented used
                                                                past responses to medication and current medication regimen. The results of this analysis should be considered
                                                                together with other measures in assessing, diagnosing, and treating clients. Synergy Neurofeedback, LLC and its
                                                                affiliates assume no responsibility whatsover for the application of any therapies or interventions based on
                                                                the information contained in this report including medication interactions. With the delivery and reading of
                                                                this report the referral requesting this service agrees with the terms and conditions and assumes all responsibility
                                                                for any clinical interventions.
                                                                <br />
                                                                <br />
                                                                Dr. Steve Rondeau ND, BCIA-EEG
                                                            </p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </> : <>
                                    <div className='template-header mb-3 justify-content-center text-center'>
                                        <img src={Template1Logo} alt="template-logo" className="mt-3" width="80" height="80" />
                                        <div className='justify-content-center text-center'>
                                            <p>2620 E Prospect Suite 190, Fort Collins, CO 80528 <br />
                                                Web: <span className="web-link">www.synergyneurofeedback.com</span> Telephone: 970-221-1106</p>
                                            <h6 className='template-title px-3 text-bold'>Quantitative Electroencephalograph and standardized Low Resolution Electromagnetic
                                                Tomography Evaluation with Medication, Supplementation and Neurofeedback Recommendations
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="template-body text-start ms-4 p-3 ">
                                        <div className="patient-information " >
                                            <p >Patient:
                                                {patientDemo.patient_name}
                                                <br />
                                                Date: {date}
                                                <br />
                                                Birth date: {patientDemo.dob}
                                                <br />
                                                Sex: {patientDemo.gender}
                                                <br />
                                                Patient ID: {patientDemo.patientid}
                                                <br />
                                                Diagnosis: {patientDemo.diagnosis_name}
                                                <br />
                                                Medications: {patientDemo.medications_current}
                                                <br />
                                            </p>
                                        </div>
                                        <div className="findings">
                                            <h6>Recording and Analysis Procedures:{enableIcon ? <BiEditAlt className="ms-2 edit-txt" onClick={enableRecordingAnalysis} /> : ""}</h6>
                                            {enableRc ? <RecordingAnalysisEditor data={{ exportPreview: exportPreview, recanalysisCallback: recanalysisCallback }} /> :
                                                <div className="html-text" dangerouslySetInnerHTML={createMarkup(patientDemo.RTAnalysisProcedures)}></div>
                                            }
                                        </div>
                                        <div className="findings mb-2">
                                            <h6>Summary of Findings: {enableIcon ? <BiEditAlt className="ms-2 edit-txt" onClick={enableSummaryFindings} /> : ""}</h6>
                                            {enableSummary ? <SummaryFindingsEditor data={{ exportPreview: exportPreview, summaryCallback: summaryCallback }} /> :
                                                <div className="html-text" dangerouslySetInnerHTML={createMarkup(patientDemo.RTsum_of_finding)}></div>
                                            }

                                        </div>
                                        <div className="findings mb-2">
                                            <h6>Interpretation of Findings:{enableIcon ? <BiEditAlt className="ms-2 edit-txt" onClick={enableInterpretation} /> : ""}</h6>
                                            {enableInt ? <> <p>The quantitative EEG topographical analysis demonstrates trends consistent with reported clinical symptoms of</p>
                                                <InterpretationEditor data={{ exportPreview: exportPreview, interpretationCallback: interpretationCallback }} />
                                                <p>While there are numerous aberrant patterns present, healthy adaptive strategies, skills, and environments may allow for great success in a variety of settings.
                                                    Treatments addressing specific presenting concerns should account for patterns reflective of symptomatology. Repeat testing may be done to monitor treatment progress.</p></> :
                                                <><p>The quantitative EEG topographical analysis demonstrates trends consistent with reported clinical symptoms of</p>
                                                    <div className="html-text" dangerouslySetInnerHTML={createMarkup(patientDemo.RTInterpretation)}></div>
                                                    <p>While there are numerous aberrant patterns present, healthy adaptive strategies, skills, and environments may allow for great success in a variety of settings.
                                                        Treatments addressing specific presenting concerns should account for patterns reflective of symptomatology. Repeat testing may be done to monitor treatment progress.</p>
                                                </>
                                            }
                                        </div>
                                        <div className="findings mb-2" >
                                            <h6>Neurofeedback Recommendations:{enableIcon ? <BiEditAlt className="ms-2 edit-txt" onClick={enableNeurofeedback} /> : ""}</h6>
                                            {enableNeuro ? <><p>Approx. 40 sessions amplitude training.
                                                Based upon the clinical information presented along with the individual's topographic maps, sLORETA images and in consideration of database deviancies,
                                                the following recommendations are made for neurofeedback training.</p> <NeurofeedbackEditor data={{ exportPreview: exportPreview, neurofeedbackCallback: neurofeedbackCallback }} /> </> :
                                                <><p>Approx. 40 sessions amplitude training.
                                                    Based upon the clinical information presented along with the individual's topographic maps,
                                                    sLORETA images and in consideration of database deviancies, the following recommendations are made for neurofeedback training.</p>
                                                    {/* <div className="html-text" dangerouslySetInnerHTML={createMarkup(patientDemo.RTNeurofeedback)}></div> */}
                                                    <h6>With Eyes Open condition:</h6>
                                                    <div className="summary-finding ps-3 preview text-start mb-3 html-text" dangerouslySetInnerHTML={createMarkup(patientDemo.neurofeedback_EO)}></div>
                                                    <h6>With Eyes Closed condition:</h6>
                                                    <div className="summary-finding ps-3 preview text-start mb-4 html-text" dangerouslySetInnerHTML={createMarkup(patientDemo.neurofeedback_EC)}></div>
                                                </>}
                                        </div>
                                       
                                        <div className="findings mb-3">
                                            <h6>Adjunct therapies {enableIcon ? <BiEditAlt className="ms-2 edit-txt" onClick={enableAdjunctTherapy} /> : ""}</h6>
                                            {enableAdjunct ? <><p>Based upon individual's topographic maps, sLORETA images, in consideration of database deviancies and known published references,
                                                the following recommendations are made for medications and supplementation when applicable.</p><AdjunctTherapiesEditor data={{ exportPreview: exportPreview, adjunctCallback: adjunctCallback }} /></> :
                                                <><p>Based upon individual's topographic maps, sLORETA images, in consideration of database deviancies and known published references,
                                                    the following recommendations are made for medications and supplementation when applicable.</p> <div className="html-text" dangerouslySetInnerHTML={createMarkup(patientDemo.RTAdjunctTherapies)}></div>
                                                </>
                                            }
                                        </div>
                                        <div className="findings mb-3">
                                            <h6>Possible appropriate medications or classes:</h6>
                                            <p>Based upon our internal studies and available published data, the following medications may be indicated.
                                                Please consult with your health care provider for appropriateness and possible interactions with current treatments.
                                                The EEG topographical analysis demonstrates trends consistent with reported clinical symptoms. With conflicting qEEG patterns,
                                                single medication options may be difficult or contraindicated. Prediction accuracy is reduced without a complete medication washout.
                                                *Note: with conflicting qEEG patterns, single medication options may be difficult or contraindicated.</p><div className="summary-finding ps-3 preview text-start">
                                                {medicationArray.map((item, i) => {
                                                    return (<p className='mb-0' key={i}><BsDot size="18" />{item}</p>)
                                                })}

                                            </div>
                                           
                                        </div>
                                        <div className="findings mb-3">
                                            <h6>Possible appropriate supplementation:</h6>
                                            <p>Based upon our internal studies and available published data, the following nutritional supplements may be indicated.
                                                Please consult with your health care provider for appropriateness and possible interactions with current treatments.</p>
                                            <div className="summary-finding ps-3 preview text-start">
                                                {supplementArray.map((item, i) => {
                                                    return (<p className='mb-0' key={i}><BsDot size="18" />{item}</p>)
                                                })}

                                            </div>
                                        </div>
                                        <div className="findings mb-3">
                                            <h6>Possible appropriate lifestyle interventions:</h6>
                                            <div className="summary-finding ps-3 preview text-start">
                                                {lifestyleArray.map((item, i) => {
                                                    return (<p className='mb-0' key={i}><BsDot size="18" />{item}</p>)
                                                })}

                                            </div>
                                        </div>
                                    </div>
                                </>}
                           
                        </div>
                        <div className='bottom-content col'>
                            <div className='row g-0'>
                                <p className='col-auto text-start mb-0'>Released & Approved by</p>
                                <div className='col-md-4 ms-2'>
                                    <select className="form-select py-1" aria-label="Default select example" defaultValue="" onChange={(e) => handleApprover(e)}>
                                        <option value="0">Select</option>
                                        <option value="1">Dr. Steve Rondeau</option>
                                        <option value="2">Staff</option>
                                    </select>
                                </div>   
                                <div className='col-auto ms-2 '><button className='btn px-4 text-white py-1' disabled={enableBtn} data-bs-toggle="modal" data-bs-target="#releasedPatient">Result Approve</button></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
            <div className="modal" id="releasedPatient"
                tabIndex="-1" >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Confirm Release</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                        </div>
                        <div className="modal-body p-4 text-start">
                            <h6>Are you sure you want to release?</h6>
                        </div>

                        <div className="modal-footer ">
                            <button type="button" id="closeReleasedModal" className="btn btn-save text-white px-4" data-bs-dismiss="modal">No</button>
                            <button type="button" className="btn btn-cancel text-white px-4" onClick={released}>Yes</button>
                        </div>

                    </div>
                </div>
            </div>
            {/* RESULT PREVIEW PAGE */}
            <div className="modal" id="resultPreview"
                tabIndex="-1" >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Result Preview</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                        </div>
                        <div className="modal-body p-4 text-start">
                            <div className='template-header'>
                                <img src={Template1Logo} alt="template-logo" width="50" height="50" />
                            </div>
                        </div>
                        <div className="modal-footer ">
                            <button type="button" id="closeReleasedModal" className="btn btn-save text-white px-4" data-bs-dismiss="modal">No</button>
                            <button type="button" className="btn btn-cancel text-white px-4" onClick={released}>Yes</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
} 
