import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactQuill from 'react-quill';
import InterpretToolbar, { modules1, formats1 } from '../tabcontent/subcontent/toolbar/InterpretToolbar.js';
import "react-quill/dist/quill.snow.css";
import PipelineRequestService from '../../../services/PipelineRequest.js';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function InterpretationEditor(props) {
    const [descid, setDescID] = useState(0);
    const location = useLocation();
    const [text, setText] = useState({ value: "" });
    //notification
    const notifyIF = () => toast.success("Interpretation of Findings updated successfully!");
    const notifyIFErr = () => toast.error("Interpretation of Findings couldn't be updated!");
    //api
    let pipreq = new PipelineRequestService();

    function fetchAssociatedInterpretation() {
        const reqData = location.state.reqId;
        pipreq.getAssociatedInterpretation(reqData)
            .then((response) => {
                if (response.data) {
                    setDescID(response.data.data.id);
                    setText({ value: response.data.data.description });

                }
            }).catch(function (error) {

            })
    }
    useEffect(() => {
        let isMounted = true;
        fetchAssociatedInterpretation();
        return () => { isMounted = false; }
    }, []);
    const handleChange = value => {
        setText({ value });
    };
   //callback function from parent
   const hideRichTextEditor=(val)=>{
    props.data.interpretationCallback(val);
   }
    const onSubmit = () => {
        let reqData = {
            "sr_associate_interpretation": descid,
            "service_request": location.state.reqId,
            "description": text.value,
        }

        pipreq.saveAssociatedInterpretation(reqData)
            .then((response) => {
                if (response.data) {
                    if (response.data.status === 'success') {
                        fetchAssociatedInterpretation();
                        props.data.exportPreview();
                        hideRichTextEditor(false);
                        notifyIF();

                    }
                }
            })
            .catch(function (error) {
                // setErrorMsg(true);
                notifyIFErr();
            })
    }
    return (
        <div>
           
            <InterpretToolbar />
          
            <ReactQuill
                theme="snow"
                value={text.value}
                modules={modules1}
                formats={formats1}
                onChange={handleChange}
            >
                 
            </ReactQuill>
            <div className='text-end my-2'>
                <button type="button" className='btn btn-save ms-auto text-white' onClick={onSubmit}>Save</button>
            </div>
        </div>
    );
}