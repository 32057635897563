import React ,{useState,useEffect} from 'react';
import ReactQuill from 'react-quill';
import { useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form";
import QuillToolbar, { modules, formats } from './QuillToolbar';
import "react-quill/dist/quill.snow.css";
import PipelineRequestService from '../../../../services/PipelineRequest';
import DOMPurify from 'dompurify';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../../../assets/styles/pipelinerequest/toolbar.scss';
import '../../../../assets/styles/pipelinerequest/analysisProcedure.scss';

export default function AnalysisAndProcedure() {
    const { handleSubmit, formState: { errors } } = useForm();
    const location = useLocation();
    const [closeModal, setcloseModal] = useState(true);
    const [text, setText] = useState({value:`
    The electroencephalograph (EEG) was digitally recorded utilizing 19 electrodes with the International 10/20 System of electrode placement.
     Electrode impedances were reduced to below 5Kohms. The EEG was recorded continuously in the awake state with eyes closed and eyes open. 
    The EEG has been visually inspected, and the artifact was rejected utilizing S.A.R.A. 
    The absolute and normalized spectral analysis has been computed for each task. When age-appropriate,
 the client's data has been compared to the qEEG Pro client database consisting of 2713 individuals in eyes open and eyes closed conditions.
  The output is displayed in tables and topographical maps. The output of magnitude, power, ratio, and coherence have been included. 
  Standardized Low-Resolution Electromagnetic Tomography has been computed and relevant images included. This analysis and report are generated using qEEG Pro software. 
  Summary of findings along with interpretation and neurofeedback recommendations have been provided by Dr. Steven Rondeau BCIA-EEG Shared variance
 (connectivity) analysis and comparison of shared variance (connectivity) to normative sample may have been completed.`});
    let pipreq = new PipelineRequestService();
    const [descid, setDescID] = useState(0);
    const [msg,setMsg] = useState('');
    const notifyAp = ()=>toast.success("Recording and analysis procedure updated successfully!");
    const notifyApErr = () => toast.error("Recording and analysis procedure couldn't be updated!");

    useEffect(() => {
      let isMounted = true;
        const reqData = location.state.reqId;
        pipreq.getAnalysisProcedure(reqData)
          .then((response) => {
            if(isMounted){
            if (response.data) {
              setDescID(response.data.data.id);
              setText({value:response.data.data.description});
            }
          }
          }).catch(function (error) {
    
          })
          return () => { isMounted=false; }
      }, []);
    function hideModal (target) {
      document.getElementById(target).click();
    };
    function fetchAnalysisProcedure()
    {
      const reqData = location.state.reqId;
        pipreq.getAnalysisProcedure(reqData)
          .then((response) => {
            if (response.data) {
              setDescID(response.data.data.id);
              setText({value:response.data.data.description});
            }
          }).catch(function (error) {
    
          })
    }
    const handleChange = value => {
        setText({ value });
      };
      const createMarkup = (html) => {
        return  {
          __html: DOMPurify.sanitize(html)
        }
      }
      const onSubmit = (data) => {
        let reqData = {
          "sr_analysisprocedures":descid,
          "service_request":location.state.reqId,
          "description":text.value,
        }
                  
        pipreq.saveAnalysisProcedure(reqData)
          .then((response) => {
            if (response.data) {
                if (response.data.status === 'success') {
                  fetchAnalysisProcedure();
                  hideModal("close");
                  
                  notifyAp();
                //   setMsg(" Updated successfully!");
                // setTimeout(() => { setMsg(""); }, 5000);
              }
            }
          })
          .catch(function (error) {
           // setErrorMsg(true);
           notifyApErr();
          })
      }
    return (
        <div className="analysis-procedure rich-editor">
            <div className="row g-0 p-2">
            {/* {msg ? <div className='row align-items-center justify-content-center mb-2'>
            <div className=" success-msg  " style={{ width: '60%' }}>{msg}</div>
          </div> : null} */}
                <h6 className="col-auto">Recording And Analysis Procedures</h6>
                <div className="col-auto ms-auto icon-btn">
                    <i
                        className="bi bi-pencil"
                        data-bs-toggle="modal"
                        data-bs-target="#editTextArea"
                    ></i>
                </div>
            </div>
            <div className="preview p-2" dangerouslySetInnerHTML={createMarkup(text.value)}></div>
            {closeModal ? <div className="modal" id="editTextArea" tabIndex="-1" >
                <div className="modal-dialog" style={{ maxWidth: '900px' }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Edit Template</h4>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                            ></button>
                        </div>

                        <div className="modal-body p-4 text-start">
                            <QuillToolbar/>
                            <ReactQuill
                                theme="snow"
                                modules={modules}
                                formats={formats}
                                value={text.value}
                                onChange={handleChange}
                                // onChange={this.handleProcedureContentChange}
                            >
                                {/* <div className="my-editing-area" /> */}
                            </ReactQuill>

                        </div>

                        <div className="modal-footer ">
                            <button id="close"
                                type="button"
                                className="btn btn-cancel text-white px-4"
                                data-bs-dismiss="modal"
                            >
                                Cancel
                            </button>
                            <button type="button" onClick={handleSubmit((onSubmit))}  className="btn btn-save text-white px-4" >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div> : null}


            {/* <div className="rawMessage">
        <p className="p-3">{state.message.toString("markdown")}</p>
      </div> */}

        </div>
    );

}

