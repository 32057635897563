import React, {useState, useEffect} from 'react';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {  columns, options } from './data/InterpretMaster';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import '../../assets/styles/masterData/supplement.scss';
import LoadingIcon from '../../assets/img/sample-icons/sandbox.gif';
//Api
import MasterDataService from '../../services/masterdata';
import { newSupplement } from "../ToolTip/ToolTip";
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddInterpretMaster from './modal/interpretmaster/AddInterpretMaster';
import DeleteInterpretMasterModal from './modal/interpretmaster/DeleteInterpretMaster';

const NoDataIndication = () => (
    <div className='spinner d-flex justify-content-center text-center h-100'>
      <img src={LoadingIcon} alt="loading-icon" width="200" height="200"/>
      {/* <ScaleLoader size={60} color="#79a3bb" style={{ marginTop: '50px' }} /> */}
    </div>
  );

export default function InterpretMaster(){
    const history = useHistory();
    const { SearchBar } = Search;
    const [supplementData, setSupplementData] = useState([]);
    const [selctedrowID, setSelectedID] = useState();
    const [selctedrowData, setSelectedData] = useState([]);
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const [errmsg, setErrorMsg] = useState(false);
    const [isMounted, setIsMounted] = useState(true);
    const [tdataLoaded, setTdataLoaded] = useState(false);
    //CALLBACK VARIABLES
    const [isFlagSet, setIsFlagSet] = useState(false);
    const [isFlagSet1, setIsFlagSet1] = useState(false);
  
    //API CALL
    const msds = new MasterDataService();
    //TOASTER
    const suppupnotify = () => toast.success("Interpretation ChoiceField created successfully!");
    const suppupnotifyErr = () => toast.error("Interpretation ChoiceField  couldn't be updated!");
    const suppupdnotify = () => toast.success("Interpretation ChoiceField updated successfully!");
    const suppdelnotify = () => toast.success("Interpretation ChoiceField  deleted successfully!");
    const suppdelnotifyErr = () => toast.error("Interpretation ChoiceField  couldn't be deleted!");
  
    //CALLBACK FUNCTION FOR CREATE SUPPLEMENT
    function messageCallback(item) {
      setIsFlagSet(item);
      suppupnotify();
    }
  
    //CALLBACK FUNCTION FOR DELETE SUPPLEMENT
    function messageCallback1(item) {
      setIsFlagSet1(item);
      suppdelnotify();
    }
  
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        setSelectedID(row.id);
        setSelectedData(row);
      },
    };
  
    const onSubmit = (data) => {
      let reqData = {
        "Mdinterpretationchoiceid":selctedrowID,
        "interpretationchoicefield_name":data.intchoiceName.trim(),
        "ls_category":data.ls_category,
        "interpretationchoicefield_hint":data.hint
      }
      msds.addInterpretationGrid(reqData)
        .then((response) => {
          if (response.data) {
            if (response.data.status === 'success') {
                getInterpretionGridData();
              hideModal('closeUpSup');
              suppupdnotify();
            }
          }
        })
        .catch(function (error) {
          setErrorMsg(true);
        })
    }
    function hideModal(target) {
      document.getElementById(target).click();
      reset();
    };
  
    function getInterpretionGridData() {
      msds.getInterpretationGrid()
        .then((response) => {
          if (isMounted) {
            if (response.data) {
              if(response.data.data !==[] || response.data.data!==null || response.data.data!==""){
                setTdataLoaded(true);
                setSupplementData(response.data.data);
            }
          }else{
            setTdataLoaded(false);
          }
          }
        }).catch(function (error) {
          if (error.response.status === 403) {
            history.push('/login');
            }
        })
    }
    useEffect(() => {
        getInterpretionGridData();
      return () => { setIsMounted(false); }
    }, [])
  
    useEffect(() => {
      reset(selctedrowData);
    }, [selctedrowData]);
  
    return(
        <div className='col supplement'>
        <div className='row g-0 ps-3 pt-3'>
          
          <div className='table-request my-2 me-3'>
            <AddInterpretMaster data={{ isFlagSet: isFlagSet, messageCallback: messageCallback, getInterpretionGridData: getInterpretionGridData }} />
            <DeleteInterpretMasterModal data={{ id: selctedrowID, messageCallback1: messageCallback1, getInterpretionGridData: getInterpretionGridData }} />
  
            {/* UPDATE SUPPLEMENT MODEL START */}
            <div className="modal" id="updateInterpretMaster" data-bs-backdrop="false"
              tabIndex="-1" >
              <div className="modal-dialog shadow-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title">Update Interpretation ChoiceField</h4>
                    <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                  </div>
                  <form onSubmit={handleSubmit(onSubmit)} >
                    <div className="modal-body p-4">
                      {errmsg ? <div className="err-msg">Interpretation ChoiceField name already exist</div> : ''}
                      <div className='row text-start'>
                        <div className="mb-3">
                          <label htmlFor="intchoiceName" className="form-label">Interpretation ChoiceField Name
                            <span className='required-field'>*</span></label>
                          <input type="text" maxLength="100"
                            defaultValue={selctedrowData.interpretationchoicefield_name}
                            onChange={(e) => setSupplementData(e.target.value)}
                            className={`form-control p-2 ${errors.intchoiceName ? "is-invalid" : ""
                              }`}
                            {...register(
                              "intchoiceName",
                              { required: true },{validate: (value) => { return !!value.trim()}}
                            )} />
                          {(errors.intchoiceName && errors.intchoiceName.type === 'required') ? <div className="invalid-feedback">This field is required</div> : ''}
                        </div>
                        <div className="mb-3">
                                    <label htmlFor="selectionType" className="form-label">Interpretation ChoiceField Name <span className='required-field'>*</span></label>
                                    <select  selected={selctedrowData.ls_category} className={`form-select p-2 ${errors.selectionType ? "is-invalid" : ""}`} {...register('ls_category', { required: true })}>
                                        <option value=""></option>
                                      <option value="Selection 1">Selection 1</option>
                                      <option value="Selection 2">Selection 2</option>
                                      <option value="Selection 3">Selection 3</option>
                                    </select>
                                    {/* <input type="text" maxLength="100"  className={`form-control p-2 ${errors.selectionType ? "is-invalid" : ""}`} {...register('selectionType',{validate: (value) => { return !!value.trim()}}, { required: true })}/> */}
                                    {(errors.selectionType && errors.selectionType.type === 'required') ? <div className="invalid-feedback">This field is required</div> : ''}
                                </div>
                        <div className="mb-3">
                          <label htmlFor="hint" className="form-label">Hint</label>
                          <input type="text"
                            defaultValue={selctedrowData.interpretationchoicefield_hint}
                            onChange={(e) => setSupplementData(e.target.value)}
                            className={`form-control p-2 ${errors.hint ? "is-invalid" : ""
                              }`}
                            {...register(
                              "hint",
                              { required: false },{validate: (value) => { return !!value.trim()}}
                            )} />
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer ">
                      <button type="button" id="closeUpSup" className="btn btn-cancel text-white px-2" data-bs-dismiss="modal">Cancel</button>
                      <button type="submit" className="btn btn-save text-white px-2">Update </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
  
            {/* UPDATE SUPPLEMENT MODEL END */}
            <div className='table-data me-4'>
              <div>{newSupplement}</div>
              <ToolkitProvider
                keyField='id'
                data={supplementData}
                columns={columns}
                search
              >
                {
                  props => (
                    <div className="pe-3">
                      <div className='row mb-2'>
                        <div className='col text-start ms-1'>
                          <h5>Interpretation ChoiceField</h5>
                        </div>
                        <div className='col text-end'>
                          <button type='button' className='btn-create btn px-3 py-1 ms-3 me-2'
                            data-bs-toggle="modal" data-bs-target="#createInterpretMaster" >
                            <i className="bi bi-person-plus-fill"></i>Add</button>
                          <i className="bi bi-search ms-1 ps-2 mb-2 " id='search-icon'></i>
                          <SearchBar placeholder="  Search Supplement "
                            {...props.searchProps} />
                        </div>
                      </div>
                      <BootstrapTable
                        {...props.baseProps}
                        rowEvents={rowEvents}
                        pagination={paginationFactory(options)}
                        wrapperClasses="table-responsive" 
                        noDataIndication={() => { return (tdataLoaded ?  "No data available":<NoDataIndication /> ) }}
                        />
                    </div>
                  )
                }
              </ToolkitProvider>
            </div>
          </div>
        </div>
      </div>
    );
}