import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import { useLocation } from 'react-router-dom';
import QuillToolbar, { modules, formats } from '../tabcontent/subcontent/QuillToolbar.js';
import "react-quill/dist/quill.snow.css";
import RecordingAnalysisService from '../../../services/RecordingAnalysis.js';
import { toast } from 'react-toastify';
import PipelineRequestService from '../../../services/PipelineRequest';

export default function RecordingAnalysisEditor(props) {
    const [text, setText] = useState({ value: "" });
    const location = useLocation();
    const [descid, setDescID] = useState(0);
    const notifyAp = () => toast.success("Recording and analysis procedure updated successfully!");
    const notifyApErr = () => toast.error("Recording and analysis procedure couldn't be updated!");

    //API
    const ra = new RecordingAnalysisService();
    let pipreq = new PipelineRequestService();

    const handleChange = value => {
        setText({ value });
    };
   
    function fetchAnalysisProcedure() {
        const reqData = location.state.reqId;
        pipreq.getAnalysisProcedure(reqData)
            .then((response) => {
                if (response.data) {
                    setDescID(response.data.data.id);
                    setText({ value: response.data.data.description });
                }
            }).catch(function (error) {

            })
    }
    useEffect(() => {
        fetchAnalysisProcedure();
    }, []);
    
  //CALLBACK FUNCTION
  const msgDisplay = (val) => {
    props.data.recanalysisCallback(val);
  }
    const onSubmit = () => {
        let reqData = {
            "sr_analysisprocedures": descid,
            "service_request": location.state.reqId,
            "description": text.value,
        }

        pipreq.saveAnalysisProcedure(reqData)
            .then((response) => {
                if (response.data) {
                    if (response.data.status === 'success') {
                        fetchAnalysisProcedure();
                        props.data.exportPreview();
                        notifyAp();
                        msgDisplay(false);
                        
                    }
                }
            })
            .catch(function (error) {
                   notifyApErr();
            })
    }
    return (
        <div >
            <QuillToolbar />
            <ReactQuill
                theme="snow"
                modules={modules}
                formats={formats}
                value={text.value}
                onChange={handleChange}
          
            >
             
            </ReactQuill>
            <div className='text-end my-2'>
            <button type="button" className='btn btn-save ms-auto text-white' onClick={onSubmit}>Save</button>
            </div>
        </div>
    );
}