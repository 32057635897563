import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import '../../../../assets/styles/pipelinerequest/tabcontent/diagnosistab.scss';
import RecordingAnalysisService from '../../../../services/RecordingAnalysis';
import { BsPlusLg } from 'react-icons/bs';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function SymptomsTab(props) {
    const location = useLocation();
    const [active, setActive] = useState();
    const [val, setVal] = useState();
    const [isactive, setIsActive] = useState();
    const [dId, setDId] = useState([]);
    const [checkBoxes, setCheckBoxes] = useState([]);
    const [selectedBox, setSelectedBox] = useState([]);
    const [isMounted, setIsMounted] = useState(true);
    const [ndID, setNdID] = useState([]);
    const [unSelectBx, setUnSelectBx] = useState([]);
    const [selectedSymptoms, setSelectedSymptoms] = useState('');
    const [symptomsID, setSymptomsID] = useState([]);
    const [unSymptoms, setUnSymptoms] = useState([]);
    const ra = new RecordingAnalysisService();
    //toaster
    const [msg, setMsg] = useState('');
    const notifySymp=()=>toast.success("Symptoms updated successfully!")
    const notifySympDel = () => toast.success("Symptoms deleted successfully!");

    //unselected checkbox
    const options = checkBoxes.map((checkBoxes) => {
        return {
            value: checkBoxes.symptoms_name,
            label: checkBoxes.symptoms_name,
            id: checkBoxes.id,
            checked: checkBoxes.ischoices

        }

    }
    );

    function getSymptoms() {
        let reqData = {
            "service_request_id": location.state.reqId
        }
        ra.GetMultiCheckBoxes(reqData).then((response) => {
            if (isMounted) {
                let unsymp = [];
                let symp = [];
                if (response.data.symptoms !== []) {

                    for (let i = 0; i < response.data.symptoms.length; i++) {
                        if (response.data.symptoms[i].ischoices === true) {
                            dId.push(response.data.symptoms[i].id);
                            symp.push(response.data.symptoms[i]);
                        }
                        else {
                            ndID.push(response.data.symptoms[i].id);
                            unsymp.push(response.data.symptoms[i]);
                        }
                    }
                }
                setCheckBoxes(symp);
                setUnSymptoms(unsymp);
            }
        })
    }

    function saveSymptoms() {
        let reqData = {
            "service_request_id": location.state.reqId,
            "symptoms_tps": symptomsID.toString(),
            "unsymptoms_tps": "",
            "diagnosis_tps": "",
            "undiagnosis_tps": "",
        }
        ra.SaveMultiCheckBoxes(reqData).then((response) => {
            if (response.data.status === "success") {
                setSelectedSymptoms(null);
                getSymptoms();
                props.data.getSymptoms();
                notifySymp();
                // setMsg("Symptoms updated successfully!");
                // setTimeout(() => { setMsg(""); }, 5000);
            }
        })
    }
   
    const handleCancel = () => {
      setSelectedSymptoms(null);
    }

    useEffect(() => {
        getSymptoms();
        return () => { setIsMounted(false) }
    }, []);
    const symptomsOption = unSymptoms.map((data, i) => {
        return {
            label: data.symptoms_name,
            value: data.symptoms_name,
            id: data.id
        }
    });

    const handleSymptomsChange = (selectedSymptoms) => {
        const values = ([...selectedSymptoms].map(o => o.id));
        setSymptomsID(values);
        setSelectedSymptoms(selectedSymptoms);
    };
  
   const removeSymptoms= (id)=>{
    let reqData = {
        "service_request_id": location.state.reqId,
        "symptoms_tps": "",
        "unsymptoms_tps": id.toString(),
        "diagnosis_tps": "",
        "undiagnosis_tps": "",
    }
    ra.SaveMultiCheckBoxes(reqData).then((response) => {
        if (response.data.status === "success") {
            getSymptoms();
            props.data.getSymptoms();
            notifySympDel();
            // setMsg("Symptoms deleted successfully!");
            // setTimeout(() => { setMsg(""); }, 5000);
        }
    })
   }
    return (
        <div className="diagnosis-tab mt-3">
            {/* <ToastContainer/> */}
            <div className='row g-0 mb-2'>
                {/* {msg ? <div className='row align-items-center justify-content-center mb-2'>
                    <div className=" success-msg  " style={{ width: '60%' }}>{msg}</div>
                </div> : null} */}
                <h6 className="text-start col">Symptoms</h6>
                <div className='ms-auto text-end col add-icon' data-bs-toggle="modal" data-bs-target="#addSymptoms">
                    <BsPlusLg />
                    {/* <button type="button" className="btn btn-primary" onClick={() => saveSymptoms()}>Save</button> */}
                </div>
            </div>
            <Container className='p-0'>
                <div className="checkbox-container mt-2 ps-1">
                    <div className='row p-2' style={{ height: 'auto' }}>
                        {checkBoxes.map((tag, i) => {
                            return (
                                <div key={i} className="tag col-auto mx-2 my-1 ps-1 py-0" style={{
                                    whiteSpace: 'no-wrap',
                                }}>
                                    {tag.symptoms_name}<div className="ms-auto col-1 p-1" key={tag.id}
                                        onClick={() => removeSymptoms(tag.id)} 
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <span>x</span>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </Container>
            <div className="modal" id="addSymptoms"
                tabIndex="-1" >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Add Symptoms</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                            onClick={()=>handleCancel()}></button>
                        </div>
                        <div className="modal-body p-4 text-start">
                            <Select
                                isMulti
                                value={selectedSymptoms}
                                onChange={handleSymptomsChange}
                                options={symptomsOption}
                            />
                        </div>
                        <div className="modal-footer ">
                            <button type="button" id="closeDelDiag" className="btn btn-save text-white px-3"
                                onClick={() => saveSymptoms()} data-bs-dismiss="modal">Save</button>
                            <button type="button" className="btn btn-cancel text-white px-3"
                                data-bs-dismiss="modal" onClick={()=>handleCancel()}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
