import { ax } from '../services/base';
import config from '../assets/config';

export default class RequestService {

GetServiceRequest(payload){
    return ax.post(`${config.apiURL}service_request/getServicerequest`,payload);
}
SaveServiceRequest(payload){
    return ax.post(`${config.apiURL}service_request/saveServicerequest`,payload);
}
PatientDropdown(accountId){
    return ax.get(`${config.apiURL}patient/getPatientacctview/${accountId}`);
}
RequestTypeDropdown(){
    return ax.get(`${config.apiURL}service_request/reqtypes`);
}
ExportServiceData(payload){
    return ax.post(`${config.apiURL}service_request/reqxl-export`,payload);
}
//Ex
}