import React from 'react';

function DeletePatientModal() {
    return (
        <div className="modal" id="deletePatient" data-bs-backdrop="false"
            tabIndex="-1" >
            <div className="modal-dialog shadow-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Confirm Delete</h4>
                        <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                    </div>
                    <div className="modal-body p-4 text-start">
                        <h6>Are you sure you want to delete this Medication?</h6>
                    </div>
                    <div className="modal-footer ">
                        <button type="button" className="btn btn-save text-white px-4" data-bs-dismiss="modal">Cancel</button>
                        <button type="button" className="btn btn-cancel text-white px-4" >Delete</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DeletePatientModal;