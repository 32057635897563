import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
// import QuillToolbar, { modules, formats } from './QuillToolbar';
import "react-quill/dist/quill.snow.css";
import PipelineRequestService from "../../../../services/PipelineRequest";
import DOMPurify from "dompurify";
import NeuroToolbar, { modules, formats } from "./toolbar/NeuroToolbar";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NeuroECToolbar,{modulesN,formatsN} from "./toolbar/NeuroECToolbar";
import RecordingAnalysisService from "../../../../services/RecordingAnalysis";


export default function Neurofeedback() {
  const {
    handleSubmit,
    formState: { errors },
  } = useForm();
  const location = useLocation();
  const [closeModal, setcloseModal] = useState(true);
  const [text, setText] = useState({ value: "" });
  const [textEC,setTextEC] = useState({value:""});
  let pipreq = new PipelineRequestService();
  const rec=new RecordingAnalysisService();
  const [descid, setDescID] = useState(0);
  const pipelineReqID = location.state.reqId;
  const [msg, setMsg] = useState("");
  const notifyNeuro = () => toast.success("Neurofeedback updated successfully!");
  const notifyNeuroErr = () => toast.error("Neurofeedback couldn't be updated!");

  useEffect(() => {
    let isMounted = true;
    const reqData = pipelineReqID;
    rec
      .getNeurofeedbackAll(reqData)
      .then((response) => {
        if (isMounted) {
          if (response.data) {
            console.log('res',response.data);
            setDescID(response.data.data.id);
            setText({ value: response.data.data.neurofeedback_EO});
            setTextEC({value:response.data.data.neurofeedback_EC});
          }
        }
      })
      .catch(function (error) { });

    return () => {
      isMounted = false;
    };
  }, []);

  function hideModal(target) {
    document.getElementById(target).click();
  }
  function fetchAssociatedNeuroFeedback() {
    const reqData = pipelineReqID;
    rec
      .getNeurofeedbackAll(reqData)
      .then((response) => {
        // if (isMounted) {
          if (response.data) {
            console.log('res',response.data);
            setDescID(response.data.data.id);
            setText({ value: response.data.data.neurofeedback_EO});
            setTextEC({value:response.data.data.neurofeedback_EC});
          }
        // }
      })
      .catch(function (error) { });
  }
  const onSubmit = () => {
    let reqData = {
      "sr_associate_neurofeedback":descid,
      "service_request":pipelineReqID,
      "description":"null",
      "neurofeedback_EO": text.value,
      "neurofeedback_EC": textEC.value,
    };
    rec
      .saveNeurofeedbackAll(reqData)
      .then((response) => {
        if (response.data) {
          if (response.data.status === "success") {
            fetchAssociatedNeuroFeedback();
            hideModal("closeneuro");
            notifyNeuro();
            // setMsg(" Updated successfully!");
            // setTimeout(() => {
            //   setMsg("");
            // }, 5000);
          }
        }
      })
      .catch(function (error) {
        // setErrorMsg(true);
        notifyNeuroErr();
      });
  };

  const handleChange = (value) => {
    setText({ value });
  };
  const handleECChange=(value)=>{
    setTextEC({value});
  }
  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };
  const createMarkup1=(html)=>{
    return{
      __html: DOMPurify.sanitize(html)
    }
  }

  return (
    <div className="analysis-procedure rich-editor">
      <div className="row g-0 p-2">
        {/* {msg ? (
          <div className="row align-items-center justify-content-center mb-2">
            <div className=" success-msg  " style={{ width: "60%" }}>
              {msg}
            </div>
          </div>
        ) : null} */}
        <h6 className="col-auto">Neurofeedback Recommendations</h6>
        <div className="col-auto ms-auto icon-btn">
          <i
            className="bi bi-pencil"
            data-bs-toggle="modal"
            data-bs-target="#editNeuroData"
          ></i>
        </div>
      </div>
      <div className="preview p-2">
        <p >Approx. 40 sessions amplitude training.
          Based upon the clinical information presented along with the individual's topographic maps,
          sLORETA images and in consideration of database deviancies, the following recommendations are made for neurofeedback training.</p>
        {/* <div
          className="my-4 fw-bold"
          dangerouslySetInnerHTML={createMarkup(text.value)}
        > */}
        {/* </div> */}
        <h6>with Eye open condition</h6>
        <div
          className="my-4 fw-bold"
          dangerouslySetInnerHTML={createMarkup(text.value)}
        >
        </div>
        <h6>With Eye closed condition</h6>
        <div
          className="my-4 fw-bold"
          dangerouslySetInnerHTML={createMarkup1(textEC.value)}
        >
        </div>
      </div>
      {closeModal ? (
        <div className="modal" id="editNeuroData" tabIndex="-1">
          <div className="modal-dialog" style={{ maxWidth: "900px" }}>
            <div className="modal-content">
              <div className="modal-header border-bottom">
                <h4 className="modal-title">Edit Template</h4>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                ></button>
              </div>

              <div className="modal-body p-4 text-start">
                <h6 className="fw-bold">With Eye Open Condition</h6>
                {/* <Toolbar/> */}
                <NeuroToolbar />
                <ReactQuill
                  // theme="snow"
                  value={text.value}
                  modules={modules}
                  formats={formats}
                  onChange={handleChange}
                // onChange={this.handleProcedureContentChange}
                >
                  {/* <div className="my-editing-area" /> */}
                </ReactQuill>
                <h6 className="mt-4 fw-bold">With Eye Closed Condition</h6>
                <NeuroECToolbar/>
                <ReactQuill
                theme="snow"
                value={textEC.value}
                modules={modulesN}
                formats={formatsN}
                onChange={handleECChange}
                ></ReactQuill>
                {/* <NeuroToolbar />
                  <NeuroECToolbar/>
                <ReactQuill
                   // theme="snow"
                  value={textEC.value}
                  modules={modulesN}
                  formats={formatsN}
                  onChange={handleECChange}
                // onChange={this.handleProcedureContentChange}
                >  </ReactQuill> 
                  {/* <div className="my-editing-area" /> */}
               
              
              </div>

              <div className="modal-footer ">
                <button
                  type="button"
                  className="btn btn-cancel text-white px-4"
                  data-bs-dismiss="modal"
                  id="closeneuro"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={handleSubmit(onSubmit)}
                  className="btn  btn-save text-white px-4"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {/* <div className="rawMessage">
        <p className="p-3">{state.message.toString("markdown")}</p>
      </div> */}
    </div>
  );
}
