import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import {  FaListUl } from 'react-icons/fa';
import { MdOutlineAdd } from 'react-icons/md';
import { TiTick } from 'react-icons/ti';
import { BiSearchAlt } from 'react-icons/bi';
import InputRange from "react-input-range";
import Select from 'react-select';
import 'react-input-range/lib/css/index.css';
import '../../assets/styles/search/amazonSearch.scss';
import MasterDataService from '../../services/masterdata.js';
import PatientAccountService from '../../services/Patient.js';
import PipelineRequestService from '../../services/PipelineRequest.js';
import RequestService from '../../services/SeriveRequest.js';
import LoadingIcon from '../../assets/img/sample-icons/sandbox.gif';
import { AiOutlinePlus } from 'react-icons/ai';
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import { format } from 'date-fns';
import SearchService from '../../services/Search';
import { options } from './data/AmazonSearchData.js';

const NoDataIndication = () => (
    <div className='spinner d-flex justify-content-center text-center h-100'>
        <img src={LoadingIcon} alt="loading-icon" width="200" height="200" />
        {/* <ScaleLoader size={60} color="#79a3bb" style={{ marginTop: '50px' }} /> #87b880 */}
    </div>
);

function statusFormatter(cell) {
    if (cell === "On Review") {
        return (
            <p style={{ color: "#ffb101", marginBottom: '0px', fontWeight: 'bolder' }}>
                {cell}
            </p>
        );
    }
    else if (cell === "Request Init") {
        return (
            <p style={{ color: "#1f98df", marginBottom: '0px', fontWeight: 'bolder' }}>{cell}</p>
        )
    }
    return (
        <p style={{ color: "#87b880", marginBottom: '0px', fontWeight: 'bolder' }}>
            {cell}
        </p>
    );
}


const RemoteAll = ({ data, page, sizePerPage, onTableChange, totalSize, rowEvents, columns, noDataIndication }) => (
    <div>
        <BootstrapTable
            remote={{ sort: true, pagination: true }}
            pagination={paginationFactory({ page, sizePerPage, totalSize })}
            keyField="id"
            data={data}
            columns={columns}
            onTableChange={onTableChange}
            noDataIndication={noDataIndication}
            rowEvents={rowEvents}
        />
    </div>
);

export default function AmazonSearch() {
    //NAVIGATION
    const history = useHistory();
    const { SearchBar } = Search;
    //SLIDER
    const [val, setVal] = useState({ min: 0, max: 100 });
    const [val1, setVal1] = useState({ min: 0.000, max: 3.000 });
    const [startVal, setStartVal] = useState(val.min);
    const [endVal, setEndVal] = useState(val.max);
    //DIAGNOSIS
    const [isMounted, setIsMounted] = useState(true);
    const [selectedDiag, setSelectedDiag] = useState("");
    const [diagBoxes, setDiagBoxes] = useState([]);
    const [undiag, setUndiag] = useState([]);
    //SYMPTOMS
    const [isMounted1, setIsMounted1] = useState(true);
    const [selectedSymp, setSelectedSymp] = useState("");
    const [symptomsData, setSymptomsData] = useState([]);
    const [unsymp, setUnsymp] = useState([]);
    //TAGS
    const [isMounted2, setIsMounted2] = useState(true);
    const [selectedTag, setSelectedTag] = useState("");
    const [tagsData, setTagsData] = useState([]);
    const [untag, setUntag] = useState([]);
    //MEDICATION
    const [status, setStatus] = useState("Present");
    const [drugName, setDrugName] = useState("");
    const [medicationData, setMedicationData] = useState([]);
    const [medicData, setMedicData] = useState([]);
    const [pastDrugName, setPastDrugName] = useState("");
    const [pastMedicData, setPastMedicData] = useState([]);
    // const [pmedicData,setPmedicData] = useState([]);
    const [pmedicationData, setPmedicationData] = useState([]);

    //HANDEDNESS
    const [selectedHand, setSelectedHand] = useState("");
    const [checkHandedness, setCheckHandedness] = useState(false);
    //CHOICEFIELD
    const [choiceField, setChoiceField] = useState([]);
    const [selectedImpression, setSelectedImpression] = useState("");
    const [selectedImpC, setSelectedImpC] = useState("");
    //chkbx
    const [isChecked, setIsChecked] = useState(false);
    const [checkAge, setCheckAge] = useState(false);
    const [checkGender, setCheckGender] = useState(false);
    const [checkDiagnosis, setCheckDiagnosis] = useState(false);
    const [checkSymptoms, setCheckSymptoms] = useState(false);
    const [checkTag, setCheckTag] = useState(false);
    const [checkMedication, setCheckMedication] = useState(false);
    const [addToSearch, setAddToSearch] = useState(false);
    //concat table data
    const [releasedData, setReleasedData] = useState([]);
    const [onReviewData, setOnReviewData] = useState([]);
    const [data, setData] = useState([]);
    const [tdataLoad, setTdataLoad] = useState(true);
    const [totalDataSize, setTotalDataSize] = useState(0);
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [inputVal, setInputVal] = useState("");
    const [sortName, setSortName] = useState("");
    const [sortOrder, setSortOrder] = useState("");
    const [gender, setGender] = useState('');
    //age
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState('');
    const [sDate, setSdate] = useState('');
    const [eDate, setEdate] = useState('');
    const [dateRangeFlag, setDateRangeFlag] = useState(false);
    //hook-form
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const [locOptions, setLocOptions] = useState();
    const [loc, setLoc] = useState();
    const [ratio, setRatio] = useState();
    const [ratioVal, setRatioVal] = useState();
    const [ratioData, setRatioData] = useState([]);

    //secondary criteria variable
    const [ratioMarker, setRatioMarker] = useState([]);
    //table
    const [searchEnabled, setSearchEnabled] = useState(false);
    const [eyeType, setEyeType] = useState();
    let b = ratioData.length;

    //API
    const msds = new MasterDataService();
    const pnt = new PatientAccountService();
    const ds = new PipelineRequestService();
    const srv = new RequestService();
    const srch = new SearchService();
    const baseurl = "https://clinicaltables.nlm.nih.gov/api/rxterms/v3/search?terms";

    const sortOrders = (order, column) => {
        if (!order) return (<span>&nbsp;&nbsp;<FaSort color="#858585" /></span>);
        else if (order === 'asc') return (<span>&nbsp;&nbsp;<font color="red"><FaSortUp color="#000" /></font></span>);
        else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="red"><FaSortDown color="#000" /></font></span>);
        return null;
    }

    const columns = [
        { dataField: 'RequestNumber', text: 'Request Number', sort: true, sortCaret: sortOrders },
        { dataField: 'patient_name', text: 'Patient Name', sort: true, sortCaret: sortOrders },

        { dataField: 'gender', text: 'Gender', sort: true, sortCaret: sortOrders },
        { dataField: 'status', text: 'Status', sort: true, sortCaret: sortOrders, formatter: statusFormatter }
        //   { dataField: 'action', text: 'Action', headerAlign: 'center',formatter:actionFormatter},
    ];
    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            if (row.status === "On Review" || row.status === "Req Init") {
                history.push({
                    pathname: '/view-request/:pipeline-request',
                    state: {
                        reqId: row.id
                    }
                });
            } else if (row.status === "Released") {
                history.push({
                    pathname: '/released-request/:dataset-information',
                    state: {
                        reqId: row.id
                    }
                })
            }

        }

    }

    //INITIAL GRID ACTIVE REQUESTS
    function getReleasedRequest() {
        let reqData = {
            SRQInput: {
                status: "Released"
            },
            DataFinder: {
                pagesize: 10,
                currentpage: 1,
                sortbycolumn: "",
                sortby: "",
                searchdata: ""
            }
        }

        srv.GetServiceRequest(reqData).then((response) => {
            if (response.data) {
                if (response.data.ServiceRequestDetail !== [] || response.data.ServiceRequestDetail !== null || response.data.ServiceRequestDetail !== undefined) {
                    setTotalDataSize(response.data.DataFinder.totalrecords);
                    setReleasedData(response.data.ServiceRequestDetail);
                }
            }
        })

    }
    useEffect(() => {
        // getReleasedRequest();
        concatenateFunctions();
    }, []);

    function getActiveRequest(page, sizePerPage, inputVal, sortName, sortOrder) {
        let reqData = {
            "ADSRInput": {
                "status": "All",
                "fromdaterange": "",
                "todaterange": "",
                "tags": "",
                "email": "",
                "category": "Service Request"
            },
            "DataFinder": {
                "pagesize": 2000,
                "currentpage": 1,
                "sortbycolumn": "",
                "sortby": "",
                "searchdata": ""
            }
        }

        srch.GetAllActiveRequest(reqData).then((response) => {
            if (response.data) {
                if (response.data.Data !== [] || response.data.Data !== null || response.data.Data !== undefined) {
                    setTdataLoad(true);
                    setData([]);
                    setSearchEnabled(false);
                    let data = response.data.Data;
                    // console.log('data',data);
                    setOnReviewData(data);
                    setTotalDataSize(response.data.DataFinder.totalrecords);
                    setData(data.slice(0, sizePerPage));
                    // setActiveRequestData(releasedData.concat(data));
                }
                else {
                    setTdataLoad(false);
                    setOnReviewData([]);
                }
            }
        })
    }


    function concatenateFunctions() {
        getDiagnosisData();
        getSymptomsData();
        getActiveRequest(page, sizePerPage);
        // getReleasedRequest();
    }

    //REMOTE TABLE CHANGE
    const handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
        let result = onReviewData;
        let order;
        //sort
        if (sortOrder === 'asc') {
            order = data.sort((a, b) => {
                if (a[sortField] > b[sortField]) {
                    return 1;
                } else if (b[sortField] > a[sortField]) {
                    return -1;
                }
                return 0;
            });
        } else {
            order = data.sort((a, b) => {
                if (a[sortField] > b[sortField]) {
                    return -1;
                } else if (b[sortField] > a[sortField]) {
                    return 1;
                }
                return 0;
            });
        }
        setSortName(sortField);
        setSortOrder(order);
        //pagination
        const currentIndex = (page - 1) * sizePerPage;
        setPage(page);
        setData(result.slice(currentIndex, currentIndex + sizePerPage));
        setSizePerPage(sizePerPage);
        if (searchEnabled === true) {
            submitSearch(page, sizePerPage, inputVal, sortField, sortOrder);
        } else {
            getActiveRequest(page, sizePerPage, inputVal, sortField, sortOrder);
        }
    }
    //DIAGNOSIS FUNCTIONS
    function getDiagnosisData() {
        msds.getDiagnosis()
            .then((response) => {
                if (isMounted) {
                    if (response.data) {
                        if (response.data.data != []) {
                            let undiag = [];
                            for (let i = 0; i < response.data.data.length; i++) {
                                undiag.push(response.data.data[i]);
                            }
                            setUndiag(undiag);
                        }
                    }
                }
            }).catch(function (error) {
                if (error.response.status === 403) {
                    history.push('/login');
                }
            })
    }

    const diagnosisOption = undiag.map((data, i) => {
        return {
            label: data.diagnosis_name,
            value: data.diagnosis_name,
            id: data.id
        }
    });

    const handleDiagnosisChange = (selectedDiag) => {
        setSelectedDiag(selectedDiag);
    };

    function saveDiagnosis() {
        for (let i = 0; i < selectedDiag.length; i++) {
            setUndiag(current => current.filter(x => {
                if (x.id !== selectedDiag[i].id)
                    return x.id;
            }))
        }
        setDiagBoxes(diagBoxes.concat(selectedDiag));
        setSelectedDiag("");
    }
    function removeDiagnosis(diagnosis) {
        const newDiag = diagBoxes.filter((diag) => diag.id !== diagnosis.id);
        const undiags = [{ diagnosis_name: diagnosis.label, id: diagnosis.id }]
        setDiagBoxes(newDiag);
        setUndiag(undiag.concat(undiags));
    }
    //AGE
    const handleDateChange = (date) => {
        setStartDate(format(new Date(date), 'MM/dd/yyyy'));
        setSdate(format(new Date(date), "yyyy-MM-dd"));
        setDateRangeFlag(false);
    };
    const handleEndDateChange = (date) => {
        setEndDate(format(new Date(date), 'MM/dd/yyyy'));
        setEdate(format(new Date(date), "yyyy-MM-dd"));
        setDateRangeFlag(false);
    };
    //SYMPTOMS FUNCTIONS
    function getSymptomsData() {
        msds.getSymptoms()
            .then((response) => {
                if (isMounted1) {
                    if (response.data) {
                        if (response.data.data !== []) {
                            let unsymp = [];
                            for (let i = 0; i < response.data.data.length; i++) {
                                unsymp.push(response.data.data[i]);
                            }
                            setUnsymp(unsymp);
                        }

                    }

                }
            }).catch(function (error) {
                if (error.response.status === 403) {
                    history.push('/login');
                }
            })
    }

    const symptomsOption = unsymp.map((data, i) => {
        return {
            label: data.symptoms_name,
            value: data.symptoms_name,
            id: data.id
        }
    });

    const handleSymptomsChange = (selectedSymp) => {
        setSelectedSymp(selectedSymp);
    };
    function saveSymptoms() {
        for (let i = 0; i < selectedSymp.length; i++) {
            setUnsymp(current => current.filter(x => {
                if (x.id !== selectedSymp[i].id)
                    return x.id;
            }))
        }
        setSymptomsData(symptomsData.concat(selectedSymp));
        // setSymptomsData(selectedSymp);
        setSelectedSymp("");
    }
    function removeSymptoms(symptoms) {
        const newSymp = symptomsData.filter((symp) => symp.id !== symptoms.id);
        const unsymps = [{ symptoms_name: symptoms.label, id: symptoms.id }]
        setSymptomsData(newSymp);
        setUnsymp(unsymp.concat(unsymps));
    }
    //TAGS FUNCTIONS
    function fetchAllPatientTags() {
        pnt.GetAllPatientTag().then((response) => {
            if (isMounted2) {
                if (response.data.data !== []) {
                    let untags = [];
                    for (let i = 0; i < response.data.data.length; i++) {
                        untags.push(response.data.data[i]);
                    }
                    setUntag(untags);
                    //   setSymptomsData(response.data.data);
                }

            }
        })
    }
    useEffect(() => {
        fetchAllPatientTags();
        return () => { setIsMounted2(false); }
    }, []);

    const tagsOption = untag.map((data, i) => {
        return {
            label: data.TagName,
            value: data.TagName,
            id: data.id
        }
    });

    function handleTagChange(selectedTag) {
        setSelectedTag(selectedTag);
    }

    function saveTags() {
        for (let i = 0; i < selectedTag.length; i++) {
            setUntag(current => current.filter(x => {
                if (x.id !== selectedTag[i].id)
                    return x.id;
            }))
        }
        setTagsData(tagsData.concat(selectedTag));
        // setSymptomsData(selectedSymp);
        setSelectedTag("");
    }
    function removeTag(tags) {
        const newTags = tagsData.filter((tag) => tag.id !== tags.id);
        const untags = [{ TagName: tags.label, id: tags.id }]
        setUntag(untag.concat(untags));
        setTagsData(newTags);
    }

    //MEDICATION
    function inputChange(value) {
        medicName(value);
    }
    function inputPastChange(value) {
        pmedicName(value);
    }
    async function medicName(val) {
        axios.get(`${baseurl}=${val}`).then((res) => {
            if (res) {
                setMedicationData(res.data[1]);
            }
        })
    }
    async function pmedicName(val) {
        axios.get(`${baseurl}=${val}`).then((res) => {
            if (res) {
                setPmedicationData(res.data[1]);
            }
        })
    }

    const options = medicationData.map(function (dbSelect, i) {
        return {
            value: dbSelect,
            label: dbSelect,
            id: i.toString()
            // id: dbSelect.id,
        };
    });

    const pastOptions = pmedicationData.map(function (pmedic, i) {
        return {
            value: pmedic,
            label: pmedic,
            id: i.toString()
        }
    })

    function handledChange(drugName) {
        setDrugName(drugName);
    }
    function handlePastDrugChange(pastDrugName) {
        setPastDrugName(pastDrugName);
    }

    function saveMedication() {
        setMedicData(medicData.concat(drugName));
        setDrugName("");
    }

    function removeMedication(medic) {
        const newMedic = medicData.filter((med) => med.value !== medic.value);
        setMedicData(newMedic);
    }
    function savePastMedication() {
        setPastMedicData(pastMedicData.concat(pastDrugName));
        setPastDrugName("");
    }
    function removePastMedication(pmedic) {
        const newPastMedic = pastMedicData.filter((med) => med.value !== pmedic.value);
        setPastMedicData(newPastMedic);
    }
    //HANDEDNESS FUNCTIONS
    const handednessOption = [
        { label: "Right Hand", value: "righthand", id: 1 },
        { label: "Left Hand", value: "lefthand", id: 2 }
    ];
    function handleHchange(selectedHand) {
        setSelectedHand(selectedHand);
    }

    //INTERPRETATION CHOICEFIELDS
    async function InterpretationChoiceField() {
        ds.GetInterpretationChoiceField().then((response) => {
            if (response.data.status === "success") {
                setChoiceField(response.data.data);
            }
        });
    }

    useEffect(() => {
        InterpretationChoiceField();
    }, []);

    const choiceFieldOptions = choiceField.map(function (item) {
        return {
            value: item,
            label: item,
        };
    });
    function impEyeOpened(selectedImpression) {
        setSelectedImpression(selectedImpression);
    }
    function impEyeClosed(selectedImpC) {
        setSelectedImpC(selectedImpC);
    }
    //SLIDER FUNCTIONS

    const onValueMin = (e) => {
        let minVal = parseInt(e.target.value, 10);
        setVal(prev => ({ ...prev, min: minVal || 0, max: prev.max }))
        setStartVal(e.target.value);
    }



    const onValueMax = (e) => {
        let maxVal = parseInt(e.target.value, 10);
        setVal(prev => ({ ...prev, max: maxVal || 0 }));
        setEndVal(e.target.value);
    }
    const addToSearchFunc = () => {
        setAddToSearch(true);
        hideModal('closeDiagModal');
    }

    function hideModal(target) {
        document.getElementById(target).click();

    };

    const onSubmit = (data) => {
        if (data.age === true) {
            setCheckAge(true);
        }
        else { setCheckAge(false); }

        if (data.tags === true) {
            setCheckTag(true);
        }
        else {setCheckTag(false);setSelectedTag("");setTagsData([]);}
        if (data.birthgender == true) {
            setCheckGender(true);
        }
        else { setCheckGender(false); setGender(""); }
        if (data.diagnosis === true) {
            setCheckDiagnosis(true);
        } else { setCheckDiagnosis(false);setSelectedDiag(""); setDiagBoxes([]); }
        if (data.symptoms === true)
            setCheckSymptoms(true);
        else { setCheckSymptoms(false); setSymptomsData([]); setSelectedSymp("");}
        if (data.medication === true) {
            setCheckMedication(true);
        }

        else { setCheckMedication(false); setPastMedicData([]); setMedicData([]);setDrugName(""); setPastDrugName(""); }
        hideModal('closeDiagModal');
        if (data.handedness === true) {
            setCheckHandedness(true);
        } else { setCheckHandedness(false); setSelectedHand(""); }
    }


    // useEffect(() => {
    //     let b = ratioData.length;
    // }, []);



    const ratioOptions = ratioMarker.map(function (marker) {
        return {
            value: marker.rulename,
            label: marker.rulename,
            id: marker.id,
            location: marker.rulelocation
        }
    });

    // const ratioOptions = ratioMarker.map((marker) => {

    //         return {
    //             value: marker.rulename,
    //             label:marker.rulename,
    //             id:marker.id


    //     }
    // })
    const handleRatioChange = (e, opt) => {
        let b = '';
        if (opt.length > 0) {
            for (let i = 0; i < opt.length; i++) {
                if (opt[i].id == e.target.value) {
                    setRatioVal(opt[i].value);
                    b = opt[i].value;
                    setLocOptions(opt[i].location);
                    setLoc(opt[i].location);
                }
            }
        }
        setVal1({ min: 0, max: 3 });
        setRatio(e.target.value);
        //     for(let j=0;j<opt.length;j++){
        //     if (opt[j].=== "Theta/beta ratio") {
        //         setLocOptions("G11");
        //     }
        //     else if (b === "Alpha/beta ratio") {
        //         setLocOptions("I16");
        //     }
        //     else {
        //         setLocOptions("B16");
        //     }
        // }
    }
    const handleEyeTypeChange = (e) => {
        let b = e.target.value;
        setEyeType(b);
        getMarkerDetail(e.target.value);
        setEyeType(e.target.value);
        // getMarkerDetail(b);
        // console.log('test',e.target.value);
        // setVal1({ min: 0, max: 3 });
        // getMarkerDetail(e.target.value);
    }


    function getMarkerDetail(eyetype) {
        clearForm();
        srch.GetMarkerDetails().then((response) => {
            if (response.data) {
                let marker = [];
                // console.log('res', response.data);
                if (response.data.data !== []) {
                    if (response.data.data.length > 0) {
                        for (let i = 0; i < response.data.data.length; i++) {
                            if (response.data.data[i].rfieldtype === "textbox" && eyetype === "eyeclosed") {
                                if (response.data.data[i].docfieldtype === "EC") {
                                    marker.push(response.data.data[i]);
                                }
                            } else if (response.data.data[i].rfieldtype === "textbox" && eyetype === "eyeopen") {
                                if (response.data.data[i].docfieldtype === "EO") {
                                    marker.push(response.data.data[i]);
                                }
                            }
                        }
                        setRatioMarker(marker);
                    }


                }
                else {
                    setRatioMarker([]);
                }

            }
        })
    }
    const onSubmit1 = () => {

        setRatioData([...ratioData.concat({ ratio: ratio, ratioVal: ratioVal, location: loc, minval: val1.min, maxval: val1.max, type: eyeType })]);
        // let b = ratioData;
        // if (ratioData.length == 0) {
        //     setRatioData([{ ratio: ratio, ratioVal: ratioVal, location: loc, minval: val1.min, maxval: val1.max, type: eyeType }]);
        //     // setRatioData([...ratioData.concat({ ratio: ratio, location: loc, minval: val1.min, maxval: val1.max })]);
        // } else {
        //     if (ratioData.length > 0) {
        //         for (let i = 0; i < ratioData.length; i++) {
        //             if (ratio == ratioData[i].ratio) {
        //                 setRatioData([{ ratio: ratio, ratioVal: ratioVal, location: loc, minval: val1.min, maxval: val1.max, type: eyeType }])
        //             }
        //             else {
        //                 setRatioData([...ratioData.concat({ ratio: ratio, ratioVal: ratioVal, location: loc, minval: val1.min, maxval: val1.max, type: eyeType })]);
        //             }
        //         }
        //     }

        // }
        clearForm();
        setEyeType("");
        //   if(ratioData.length>0){
        // for(let i=0;i<ratioData.length;i++){
        //     if(ratio==ratioData[i].ratio){
        //         console.log('same');
        //     }
        //     else{
        //         setRatioData([...ratioData.concat({ratio:ratio,location:loc,minval:val1.min,maxval:val1.max})]);
        //     }
        // }
        //   }/

        // console.log('ratio', ratio, 'location', loc, 'minval', val1.min, 'maxval', val1.max);
        // for(let i=0;i<ratio.length;i++){
        //     for(let j=0;j<ratioData.length;j++){
        //         if(i==j){
        //            if(ratio[i]==ratioData[j].ratio){
        //             console.log('ratios',ratioData[j].ratio,ratio[i]);
        //            }
        //            else{
        //             console.log('unew',ratio[i],ratioData[j].ratio);
        //            }
        //         }
        //     }

        // }
        // setRatioData({ratio:ratio,location:loc,minval:val1.min,min:val1.max});
        hideModal('closeDiagModal1');
    }
   

    const submitSearch = (page, sizePerPage) => {

        setOnReviewData([]);
        setTdataLoad(true);
        let fdata = [];
        let pastMedication = [];
        let presentMedication = [];
        let diagnosis = [];
        let symptoms = [];
        let tags = [];
        let d = "";
        let e = "";
        ratioData.forEach((items, i) => {
            fdata.push({
                "rule_id": items.ratio,
                "start_on": items.minval,
                "end_on": items.maxval,
                "type": items.type
            });
        })
        // if (selectedDiag !== "") {
        //     diagnosis = selectedDiag.map((x) => {
        //         return x.label;
        //     });
        // }
        // else if(selectedDiag!=="" && diagBoxes!==""){
            
        // }
        //  else {
            diagnosis = diagBoxes.map((x) => {
                return x.label;
            });
        // }
        // if (selectedSymp !== "") {
        //     symptoms = selectedSymp.map((s) => {
        //         return s.label;
        //     })
        // } else {
            symptoms = symptomsData.map((s) => {
                return s.label;
            })
    //    / }
        // if (selectedTag !== "") {
        //     tags = selectedTag.map((t) => {
        //         return t.label;
        //     })
        // }
        // else {
            tags = tagsData.map((t) => {
                return t.label;
            })
        // }

        // if (pastDrugName !== "") {
        //     pastMedication = pastDrugName.map((past) => {
        //         return past.value;
        //     });
        // } else {
            pastMedication = pastMedicData.map((past) => {
                return past.value;
            });
        // }

        // if (drugName !== "") {
        //     presentMedication = drugName.map((present) => {
        //         return present.value;
        //     });
        // } else {
            presentMedication = medicData.map((present) => {
                return present.value;
            });
        // }
        // if (status === "Past") {
        //     pastMedication = pmedicData.map((past) => {
        //         return past.value;
        //     })
        // } else {
        //     presentMedication = medicData.map((present) => {
        //         return present.value;
        //     })
        // }


        if (checkAge === true) {
            d = parseInt(startVal, 10);
            e = parseInt(endVal, 10);
        } else {
            d = "";
            e = "";
        }

        let reqData = {
            "primary_criteria": {
                "age_start": d,
                "age_end": e,
                "gender": gender,
                "pnt_tags": tags.join("|") || "",
                "pntmedic_past": pastMedication.join("|") || "",
                "pntmedic_present": presentMedication.join("|") || "",
                "diagnosis": diagnosis.join("|") || "",
                "symptoms": symptoms.join("|") || "",
                "handedness": selectedHand.value || ""
            },
            "additional_criteria": fdata,
            "Datasorting": {
                "pagesize": 2000,
                "currentpage": 1,
                "sortbycolumn": "created_on",
                "sortby": "desc",
                "searchdata": ""
            }

        }

        srch.EEGSearch(reqData).then((response) => {
            if (response.data) {
                if (response.data.AdvanceSearchRequestDetail !== "[]" && response.data.AdvanceSearchRequestDetail !== [] && response.data.Datasorting.totalrecords !== 0) {
                    setTdataLoad(true);
                    setOnReviewData([]);
                    setTotalDataSize(0);
                    setSearchEnabled(true);
                    setData([]);
                    setOnReviewData(response.data.AdvanceSearchRequestDetail);
                    let data = response.data.AdvanceSearchRequestDetail;
                    setTotalDataSize(response.data.Datasorting.totalrecords);
                    // setData(data.slice(0, sizePerPage));
                    setData(data.slice(0, sizePerPage));
                    // if (response.data.stataus === "success") {
                    //     console.log(response.data);
                    // }
                } else {
                    setTdataLoad(false);
                    setOnReviewData([]);
                }
            }
        })
    }
    const removeItem = (i) => {
        const newItem = ratioData.filter((x, id) => id !== i);
        setRatioData(newItem);
    }
    function clearForm() {
        // setRatioData([]);
        setLoc('');
        setRatio('');
        setEyeType('');
        setLocOptions('');
        setVal1({ min: 0, max: 3 });
    }
    const handleSliderChange = (e) => {

        setStartVal(e.min);
        setEndVal(e.max);
        setVal(e);
    }
    const changeMedicationStatus = (e) => {
       
        if (e.target.checked === true) {
            setStatus(e.target.value);
        }
        // else if(e.target.value==="Present"){
        //     setStatus("Present");
        // }
    }

    return (
        <div className='col diagnosis amazonSearch'>
            <div className='row g-0 px-4 pt-3'>
                <div className='table-request my-2 me-3'>
                    <div className='table-data me-4'>
                        <div className="pe-3">
                            <div className='row mb-2'>
                                <div className=" col-md-4 filter-section">
                                    <h5 className='text-start'>Filter <FaListUl className='ms-2' /></h5>
                                    <div className='card bg-white rounded border-0 p-2'>
                                        <div className='card-header border-0'>
                                            <Row className="px-2">
                                                <h6 className='col-md-6 text-start fw-800'>Primary Criteria</h6>
                                                <div className='col-md-6 text-end' >
                                                    <MdOutlineAdd data-bs-toggle="modal" data-bs-target="#primaryCriteria" className='add-srch-btn' />
                                                </div>
                                            </Row>
                                        </div>
                                        <div className='active-sessions text-start ps-2 my-2'>
                                            <button className='btn btn-active px-3 py-1'>All Active Requests</button>
                                        </div>
                                        <div className='search-container px-2 my-2 py-2'>
                                            {/* <div className='card row border-0'>
                                            <div className='card-header col-md-1 border-0'>

                                            </div>
                                            <div className='card-body col border-0'>

                                            </div> */}
                                            {/* </div> */}
                                            <div className="accordion accordion-flush" id="accordionFlushExample">
                                                {checkAge === true ?
                                                    <div className="accordion-item mb-2 border-0">
                                                        <h2 className="accordion-header" id="flush-headingOne">
                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-age" aria-expanded="false" aria-controls="flush-age">
                                                                <AiOutlinePlus className='me-2 rotate-icon' size={18} />  Age
                                                            </button>
                                                        </h2>
                                                        <div id="flush-age" className="accordion-collapse collapse border-0" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                                            <div className="accordion-body">
                                                                <div className='row mb-3 '>
                                                                    <label className="col-auto m-1">Range</label>
                                                                    <input type="number" className='col form-control w-25 m-1' value={startVal} onChange={onValueMin} />
                                                                    <input type="number" className="form-control col w-25 m-1" value={endVal} onChange={onValueMax} />

                                                                    {/* <Col className="me-0">
                                                                        <DatePicker
                                                                            className='form-control col-auto'
                                                                            dateFormat="MM/dd/yyyy"
                                                                            autoComplete='off'
                                                                            placeholderText="Select date"
                                                                            name="startDate"
                                                                            maxDate={new Date()}
                                                                            value={startDate}
                                                                            onChange={handleDateChange}
                                                                            showMonthDropdown
                                                                            showYearDropdown
                                                                            dropdownMode="select"
                                                                            onKeyDown={(e) => {
                                                                                e.preventDefault();
                                                                            }}
                                                                        /></Col>
                                                                    to
                                                                    <Col>
                                                                        <DatePicker
                                                                            className='form-control col-auto'
                                                                            dateFormat="MM/dd/yyyy"
                                                                            placeholderText="Select date"
                                                                            name="endDate"
                                                                            autoComplete='off'
                                                                            minDate={new Date(startDate)}
                                                                            maxDate={new Date()}
                                                                            value={endDate}
                                                                            onChange={handleEndDateChange}
                                                                            showMonthDropdown
                                                                            showYearDropdown
                                                                            dropdownMode="select"
                                                                            onKeyDown={(e) => {
                                                                                e.preventDefault();
                                                                            }}
                                                                        /></Col> */}
                                                                </div>
                                                                <div className=" mb-2 ps-3 pe-4 rnge1 display-inline">
                                                                    <InputRange
                                                                        maxValue={100}
                                                                        minValue={0}
                                                                        step={1}
                                                                        value={val}
                                                                        onChange={handleSliderChange}
                                                                    />

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : ""}
                                                {checkGender === true ?
                                                    <div className="accordion-item mb-2">
                                                        <h2 className="accordion-header" id="flush-headingTwo">
                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-gender" aria-expanded="false" aria-controls="flush-gender">
                                                                <AiOutlinePlus className='me-2 rotate-icon' size={18} /> Birth Gender
                                                            </button>
                                                        </h2>
                                                        <div id="flush-gender" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                                            <div className="accordion-body">

                                                                <div className='row'>
                                                                    <div className="form-check col">
                                                                        <input className="radio-btn w-25" type="radio" name="flexRadioDefault" value="Male" id="flexRadioDefault1" onChange={(e) => setGender(e.target.value)} />
                                                                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                                            Male
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check col">
                                                                        <input className="radio-btn w-25" type="radio" name="flexRadioDefault" value="Female" id="flexRadioDefault2" onChange={(e) => setGender(e.target.value)} />
                                                                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                                                                            Female
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check col">
                                                                        <input className="radio-btn w-25" type="radio" name="flexRadioDefault" value="Others" id="flexRadioDefault3" onChange={(e) => setGender(e.target.value)} />
                                                                        <label className="form-check-label" htmlFor="flexRadioDefault3">
                                                                            Others
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : ""}
                                                {checkDiagnosis === true ?
                                                    <div className="accordion-item mb-2">
                                                        <h2 className="accordion-header" id="flush-headingTwo">
                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-diagnosis" aria-expanded="false" aria-controls="flush-diagnosis">
                                                                <AiOutlinePlus className='me-2 rotate-icon' size={18} /> Diagnosis
                                                            </button>
                                                        </h2>
                                                        <div id="flush-diagnosis" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                                            <div className="accordion-body">
                                                                {/* <div className='input-group'>
                                                                
                                                            </div> */}
                                                                <div className="row g-0 mb-2">
                                                                    <Select
                                                                        className='col-md-11 text-start border-end-0'
                                                                        isMulti
                                                                        value={selectedDiag}
                                                                        onChange={handleDiagnosisChange}
                                                                        options={diagnosisOption}
                                                                    />
                                                                    <button className="col-md-1 search-butn border-top border-end border-bottom border-left-0 bg-white p-1" disabled={selectedDiag == "" ? true : false} onClick={() => saveDiagnosis()}>
                                                                        <TiTick className='bg-white border-start but-add ps-1' color="#5A53B2" size={23} />
                                                                    </button>
                                                                    {/* <input type="text" className="form-control fs-6 border-end-0" placeholder="Search" />
                                                            <button className="btn border-start-0 border-end border-top border-bottom" type="button" id="button-addon2">
                                                                <BsSearch />'hsl(0, 0%, 80%)'
                                                            </button> */}
                                                                </div>

                                                                <div className="border rounded row container bg-white m-0 p-2" >
                                                                    {diagBoxes.map((diagnosis, i) => {
                                                                        return (
                                                                            <div className='tag-list col-md-auto m-1 rounded py-1' key={diagnosis.id}>{diagnosis.label} <span className='ms-2' onClick={() => removeDiagnosis(diagnosis)}>x</span></div>
                                                                        )
                                                                    })}
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    : ""}
                                                {checkSymptoms === true ?
                                                    <div className="accordion-item mb-2">
                                                        <h2 className="accordion-header" id="flush-headingTwo">
                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-symptoms" aria-expanded="false" aria-controls="flush-symptoms">
                                                                <AiOutlinePlus className='me-2 rotate-icon' size={18} /> Symptoms
                                                            </button>
                                                        </h2>
                                                        <div id="flush-symptoms" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                                            <div className="accordion-body">
                                                                {/* <div className='input-group'>
                                                                
                                                            </div> */}
                                                                <div className="input-group col-md-8 mb-2">
                                                                    <Select
                                                                        className='col-md-11 text-start'
                                                                        isMulti
                                                                        value={selectedSymp}
                                                                        onChange={handleSymptomsChange}
                                                                        options={symptomsOption}
                                                                    />
                                                                    <button className="col-md-1 border-top border-end border-bottom border-left-0 bg-white p-1" disabled={selectedSymp == "" ? true : false} onClick={() => saveSymptoms()}  >
                                                                        <TiTick className='bg-white border-start ps-1' color="#5A53B2" size={23} />
                                                                    </button>
                                                                </div>
                                                                <div className="border rounded row container bg-white m-0 p-2">
                                                                    {symptomsData.map((symptoms, i) => {
                                                                        return (
                                                                            <div className='tag-list col-md-auto m-1 rounded py-1' key={symptoms.id}>{symptoms.label} <span className='ms-2' onClick={() => removeSymptoms(symptoms)}>x</span></div>
                                                                        )
                                                                    })}
                                                                </div>



                                                            </div>
                                                        </div>
                                                    </div>
                                                    : ""}
                                                {checkTag === true ?
                                                    <div className="accordion-item mb-2">
                                                        <h2 className="accordion-header" id="flush-headingTwo">
                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-tags" aria-expanded="false" aria-controls="flush-tags">
                                                                <AiOutlinePlus className='me-2 rotate-icon' size={18} /> Tags
                                                            </button>
                                                        </h2>
                                                        <div id="flush-tags" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                                            <div className="accordion-body">
                                                                <div className="row g-0 mb-2">
                                                                    <Select
                                                                        className='col-md-11 text-start'
                                                                        isMulti
                                                                        value={selectedTag}
                                                                        onChange={handleTagChange}
                                                                        options={tagsOption}
                                                                    />
                                                                    <button className="col-md-1 border-top border-end border-bottom border-left-0 bg-white p-1" disabled={selectedTag == "" ? true : false} onClick={() => saveTags()}>
                                                                        <TiTick className='bg-white border-start ps-1' color="#5A53B2" size={23} />
                                                                    </button>
                                                                    {/* <input type="text" className="form-control fs-6 border-end-0" placeholder="Search" />
                                                            <button className="btn border-start-0 border-end border-top border-bottom" type="button" id="button-addon2">
                                                                <BsSearch />
                                                            </button> */}
                                                                </div>

                                                                <div className="border rounded row container bg-white m-0 p-2" >
                                                                    {tagsData.map((tag, i) => {
                                                                        return (
                                                                            <div className='tag-list col-md-auto m-1 rounded py-1' key={tag.id}>{tag.label} <span className='ms-2' onClick={() => removeTag(tag)}>x</span></div>
                                                                        )
                                                                    })}
                                                                </div>


                                                            </div>
                                                        </div>
                                                    </div>
                                                    : ""}
                                                {checkMedication === true ?
                                                    <div className="accordion-item mb-2">
                                                        <h2 className="accordion-header" id="flush-headingTwo">
                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-medication" aria-expanded="false" aria-controls="flush-medication">
                                                                <AiOutlinePlus className='me-2 rotate-icon' size={18} /> Medication
                                                            </button>
                                                        </h2>
                                                        <div id="flush-medication" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                                            <div className="accordion-body">
                                                                <div className="row g-0 mb-2">
                                                                    {/* <input type="radio" id="present" value="Present" name="medication" checked onChange={(e) => setStatus(e.target.value)} /> */}

                                                                    {/* <ul className="donate-now mt-0 mb-2 d-flex" >
                                                                        <li className="col">
                                                                            <input type="radio" id="medication" value="Present" name="medication" checked onChange={(e) => changeMedicationStatus(e)} />
                                                                            <label htmlFor="present">Present</label>
                                                                        </li>
                                                                        <li className="col">
                                                                            <input type="radio" id="medication" className='w-50'  name="medication"  value="Past" onChange={(e) => changeMedicationStatus(e)} />
                                                                            <label htmlFor="past" className=" px-2">Past</label>
                                                                        </li>
                                                                    </ul> */}
                                                                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                                                        <li className="nav-item" role="presentation">
                                                                            <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Present</button>
                                                                        </li>
                                                                        <li className="nav-item" role="presentation">
                                                                            <button className="nav-link px-4" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Past</button>
                                                                        </li>

                                                                    </ul>
                                                                    <div className="tab-content" id="pills-tabContent">
                                                                        <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                                                            <div className="row g-0 mb-2">
                                                                                <Select
                                                                                    className='col-md-11 text-start rounded'
                                                                                    isMulti
                                                                                    value={drugName}
                                                                                    onChange={handledChange}
                                                                                    options={options}
                                                                                    defaultValue={drugName}
                                                                                    onInputChange={inputChange}
                                                                                />
                                                                                <button className="col-md-1 border-top border-end border-bottom border-left-0 bg-white p-1" disabled={drugName === "" ? true : false} onClick={() => saveMedication()}>
                                                                                    <TiTick className='bg-white border-start ps-1' color="#5A53B2" size={23} />
                                                                                </button>
                                                                                <div className="border rounded bg-white row container mx-0 mt-2 p-2" >
                                                                                    {medicData.map((medic, i) => {
                                                                                        return (
                                                                                            <div className='tag-list col-md-auto m-1 rounded py-1' key={i}>{medic.label} <span className='ms-2' onClick={() => removeMedication(medic)}>x</span></div>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                                                            <div className="row g-0 mb-0">
                                                                                <Select
                                                                                    className='col-md-11 text-start rounded'
                                                                                    isMulti
                                                                                    value={pastDrugName}
                                                                                    onChange={handlePastDrugChange}
                                                                                    options={pastOptions}
                                                                                    defaultValue={pastDrugName}
                                                                                    onInputChange={inputPastChange}
                                                                                />
                                                                                <button className="col-md-1 border-top border-end border-bottom border-left-0 bg-white p-1" disabled={pastDrugName === "" ? true : false} onClick={() => savePastMedication()}>
                                                                                    <TiTick className='bg-white border-start ps-1' color="#5A53B2" size={23} />
                                                                                </button>
                                                                                <div className="border rounded bg-white row container mx-0 mt-2 p-2" >
                                                                                    {pastMedicData.map((medic, i) => {
                                                                                        return (
                                                                                            <div className='tag-list col-md-auto m-1 rounded py-1' key={i}>{medic.label} <span className='ms-2' onClick={() => removePastMedication(medic)}>x</span></div>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>



                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : ""}
                                                {checkHandedness === true ?
                                                    <div className="accordion-item mb-2">
                                                        <h2 className="accordion-header" id="flush-headingTwo">
                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-handedness" aria-expanded="false" aria-controls="flush-handedness">
                                                                <AiOutlinePlus className='me-2 rotate-icon' size={18} /> Handedness
                                                            </button>
                                                        </h2>
                                                        <div id="flush-handedness" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                                            <div className="accordion-body">
                                                                <div className="row g-0 mb-2">

                                                                    <div className="row g-0 mb-2">
                                                                        <Select
                                                                            className='col-md-12 text-start border rounded'
                                                                            onChange={handleHchange}
                                                                            options={handednessOption} p
                                                                            defaultValue={selectedHand}
                                                                        />
                                                                        {/* <button className="col-md-1 border-top border-end border-bottom border-left-0 bg-white p-1" disabled={selectedHand === "" ? true : false} > */}
                                                                        {/* <TiTick className='bg-white border-start ps-1' color="#5A53B2" size={23} /> */}
                                                                        {/* </button> */}
                                                                        {/* <input type="text" className="form-control fs-6 border-end-0" placeholder="Search" />
                                                            <button className="btn border-start-0 border-end border-top border-bottom" type="button" id="button-addon2">
                                                                <BsSearch />
                                                            </button> */}
                                                                    </div>

                                                                    {/* <div className="border rounded bg-white row container m-0 p-2" >
                                                                        {medicData.map((medic, i) => {
                                                                            return (
                                                                                <div className='tag-list col-md-auto m-1 rounded py-1' key={i}>{medic.label} <span className='ms-2' onClick={() => removeMedication(medic)}>x</span></div>
                                                                            )
                                                                        })}
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : ""}
                                            </div>
                                        </div>
                                        <div className="card-header border-0">
                                            <Row className="px-2 ">
                                                <h6 className='col-md-6 text-start fw-800'>Additional Criteria</h6>
                                                <div className='col-md-6 text-end' >
                                                    <MdOutlineAdd data-bs-toggle="modal" data-bs-target="#secondaryCriteria" className='add-srch-btn' />
                                                </div>
                                            </Row>
                                        </div>

                                        {/* {ratioData.length > 0 ? */}
                                        <div className='search-container px-2 my-2 py-2'>
                                            {ratioData.map((ratio, i) => {
                                                return (

                                                    <div className='card-body shadow-sm mb-2' key={i}>
                                                        <Row>
                                                            <p className='col mb-0 text-start ratio-content fs-6'>With {ratio.ratioVal} from location {ratio.location} between {ratio.minval} and {ratio.maxval} <span className='ms-2 p-1 btn text-dark pb-2 typeeye'>{ratio.type}</span></p> <span onClick={() => removeItem(i)} className='text-danger my-auto fs-6 col-auto px-2 '>x</span>
                                                        </Row>
                                                    </div>

                                                );
                                                // return (
                                                //     <div className='card-body shadow-sm mb-2' key={i}>
                                                //         <Row>
                                                //             <p className='col mb-0 text-start ratio-content fs-6'>With {ratio} from location {loc} between 4.5 and 6</p> <span className='text-danger fs-6 col-auto px-2'>x</span>
                                                //         </Row>
                                                //     </div>
                                                // );
                                            })}

                                        </div>
                                        {/* : ""}  */}
                                        <button type="button" className='col-md-auto ms-auto btn-save rounded py-1 text-white px-2' onClick={() => submitSearch(1, 10)}><BiSearchAlt /> Search</button>
                                    </div>

                                </div>
                                <div className='col ms-1'>
                                    {/* <Row> */}
                                    {/* <FaFilter className='col-auto pe-0 mt-1 filter-icon' data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample" /> */}
                                    {/* <div className='col text-start ms-1 ps-0'>
                                            <h5 className='ms-3'>Search</h5>
                                        </div> */}
                                    {/* <div className='row g-0 mb-2'>
                                        <div className='col text-start ms-1'>
                                            <h5>Search</h5>
                                        </div>
                                        <div className='col text-end'>
                                            <i className="bi bi-search ms-1 ps-2 mb-2 " id='search-icon'></i>
                                            <input
                                                // onKeyDown={(e) => handleSearch(e)}
                                                // onKeyUp={(e) => resetSearch(e)}
                                                className='py-1 ps-3' placeholder="Search Request" />
                                        </div>
                                    </div> */}
                                    {/* <ToolkitProvider
                keyField='id'
                data=""
                columns={columns}
                search>
                {
                  props => (
                    <div className="pe-3">
                      <div className='row mb-2'>
                        <div className='col text-start ms-1'>
                          <h5>Marker</h5>
                        </div>
                        <div className='col text-end'>
                          <button type='button' className='btn-create btn px-3 py-1 ms-3 me-2'
                            data-bs-toggle="modal" data-bs-target="#createMarker"
                          // data-tip data-for="newMarkerTip"
                          >
                            <i className="bi bi-person-plus-fill"></i>Add</button>
                          <i className="bi bi-search ms-1 ps-2 mb-2 " id='search-icon'></i>
                          {/* <SearchBar placeholder=" Search Marker Name "
                            {...props.searchProps} /> */}
                                    {/* </div>
                      </div>  */}
                                    {/* <BootstrapTable
                                    keyField='id'
                                        data={onReviewData}
                                        // page={page}
                                        columns={columns}
                                        // sizePerPage={sizePerPage}
                                        // totalSize={totalDataSize}
                                        rowEvents={rowEvents}
                                        noDataIndication={() => { return (tdataLoad === true ? <NoDataIndication /> : "No data available") }}
                                        
                                        // onTableChange={handleTableChange}
                                    /> */}

                                    {/* </Row> */}

                                    {/* </div>
                  )
                }
              </ToolkitProvider> */}
                                    <ToolkitProvider
                                        keyField='id'
                                        data={onReviewData}
                                        columns={columns}
                                        search>
                                        {
                                            props => (
                                                <div className="pe-3 mx-0">
                                                    <div className='row mb-2'>
                                                        <div className='col text-start ms-3'>
                                                            <h5>Search</h5>
                                                        </div>
                                                        <div className='col text-end'>


                                                            <i className="bi bi-search ms-1 ps-2 mb-2 " id='search-icon'></i>
                                                            <SearchBar placeholder=" Search  "
                                                                {...props.searchProps} />
                                                        </div>
                                                    </div>
                                                    <BootstrapTable
                                                        {...props.baseProps}
                                                        className="mx-0"
                                                        rowEvents={rowEvents}
                                                        wrapperClasses="table-responsive"
                                                        noDataIndication={() => { return (tdataLoad === false ? "No data available" : <NoDataIndication />) }}
                                                        pagination={paginationFactory(options)}

                                                    />


                                                </div>
                                            )
                                        }
                                    </ToolkitProvider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            {/* PRIMARY CRITERIA MODAL POUP */}
            <div className="modal" id="primaryCriteria" data-bs-backdrop="false"
                tabIndex="-1" >
                <div className="modal-dialog shadow-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Add Primary Criteria</h4>
                            <button type="button" className="btn-close"
                                data-bs-dismiss="modal" ></button>
                        </div>
                        <form >
                            <div className="modal-body p-4">

                                <div className="checkbox-container mt-3 ps-1">
                                    <div className='row px-2 '>
                                        <div className='col-md-3 mb-3'>
                                            <input type="checkbox" className="btn-check" id="btn-check" onChange={(e) => setIsChecked(e.target.checked)} {...register("age", { required: false })} />
                                            <label className="w-100 btn btn-chck" htmlFor="btn-check">Age</label>
                                        </div>
                                        <div className='col-md-3 mb-3'>
                                            <input type="checkbox" className='btn-check' id="gender" onChange={(e) => setIsChecked(e.target.checked)} {...register('birthgender', { required: false })} />
                                            <label className='w-100 btn btn-chck' htmlFor="gender" >Gender</label>
                                        </div>
                                        {/* <div className='col-md-3 mb-3'>
                                            <input type="checkbox" className="btn-check"  {...register("birthgender", { required: false })} onChange={(e)=>console.log(e.target.checked)} onChange={(e) => e.target.checked} />
                                            <label className="w-100 btn btn-chck" >Birth Gender</label>
                                        </div> */}
                                        <div className='col-md-3 mb-3'>
                                            <input type="checkbox" className="btn-check" id="btn-check1" onChange={(e) => setIsChecked(e.target.checked)} {...register("diagnosis", { required: false })} />
                                            <label className="w-100 btn btn-chck" htmlFor="btn-check1">Diagnosis</label>
                                        </div>
                                        <div className='col-md-3 mb-3'>
                                            <input type="checkbox" className="btn-check" id="btn-check2" onChange={(e) => setIsChecked(e.target.checked)}  {...register("symptoms", { required: false })} />
                                            <label className="w-100 btn btn-chck" htmlFor="btn-check2">Symptoms</label>
                                        </div>
                                        <div className='col-md-3 mb-3'>
                                            <input type="checkbox" className="btn-check" id="btn-check3"  {...register("tags", { required: false })} />
                                            <label className="w-100 btn btn-chck" htmlFor="btn-check3">Tags</label>
                                        </div>
                                        <div className='col-md-3 mb-3'>
                                            <input type="checkbox" className="btn-check" id="btn-check4" {...register("medication", { required: false })} />
                                            <label className="w-100 btn btn-chck" htmlFor="btn-check4">Medication</label>
                                        </div>
                                        <div className='col-md-3 mb-3'>
                                            <input type="checkbox" className="btn-check" id="btn-check6" {...register("handedness", { required: false })} />
                                            <label className="w-100 btn btn-chck" htmlFor="btn-check6">Handedness</label>
                                        </div>
                                        {/* <div className="check-btn p-0 col-auto p-2 mx-1" onClick={() => setActive(!active)} >
                                            <input type="checkbox" className="btn-check"
                                                // checked={option.checked}
                                                checked={checkAge}
                                                // defaultChecked={true}
                                                onChange={(e) => console.log(e.target.checked)} />
                                            <label className={`w-100 btn ${checkAge === true ? "active" : ""}`} htmlFor="">Age</label>
                                        </div> */}
                                    </div>
                                </div>
                                {/* <div className="border rounded row container m-0 p-2" >
                                    <div className='tag-list col-md-auto m-1 rounded py-1' >Age <span className='ms-2' >x</span></div>
                                    <div className='tag-list col-md-auto m-1 rounded py-1' >Birth Gender <span className='ms-2' >x</span></div>
                                    <div className='tag-list col-md-auto m-1 rounded py-1' >Diagnosis <span className='ms-2' >x</span></div>
                                    <div className='tag-list col-md-auto m-1 rounded py-1' >Symptoms <span className='ms-2' >x</span></div>
                                    <div className='tag-list col-md-auto m-1 rounded py-1' >Tags <span className='ms-2' >x</span></div>
                                    <div className='tag-list col-md-auto m-1 rounded py-1' >Medication <span className='ms-2' >x</span></div>
                                </div> */}
                            </div>
                            <div className="modal-footer ">
                                <button type="button"
                                    id="closeDiagModal" className="btn btn-cancel text-white px-2 " data-bs-dismiss="modal" >Cancel</button>
                                <button type="button" className="btn btn-save text-white px-3" onClick={(handleSubmit((onSubmit)))}>OK </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {/* SECONDARY CRITERIA MODAL POPUP */}
            <div className="modal" id="secondaryCriteria" data-bs-backdrop="false"
                tabIndex="-1" >
                <div className="modal-dialog shadow-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Add Additional Criteria</h4>
                            <button type="button" className="btn-close"
                                data-bs-dismiss="modal" onClick={() => clearForm()}></button>
                        </div>
                        <form >
                            <div className="modal-body p-3 mt-2">
                                <div className='row px-2 mb-3'>
                                    <div className='form-group text-start col-md-6'>
                                        <label>Type</label>
                                        <select className='form-select form-control' value={eyeType} onChange={handleEyeTypeChange}>
                                            <option value="">Select</option>
                                            <option value="eyeopen">Eye Open</option>
                                            <option value="eyeclosed">Eye Closed</option>
                                        </select>
                                    </div>
                                    <div className='form-group text-start col-md-6'>
                                        <label>Ratio</label>
                                        <select className='form-select form-control' name="ratio" value={ratio} onChange={(e) => handleRatioChange(e, ratioOptions
                                        )}>
                                            <option value="">Select</option>
                                            {ratioOptions.map((mark) => {
                                                return <option value={mark.id} label={mark.label} key={mark.id}>{mark.label}</option>
                                            })}
                                            {/* <option value="Theta/beta ratio">Theta/beta ratio</option>
                                            <option value="Alpha/beta ratio">Alpha/beta ratio</option>
                                            <option value="Posterior Dominant Rhythm">Posterior Dominant Rhythm</option> */}
                                        </select>
                                    </div>

                                </div>
                                <div className='row px-2'>
                                    <div className='form-group text-start col-md-6'>
                                        <label>Location</label>
                                        <select className='form-control' name="location" value={loc} onChange={(e) => setLoc(e.target.value)}>
                                            {/* <option value="">Select</option> */}
                                            {locOptions !== "" || locOptions !== null ? <option value={locOptions}>{locOptions}</option> : <option value="">Select</option>}

                                            {/* <option value={locOptions}>{locOptions}</option> */}
                                            {/* <option value="G11">G11</option>
                                            <option value="I16">I16</option>
                                            <option value="B16">B16</option> */}
                                        </select>
                                    </div>
                                    <div className='form-group text-start col-md-6 px-2'>
                                        <label>with Range</label>
                                        <div className='mx-2 mt-3'>
                                            <InputRange name='range'
                                                maxValue={3.000}
                                                minValue={0.000}
                                                step={0.001}
                                                value={val1}
                                                // onChange={(e) =>handleRatioChange(e)}
                                                onChange={(e) => setVal1({ min: Number(e.min.toFixed(3)), max: Number(e.max.toFixed(3)) })}
                                            />
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div className="modal-footer ">
                                <button type="button"
                                    id="closeDiagModal1" className="btn btn-cancel text-white px-2 " data-bs-dismiss="modal" onClick={() => clearForm()} >Cancel</button>
                                <button type="button" className="btn btn-save text-white px-3" onClick={handleSubmit((onSubmit1))}>OK </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div >
    );
}