import React, { useState } from 'react';
import { ImWarning } from 'react-icons/im';
import DeleteIcon from '../../../../assets/img/sample-icons/delete.gif';
//API
import MasterDataService from '../../../../services/masterdata';

function DeleteLifestyleModal(props) {
    const id = props.data.id;
    const [isTrue, setIsTrue] = useState(false);
    const [displayImg, setDisplayImg] = useState(false);
    //api
    const msds = new MasterDataService();

    function delRecord(val) {
      
        let reqData = {
            "conform_data": val,
            "lifestyleid": id
        }
        msds.removeLifestyle(reqData)
            .then((response) => {
                if (response.data) {
                    setIsTrue(false);
                    setDisplayImg(false);
                    hideModal('closeDelLife');
                    msgDisplay(true);
                    props.data.getLifestyleData();
                }
            }).catch(function (error) {
                setTimeout(() => {
                    setDisplayImg(true);
                }, 5000)
                setIsTrue(true);
            })
    }

    function hideModal(target) {
        document.getElementById(target).click();
    };

    const msgDisplay = (val) => {
        props.data.messageCallback1(val);
    }

    function handleCancel() {
        setIsTrue(false);
        setDisplayImg(false);
    }
    return (
        <>
            <div className="modal" id="deleteLifestyle" data-bs-backdrop="false"
                tabIndex="-1" >
                <div className="modal-dialog shadow-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                        {isTrue ? <>{displayImg ? <h4 className="modal-title text-danger"><ImWarning className='me-2 mb-1 text-danger' />Re-Confirm Delete</h4> : ""}</> : <h4 className="modal-title">Confirm Delete</h4>}
                            <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => handleCancel()}></button>
                        </div>
                        <div className="modal-body p-4 text-center">
                        {isTrue ? <>{displayImg ? <h6 className='text-start'>This lifestyle is associated with service requests, do you still want to delete?</h6> : <img src={DeleteIcon} className="ms-auto" alt="delete-icon" width="200" height="200" />}</> :
                                <h6 className='text-start'>Are you sure you want to delete this lifestyle?</h6>}
                        </div>
                        <div className="modal-footer ">
                        {isTrue? <>{displayImg ? <button type="button" id="closeDelLife" className="btn btn-save text-white px-3" onClick={() => handleCancel()} data-bs-dismiss="modal">No</button>:""}</>:
                         <button type="button" id="closeDelLife" className="btn btn-save text-white px-3" onClick={() => handleCancel()} data-bs-dismiss="modal">No</button>}  
{/* 
                            <button type="button" id="closeDelLife" className="btn btn-save text-white px-3" data-bs-dismiss="modal" onClick={()=> handleCancel()}>No</button> */}
                              {isTrue ? <>{displayImg ? <button type="button" className="btn btn-cancel text-white px-3" onClick={() => delRecord("True")}>Yes</button> : ""}</> :
                                <button type="button" className="btn btn-cancel text-white px-3" onClick={() => delRecord("False")}>Yes</button>}
                            {/* {isTrue ? <button type="button" className="btn btn-cancel text-white px-3" onClick={() => delRecord("True")}>Yes</button> :
                                <button type="button" className="btn btn-cancel text-white px-3" onClick={() => delRecord("False")}>Yes</button>} */}
                        </div>

                    </div>
                </div>
            </div>
           
        </>
    );
}

export default DeleteLifestyleModal;