import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { MaskedInput, createDefaultMaskGenerator } from 'react-hook-mask';
import AdminAccountService from "../../../services/AdminAccount";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const maskGenerator = createDefaultMaskGenerator('(999) 999-9999');

function UpdateAccountModal(props) {
  const [setActive, setInActive] = useState(true);
  const [tempVal, setTempVal] = useState("");
  const location = useLocation();
  const history = useHistory();
  const [isMounted,setIsMounted] = useState(true);
  const [accountData, setAccountData] = useState([]);
  const [contactNo, setContactNo] = useState("");
  const acId = location.state.rowId;
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const accountupnotify = () => toast.success("Account updated successfully!");
  const accountupnotifyErr = () => toast.error("Account couldn't be updated!");
  //API CALL
  const accs = new AdminAccountService();

  const msgDisplay = (val) => {
    props.data.messageCallback(val);
  }

  function fetchUpdateAccountData() {
    accs.getAccountDetail(acId)
      .then((response) => {
        if (response.data) {
          if(isMounted){
          if (response.data.status === "success") {
            setAccountData(response.data.data);
            setContactNo(response.data.data.contact_phone);
            if (response.data.data.status) {
              if (response.data.data.status === 'Active') {
                setInActive(true);
                setTempVal("Active");
              }
              else {
                setInActive(false);
                setTempVal("InActive");
              }

            }
            else {
              setInActive(true);
              setTempVal("Active");
            }

          }
          else if(response.data.status === "error"){
              
          }
        }
        }

      })
      .catch(function (error) {
        if(error.response.status === 403){
          history.push('/login');
        }
      });
  }

  useEffect(() => {
    fetchUpdateAccountData();
    return()=>{setIsMounted(false);}
  }, []);
  useEffect(() => {
    reset(accountData);
    
  }, [accountData, tempVal]);

  const onSubmit = (data) => {
    let reqData = {
      AccountID: acId,
      account_name: data.accountname,
      first_name: data.fname,
      last_name: data.lname,
      contact_phone: data.phone,
      contact_email: data.email,
      contact_fax: data.fax,
      contact_address: data.address,
      state: data.state,
      city: data.city,
      zip: data.zip,
      status: tempVal,
    };
    accs.CreateAccount(reqData)
      .then((response) => {
        if (response.data) {
          if (response.data.status === "success") {
            msgDisplay(true);
            hideModal('closeUpdateModal');
            accountupnotify();
            props.data.fetchAccountDetailData();
          }
        }
      })
      .catch(function (error) {
        
      });
  };

  function hideModal(target) {
    document.getElementById(target).click();
  };

  return (
    <div className="modal" id="updateModal" data-bs-backdrop="false" tabIndex="-1" >
      <div className="modal-dialog shadow-lg" style={{ maxWidth: "800px" }}>
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Update Account</h4>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
            ></button>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-body p-4">
              <div className="row text-start">
                <div className="col mb-3">
                  <label htmlFor="accountname" className="form-label">
                    Account Name<span className='required-field'>*</span>
                  </label>
                  <input
                    type="text"
                    onChange={(e) => setAccountData(e.target.value)}
                    defaultValue={accountData.account_name}
                    className={`form-control p-2 ${errors.accountname ? "is-invalid" : ""
                      }`}
                    {...register("accountname", { validate: (value) => { return !!value.trim()}, required: true })}
                  />
                  {errors.accountname &&
                    errors.accountname.type === "required" ? (
                    <div className="invalid-feedback">
                      This field is required
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="col mb-3">
                  <label htmlFor="fname" className="form-label">
                    First Name<span className='required-field'>*</span>
                  </label>

                  <input
                    type="text"
                    className={`form-control p-2 ${errors.fname ? "is-invalid" : ""
                      }`}
                    defaultValue={accountData.first_name}
                    onChange={(e) => setAccountData(e.target.value)}
                    {...register("fname", { validate: (value) => { return !!value.trim()}, required: true })}
                  />

                  {errors.fname && errors.fname.type === "required" ? (
                    <div className="invalid-feedback">
                      This field is required
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="col mb-3">
                  <label htmlFor="lname" className="form-label">
                    Last Name
                  </label>

                  <input
                    type="text"
                    className="form-control"
                    defaultValue={accountData.last_name}
                    onChange={(e) => setAccountData(e.target.value)}
                    {...register("lname", { required: false })}
                  />
                </div>
              </div>

              <div className="row text-start">
                <div className="col mb-3">
                  <label htmlFor="phone" className="form-label">
                    Phone<span className='required-field'>*</span>
                  </label>
                  <MaskedInput
                    className={`form-control p-2 ${errors.phone ? "is-invalid" : ""
                      }`}
                    {...register("phone", { validate: (value) => { return !!value.trim()}, required: true })}
                    maskGenerator={maskGenerator}
                    value={contactNo}
                    onChange={setContactNo}
                  />
                 {errors.phone && errors.phone.type === "required" ? (
                    <div className="invalid-feedback">
                      This field is required
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col mb-3">
                  <label htmlFor="email" className="form-label">
                    Email<span className='required-field'>*</span>
                  </label>
                  <input
                    type="text"
                    defaultValue={accountData.contact_email}
                    onChange={(e) => setAccountData(e.target.value)}
                    className={`form-control p-2 ${errors.email ? "is-invalid" : ""
                      }`}
                    {...register(
                      "email",
                      {  validate: (value) => { return !!value.trim()},required: true },
                      { pattern: /^\S+@\S+$/i }
                    )}
                  />
                  {errors.email && errors.email.type === "required" ? (
                    <div className="invalid-feedback">
                      This field is required
                    </div>
                  ) : (
                    ""
                  )}
                  {errors.email && errors.email.type === "pattern" ? (
                    <div className="invalid-feedback">
                      Enter a valid email id
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col mb-3">
                  <label htmlFor="fax" className="form-label">
                    Fax
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    defaultValue={accountData.contact_fax}
                    onChange={(e) => setAccountData(e.target.value)}
                    {...register("fax", { required: false })}
                  />
                </div>
              </div>
              <div className="row text-start">
                <div className="col mb-3">
                  <label htmlFor="address" className="form-label">
                    Address<span className='required-field'>*</span>
                  </label>
                  <input
                    type="text"
                    defaultValue={accountData.contact_address}
                    onChange={(e) => setAccountData(e.target.value)}
                    className={`form-control p-2 ${errors.address ? "is-invalid" : ""
                      }`}
                    {...register("address", {  validate: (value) => { return !!value.trim()},required: true })}
                  />
                  {errors.address && errors.address.type === "required" ? (
                    <div className="invalid-feedback">
                      This field is required
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col mb-3">
                  <label htmlFor="city" className="form-label">
                    City
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={accountData.city}
                    onChange={(e) => setAccountData(e.target.value)}
                    {...register("city", { required: false })}
                  />
                </div>
                <div className="col mb-3">
                  <label htmlFor="state" className="form-label">
                    State
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={accountData.state}
                    onChange={(e) => setAccountData(e.target.value)}
                    {...register("state", { required: false })}
                  />
                </div>
              </div>
              <div className="row text-start">
                <div className="col-md-4 mb-3">
                  <label htmlFor="state" className="form-label">
                    Zip
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    defaultValue={accountData.zip}
                    onChange={(e) => setAccountData(e.target.value)}
                    {...register("zip", { required: false })}
                  />
                </div>
                <div className="col-md-4 mb-3">
                  <label htmlFor="status" className="form-label row ms-1">
                    Status
                  </label>
                  <div className="btn col-md-4 w-100 p-0" onClick={() => setInActive(!setActive)}
                  >
                    {setActive ? (
                      <button type="button" className="btn btn-active w-100" value="Active" onClick={() => setTempVal("InActive")} >
                        Active
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn w-100 "
                        style={{ border: "1px solid red", color: "red" }}
                        value="InActive" onClick={() => setTempVal("Active")}>
                        InActive
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer me-1">
              <button
                type="button"
                id='closeUpdateModal'
                className="btn btn-cancel text-white px-2"
                data-bs-dismiss="modal">
                Cancel
              </button>
              <button type="submit" className="btn btn-save text-white px-2">
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
export default UpdateAccountModal;
