import React, { useState } from "react";
import PatientAccountService from "../../services/Patient";
import { useForm } from "react-hook-form";
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import Select from 'react-select';



function CreatePatientMedication(props) {
  const location = useLocation();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const pnt = new PatientAccountService();
  const pid = location.state.patientId;
  const [drugName, setDrugName] = useState('');
  const [dbSelect, setDbSelect] = useState([]);
  const [value, setValue] = useState([]);
  const [dosage, setDosage] = useState([]);
  const [dose, setDose] = useState('');
  const [doseVal,setDoseVal] = useState('');
  const [doseCode, setDoseCode] = useState([]);
  const [dcode, setDcode] = useState('');
  const [dcodeVal,setDcodeVal] = useState('');
  const [dataLoading, setDataLoading] = useState(false);
  //is;empty
  const [drugNameErr, setDrugNameErr] = useState(false);
  const [drugEmpty, setDrugEmpty] = useState(false);
  const [drugDoseErr, setDrugDoseErr] = useState(false);
  const [doseEmpty, setDoseEmpty] = useState(false);
  const [drugCodeErr, setDrugCodeErr] = useState(false);
  const [codeEmpty, setCodeEmpty] = useState(false);

  const patientMedicnotify = () => toast.success("Medicine created successfully!");
  const patientMedicnotifyErr = (val) => toast.error(val);

  const baseurl = "https://clinicaltables.nlm.nih.gov/api/rxterms/v3/search?terms";

  const msgDisplay = (val) => {
    props.data.messageCallback1(val);
  }
  //  "dosage": dose.value||"",
  // "drug_code": dcode.value||"",

  function hideModal(target) {
    document.getElementById(target).click();
  };

  function handleCancel() {
    reset();
    setDrugName('');
    setDrugNameErr(false);
    setValue('');
    setDbSelect([]);
    setDose('');
    setDrugEmpty(false);
    setCodeEmpty(false);
    setDoseEmpty(false);
    setDoseCode([]);
    setDoseVal('');
    setDcodeVal('');
    setDosage([]);
    setDcode('');
  }
  function handledChange(drugName) {
    setValue(drugName);
    setDrugNameErr(true);
    setDrugName(drugName);
    setDataLoading(false);
  }

  async function medicName(val) {
    axios.get(`${baseurl}=${val}`).then((res) => {
      if (res) {
        setDbSelect(res.data[1]);
        getDosage(val);
        setDose(null);
        setDcode(null);
      }
    })
  }

  const options = dbSelect.map(function (dbSelect, i) {
    return {
      value: dbSelect,
      label: dbSelect,
      id: i.toString()
      // id: dbSelect.id,
    };
  });
  const dosageOptions = dosage.map(function (dosage, i) {
    return {
      value: dosage,
      label: dosage,
      id: i
    }
  });
  const doseCodeOptions = doseCode.map(function (dosage, i) {
    return {
      value: dosage,
      label: dosage,
      id: i
    }
  });

  function getDosage(vale) {
    axios.get(`${baseurl}=${vale}&ef=STRENGTHS_AND_FORMS,RXCUIS`).then((res) => {
      if (res) {
        // if(res.data[2].length>0){
          if(res.data[2].STRENGTHS_AND_FORMS.length>0){
            setDosage(res.data[2].STRENGTHS_AND_FORMS[0]);
            // }else return null;
        }
        else return null;
       

      }
    })
  }
  function getRxCode(dosename) {
    axios.get(`${baseurl}=${drugName.value}&ef=STRENGTHS_AND_FORMS=${dosename},RXCUIS`).then((res) => {
      if (res) {
        if(res.data[2].RXCUIS.length>0){
        setDoseCode(res.data[2].RXCUIS[0]);
        }else return setDoseCode([]);

      }
    })
  }

  const handleDosageChange = (dose) => {
    setDose(dose);
    setDrugDoseErr(true);
    setDoseVal(dose.value);
    getRxCode(dose.value);
    setDataLoading(false);
  };
  const handleDoseCodeChange = (dcode) => {
    setDcode(dcode);
    setDcodeVal(dcode.value);
    setDrugCodeErr(true);
    setDataLoading(false);
  };
  function handleSelectChange(val) {
    setValue(val);
    setDrugName(val);
    getDosage(val);
  }
  function inputChange(value) {
    medicName(value);
    setDose(null);
    setDcode(null);

  }

  const onSubmit = (data) => {
    setDataLoading(true);
    let reqData = {
      "medicationid": 0,
      "medicine_name": drugName.value || "",
      "dosage": doseVal||"",
      "drug_code":dcodeVal||"",
      "patientid": pid,
      "medication_started_on": data.takenfrom,
      "medication_ended_on": data.stopped,
      "notes": data.notes,
      "status": data.status
    }
    if (drugNameErr === false ) {
      setDrugEmpty(true);

    } 
    
    else {
     
          pnt.AddMedication(reqData)
            .then((response) => {
              if (response.data.status === "success") {
                msgDisplay(true);
                setDataLoading(false);
                handleCancel();
                hideModal('closeMedicModal');
                patientMedicnotify();
                let page = 1;
                let sizePerPage = 10;
                props.data.fetchPatientMedicationData(page, sizePerPage);
              }
            })
            .catch(function (error) {
              setDataLoading(false);
              patientMedicnotifyErr(error.response.data.data);
    
            })
          
        }

   
  }

  return (
    <div className="modal" id="createPatientMedicine" data-bs-backdrop="false" tabIndex="-1">
      <div className="modal-dialog shadow-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Create Patient Medicine</h4>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              onClick={handleCancel}
            ></button>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-body p-4">
              <div className="row text-start">
                <div className="mb-3 drug-name">
                  <label htmlFor="medicineName" className="form-label">
                    Medicine Name<span className='required-field'>*</span>
                  </label>
                  <br />
                  <Select
                    value={drugName}
                    onChange={handledChange}
                    options={options}
                    defaultValue={drugName}
                    onInputChange={inputChange}
                  />
                  {drugNameErr === false && drugEmpty === true ? <div className='text-danger text-left'>This field is required</div> : ""}
                  {/* <input
                    type="text"
                    className={`form-control p-2 ${errors.medicineName ? "is-invalid" : ""}`} {...register('medicineName', { required: true })} />
                  {(errors.medicineName && errors.medicineName.type === 'required') ? <div className="invalid-feedback">This field is required</div> : ''} */}
                </div>
                <div className="mb-3">
                  <label htmlFor="dosage" className="form-label">
                    Dosage
                  </label>
                  <Select
                    value={dose}
                    onChange={handleDosageChange}
                    options={dosageOptions}
                  />
                  {/* {drugDoseErr === false && doseEmpty === true ? <div className='text-danger text-left'>This field is required</div> : ""} */}
                  {/* <input type="text" className={`form-control p-2 ${errors.dosage ? "is-invalid" : ""}`} {...register('dosage', { required: true })} />
                  {(errors.dosage && errors.dosage.type === 'required') ? <div className="invalid-feedback">This field is required</div> : ''} */}
                </div>
                <div className="mb-3">
                  <label htmlFor="dosage" className="form-label">
                    Code
                  </label>
                  <Select
                    value={dcode}
                    onChange={handleDoseCodeChange}
                    options={doseCodeOptions}
                  />
                  {/* {drugCodeErr === false && codeEmpty === true ? <div className='text-danger text-left'>This field is required</div> : ""} */}
                  {/* <input type="text" className={`form-control p-2 ${errors.dosage ? "is-invalid" : ""}`} {...register('dosage', { required: true })} />
                  {(errors.dosage && errors.dosage.type === 'required') ? <div className="invalid-feedback">This field is required</div> : ''} */}
                </div>
                <div className="mb-3">
                  <label htmlFor="takenfrom" className="form-label">
                    Medication Started On
                  </label>
                  <input type="text" className={`form-control p-2 ${errors.takenfrom ? "is-invalid" : ""}`} {...register('takenfrom', { required: false })} />
                  {(errors.takenfrom && errors.takenfrom.type === 'required') ? <div className="invalid-feedback">This field is required</div> : ''}
                </div>
                <div className="mb-3">
                  <label htmlFor="stopped" className="form-label">
                    Medication Ended On
                  </label>
                  <input type="text" className={`form-control p-2 ${errors.stopped ? "is-invalid" : ""}`} {...register('stopped', { required: false })} />
                  {(errors.stopped && errors.stopped.type === 'required') ? <div className="invalid-feedback">This field is required</div> : ''}
                </div>
                <div className="mb-3">
                  <label htmlFor="notes" className="form-label">
                    Notes
                  </label>
                  <textarea className="form-control" rows="5"{...register('notes', { required: false })} />
                </div>
                <div className="mb-3">
                  <label htmlFor="status" className="form-label">
                    Status<span className='required-field'>*</span>
                  </label>
                  <select className={`form-select p-2 ${errors.status ? "is-invalid" : ""}`} {...register('status', { required: true })} >
                    <option value="">Select</option>
                    <option value="Present">PRESENT</option>
                    <option value="Past">PAST</option>
                  </select>
                  {(errors.status && errors.status.type === 'required') ? <div className="invalid-feedback">This field is required</div> : ''}
                </div>
              </div>
            </div>
            <div className="modal-footer ">
              <button
                type="button"
                id="closeMedicModal"
                className="btn btn-cancel text-white px-2 "
                data-bs-dismiss="modal"
                onClick={() => handleCancel()}>
                Cancel
              </button>
              <button type="submit" className="btn btn-save text-white px-2"
                disabled={dataLoading ? true : false}>
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreatePatientMedication;
