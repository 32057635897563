import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { Nav, Tab, Row, Col,  } from "react-bootstrap";
import DiagnosisTab from "./subcontent/DiagnosisTab";
import '../../../assets/styles/pipelinerequest/tabcontent/recordinganalysis.scss';
import SymptomsTab from "./subcontent/SymptomsTab";
import SummaryFindings from "./subcontent/SummaryFindings";
import Neurofeedback from "./subcontent/NeuroFeedback";
import AdjunctTherapy from "./subcontent/AdjunctTherapy";
import PossibleSupplementation from "./subcontent/PossibleSupplementation";

import RecordingAnalysisService from "../../../services/RecordingAnalysis";
import AssociateMedication from "./subcontent/AssociateMedication";
import AssociateLifestyle from "./subcontent/AssociatedLifeStyle";
import AnalysisAndProcedure from "./subcontent/AnalysisAndProcedure";
import InterpretationOfFindings from "./subcontent/InterpretationOfFindings";
import { ClipLoader } from "react-spinners";
import DiagnosisIcon from '../../../assets/img/recording-analysis-icons/1.png';
import SymptomsIcon from '../../../assets/img/recording-analysis-icons/2.png';
import RecordingAnalysisIcon from '../../../assets/img/recording-analysis-icons/3.png';
import SummaryFindingsIcon from '../../../assets/img/recording-analysis-icons/4.png';
import InterpretationIcon from '../../../assets/img/recording-analysis-icons/5.png';
import NeuroFeedbackIcon from '../../../assets/img/recording-analysis-icons/6.png';
import EyeOpenIcon from '../../../assets/img/recording-analysis-icons/7.png';
import EyeClosedIcon from '../../../assets/img/recording-analysis-icons/8.png';
import AdjunctIcon from '../../../assets/img/recording-analysis-icons/9.png';
import MedicationIcon from '../../../assets/img/recording-analysis-icons/10.png';
import SupplementationIcon from '../../../assets/img/recording-analysis-icons/11.png';
import LifestyleIcon from '../../../assets/img/recording-analysis-icons/12.png';
import PatientAccountService from "../../../services/Patient";
import PipelineRequestService from "../../../services/PipelineRequest";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Container } from 'react-bootstrap';
import { FiPlus } from 'react-icons/fi';
import MaleImage from '../../../assets/img/male-avatar-icon.jpeg';
import CreatableSelect from 'react-select/creatable';
import '../../../assets/styles/patientList/tags.scss';


export default function RecordingAnalysis(props) {
  const location = useLocation();
  const [displayTemplate, setDisplayTemplate] = useState();
  const [tempID, setTempID] = useState('');
  const [docLoading, setDocLoading] = useState(false);
  const [requestData, setRequestData] = useState([]);
  const [isMounted1, setIsMounted1] = useState(true);
  //diagnosis and symptoms
  const [dsMounted, setDsmounted] = useState(true);
  const [tags, setTags] = useState([]);
  const [dbTags, setDbTags] = useState([]);
  const [isMountedAllTag, setIsMountedAllTag] = useState(true);
  // SAVE DIAGNOSIS
  const [dId, setDId] = useState([]);
  const [checkBoxes, setCheckBoxes] = useState([]);
  const [ndID, setNdID] = useState([]);
  const [selectedDiag, setSelectedDiag] = useState('');
  const [undiag, setUndiag] = useState([]);
  //SAVE SYMPTOMS
  const [sId, setSId] = useState([]);
  const [unSymptoms, setUnSymptoms] = useState([]);
  const [checkBoxes1, setCheckBoxes1] = useState([]);
  const [sndID, setSndID] = useState([]);
  const [selectedSymp, setSelectedSymp] = useState('');
  const [isMounted, setIsMounted] = useState(true);
  //TAG
  const [dbSelect, setDbSelect] = useState([]);
  const [selectedTag, setSelectedTag] = useState('');
  const [saveDbTags, setSaveDbTags] = useState([]);

   //CALLBACK FUNCTION
   const [isFlagSet, setIsFlagSet] = useState(false);
   const [msg, setMsg] = useState('');

  //API
  const ds = new PipelineRequestService();
  const pnt = new PatientAccountService();
  const pId = localStorage.getItem('patientid');
  const ra = new RecordingAnalysisService();
  //TOASTER
  const notifyDiag = () => toast.success("Diagnosis updated successfully!");
  const notifySymp = () => toast.success("Symptoms updated successfully!");
  const notifyTag = () => toast.success("Tag updated successfully!");
  const notifyDelTag = () => toast.success('Tag deleted successfully!');
  const notifySympDel = () => toast.success("Symptoms removed successfully!");
  const notifyDiagDel = () => toast.success("Diagnosis removed successfully!");

 

  function messageCallback(item) {
    setIsFlagSet(item);
    setMsg("Account successfully created!");
    setTimeout(() => { setMsg(""); setIsFlagSet(false) }, 5000);
  }
  const options = dbSelect.map(function (dbSelect) {
    return {
      value: dbSelect.TagName,
      label: dbSelect.TagName,
      id: dbSelect.id,
    };
  });

  const handleTagChange = (selectedTag) => {
    const values = ([...selectedTag].map(o => o.value));
    setSelectedTag(selectedTag);
    setSaveDbTags(values);
  };
  function fetchAllTags() {
    pnt.GetPatientTag(pId).then((response) => {
      if (isMountedAllTag) {
        if (response.data.status === "success") {
          setDbTags(response.data.data);
          fetchAllPatientTags(response.data.data);
        }
        else return;
      }
    })
  }
  useEffect(() => {
    fetchAllTags();
  }, []);
  function fetchAllPatientTags(arr) {
    pnt.GetAllPatientTag().then((response) => {
      // if (isMounted) {
        if (response.data) {
          const val = response.data.data;
          const output = [...new Map(val.map(o => [o.TagName, o])).values()];
          setDbSelect(output.filter(ar => !arr.find(rm => (rm.TagName === ar.TagName))));
        }
        else return;
      // }
    })
  }

  function fetchDataset() {
    let reqId = location.state.reqId;
    ds.GetPipelineDataset(reqId).then((response) => {
      if (isMounted1) {
        if (response.data.status === "success") {
          setRequestData(response.data.data[0]);
        }
      }
    })
      .catch(function (error) {
        // if (error.response.status === 403) {
        //     history.push('/login');
        // }
      })
  }

  useEffect(() => {
    fetchDataset();
    return () => { setIsMounted1(false) }
  }, []);

  const movePrev = () => {
    props.data.prev();
  }
  const moveNext = () => {
    props.data.next();
  }
  function getDiagnosis() {
    let reqData = {
      service_request_id: location.state.reqId,
    };
    ra.GetMultiCheckBoxes(reqData).then((response) => {
      if (dsMounted) {
        if (response.data.diagnosis !== []) {
          let diag = [];
          let undiag = [];
          // setCheckBoxes(response.data.diagnosis);
          for (let i = 0; i < response.data.diagnosis.length; i++) {
            if (response.data.diagnosis[i].ischoices === true) {
              dId.push(response.data.diagnosis[i].id);
              diag.push(response.data.diagnosis[i]);
            }
            else {
              ndID.push(response.data.diagnosis[i].id);
              undiag.push(response.data.diagnosis[i]);
            }
          }
          setDId(dId);
          setNdID(ndID);
          setCheckBoxes(diag);
          setUndiag(undiag);
        }
      }
    });

  }
  useEffect(() => {
    getDiagnosis();
    return () => { setDsmounted(false); }
  }, []);
  function getSymptoms() {
    let reqData = {
      "service_request_id": location.state.reqId
    }
    ra.GetMultiCheckBoxes(reqData).then((response) => {
      if (isMounted) {
        let unsymp = [];
        let symp = [];
        if (response.data.symptoms !== []) {

          for (let i = 0; i < response.data.symptoms.length; i++) {
            if (response.data.symptoms[i].ischoices === true) {
              sId.push(response.data.symptoms[i].id);
              symp.push(response.data.symptoms[i]);
            }
            else {
              sndID.push(response.data.symptoms[i].id);
              unsymp.push(response.data.symptoms[i]);
            }
          }
        }
        setCheckBoxes1(symp);
        setUnSymptoms(unsymp);
      }
    })
  }
  useEffect(() => {
    getSymptoms();
  }, []);



 

  //SAVE DIAGNOSIS
  const diagnosisOption = undiag.map((data, i) => {
    return {
      label: data.diagnosis_name,
      value: data.diagnosis_name,
      id: data.id
    }
  });

  const handleDiagnosisChange = (selectedDiag) => {
    const values = ([...selectedDiag].map(o => o.id));
    setSelectedDiag(selectedDiag);
    setDId(values);
    setNdID(current =>
      current.filter(diag => {
        if (diag.id !== values)
          return diag.id;
      }));
  };
  const removeDiagnosis = (id) => {
    let reqData = {
      "service_request_id": location.state.reqId,
      "diagnosis_tps": "",
      "undiagnosis_tps": id.toString(),
      "symptoms_tps": "",
      "unsymptoms_tps": "",
    }
    ra.SaveMultiCheckBoxes(reqData).then((response) => {
      if (response.data.status === "success") {
        getDiagnosis();
        notifyDiagDel();
      }
    })
  }
  function savediagnosis() {
    let reqData = {
      "service_request_id": location.state.reqId,
      "diagnosis_tps": dId.toString(),
      "undiagnosis_tps": "",
      "symptoms_tps": "",
      "unsymptoms_tps": "",
    }
    // console.log(reqData);
    ra.SaveMultiCheckBoxes(reqData).then((response) => {
      if (response.data.status === "success") {
        handleCancel();
        getDiagnosis();
        notifyDiag();
      }
    })
  }

  const handleCancel = () => {
    setSelectedDiag('');
  }

  //symptoms
  const symptomsOption = unSymptoms.map((data, i) => {
    return {
      label: data.symptoms_name,
      value: data.symptoms_name,
      id: data.id
    }
  });

  const handleSymptomsChange = (selectedSymp) => {
    const values = ([...selectedSymp].map(o => o.id));
    setSId(values);
    setSelectedSymp(selectedSymp);
  };
  function saveSymptoms() {
    let reqData = {
      "service_request_id": location.state.reqId,
      "symptoms_tps": sId.toString(),
      "unsymptoms_tps": "",
      "diagnosis_tps": "",
      "undiagnosis_tps": "",
    }
    ra.SaveMultiCheckBoxes(reqData).then((response) => {
      if (response.data.status === "success") {
        handleSympCancel();
        getSymptoms();
        notifySymp();
        // setMsg("Symptoms updated successfully!");
        // setTimeout(() => { setMsg(""); }, 5000);
      }
    });
  }

  const handleSympCancel = () => {
    setSelectedSymp('');
  }
  const removeSymptoms = (id) => {
    let reqData = {
      "service_request_id": location.state.reqId,
      "symptoms_tps": "",
      "unsymptoms_tps": id.toString(),
      "diagnosis_tps": "",
      "undiagnosis_tps": "",
    }
    ra.SaveMultiCheckBoxes(reqData).then((response) => {
      if (response.data.status === "success") {
        getSymptoms();
        notifySympDel();
      }
    })
  }

  //ADD TAG
  const addTag = (e) => {
    if (e.key === "Enter") {
      if (tags.find(tags => tags.toLowerCase() === e.target.value.toLowerCase())) {
        return alert("No duplicate value allowed")
      }
      else if (e.target.value.length > 0) {
        setTags([...tags, e.target.value]);
        e.target.value = "";
      }
    }
  };

  const removeTag = (removedTag) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    setTags(newTags);
  };
  function removeDbTag(id) {
    pnt.RemovePatientTag(id).then((response) => {
      if (response.data.status === "success") {
        fetchAllTags();
        notifyDelTag();
      }
    })
  }
  function clearTags() {
    setTags([]);
  }
  const onSubmit = () => {
    let reqData = {
      "tags": saveDbTags.toString(),
      "patientid": pId
    }
    pnt.CreatePatientTag(reqData)
      .then((response) => {
        if (response.data.status === "success") {
          hideModal('closeTag');
          fetchAllTags();
          notifyTag();
        }
      })
  }
  function hideModal(target) {
    document.getElementById(target).click();
    setTags([]);
  };


// style={{ marginLeft: 0, marginRight: 0,height:'30vw'}}
  return (
    <Row className="g-0 pb-3 rec-tab-row" >
      <div className="tab-content recording-analysis p-0  col-md-8 mb-3" style={{ background: 'none' }}>
        <div className="tab-content content px-4 py-2 ms-0">
          <div className="tab-bodycontent px-2 mt-3 me-2 pe-0">
            <Tab.Container id='left-tabs' defaultActiveKey="diagnosis">
              <Row>
                <Col md='auto' className="me-2 p-0 text-start " style={{ border: '1px solid lightgray'}}>
                  <Nav variant="pills" className="flex-column mt-1" >
                    {/* <Nav.Item>
                  <Nav.Link eventKey="patient-demography">Patient Demography</Nav.Link>
                </Nav.Item> */}
                    <Nav.Item>
                      <Nav.Link eventKey="diagnosis">
                        <img src={DiagnosisIcon} alt="diagnosis-icon" width="18" height="18" className="me-2"></img>
                        Diagnosis</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="symptoms">
                        <img src={SymptomsIcon} alt="diagnosis-icon" width="18" height="18" className="me-2" />
                        Symptoms</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="recording-analysis">
                        <img src={RecordingAnalysisIcon} alt="diagnosis-icon" width="18" height="18" className="me-2" />
                        Recording And Analysis Procedures</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="summary-findings">
                        <img src={SummaryFindingsIcon} alt="diagnosis-icon" width="18" height="18" className="me-2" />
                        Summary Of Findings</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="interpretation-findings">
                        <img src={InterpretationIcon} alt="diagnosis-icon" width="18" height="18" className="me-2" />
                        Interpretation of Findings</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="neurofeedback">
                        <img src={NeuroFeedbackIcon} alt="diagnosis-icon" width="18" height="18" className="me-2" />
                        Neurofeedback Recommendations</Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                      <Nav.Link eventKey="eyes-open-condition">
                        <img src={EyeOpenIcon} alt="eyeopen-icon" width="18" height="18" className="me-2" />
                        With Eyes Open Condition</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="eyes-closed-condition">
                        <img src={EyeClosedIcon} alt="eyeclosed-icon" width="18" height="18" className="me-2" />
                        With Eyes Closed Condition</Nav.Link>
                    </Nav.Item> */}
                    <Nav.Item>
                      <Nav.Link eventKey="adjunct">
                        <img src={AdjunctIcon} alt="diagnosis-icon" width="18" height="18" className="me-2" />
                        Adjunct Therapies</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="possible-medication">
                        <img src={MedicationIcon} alt="diagnosis-icon" width="18" height="18" className="me-2" />
                        Possible Appropriate Medication</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="possible-supplementation">
                        <img src={SupplementationIcon} alt="supplement-icon" width="18" height="18" className="me-2" />
                        Possible Appropriate Supplementation</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="lifestyle-interventions">
                        <img src={LifestyleIcon} alt="lifestyle-icon" width="18" height="18" className="me-2" />
                        Lifestyle Interventions</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col style={{ background: '#f5f5f5' }}>
                  <Tab.Content>
                    {/* <Tab.Pane eventKey="patient-demography">
                  <PatientDemography />
                </Tab.Pane> */}
                    <Tab.Pane eventKey="diagnosis">
                      <DiagnosisTab data={{ getDiagnosis: getDiagnosis }} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="symptoms">
                      <SymptomsTab data={{ getSymptoms: getSymptoms }} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="recording-analysis">
                      <AnalysisAndProcedure />
                    </Tab.Pane>
                    <Tab.Pane eventKey="summary-findings">
                      <SummaryFindings />
                    </Tab.Pane>
                    <Tab.Pane eventKey="interpretation-findings">
                      <InterpretationOfFindings />
                    </Tab.Pane>
                    <Tab.Pane eventKey="neurofeedback">
                      <Neurofeedback />
                    </Tab.Pane>
                    {/* <Tab.Pane eventKey="eyes-open-condition">
                      <EyesOpenCondition />
                    </Tab.Pane>
                    <Tab.Pane eventKey="eyes-closed-condition">
                      <EyesClosedCondition />
                    </Tab.Pane> */}
                    <Tab.Pane eventKey="adjunct">
                      <AdjunctTherapy />
                    </Tab.Pane>
                    <Tab.Pane eventKey="possible-medication">
                      <AssociateMedication />
                    </Tab.Pane>
                    <Tab.Pane eventKey="possible-supplementation">
                      <PossibleSupplementation />
                    </Tab.Pane>
                    <Tab.Pane eventKey="lifestyle-interventions">
                      <AssociateLifestyle />
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </div>
        {/* PREVIEW CONSENT TEMPLATE */}
        <div className='modal' id="previewTemplate"  data-bs-backdrop="false">
          <div className="modal-dialog shadow-lg" style={{ maxWidth: '700px', height: '500px' }}>
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Template {tempID} preview</h4>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                ></button>
              </div>
              <div className="modal-body">
                {docLoading ? <div className="mt-5"><ClipLoader size="15" /> <span className="ms-1 mb-1">Loading...</span></div> : ""}
                <iframe src={displayTemplate} width="100%" height="380" />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-save text-white "
                  data-bs-dismiss="modal">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <div className='btn-bg d-flex justify-content-between mt-4 '>
          <button className='btn btn-back px-4' onClick={() => movePrev()} >Back</button>
          <button className='btn btn-next px-4' onClick={() => moveNext()}>Next</button>
        </div> */}
      </div>
      <div className=' user-information col-md-4 ps-3 pb-0'>
        <div className='bg-white rounded p-2 pnt-card mb-0'>
          <div className='img-container '>
            <img src={MaleImage} alt="user-image" className='rounded-circle w-25 pt-2' />
            <div className='name-content text-center mt-2 pb-2'>
              <h6 className='mb-0 text-capitalize'>{requestData.patient_name}</h6>
              <p className='mb-0'>{requestData.gender}-{requestData.dob}</p>
            </div>
          </div>
          <div className='row g-0 mt-3'>
            <div className='col-auto text-start me-5'>
              <label>Request ID</label>
              <p >{requestData.RequestNumber}</p>
            </div>
            <div className='col-auto text-start'>
              <label>Account Name </label>
              <p className='text-capitalize'>{requestData.account_name}</p>
            </div>
            <hr />
          </div>
          <div className='ass-tag'>
            <div className='row g-0'>
              <h6 className='col-auto text-start mb-0'>Diagnosis</h6>
              {/* <FiPlus className='col-auto ms-auto add-icon' data-bs-toggle="modal" data-bs-target="#addPntDiagnosis" /> */}
              <Container className='p-0'>
                <div className="checkbox-container mt-2 ps-1">
                  <div className='row ' style={{ height: 'auto' }}>
                    {checkBoxes.map((tag, i) => {
                     
                      return (
                        // <li key={i} className="text-dark">{tag.diagnosis_name}</li>
                        <div key={i} className="tag col-auto mx-2 my-1 p-1 text-dark" style={{ whiteSpace: 'no-wrap' }}>
                          {tag.diagnosis_name}<div className="ms-auto col-1 p-1 remove-tag" key={tag.id}
                          // onClick={() => removeDiagnosis(tag.id)}
                          >
                           
                            {/* <span>x</span> */}
                          </div>
                        </div>
                    
                      );
                    })}
                  </div>
                </div>
              </Container>
            </div>
            <hr />
          </div>
          <div className='ass-tag'>
            <div className='row g-0'>
              <h6 className='col-auto text-start mb-0'>Symptoms</h6>
              {/* <FiPlus className='col-auto ms-auto add-icon' data-bs-toggle="modal" data-bs-target="#addPntSymptoms" /> */}
              <Container className='p-0'>
                <div className="checkbox-container mt-2 ps-1">
                  <div className='row' style={{ height: 'auto' }}>
                    {checkBoxes1.map((tag, i) => {
                      return (
                        <div key={i} className="tag col-auto mx-2 my-1 p-1" style={{
                          whiteSpace: 'no-wrap',
                        }}>
                          {tag.symptoms_name}<div className="ms-auto col-1 p-1" key={tag.id}
                            // onClick={() => removeSymptoms(tag.id)}
                            style={{ cursor: 'pointer' }}
                          >
                            {/* <span>x</span> */}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Container>
            </div>
            <hr />
          </div>
          <div className='ass-tag pb-2 mb-2'>
            <div className='row g-0 '>
              <h6 className='col-auto text-start mb-2'>Associated Tags</h6>
              <FiPlus className='col-auto ms-auto add-icon' data-bs-toggle="modal" data-bs-target="#addPntTag" />
              <Container className='p-0 text-start'>
                <div className="checkbox-container px-1" style={{display:'inline-block'}}>
                  <div className='row'>
                    {dbTags.map((tag, i) => {
                      return (
                        <div key={i} className="tag col-auto mx-2 my-1 ps-1 py-0" style={{
                          whiteSpace: 'no-wrap',
                        }}>
                          {tag.TagName}<div className="ms-auto col-1 p-1" key={tag.id}
                            onClick={() => removeDbTag(tag.id)}
                          >
                            <span>x</span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Container>
            </div>
          </div>


          {/* ADD TAG */}
          <div className="modal" id="addPntTag"  data-bs-backdrop="false">
            <div className="modal-dialog shadow-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Add Tag</h4>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    onClick={() => clearTags()}
                  ></button>
                </div>
                <div className="modal-body">
                  {/* <div className="tag-box"> */}
                    <div className="tag-container text-start">
                      <CreatableSelect
                        isMulti
                        className="col-md-12"
                        value={selectedTag}
                        onChange={handleTagChange}
                        options={options} />
                    </div>
                  {/* </div> */}
                </div>
                <div className="modal-footer ">
                  <button
                    type="button"
                    className="btn btn-cancel text-white px-2"
                    data-bs-dismiss="modal"
                    id="closeTag"
                    onClick={() => clearTags()}>
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-save text-white px-3"
                    onClick={() => onSubmit()}>
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div className='btn-bg d-flex justify-content-between col-md-8 my-3' >
          <button className='btn btn-back px-4 mb-3' onClick={() => movePrev()} >Back</button>
          <button className='btn btn-next px-4 mb-3' onClick={() => moveNext()}>Next</button>
        </div>
    </Row>
  );

}

