import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { columns, options } from './data/MarkerData';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import LoadingIcon from '../../assets/img/sample-icons/sandbox.gif';
// Modal Box
import AddMarkerModal from './modal/marker/AddMarkerModal';
//API
import MasterDataService from "../../services/masterdata";
import DeleteMarkerModal from './modal/marker/DeleteMarkerModal';

const NoDataIndication = () => (
  <div className='spinner d-flex justify-content-center text-center h-100'>
    <img src={LoadingIcon} alt="loading-icon" width="200" height="200" />
    {/* <ScaleLoader size={60} color="#79a3bb" style={{ marginTop: '50px' }} /> */}
  </div>
);

export default function Marker() {
  const { SearchBar } = Search;
  const history = useHistory();
  const [markerID, setMarkerID] = useState();
  const [markerData, setMarkerData] = useState([]);
  const [updateMarker, setUpdateMarker] = useState([]);
  const [tdataLoaded, setTdataLoaded] = useState(false);
  const [eyeType, setEyeType] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  //API
  let md = new MasterDataService();
  //notification
  const markerSuccess = () => toast.success("Marker updated successfully!");
  const markerError = (val) => toast.error(val);

  function getMarkers() {
    md.getMarker().then((response) => {
      if (response.data) {
        if (response.data) {
          setTdataLoaded(true);
          setUpdateMarker([]);
          setMarkerData(response.data.data);
        }
        else {
          setTdataLoaded(false);
        }

      }
    }).catch(function (error) {
      if (error.response.status === 403) {
        history.push('/login');
      }
    })
  }

  useEffect(() => {
    reset(updateMarker)
  }, [updateMarker]);

  useEffect(() => {
    getMarkers();
  }, []);

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      getMarkerDetail(row.id);
      setMarkerID(row.id);
    },
  };

  function getMarkerDetail(id) {
    md.getMarkerDetail(id).then((response) => {
      if (response.data) {
        if (response.data.status === "success") {
          setUpdateMarker([]);
          setUpdateMarker(response.data.data[0]);
        }
      }
    })
  }

  const onSubmit = (data) => {
    let reqData = {
      "mdmarkerid": markerID,
      "markername": data.markerName,
      "mfieldname": data.markerName,
      "mfieldtype": data.fieldType,
      "status": data.status,
      "EC_isactive": data.eyeclosed || false,
      "EO_isactive": data.eyeopen || false
    }
    if (eyeType == false) {
      toast.error("Eye type is required");
    } else {
      md.saveMarker(reqData).then((response) => {
        if (response.data) {
          if (response.data.status === "success") {
            getMarkers();
            setUpdateMarker([]);
            markerSuccess();
            hideModal('closeUpMrkrModal');
          }
        }
      })
        .catch(function (error) {
          markerError(error.response.data.data);
          console.log(error.response.data.data);
        })
    }
  }

  function hideModal(target) {
    document.getElementById(target).click();
  };
  const handleEyeType = (e) => {
    if (e.target.value == true) {
      setEyeType(true);
    }
    else {
      setEyeType(false);
    }
  }
  return (

    <div className='col diagnosis'>
      <div className='row g-0 ps-3 pt-3'>
        <div className='table-request my-2 me-3'>
          <AddMarkerModal data={{ getMarkers: getMarkers }} />
          <DeleteMarkerModal data={{ id: markerID, getMarkers: getMarkers }} />
          {/* UPDATE LIFESTYLE START */}
          <div className="modal" id="updateMarker" data-bs-backdrop="false"
            tabIndex="-1" >
            <div className="modal-dialog shadow-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Update Marker</h4>
                  <button type="button" className="btn-close"
                    data-bs-dismiss="modal"></button>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="modal-body p-4">

                    <div className='row text-start'>
                      <div className="mb-3">
                        <label htmlFor="markerName" className="form-label">Marker Name <span className='required-field'>*</span></label>
                        <input type="text" maxLength="100"
                          onChange={(e) => setUpdateMarker(e.target.value)} readOnly
                          defaultValue={updateMarker.markername} className={`form-control p-2 ${errors.markerName ? "is-invalid" : ""}`} {...register('markerName', { required: true }, { validate: (value) => { return !!value.trim() } })} />
                        {errors.markerName && errors.markerName.type === "required" && errors.markerName.value === true ? (
                          <div className="invalid-feedback">
                            This field is required
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="mb-3">
                        <label htmlFor="fieldType" className="form-label">Field Type <span className='required-field text-danger'>*</span></label>
                        <select value={updateMarker.mfieldtype} defaultValue={updateMarker.mfieldtype} className={`form-select p-2 ${errors.fieldType ? "is-invalid" : ""}`} {...register('fieldType', { required: true })}
                          readOnly >
                          <option value="dropdown">Dropdown</option>
                          <option value="textbox">Textbox</option>
                          <option value="radio">Radio</option>
                        </select>
                        {(errors.fieldType && errors.fieldType.type === 'required') ? <div className="invalid-feedback">This field is required</div> : ''}

                      </div>
                      <div className="mb-3">
                        <label htmlFor="status" className="form-label">Type<span className='required-field text-danger'>*</span></label>

                        <div className='form-group row'>
                          <div className='col-md-3'>
                            <input type="checkbox" id="eyeopen"  {...register('eyeopen', { required: false })} onChange={(e) => handleEyeType(e)} />
                            <label className='ms-1 mb-1'>Eye Open</label>
                          </div>
                          <div className='col-md-3'>
                            <input type="checkbox" id='eyeclosed'  {...register('eyeclosed', { required: false })} onChange={(e) => handleEyeType(e)} />
                            <label className='ms-1 mb-1'>Eye Closed</label>
                          </div>
                        </div>
                      </div>
                      <div className=" mb-3">
                        <label htmlFor="gender" className="form-label ms-1">Status
                          <span className='required-field'>*</span></label>
                        <select selected={updateMarker.status} className={`form-select p-2 ${errors.status ? "is-invalid" : ""}`} {...register('status', { required: true })} >
                          <option value="Active">Active</option>
                          <option value="Inactive">Inactive</option>
                        </select>
                        {(errors.status && errors.status.type === 'required') ? <div className="invalid-feedback">This field is required</div> : ''}
                      </div>

                    </div>
                  </div>

                  <div className="modal-footer ">
                    <button type="button"
                      id="closeUpMrkrModal" className="btn btn-cancel text-white px-2" data-bs-dismiss="modal">Cancel</button>
                    <button type="submit" className="btn btn-save text-white px-2">Update </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className='table-data me-4'>
            <ToolkitProvider
              keyField='id'
              data={markerData}
              columns={columns}
              search>
              {
                props => (
                  <div className="pe-3">
                    <div className='row mb-2'>
                      <div className='col text-start ms-1'>
                        <h5>Marker</h5>
                      </div>
                      <div className='col text-end'>
                        <button type='button' className='btn-create btn px-3 py-1 ms-3 me-2'
                          data-bs-toggle="modal" data-bs-target="#createMarker"
                        // data-tip data-for="newMarkerTip"
                        >
                          <i className="bi bi-person-plus-fill"></i>Add</button>
                        <i className="bi bi-search ms-1 ps-2 mb-2 " id='search-icon'></i>
                        <SearchBar placeholder=" Search Marker Name "
                          {...props.searchProps} />
                      </div>
                    </div>
                    <BootstrapTable
                      {...props.baseProps}
                      pagination={paginationFactory(options)}
                      wrapperClasses="table-responsive"
                      rowEvents={rowEvents}
                      noDataIndication={() => { return (tdataLoaded ? "No data available" : <NoDataIndication />) }}
                    />
                  </div>
                )
              }
            </ToolkitProvider>
          </div>
        </div>
      </div>
    </div>
  )
}
