import React, { useEffect, useState, useRef } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import paginationFactory from 'react-bootstrap-table2-paginator';
import '../../assets/styles/table/table.scss';
import '../../assets/styles/releasedRequest/releasedRequest.scss';
import RequestService from '../../services/SeriveRequest';
import { ScaleLoader } from "react-spinners";
import { format } from 'date-fns';
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";
import { Row, Col, Toast, Button } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingIcon from '../../assets/img/sample-icons/sandbox.gif';

function DateFormatter(cell, row) {
  if (row) {
    return (
      <span>
        {format(new Date(cell), 'MM/dd/yyyy')}
      </span>
    );
  }
}
function stateFormatter(cell) {
  if (cell === "Released") {
    return (
      <p style={{ color: "#559c7e", marginBottom: '0px' }}>
        {cell}{" "}
      </p>
    );
  }

  return (
    <p style={{ color: "#1CBF02" }}>
      {cell}{" "}
    </p>
  );
}

const NoDataIndication = () => (
  <div className='spinner d-flex justify-content-center text-center h-100'>
    <img src={LoadingIcon} alt="loading-icon" width="200" height="200" />
    {/* <ScaleLoader size={60} color="#79a3bb" style={{ marginTop: '50px' }} /> */}
  </div>
);

const RemoteAll = ({ data, page, sizePerPage, onTableChange, totalSize, rowEvents, columns, noDataIndication }) => (
  <div>

    <BootstrapTable
      remote={{ sort: true, pagination: true }}
      pagination={paginationFactory({ page, sizePerPage, totalSize })}
      keyField="id"
      data={data}
      columns={columns}
      onTableChange={onTableChange}
      noDataIndication={noDataIndication}
      rowEvents={rowEvents}
    />
  </div>
);

function ReleasedRequest() {
  const notify = () => toast("Wow so easy !");
  const { url } = useRouteMatch();
  const [requestData, setRequestData] = useState([]);
  const history = useHistory();
  const svc = new RequestService();
  const [isMounted, setIsMounted] = useState(true);
  const [show, setShow] = useState(false);
  //remote table
  const [data, setData] = useState([]);
  const [tdataLoad, setTdataLoad] = useState(true);
  const [totalDataSize, setTotalDataSize] = useState(0);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [inputVal, setInputVal] = useState("");
  const [sortName, setSortName] = useState("");
  const [sortOrder, setSortOrder] = useState("");

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      history.push({
        pathname: `${url}/:dataset-information`,
        state: {
          reqId: row.id
        }
      });
    },

  };
  const sortOrderTable = (order, column) => {
    if (!order) return (
      <><span>&nbsp;&nbsp;<FaSort color="#858585" /></span>
      </>);
    else if (order === 'asc') return (<><span>&nbsp;&nbsp;<font color="red"><FaSortUp color="#000" /></font></span>
    </>);
    else if (order === 'desc') return (<><span>&nbsp;&nbsp;<font color="red"><FaSortDown color="#000" /></font></span>
    </>);
    return null;
  }

  const columns = [
    {
      dataField: 'sl.no', text: 'S.No', formatter: (cell, row, rowIndex, formatExtraData) => {
        if (page === 1) { return rowIndex + 1; }
        else { return ((page - 1) * sizePerPage) + (rowIndex + 1); }
      },
    },
    { dataField: 'RequestNumber', text: 'Request No', sort: true, sortCaret: sortOrderTable },
    { dataField: 'patient_name', text: 'Patient Name', sort: true, sortCaret: sortOrderTable },
    { dataField: 'dob', text: 'Date of Birth', sort: true, sortCaret: sortOrderTable },
    { dataField: 'gender', text: 'Gender', sort: true, sortCaret: sortOrderTable },
    { dataField: 'account_name', text: 'Account Name', sort: true, sortCaret: sortOrderTable },
    { dataField: 'created_on', text: 'Submitted date', sort: true, sortCaret: sortOrderTable, formatter: DateFormatter },
    { dataField: 'status', text: 'Status', formatter: stateFormatter }
  ];

  //REMOTE TABLE CHANGE
  const handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    let result = requestData;
    let order;
    //sort
    if (sortOrder === 'asc') {
      order = data.sort((a, b) => {
        if (a[sortField] > b[sortField]) {
          return 1;
        } else if (b[sortField] > a[sortField]) {
          return -1;
        }
        return 0;
      });
    } else {
      order = data.sort((a, b) => {
        if (a[sortField] > b[sortField]) {
          return -1;
        } else if (b[sortField] > a[sortField]) {
          return 1;
        }
        return 0;
      });
    }
    setSortName(sortField);
    setSortOrder(order);

    //pagination
    const currentIndex = (page - 1) * sizePerPage;
    setPage(page);
    setData(result.slice(currentIndex, currentIndex + sizePerPage));
    setSizePerPage(sizePerPage);
    fetchServiceRequest(page, sizePerPage, inputVal, sortField, sortOrder);


  }

  async function fetchServiceRequest(page, sizePerPage, inputVal, sortName, sortOrder) {

    let reqData = {
      SRQInput: {
        status: "Released"
      },
      DataFinder: {
        pagesize: sizePerPage,
        currentpage: page,
        sortbycolumn: sortName || "",
        sortby: sortOrder || "",
        searchdata: inputVal || ""
      }
    }
    svc.GetServiceRequest(reqData).then((response) => {
      if (isMounted) {
        if (response.data) {
          if (response.data.ServiceRequestDetail !== [] && response.data.DataFinder.totalrecords !== 0) {
            setTdataLoad(true);
            setRequestData(response.data.ServiceRequestDetail);
            setTotalDataSize(response.data.DataFinder.totalrecords);
            setData(response.data.ServiceRequestDetail.slice(0, sizePerPage));
          } else {
            setTdataLoad(false);
            setData([]);
          }
        }

      }
    })
      .catch(function (error) {
        if (error.response.status === 403) {
          history.push('/login');
        }
      })
  }
  useEffect(() => {
    fetchServiceRequest(page, sizePerPage, inputVal, sortName, sortOrder);
    return () => { setIsMounted(false) }
  }, []);



  const handleSearch = (e) => {
    if (e.key === "Enter") {
      setInputVal(e.target.value);
      fetchServiceRequest(page, sizePerPage, e.target.value);
    }
  }
  const resetSearch = (e) => {
    if (e.key === "Backspace" && !e.target.value) {
      setInputVal("");
      fetchServiceRequest(page, sizePerPage, e.target.value);
    }
  }
  //checking toaster
  // var [toast, setToast] = useState(false);
  // const toastRef = useRef();

  // useEffect(() => {
  //   var myToast = toastRef.current
  //   var bsToast = bootstrap.Toast.getInstance(myToast)

  //   if (!bsToast) {
  //     // initialize Toast
  //     bsToast = new Toast(myToast, { autohide: false })
  //     // hide after init
  //     bsToast.hide()
  //     setToast(false)
  //   }
  //   else {
  //     // toggle
  //     toast ? bsToast.show() : bsToast.hide()

  //   }
  // });
  return (
    <div className='col new-request'>
      <div className='row g-0 ps-3 pt-3'>
        <div className='table-request my-2 me-3'>
          <div className='table-data me-4'>
            <div className='pe-3'>
              <div className='row g-0 mb-2'>
                <div className='col text-start ms-1'>
                  <h5>Released Request</h5>
                </div>
                <div className='col text-end'>
                  <i className="bi bi-search ms-1 ps-2 mb-2 " id='search-icon'></i>
                  <input
                    onKeyDown={(e) => handleSearch(e)}
                    onKeyUp={(e) => resetSearch(e)}
                    className='py-1 ps-3' placeholder="Search Request" />
                </div>
              </div>
              <RemoteAll
                data={data}
                page={page}
                columns={columns}
                sizePerPage={sizePerPage}
                totalSize={totalDataSize}
                rowEvents={rowEvents}
                noDataIndication={() => { return (tdataLoad ? <NoDataIndication /> : "No data available") }}
                onTableChange={handleTableChange}
              />
            </div>
          </div>
        </div>

      </div>

    </div>
  );
}
export default ReleasedRequest;