import React, { useState } from 'react';
import {  useHistory } from 'react-router-dom';
import { useForm } from "react-hook-form";
import AdminAccountService from '../../../services/AdminAccount';
import InputMask from "react-input-mask";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function CreateAccountModal(props) {
    const [isSuccess, setIsSuccess] = useState(false);
    const [errMsg, setErrMsg] = useState([]);
    const history = useHistory();
    const [dataLoading,setDataLoading] = useState(false);
    const { register, handleSubmit,reset, setValue, formState: { errors } } = useForm();
    const accs = new AdminAccountService();
    const accountnotify = () => toast.success("Account created successfully!");
    const accountnotifyErr = () => toast.error("Account couldn't be created!");


    const msgDisplay = (val) => {
        props.data.messageCallback(val); 
    }

    const onSubmit = (data) => {
        setDataLoading(true);
        let reqData = {
            "AccountID": 0,
            "account_name": data.accountname,
            "first_name": data.fname,
            "last_name": data.lname,
            "contact_phone": data.phone,
            "contact_email": data.email,
            "contact_fax": data.fax,
            "contact_address": data.address,
            "state": data.state,
            "city": data.city,
            "zip": data.zip,
            "status": "Active"
        }
        accs.CreateAccount(reqData)
            .then((response) => {
                if (response.data) {
                    if (response.data.status === 'success') {
                        setDataLoading(false);
                        hideModal('closeActModal');
                        msgDisplay(true);  
                        setIsSuccess(false);
                        accountnotify();
                        
                        let page = 1;
                        let sizePerPage = 10;
                        props.data.getAccountDetails(page, sizePerPage);
                        handleClear(); 
                    }
                }
            })
            .catch(function (error) {
                setIsSuccess(true);
                setDataLoading(false);
                
                setErrMsg(error.response.data);
            })
    }

    function hideModal(target) {
        document.getElementById(target).click();
      };
    function handleClear(){
        reset();
        reset({phone:""});
    }
    return (
        <div className="modal " id="accountModal" data-bs-backdrop="false" tabIndex="-1">
            <div className="modal-dialog shadow-lg" style={{ maxWidth: '800px' }}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Create Account</h4>
                        <button type="button" className="btn-close"
                        onClick={()=>handleClear()} data-bs-dismiss="modal"></button>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)} >
                        <div className="modal-body p-4">
                            <div className='row text-start'>
                                <div className="col mb-3">
                                    <label htmlFor="accountname" className="form-label">Account Name
                                        <span className='required-field'>*</span></label>
                                    <input type="text" className={`form-control p-2 ${errors.accountname ? "is-invalid" : ""}`} {...register('accountname', { validate: (value) => { return !!value.trim()}, required: true })} />
                                    {(errors.accountname && errors.accountname.type === 'required') ? <div className="invalid-feedback">This field is required</div> : ''}
                                </div>
                                <div className="col mb-3">
                                    <label htmlFor="fname" className="form-label">First Name
                                        <span className='required-field'>*</span></label>
                                    <input type="text" className={`form-control p-2 ${errors.fname ? "is-invalid" : ""}`} {...register('fname', {  validate: (value) => { return !!value.trim()},required: true })} />
                                    {(errors.fname && errors.fname.type === 'required') ? <div className="invalid-feedback">This field is required</div> : ''}
                                </div>
                                <div className="col mb-3">
                                    <label htmlFor="lname" className="form-label">Last Name</label>
                                    <input type="text" className="form-control" {...register('lname', { required: false })} />
                                </div>
                            </div>
                            <div className='row text-start'>
                                <div className="col mb-3">
                                    <label htmlFor="phone" className="form-label">Phone
                                        <span className='required-field'>*</span></label>
                                    <InputMask className={`form-control p-2 ${errors.phone ? "is-invalid" : ""
                                        }`}
                                        {...register("phone", { validate: (value) => { return !!value.trim()}, required: true })}
                                        mask="(999) 999-9999" maskChar="" />
                                    {(errors.phone && errors.phone.type === 'required') ? <div className="invalid-feedback">This field is required</div> : ''}

                                </div>
                                <div className="col mb-3">
                                    <label htmlFor="email" className="form-label">Email  <span className='required-field'>*</span></label>
                                    <input type="text" className={`form-control p-2 ${errors.email ? "is-invalid" : ""}`} {...register('email', { validate: (value) => { return !!value.trim()}, required: true }, { pattern: /^\S+@\S+$/i })} />
                                    {(errors.email && errors.email.type === 'required') ? <div className="invalid-feedback">This field is required</div> : ''}
                                    {(errors.email && errors.email.type === 'pattern') ? <div className="invalid-feedback">Enter a valid email id</div> : ''}
                                  
                                </div>
                                <div className="col mb-3">
                                    <label htmlFor="fax" className="form-label">Fax</label>
                                    <input type="number" className="form-control" {...register('fax', { required: false })} />
                                </div>
                            </div>
                            <div className='row text-start'>
                                <div className="col mb-3">
                                    <label htmlFor="address" className="form-label">Address <span className='required-field'>*</span></label>
                                    <input type="text" className={`form-control p-2 ${errors.address ? "is-invalid" : ""}`} {...register('address', { validate: (value) => { return !!value.trim()}, required: true })} />
                                    {(errors.address && errors.address.type === 'required') ? <div className="invalid-feedback">This field is required</div> : ''}
                                </div>
                                <div className="col mb-3">
                                    <label htmlFor="city" className="form-label">City</label>
                                    <input type="text" className="form-control" {...register('city', { required: false })} />

                                </div>
                                <div className="col mb-3">
                                    <label htmlFor="state" className="form-label">State</label>
                                    <input type="text" className="form-control" {...register('state', { required: false })} />
                                </div>
                            </div>
                            <div className='row text-start' style={{ width: '34.5%' }}>
                                <div className="col mb-3">
                                    <label htmlFor="zip" className="form-label">Zip <span className='required-field'>*</span></label>
                                    <input type="number" className={`form-control p-2 ${errors.zip ? "is-invalid" : ""}`} {...register('zip', {  validate: (value) => { return !!value.trim()},required: true })} />
                                    {(errors.zip && errors.zip.type === 'required') ? <div className="invalid-feedback">This field is required</div> : ''}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer me-1">
                            <button type="button" id="closeActModal" onClick={()=>handleClear()}  className="btn btn-cancel text-white px-2 " data-bs-dismiss="modal">Cancel</button>
                            <button type="submit" disabled={dataLoading?true:false} className="btn btn-save text-white px-2">Submit </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default CreateAccountModal;