import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import PipelineRequestService from '../services/PipelineRequest';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../assets/styles/edfconfig/edfConfig.scss';
import { Nav, Tab, Row, Col, Tabs } from "react-bootstrap";
import '../assets/styles/edfconfig.scss';
import { Multiselect } from "multiselect-react-dropdown";
import Select from 'react-select';

export default function EdfConfig() {
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [dataLoading, setDataLoading] = useState(false);
  const [configID, setConfigID] = useState(0);
  const [configName, setConfigName] = useState(null);
  const [fieldtype, setFieldtype] = useState(null);
  const [fieldValue, setFieldValue] = useState(null);
  const [stepId, setStepId] = useState(null);
  const [d, setD] = useState(null);
  const [processing, setProcessing] = useState(false);
  const ms = new PipelineRequestService();
  const reqnotify = () => toast.success("Saved successfully!");
  const reqnotifyErr = (val) => toast.error(val);

  const optionList = [
    // { value: "A2-A1", label: "A2-A1" },
    { value: "F1", label: "F1" },
    { value: "Fp1", label: "Fp1" },
    { value: "Fp2", label: "Fp2" },
    { value: "F7", label: "F7" },
    { value: "F3", label: "F3" },
    { value: "Fz", label: "Fz" },
    { value: "F4", label: "F4" },
    { value: "F8", label: "F8" },
    { value: "T3", label: "T3" },
    { value: "C3", label: "C3" },
    { value: "Cz", label: "Cz" },
    { value: "C4", label: "C4" },
    { value: "T4", label: "T4" },
    { value: "T5", label: "T5" },
    { value: "P3", label: "P3" },
    { value: "Pz", label: "Pz" },
    { value: "P4", label: "P4" },
    { value: "T6", label: "T6" },
    { value: "O1", label: "O1" },
    { value: "O2", label: "O2" },
  ];

  
  const option = [
    { value: "fastica", label: "Fastica" },
    { value: "infomax", label: "Infomax" },
    // { value: "Runica", label: "Runica" },
    // { value: "Binica", label: "Binica" },
    // { value: "Jader", label: "Jader" },
    // { value: "Sobi", label: "Sobi" },
    // { value: "acsobiro", label: "acsobiro" },
  ];

  function clickReference(e){
    console.log("value1",e.target.value);
    if (e.target.value == "reference") {
      setProcessing(true);

  }
  else if (e.target.value == "average"){
    setProcessing(false);
  } 
  }



  function clearForm() {
    reset();
    setDataLoading(false);
  }
  function hideModal(target) {
    document.getElementById(target).click();
    reset();
  };

  function FieldValue() {
   setFieldValue()
  }

  
  const onSubmit = (data) => {
    let inputdata = {
      // "edfconfig_id": configID,
      // "config_jobname": "",
      // "configfieldtype": "text",
      // "bad_channel": "",
      // "low_band_pass": data.lowband || d.low_band_pass,
      // "high_band_pass": data.highband || d.high_band_pass

      "edfconfig_id":configID,
      "stepid": stepId,
      "config_jobname":configName,
      "configfieldtype":fieldtype,
      "field_value":fieldValue
    }
    ms.saveEdfConfig(inputdata).then((res) => {
      if (res.data) {
        reqnotify();
        // getConfig();
        getEdfJobConfig();
        // FieldValue();
      }
    }).catch(function (err) {
      reqnotifyErr();
    })
  }

  function getEdfJobConfig() {
    ms.EdfJobConfig().then((res) => {
      if (res.data) {
        // console.log("dataaaassss", res.data.data);
        // console.log("evng", res.data.data[2].config_data[0].configfieldtype);
        setConfigID(res.data.data[0].id);
        setD(res.data.data[0].config_data[0]);
        setConfigName(res.data.data[0].config_data[0].config_jobname);
        setStepId(res.data.data[0].config_data[0].stepid);
        setFieldtype(res.data.data[0].config_data[0].configfieldtype);
        setFieldValue(res.data.data[0].config_data[0].field_value);
      }
    })
  }
  useEffect(() => {
    getEdfJobConfig();
  }, [])
  
  // function getConfig() {
  //   ms.getEdfConfig(1).then((res) => {
  //     if (res.data) {
  //       // console.log("data", res.data.data[0]);
  //       setConfigID(res.data.data[0].id);
  //       setD(res.data.data[0]);
  //     }
  //   })
  // }
  // useEffect(() => {
  //   getConfig();
  // }, [])

  return (
    <>
      {/* TAB CONTENT STARTS HERE */}
      <div className="config row pt-3 pe-4 my-3 mx-2">

        <Tabs
          defaultActiveKey="line-noise-removal"
          transition={false}
          className=""
        >
          <Tab eventKey="form" title="General" >
            <div className='bg-white ps-2 py-4 text-start' style={{ height: '400px' }}>
              This page is under development
            </div>
          </Tab>
          <Tab eventKey="line-noise-removal" title="EDF Settings">
            <Tab.Container id='left-tabs' defaultActiveKey="linenoiseremoval">
              <Row className="p-3">
                <Col md='2' className=" p-0 text-start rounded " style={{ background: '#f9f9f9', color: "#000" }}>
                  <Nav variant="pills" className="flex-column mt-1" >
                    {/* <Nav.Item>
                  <Nav.Link eventKey="patient-demography">Patient Demography</Nav.Link>
                </Nav.Item> */}
                    <Nav.Item>
                      <Nav.Link eventKey="edfsetting" className="text-dark">
                        
                        Edf Common Settings</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="linenoiseremoval" className="text-dark">
                       
                        Line Noise Removal</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="badchannel" className="text-dark">
                       
                        Bad channel Rejection</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="re-referencing" className="text-dark">
                     
                        Re-referencing</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="ica" className="text-dark">
                      
                        ICA</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="interpolation" className="text-dark">
                       
                        Bad Channel Interpolation</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="badtimesegment" className="text-dark">
                        
                        Bad time segments removal</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="dataepoching" className="text-dark">
                       
                        Data epoching</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="powerspectrum" className="text-dark">
                      
                        Power Spectrum</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="alphapeak" className="text-dark">
                        
                        Alpha Peak Frequency</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="powertopographies" className="text-dark">
                       
                        Power topographies</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="phasebased" className="text-dark">
                       
                        Phase-based connectivity Amplitude-based connectivity</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col className="p-0" >
                  <Tab.Content>
                    <Tab.Pane eventKey="edfsetting">
                     
                      <div className='bg-white ps-2 py-4 text-start' style={{ height: '400px' }}>
                         
                      {/* <div className="my-3 ms-2 me-0 h-100"> */}
                        {/* <div className=" p-2 rounded bg-white text-start h-50"> */}
                          <div className='d-flex'>
                            <h5 className='col-auto'>EDF Setting</h5>
                            {/* <AiFillPlusCircle className="col-auto ms-auto " color="#5754a3" size={24} data-bs-toggle="modal" data-bs-target="#linenoise" /> */}
                          </div>
                          <form >
                            <div className='form-control   p-4 gray-bg border-0'>
                             

                              <div className='row mt-2'>
                                <div className="col-md-3 me-5">
                                  <div className="row">
                                    <label className='mb-2 ps-0'>Console Run Duration</label>
                                    <input type="number" className={`form-control p-2 `} 
                                      maxLength="100" />
                                  </div>
                                </div>
                                {/* <div className="col-md-4">
                                  <div className='row'>
                                    <label className='mb-2 ps-0'> Duration Time</label>
                                    <input type="number"  className={`form-control p-2`}  
                                      maxLength="100" />
                                  </div>
                                </div> */}
                              </div>

                              <div className='row mt-2'>
                              <h6 className='mb-2 ps-0'>Graph Settings: </h6>
                              <div className="col-md-3 me-5">
                                  <div className='row'>
                                    <label className='mb-2 ps-0'>Duration Time</label>
                                    <input type="number"  className={`form-control p-2`}  
                                      maxLength="100" />
                                  </div>
                                </div>
                                <div className="col-md-3 ">
                                  <div className='row'>
                                    <label className='mb-2 ps-0'>Start Time</label>
                                    <input type="number"  className={`form-control p-2`}  
                                      maxLength="100" />
                                  </div>
                                </div>
                                <div className="col-md-3 ms-5">
                                  <div className='row'>
                                    <label className='mb-2 ps-0'>End Time</label>
                                    <input type="number"  className={`form-control p-2`}  
                                      maxLength="100" />
                                  </div>
                                </div>
                              </div>

                              <div className='row'>
                                <div className="col-md-auto mt-3">
                                  <div className="row">
                                    <button type="submit" className='btn btn-saveInt text-white px-2' >Save</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            
                          </form>
                        </div>
                      {/* </div> */}

                      {/* </div> */}
                    </Tab.Pane>
                    <Tab.Pane eventKey="linenoiseremoval">
                      {/* <DiagnosisTab data={{ getDiagnosis: getDiagnosis }} /> */}
                      {/* <div className="my-3 ms-2 me-0 h-100"> */}
                      <div className='bg-white ps-2 py-4 text-start' style={{ height: '400px' }}>
                        {/* <div className=" p-2 rounded bg-white text-start h-50"> */}
                          <div className='d-flex'>
                            <h5 className='col-auto'>Line Noise Removal</h5>
                            {/* <AiFillPlusCircle className="col-auto ms-auto " color="#5754a3" size={24} data-bs-toggle="modal" data-bs-target="#linenoise" /> */}
                          </div>
                          <form onSubmit={handleSubmit(onSubmit)} >
                            <div className='form-control   p-4 gray-bg border-0'>
                              <div className='row'>
                                <div className="col-md-4 me-5">
                                  <div className="row">
                                    <label className='mb-2 ps-0'>Low pass Bandwidth</label>
                                    <input type="text"
                                     defaultValue={d !== null ? d.low_band_pass : ""} 
                                     className={`form-control p-2 `} {...register('lowband', { required: false }, { validate: (value) => { return !!value.trim() } })}
                                      maxLength="100" />
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className='row'>
                                    <label className='mb-2 ps-0'>High pass Bandwidth</label>
                                    <input type="text"
                                     defaultValue={d !== null ? d.high_band_pass : ""} 
                                      className={`form-control p-2`} {...register('highband', { required: false }, { validate: (value) => { return !!value.trim() } })}
                                      maxLength="100" />
                                  </div>
                                </div>
                              </div>

                              <div className='row mt-2'>
                                <div className="col-md-4 me-5">
                                  <div className="row">
                                    <label className='mb-2 ps-0'>Start Time</label>
                                    <input type="text" className={`form-control p-2 `} 
                                      maxLength="100" />
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className='row'>
                                    <label className='mb-2 ps-0'>Duration</label>
                                    <input type="text"  className={`form-control p-2`}  
                                      maxLength="100" />
                                  </div>
                                </div>
                              </div>

                              <div className='row'>
                                <div className="col-md-auto mt-3">
                                  <div className="row">
                                    <button type="submit" className='btn btn-saveInt text-white px-2' >Save</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            
                          </form>
                        </div>
                      {/* </div> */}
                    </Tab.Pane>
                    <Tab.Pane eventKey="badchannel">
                    <div className='bg-white ps-2 py-4 text-start' style={{ height: '400px' }}>
                        <h5 className='col-auto'>Bad Channel Rejection</h5>
                        <form onSubmit={handleSubmit(onSubmit)} >
                          <div className='form-control   p-4 gray-bg border-0'>
                            <div className='row'>
                              <div className="col-md-4 me-5 ps-0">
                                <div className="row">
                                  <label className='mb-2 '>Bad Channel</label>
                                  <div className='row'>
                                    <div className="col-md-3 form-check text-start" >
                                      <input className="form-check-input ms-1" type="checkbox" />
                                      <label className="form-check-label ms-1" >
                                        F1
                                      </label>
                                    </div>
                                    <div className="col-md-3 form-check text-start" >
                                      <input className="form-check-input ms-1" type="checkbox" />
                                      <label className="form-check-label ms-1"  >
                                        Fp1
                                      </label>
                                    </div>
                                    <div className="col-md-3 form-check text-start" >
                                      <input className="form-check-input ms-1" type="checkbox" />
                                      <label className="form-check-label ms-1"  >
                                        Fp2
                                      </label>
                                    </div>
                                    <div className="col-md-3 form-check text-start" >
                                      <input className="form-check-input ms-1" type="checkbox" />
                                      <label className="form-check-label ms-1"  >
                                        F7
                                      </label>
                                    </div>
                                  </div>


                                  <div className='row'>
                                    <div className="col-md-3 form-check text-start" >
                                      <input className="form-check-input ms-1" type="checkbox" />
                                      <label className="form-check-label ms-1" >
                                        F3
                                      </label>
                                    </div>
                                    <div className="col-md-3 form-check text-start" >
                                      <input className="form-check-input ms-1" type="checkbox" />
                                      <label className="form-check-label ms-1"  >
                                        Fz
                                      </label>
                                    </div>
                                    <div className="col-md-3 form-check text-start" >
                                      <input className="form-check-input ms-1" type="checkbox" />
                                      <label className="form-check-label ms-1"  >
                                        F4
                                      </label>
                                    </div>
                                    <div className="col-md-3 form-check text-start" >
                                      <input className="form-check-input ms-1" type="checkbox" />
                                      <label className="form-check-label ms-1"  >
                                        F8
                                      </label>
                                    </div>
                                  </div>


                                  <div className='row'>
                                    <div className="col-md-3 form-check text-start" >
                                      <input className="form-check-input ms-1" type="checkbox" />
                                      <label className="form-check-label ms-1" >
                                        T3
                                      </label>
                                    </div>
                                    <div className="col-md-3 form-check text-start" >
                                      <input className="form-check-input ms-1" type="checkbox" />
                                      <label className="form-check-label ms-1"  >
                                        C3
                                      </label>
                                    </div>
                                    <div className="col-md-3 form-check text-start" >
                                      <input className="form-check-input ms-1" type="checkbox" />
                                      <label className="form-check-label ms-1"  >
                                        Cz
                                      </label>
                                    </div>
                                    <div className="col-md-3 form-check text-start" >
                                      <input className="form-check-input ms-1" type="checkbox" />
                                      <label className="form-check-label ms-1"  >
                                        C4
                                      </label>
                                    </div>
                                  </div>


                                  <div className='row'>
                                    <div className="col-md-3 form-check text-start" >
                                      <input className="form-check-input ms-1" type="checkbox" />
                                      <label className="form-check-label ms-1" >
                                        T4
                                      </label>
                                    </div>
                                    <div className="col-md-3 form-check text-start" >
                                      <input className="form-check-input ms-1" type="checkbox" />
                                      <label className="form-check-label ms-1"  >
                                        T5
                                      </label>
                                    </div>
                                    <div className="col-md-3 form-check text-start" >
                                      <input className="form-check-input ms-1" type="checkbox" />
                                      <label className="form-check-label ms-1"  >
                                        P3
                                      </label>
                                    </div>
                                    <div className="col-md-3 form-check text-start" >
                                      <input className="form-check-input ms-1" type="checkbox" />
                                      <label className="form-check-label ms-1"  >
                                        Pz
                                      </label>
                                    </div>
                                  </div>

                                  <div className='row'>
                                    <div className="col-md-3 form-check text-start" >
                                      <input className="form-check-input ms-1" type="checkbox" />
                                      <label className="form-check-label ms-1" >
                                        P4
                                      </label>
                                    </div>
                                    <div className="col-md-3 form-check text-start" >
                                      <input className="form-check-input ms-1" type="checkbox" />
                                      <label className="form-check-label ms-1"  >
                                        T6
                                      </label>
                                    </div>
                                    <div className="col-md-3 form-check text-start" >
                                      <input className="form-check-input ms-1" type="checkbox" />
                                      <label className="form-check-label ms-1"  >
                                        O1
                                      </label>
                                    </div>
                                    <div className="col-md-3 form-check text-start" >
                                      <input className="form-check-input ms-1" type="checkbox" />
                                      <label className="form-check-label ms-1"  >
                                        O2
                                      </label>
                                    </div>
                                  </div>

                                </div>
                              </div>

                            </div>

                            <div className='row'>
                              <div className="col-md-auto mt-3">
                                <div className="row">
                                  <button type="submit" className='btn btn-saveInt text-white px-2' >Save</button>
                                </div>
                              </div>
                            </div>
                          </div>

                        </form>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="re-referencing">
                      <div className='bg-white ps-2 py-4 text-start' style={{ height: '400px' }}>
                      <h5 className='col-auto'>Re-Referencing</h5>
                      <form  >
                            <div className='   p-4 gray-bg border-0'>
                              <div className='row'>
                                <div className="col-md-6 me-5 ps-0">
                                  <div className="row">
                                    <label className='mb-2 '>Re-Refrence</label>
                                    {/* <div className="form-check text-start" >
                                            <input className="form-check-input ms-1" type="radio" id="radio1" />
                                            <label className="form-check-label"for="radio1" >
                                               Compute Average Reference
                                            </label>
                                        </div>
                                        <div className="form-check text-start" >
                                            <input className="form-check-input ms-1" type="radio" id="radio2" />
                                            <label className="form-check-label" for="radio2" >
                                               Re-Reference data to channel(s)
                                            </label>
                                        </div> */}

                                  <div className="form-check text-start">
                                    <input className="form-check-input ms-1" type="radio" name="flexRadioDefault" value="average" id="flexRadioDefault1" onChange={(e) => clickReference(e)}/>
                                    <label className="form-check-label ms-1" for="flexRadioDefault1">
                                      Compute Average Reference
                                    </label>
                                  </div>
                                  <div className='row'>
                                  <div className="col-md-6 form-check text-start"> 
                                    <input className="form-check-input ms-1" type="radio"  name="flexRadioDefault" value="reference" id="flexRadioDefault2" onChange={(e) => clickReference(e)}/>
                                    <label className="form-check-label ms-1" for="flexRadioDefault2" >
                                      Re-Reference data to channel(s)
                                    </label>
                                    </div>
                                    
                                    <div className='col'>
                                    {processing == false ? null:<>
                                  <Multiselect
                                  className='ps-0 bg-white'
                                    // selectedValues={selectedSymptoms}
                                    options={optionList}
                                    // defaultValue="A2-A1"
                                    displayValue="value"
                                    closeIcon="cancel"
                                  // onSelect={handleSymptomsChange}
                                  /></> }
                                  </div>
                                  </div>
                                    
                                  
                                 
                                  </div>
                                </div>
                                
                              </div>

                              <div className='row'>
                                <div className="col-md-auto mt-3">
                                  <div className="row">
                                    <button type="submit" className='btn btn-saveInt text-white px-2' >Save</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            
                          </form>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="ica">
                    <div className='bg-white ps-2 py-4 text-start' style={{ height: '400px' }}>
                      <h5 className='col-auto'>ICA Algorithm</h5>
                        <form  >
                       
                            <div className='form-control   p-4 gray-bg border-0'>
                              <div className='row'>
                                <div className="col-md-4 me-5 ">
                                  <div className="row">
                                    <label className='mb-2 ps-0'>ICA Algorithm List</label>
                                  <Select
                                  className='ps-0'
                                    // selectedValues={selectedSymptoms}
                                    options={option}
                                    // defaultValue="A2-A1"
                                    defaultValue={d !== null ? d.field_value : ""}
                                    displayValue="value"
                                    closeIcon="cancel"
                                  // onSelect={handleSymptomsChange}
                                  />
                                  </div>
                                </div>                             
                              </div>
                              <div className='row'>
                                <div className="col-md-auto mt-3">
                                  <div className="row">
                                    <button type="submit" className='btn btn-saveInt text-white px-2' >Save</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            
                          </form>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="interpolation">
                    <div className='bg-white ps-2 py-4 text-start' style={{ height: '400px' }}>
                        <h5 className='col-auto'>Bad Channel Interpolation</h5>
                        <form  >
                          <div className='form-control   p-4 gray-bg border-0'>
                            <div className='row'>
                              <div className="col-md-4 me-5 ps-0">
                                <div className="row">
                                  <label className='mb-2 '>Bad Channel</label>
                                  <div className='row'>
                                    <div className="col-md-3 form-check text-start" >
                                      <input className="form-check-input ms-1" type="checkbox" />
                                      <label className="form-check-label ms-1" >
                                        F1
                                      </label>
                                    </div>
                                    <div className="col-md-3 form-check text-start" >
                                      <input className="form-check-input ms-1" type="checkbox" />
                                      <label className="form-check-label ms-1"  >
                                        Fp1
                                      </label>
                                    </div>
                                    <div className="col-md-3 form-check text-start" >
                                      <input className="form-check-input ms-1" type="checkbox" />
                                      <label className="form-check-label ms-1"  >
                                        Fp2
                                      </label>
                                    </div>
                                    <div className="col-md-3 form-check text-start" >
                                      <input className="form-check-input ms-1" type="checkbox" />
                                      <label className="form-check-label ms-1"  >
                                        F7
                                      </label>
                                    </div>
                                  </div>


                                  <div className='row'>
                                    <div className="col-md-3 form-check text-start" >
                                      <input className="form-check-input ms-1" type="checkbox" />
                                      <label className="form-check-label ms-1" >
                                        F3
                                      </label>
                                    </div>
                                    <div className="col-md-3 form-check text-start" >
                                      <input className="form-check-input ms-1" type="checkbox" />
                                      <label className="form-check-label ms-1"  >
                                        Fz
                                      </label>
                                    </div>
                                    <div className="col-md-3 form-check text-start" >
                                      <input className="form-check-input ms-1" type="checkbox" />
                                      <label className="form-check-label ms-1"  >
                                        F4
                                      </label>
                                    </div>
                                    <div className="col-md-3 form-check text-start" >
                                      <input className="form-check-input ms-1" type="checkbox" />
                                      <label className="form-check-label ms-1"  >
                                        F8
                                      </label>
                                    </div>
                                  </div>


                                  <div className='row'>
                                    <div className="col-md-3 form-check text-start" >
                                      <input className="form-check-input ms-1" type="checkbox" />
                                      <label className="form-check-label ms-1" >
                                        T3
                                      </label>
                                    </div>
                                    <div className="col-md-3 form-check text-start" >
                                      <input className="form-check-input ms-1" type="checkbox" />
                                      <label className="form-check-label ms-1"  >
                                        C3
                                      </label>
                                    </div>
                                    <div className="col-md-3 form-check text-start" >
                                      <input className="form-check-input ms-1" type="checkbox" />
                                      <label className="form-check-label ms-1"  >
                                        Cz
                                      </label>
                                    </div>
                                    <div className="col-md-3 form-check text-start" >
                                      <input className="form-check-input ms-1" type="checkbox" />
                                      <label className="form-check-label ms-1"  >
                                        C4
                                      </label>
                                    </div>
                                  </div>


                                  <div className='row'>
                                    <div className="col-md-3 form-check text-start" >
                                      <input className="form-check-input ms-1" type="checkbox" />
                                      <label className="form-check-label ms-1" >
                                        T4
                                      </label>
                                    </div>
                                    <div className="col-md-3 form-check text-start" >
                                      <input className="form-check-input ms-1" type="checkbox" />
                                      <label className="form-check-label ms-1"  >
                                        T5
                                      </label>
                                    </div>
                                    <div className="col-md-3 form-check text-start" >
                                      <input className="form-check-input ms-1" type="checkbox" />
                                      <label className="form-check-label ms-1"  >
                                        P3
                                      </label>
                                    </div>
                                    <div className="col-md-3 form-check text-start" >
                                      <input className="form-check-input ms-1" type="checkbox" />
                                      <label className="form-check-label ms-1"  >
                                        Pz
                                      </label>
                                    </div>
                                  </div>

                                  <div className='row'>
                                    <div className="col-md-3 form-check text-start" >
                                      <input className="form-check-input ms-1" type="checkbox" />
                                      <label className="form-check-label ms-1" >
                                        P4
                                      </label>
                                    </div>
                                    <div className="col-md-3 form-check text-start" >
                                      <input className="form-check-input ms-1" type="checkbox" />
                                      <label className="form-check-label ms-1"  >
                                        T6
                                      </label>
                                    </div>
                                    <div className="col-md-3 form-check text-start" >
                                      <input className="form-check-input ms-1" type="checkbox" />
                                      <label className="form-check-label ms-1"  >
                                        O1
                                      </label>
                                    </div>
                                    <div className="col-md-3 form-check text-start" >
                                      <input className="form-check-input ms-1" type="checkbox" />
                                      <label className="form-check-label ms-1"  >
                                        O2
                                      </label>
                                    </div>
                                  </div>

                                </div>
                              </div>

                            </div>

                            <div className='row'>
                              <div className="col-md-auto mt-3">
                                <div className="row">
                                  <button type="submit" className='btn btn-saveInt text-white px-2' >Save</button>
                                </div>
                              </div>
                            </div>
                          </div>

                        </form>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="badtimesegment">
                      <div className='bg-white ps-2 py-4 text-start' style={{ height: '400px' }}>
                        This page is under development
                      </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey="dataepoching">
                      <div className='bg-white ps-2 py-4 text-start' style={{ height: '400px' }}>
                        This page is under development
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="powerspectrum">
                      <div className='bg-white ps-2 py-4 text-start' style={{ height: '400px' }}>
                        This page is under development
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="alphapeak">
                      <div className='bg-white ps-2 py-4 text-start' style={{ height: '400px' }}>
                        This page is under development
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="powertopographies">
                      <div className='bg-white ps-2 py-4 text-start' style={{ height: '400px' }}>
                        This page is under development
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="phasebased">
                      <div className='bg-white ps-2 py-4 text-start' style={{ height: '400px' }}>
                        This page is under development
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Tab>

          {/* <Tab eventKey="form1" title="Form1" >


          </Tab> */}
        </Tabs>
      </div>
    </>
  )
}